import { createSlice } from '@reduxjs/toolkit'

export const assetPopoverSlice = createSlice({
  name: 'assetPopover',
  initialState: {
    isOpen: false
  },
  reducers: {
    opened: (state) => {
      state.isOpen = true
    },
    closed: (state, action) => {
      state.isOpen = false
    }
  }
})

export const { opened, closed } = assetPopoverSlice.actions
