import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import Drawer from '@material-ui/core/Drawer'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import DrawerHeader from './DrawerHeader'
import DrawerFooter from './DrawerFooter'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import { getRoutesForUser } from '../../helpers/GenericHelpers.js'
import Divider from '@material-ui/core/Divider'
import { isLoggedIn } from '../../utils/Authentication.js'
import * as CONSTANTS from '../../utils/url_constant.js'
import { LayoutContext } from 'mui-layout'

const drawerWidth = 301
const useStyles = theme => ({
  drawer: {
    width: drawerWidth
  },
  drawerPaper: {
    width: drawerWidth,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
  paperAnchorDockedLeft: {
    borderRight: 'none'
  },
  mainMenuList: {
    outline: 0,
    flex: 1
  },
  menuItemLink: {
    color: '#000000',
    textDecorationLine: 'none'
  },
  activeListItemText: {
    fontWeight: 500
  },
  icon: {
    color: '#000'
  },
  activeIcon: {
    color: '#0d548c'
  }
})

class Sidebar extends Component {
  static contextType = LayoutContext
  constructor (props) {
    super(props)
    if (!isLoggedIn()) {
      this.props.history.push(CONSTANTS.URLPATH_LOGIN)
    }
    this.state = {
      drawerStatus: false,
      currentPage: (this.props.history.location.pathname) ? this.props.history.location.pathname : '/'
    }
  }

  onItemClickHandler = event => {
    this.context.setOpened(false)
    const path = event.currentTarget.getAttribute('data-key')
    this.setState({ drawerStatus: false })
    this.setState({ currentPage: path })
    if (path !== CONSTANTS.URLPATH_FEEDBACK) {
      this.props.history.push(path)
    } else {
      this.props.history.replace(path)
    }
  }

  render () {
    const routes = getRoutesForUser()
    const drawerItems = (
      <MenuList className={this.props.classes.mainMenuList}>
        {routes.map((prop, key) => {
          const currentRoutePage = this.props.history.location.pathname
          return (
            <div key={key} className={this.props.classes.menuItemLink}>
              <MenuItem
                data-cy={prop.path}
                className={currentRoutePage === prop.path ? this.props.classes.activeItem : ''}
                data-key={prop.path}
                onClick={this.onItemClickHandler.bind(this)}
              >
                <ListItemIcon
                  className={currentRoutePage === prop.path ? this.props.classes.activeIcon : this.props.classes.icon}
                >
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: currentRoutePage === prop.path ? this.props.classes.activeListItemText : '' }}
                  primary={prop.sidebarName}
                />
              </MenuItem>
              <Divider />
            </div>
          )
        })}
      </MenuList>
    )
    return (
      <Drawer
        className={this.props.classes.drawer}
        open={this.state.drawerStatus}
        variant='permanent'
        classes={{
          paper: this.props.classes.drawerPaper,
          paperAnchorDockedLeft: this.props.classes.paperAnchorDockedLeft
        }}
      >
        <div className={this.props.classes.drawerHeader}>
          <DrawerHeader />
        </div>
        {drawerItems}
        <div>
          <DrawerFooter sidebarProps={this.props} sidebarHandler={this.onItemClickHandler.bind(this)} />
        </div>
      </Drawer>
    )
  }
}
Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(Sidebar)
