import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Form from '../../common/form'
import OrderDetails from './OrderDetails'
import { getOrderChecklist, updateCheckListDraft, discardDraft, updateChecklistDraftForCheckedoutAsset } from '../../helpers/api.js'
import { getData } from '../../utils/Authentication'
import Response from '../../common/Response'
import { strings } from '../../localizations/strings'
import MobileContainer from '../LayoutComponents/MobileContainer'
import * as CONSTANTS from '../../utils/url_constant'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent, getCurrentTimeStamp, StateType, ScreenTypes, CallingFrom, createDocumentTitle } from '../../helpers/GenericHelpers.js'

const useStyles = () => ({
})

class Order extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      errors: {},
      disabled: false,
      displayStatus: 'none',
      rowId: '',
      companyId: null,
      assetStatus: null,
      assetId: null,
      checkList: {},
      signature: { name: '', data: '' },
      checkListId: '',
      submitButtonTitle: '',
      lastTransactionId: '',
      lastIndexGroup: '',
      idFieldnameAssociation: [],
      previousValues: {},
      lastUpdatedAt: {},
      showComponent: false,
      open: false,
      setOpen: false,
      setProgressBar: false,
      link: false,
      showModal: false,
      isEditOrder: true
    }
    this.orderDetailsRef = React.createRef()
    this.screenType = ScreenTypes.desktop
    this.asset = null
    this.getScreenTypeData()
  }

  // since we are using same component for mobile and web
  // need to check whether it's web or mobile
  getScreenTypeData = () => {
    if (this.props.location && this.props.location.state) {
      this.screenType = this.props.location.state.screenType
    }
    this.getMachineDataFromProps()
  }

  // if it's edit form, read machine data from props
  getMachineDataFromProps = () => {
    if (this.screenType === ScreenTypes.mobile) {
      this.asset = this.props.location.state.asset

      if (this.props.location.state.callingFrom === CallingFrom.assetDetails) {
        this.state.companyId = this.props.location.state.asset.company.id
        this.state.assetStatus = this.props.location.state.asset.availability
        this.state.assetId = this.props.location.state.asset.id
      }
      if (this.props.location.state.callingFrom === CallingFrom.assetList) {
        this.state.companyId = this.props.location.state.asset.company_id
        this.state.assetStatus = this.props.location.state.asset.availability
        this.state.assetId = this.props.location.state.asset.id
      }
    } else {
      this.asset = this.props.asset
      if (this.props.callingFrom === CallingFrom.assetDetails) {
        this.state.companyId = this.props.asset.company.id
        this.state.assetStatus = this.props.asset.availability
        this.state.assetId = this.props.asset.id
      }
      if (this.props.callingFrom === CallingFrom.assetList) {
        this.state.companyId = this.props.asset.company_id
        this.state.assetStatus = this.props.asset.availability
        this.state.assetId = this.props.asset.id
      }
    }
  }

  componentDidMount () {
    if (this.state.assetStatus === 'IN_SERVICE') {
      this.setState({ showComponent: false })
    } else {
      const params = {
        assetId: this.state.assetId,
        companyId: this.state.companyId,
        token: getData('acccess_token')
      }
      getOrderChecklist(params, (error, response) => {
        if (error) {
          return window.alert(error)
        } else {
          const checkLists = response.data.data.check_list.check_groups[0].check_items
          const status = response.data.data.asset.availability
          const association = []
          checkLists.map(item => (
            association.push({ [item.name]: item.id })
          ))

          const data = this.state.data
          checkLists.map(item => (
            data[item.name] = typeof item.check_value.value === 'undefined' ? '' : item.check_value.value
          ))
          this.setState({ data })

          const previousValues = []

          for (let i = 1; i < response.data.data.check_list.check_groups.length; i++) {
            const arr = response.data.data.check_list.check_groups[i].check_items
            const groupId = response.data.data.check_list.check_groups[i].id

            arr.map(item => {
              const needsReset = (status === 'CHECKED_IN' || status === 'CHECKED_OUT')
              return previousValues.push({
                check_id: item.id,
                check_group_id: groupId,
                check_value: this.checkValueForCurrentTransaction(item, needsReset)
              })
            })
          }

          let setObject = {
            checkList: response.data.data.check_list.check_groups[0]
          }
          let lastTransIdNo = 0
          if (response.data.data.check_list.check_groups[0].last_transaction_id !== null) { lastTransIdNo = response.data.data.check_list.check_groups[0].last_transaction_id }
          let lastGrIndex = 0
          if (typeof response.data.data.check_list.check_groups[0].last_group_index !== 'undefined' &&
        response.data.data.check_list.check_groups[0].last_group_index !== null) { lastGrIndex = response.data.data.check_list.check_groups[0].last_group_index }
          setObject = Object.assign(setObject, {
            customerName: response.data.data.asset.asset_transaction.customer_name,
            lastTransactionId: lastTransIdNo,
            lastIndexGroup: lastGrIndex,
            checkListId: response.data.data.check_list.id,
            displayStatus: this.state.displayStatus === 'none' ? 'block' : 'none',
            idFieldnameAssociation: association,
            previousValues,
            lastUpdatedAt: response.data.data.check_list.updated_at,
            invoiceNo: response.data.data.asset.asset_transaction.invoice_no
          })
          this.setState(setObject)
          const signature = this.state.signature
          if (typeof response.data.data.asset.asset_transaction.customer_signature !== 'undefined') { signature.data = response.data.data.asset.asset_transaction.customer_signature }
          if (typeof response.data.data.asset.asset_transaction.customer_name !== 'undefined') { signature.name = response.data.data.asset.asset_transaction.customer_name }
          this.setState(signature)
          this.setState({ showComponent: true })

          if (this.state.assetStatus === 'CHECKED_IN') {
            this.updateButtonLabel(strings.create_draft)
            this.setState({ isEditOrder: false })
          }
          if (this.state.assetStatus === 'DRAFT') {
            this.updateButtonLabel(strings.save)
          }
          if (this.state.assetStatus === 'CHECKED_OUT') {
            this.updateButtonLabel(strings.save)
          }
          if (this.state.assetStatus === 'CHECKIN_DRAFT') {
            this.updateButtonLabel(strings.save)
          }
        }
      })
    }

    document.title = createDocumentTitle(document.title, this.getToolbarTitle())
  }

  checkValueForCurrentTransaction (item, needsReset) {
    const reset = (needsReset && ((item.type === 'TEXT' && item.keep_value === 0) || item.type === 'IMAGE' || (item.type === 'OK_NOT_OK' && item.check_value.value === 'OK')))
    const checkValue = {
      value: reset ? '' : item.check_value.value,
      is_new: reset ? 1 : item.check_value.is_new,
      reported_at: reset ? '' : item.check_value.reported_at,
      images: reset ? [] : item.check_value.check_value_images,
      comment: reset ? '' : item.check_value.comment
    }
    return checkValue
  }

  updateButtonLabel (label) {
    this.setState({ submitButtonTitle: label })
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  handleSubmit = (orderDetails, oldOrderData, key) => {
    if (!this.state.isEditOrder) { this.setState({ setProgressBar: true }) }
    const updatedValues = []
    for (let i = 0; i < this.state.idFieldnameAssociation.length; i++) {
      let timestamp = null
      if (
        orderDetails[Object.keys(this.state.idFieldnameAssociation[i])] !== oldOrderData[Object.keys(this.state.idFieldnameAssociation[i])]) {
        timestamp = getCurrentTimeStamp()
      }
      updatedValues.push({
        check_id: Object.values(this.state.idFieldnameAssociation[i])[0],
        check_group_id: -1,
        check_value:
        {
          value: orderDetails[Object.keys(this.state.idFieldnameAssociation[i])],
          is_new: 1,
          checkitem_timestamp: timestamp
        }
      })
    }
    const pre = this.state.previousValues
    const fullValue = updatedValues.concat(pre)
    const firstVal = [...this.state.previousValues]
    firstVal.concat(updatedValues)
    this.setState({ previousValues: firstVal })

    const requestBody = JSON.parse('{"check_items":[]}')
    requestBody.check_items = fullValue
    requestBody.company_id = this.state.companyId
    requestBody.signature = this.state.signature
    requestBody.last_transaction_id = this.state.lastTransactionId
    requestBody.last_group_index = this.state.lastIndexGroup
    requestBody.signature = this.state.signature
    requestBody.checklist_updated_at = this.state.lastUpdatedAt
    requestBody.check_list_id = this.state.checkListId

    if (this.state.assetStatus === 'CHECKED_IN') {
      requestBody.signature.name = ''
      requestBody.signature.data = ''
    }

    if (this.state.assetStatus === 'CHECKED_IN' || this.state.assetStatus === 'DRAFT') {
      requestBody.draft_type = 'DRAFT_CHECK_OUT'

      if (this.state.assetStatus === 'CHECKED_IN') {
        this.updateButtonLabel(strings.create_draft_inprogress)
      }
      if (this.state.assetStatus === 'DRAFT') {
        this.updateButtonLabel(strings.save_draft)
      }
    } else if (this.state.assetStatus === 'CHECKED_OUT') {
      requestBody.draft_type = 'CHECKED_OUT'
      this.updateButtonLabel(strings.save_order)
    } else if (this.state.assetStatus === 'CHECKIN_DRAFT') {
      requestBody.draft_type = 'CHECKED_OUT'
      this.updateButtonLabel(strings.save_order)
    }

    const params = {
      body: requestBody,
      assetId: this.state.assetId,
      companyId: this.state.companyId,
      token: getData('acccess_token')
    }
    if (this.state.assetStatus === 'CHECKED_OUT' || this.state.assetStatus === 'CHECKIN_DRAFT') {
      updateChecklistDraftForCheckedoutAsset(params, (error, response) => {
        if (error) {
          let message = 'Order not updated'
          if (error.message === 'Network Error') {
            message = strings.no_internet_connection
          }
          this.apiStatus = { status: 'error', message }
          this.handleResponse(key, StateType.failure)
          callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ERROR, { description: error }, this.asset)
          return window.alert(error)
        } else {
          if (response.data.status === 0) {
            this.apiStatus = { status: 'error', message: strings.machine_failure_status }
            this.handleResponse(key, StateType.failure)
            callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ERROR, { description: response.data.message }, this.asset)
          } else {
            this.apiStatus = { status: 'success', message: strings.order_drafted }
            this.handleResponse(key, StateType.success)
            callFirebaseEvent(FA_CONSTANTS.FA_ORDER_SAVED, {}, this.asset)
          }
        }
      }, key)
    } else {
      updateCheckListDraft(params, (error, response) => {
        if (error) {
          let message = 'Order not updated'
          if (error.message === 'Network Error') {
            message = strings.no_internet_connection
          }
          this.apiStatus = { status: 'error', message }
          this.handleResponse(key, StateType.failure)
          callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ERROR, { description: error }, this.asset)
        //  return window.alert(error)
        } else {
          if (response.data.status === 0) {
            if (response.data.message === 'ASSET_STATE_CHANGED') {
              this.apiStatus = { status: 'error', message: strings.machine_status }
            } else {
              this.apiStatus = { status: 'error', message: strings.machine_failure_status }
            }
            this.handleResponse(key, StateType.failure)
          } else {
            const msg = this.state.assetStatus === 'CHECKED_IN' ? strings.order_created : strings.order_drafted
            this.apiStatus = { status: 'success', message: msg }
            this.handleResponse(key, StateType.success)
          }
        }
      }, key)
    }
  }

  handleResponse = (key, stateType) => {
    if (this.state.isEditOrder) {
      if (this.orderDetailsRef.current) { this.orderDetailsRef.current.setInputState(stateType, key) }
      if (StateType.failure === stateType && this.orderDetailsRef.current) {
        this.orderDetailsRef.current.showBar(this.apiStatus)
      }
      if (StateType.success === stateType) {
        this.sleep(1000).then(() => {
          if (this.screenType === ScreenTypes.desktop) {
            this.props.handleDataChanged()
          }
        })
      }
    } else {
      this.sleep(1000).then(() => {
        if (this.screenType === ScreenTypes.desktop) {
          this.props.onResponse(this.apiStatus)
        }
        if (this.screenType === ScreenTypes.mobile) {
          this.handleArrowClick()
        }
      })
      this.setState({ setProgressBar: false })
    }
  }

  handleArrowClick = () => {
    if (this.screenType === ScreenTypes.mobile) {
      if (this.props.location.state.callingFrom === CallingFrom.assetList) {
        this.props.history.push(CONSTANTS.URLPATH_ASSET_LIST)
      }
      if (this.props.location.state.callingFrom === CallingFrom.assetDetails) {
        this.props.history.push({
          pathname: '/' + this.asset.guid
        })
      }
    }
  }

  getToolbarTitle = () => {
    let title = ''
    if (this.state.assetStatus === 'CHECKED_OUT') {
      title = strings.order_details
    }
    if (this.state.assetStatus === 'DRAFT') {
      title = strings.checkout_draft
    }
    if (this.state.assetStatus === 'CHECKED_IN') {
      title = strings.create_order_data
    }
    if (this.state.assetStatus === 'CHECKIN_DRAFT') {
      title = strings.return_draft
    }

    return title
  }

  handleDiscard = (assetId) => {
    this.setState({
      setProgressBar: false,
      open: true,
      setOpen: true,
      link: true,
      linkTitle: strings.delete_draft,
      hideIcon: true,
      showModal: true
    })
  }

  linkAction = () => {
    this.setState({ setProgressBar: true })
    let discardDraftType = 'DRAFT'
    if (this.state.assetStatus === 'CHECKIN_DRAFT') { discardDraftType = 'DRAFT_CHECK_IN' }
    if (this.state.assetStatus === 'DRAFT') { discardDraftType = 'DRAFT_CHECK_OUT' }
    const requestBody = { company_id: this.state.companyId, draft_type: discardDraftType }
    const params = {
      assetId: this.state.assetId,
      companyId: this.state.companyId,
      token: getData('acccess_token'),
      body: requestBody
    }
    this.setState({
      setProgressBar: false,
      open: false,
      setOpen: false,
      message: 'Success'
    })
    discardDraft(params, (error, response) => {
      if (error) {
        let message = 'Order discard failed'
        if (error.message === 'Network Error') {
          message = strings.no_internet_connection
        }
        this.apiStatus = { status: 'error', message }
        if (this.screenType === ScreenTypes.desktop) {
          this.props.onResponse(this.apiStatus)
        }
        callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ERROR, { description: error }, this.state.asset)
      } else {
        if (response.data.status === 0) {
          this.apiStatus = { status: 'error', message: strings.machine_failure_status }
          callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ERROR, { description: response.data.message }, this.state.asset)
        } else {
          this.apiStatus = { status: 'success', message: strings.order_deleted }
          callFirebaseEvent(FA_CONSTANTS.FA_ORDER_DELETED, {}, this.state.asset)
        }
        if (this.screenType === ScreenTypes.desktop) {
          this.props.onResponse(this.apiStatus)
        } else {
          this.handleArrowClick()
        }
        this.setState({ setProgressBar: false, message: response.data.message })
      }
    })
  }

  closePopUp = () => {
    this.setState({
      open: false
    })
  }

  handleClose = () => {
    if (this.screenType === ScreenTypes.desktop) {
      this.props.onClose()
    } else {
      this.handleArrowClick()
    }
  }

  render () {
    const childView =
      <div>
        {this.state.showComponent &&
          <OrderDetails
            screenType={this.screenType}
            ref={this.orderDetailsRef}
            asset={this.asset}
            checkList={this.state.checkList}
            assetId={this.state.assetId}
            assetStatus={this.state.assetStatus}
            submitButtonTitle={this.state.submitButtonTitle}
            setProgressBar={this.state.setProgressBar}
            invoiceNo={this.state.invoiceNo}
            isUpdate={this.state.isEditOrder}
            onSubmit={this.handleSubmit}
            onDiscard={this.handleDiscard}
            onClose={this.handleClose}
          />}

        {this.state.showModal &&
          <Response
            open={this.state.open}
            setOpen={this.state.setState}
            messageTitle={strings.popUpDeleteOrder}
            message={strings.popUpDeleteDescription}
            closePopUp={this.closePopUp}
            link={this.state.link}
            linkTitle={strings.popUpDeleteDraft}
            linkAction={this.linkAction}
            secondLink
            secondLinkTitle={strings.cancel}
            secondLinkAction={this.closePopUp}
            overrideTitlesStyle={{ textAlign: 'left' }}
            overrideLinkStyle={{ color: '#fa3900' }}
          />}
      </div>

    let parentView = childView
    // if it's a mobile screen wrap the child component inside Mobile container
    if (this.screenType === ScreenTypes.mobile) {
      parentView =
        <MobileContainer title={this.getToolbarTitle()} handleClick={() => this.handleArrowClick()}>
          {childView}
        </MobileContainer>
    }
    return (
      <div>
        {parentView}
      </div>
    )
  }
}

Order.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(Order)
