import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { connectivityChanged } from '../redux/slices/appSlice'

export function useAppConnectivity () {
  const dispatch = useDispatch()

  const onConnectivityChange = () => {
    const isOnline = navigator.onLine === true
    dispatch(connectivityChanged(isOnline))
  }

  useEffect(() => {
    window.addEventListener('online', onConnectivityChange, false)
    window.addEventListener('offline', onConnectivityChange, false)

    return () => {
      window.removeEventListener('online', onConnectivityChange)
      window.removeEventListener('offline', onConnectivityChange)
    }
  })
}
