import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import FieldLayoutGrid from './FieldLayoutGrid'
import { strings } from '../../../../../localizations/strings'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  subTitleLink: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    textAlign: 'left',
    color: '#fa3900'
  }
}))

export default function SendInvite (props) {
  const classes = useStyles()

  return (
    <FieldLayoutGrid>
      <Link
        style={{ cursor: 'pointer' }}
        data-cy='delete-user'
        className={classNames(classes.subTitleLink, props.linkClassName)}
        underline='always'
      >
        <div onClick={() => props.doDelete()}>
          {strings.delete_user_title}
        </div>
      </Link>
    </FieldLayoutGrid>
  )
}
