import { withStyles } from '@material-ui/styles'
import Form from '../../../../common/form'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Grid } from '@material-ui/core'
import { strings } from '../../../../localizations/strings'
import Container from '@material-ui/core/Container'
import FormHelperText from '@material-ui/core/FormHelperText'
import {
  getToken,
  getUserInfo,
  getUserCompanyId,
  getData,
  getUserBranch,
  getSearchFilters
} from '../../../../utils/Authentication.js'
import { companyListApi, createUserAPI, editUserAPI, sendInviteMail } from '../../../../helpers/api.js'
import {
  StateType,
  callFirebaseEvent,
  FormTypes,
  getScreenType,
  ScreenTypes,
  sleep
} from '../../../../helpers/GenericHelpers.js'
import Snackbar from '../../../LayoutComponents/Snackbar'
import * as FA_CONSTANTS from '../../../../utils/AnalyticsConstants.js'
import ListPicker from '../../../../common/AddItem/ListPicker'
import * as CONSTANTS from '../../../../utils/GenericConstants'
import AccessLocationsMenu from '../UserAccessLocationsMenu'

import ProgressLoader from './Components/ProgressLoader'
import CloseButton from './Components/CloseButton'
import SendInvite from './Components/SendInvite'
import EditUserTitle from './Components/EditUserTitle'
import DeleteUser from './Components/DeleteUser'
import FullFieldLayoutGrid from './Components/FullFieldLayoutGrid'
import { UserFieldsSchema } from './FieldsSchema'

import {
  getLocationsApi,
  getLocationList,
  getCompanyList,
  getButtonTitle,
  convertUserRoleString,
  getUserRole,
  InlineEditKeys,
  getUserRoleList,
  getIdForUserRole,
  getUserRoleForId,
  appendAnElementIntoArray
} from './AddEditUserHelpers'

const useStyles = theme => ({
  root: {},

  layout: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 40px 10px',
    zIndex: 1,
    position: 'relative'
  },

  leftRightMargin: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  topMargin40PX: {
    marginTop: '40px',
    marginBottom: '40px'
  },
  MuiFormControlRoot: {
    width: '400px'
  },
  group: {
    margin: theme.spacing(1, 0),
    flexDirection: 'row',
    fontSize: '14px'
  },
  buttonStyle: {
    backgroundColor: '#CBE7F2',
    '&:hover': {
      backgroundColor: '#CBE7F2'
    }
  },
  errorStyle: {
    backgroundColor: '#FF0000',
    '&:hover': {
      backgroundColor: '#FF0000'
    }
  },
  successStyle: {
    backgroundColor: '#49b157',
    '&:hover': {
      backgroundColor: '#49b157'
    }
  },
  label: {
    color: '#ffffff',
    '&:hover': {
      color: '#ffffff'
    }
  },
  deleteSmallScreen: {
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '40px'
  },
  rolesView: {
    width: '400px'
  },
  formControlWidth: {
    width: '400px'
  }
})

const WAIT_INTERVAL = 1000
const RESET_INTERVAL = 4000

class AddEditUserContainer extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        salutation: '',
        email: '',
        first_name: '',
        last_name: '',
        telephone: ''
      },
      selectedUserRole: null,
      companyID: '',
      branchId: '',
      restrictedBranches: [],
      selectedUserId: '',
      selectedUserStatus: '',

      userRole: '',
      currentState: {
        salutation: StateType.none,
        first_name: StateType.none,
        last_name: StateType.none,
        telephone: StateType.none,
        mrs: StateType.none,
        mr: StateType.none,
        userRole: StateType.none,
        company: StateType.none,
        branch: StateType.none,
        access: StateType.none
      },

      companies: [],
      companyBranches: [],

      showSnackBar: false,
      responseStatus: '',
      setProgressBar: false,
      isSendInvite: false,
      errors: {},
      errorSelectedUserRole: null,
      errorCompanyId: null,
      errorAccessLocation: null,
      apiCallInProgress: false

    }
    this.isSettingFilterCalled = false
    this.timer = null
    this.isEdit = false
    this.previouslySelectedRole = ''
    this.previousCompanyID = null
    this.previousBranchId = null
    this.previousRestrictedBranches = []
    // This flag will be used to differentiate whether it's a inline-editing api call or not
    this.isInlineApiCall = false
    this.screenType = getScreenType()
  }

  componentDidMount () {
    const { data } = this.state
    let companyId = ''
    let selectedUser = null
    let selectedUserRole = ''

    const userInfo = getUserInfo()
    const userRole = getUserRole(userInfo)
    this.setState({
      userRole
    })

    if (this.props.openFrom === FormTypes.editUser) {
      callFirebaseEvent(FA_CONSTANTS.FA_USER_EDIT)
      this.isEdit = true

      selectedUser = this.props.selectedUser
      if (selectedUser) {
        selectedUserRole = getUserRole(selectedUser)
        this.previouslySelectedRole = selectedUserRole
        this.setState({
          selectedUserId: selectedUser.id,
          selectedUserStatus: selectedUser.status,
          selectedUserRole
        })

        data.salutation = selectedUser.salutation
        if (selectedUser.first_name !== null) { data.first_name = selectedUser.first_name }
        if (selectedUser.last_name !== null) { data.last_name = selectedUser.last_name }
        if (selectedUser.phone !== null) data.telephone = selectedUser.phone
        data.email = selectedUser.email

        const companyID = selectedUser.company_id
        if (companyID) {
          companyId = companyID
          this.setState({ companyID })
          this.previousCompanyID = companyID
        }

        const branchID = selectedUser.branch_id
        if (branchID) {
          this.setState({ branchId: branchID })
          this.previousBranchId = branchID
        }
      }
    } else {
      callFirebaseEvent(FA_CONSTANTS.FA_USER_CREATE)

      const userCompanyID = getUserCompanyId()
      if (userCompanyID && userCompanyID !== 0) {
        companyId = userCompanyID
        this.setState({ companyID: userCompanyID })
      }

      const userBranch = getUserBranch()
      if (userBranch && userBranch.id) {
        this.setState({ branchId: userBranch.id })
      }
    }

    this.setDataLists(userRole, selectedUser, selectedUserRole, companyId)
  }

  setDataLists = (userRole, selectedUser, selectedUserRole, companyId) => {
    if (userRole === CONSTANTS.SUPPORT) {
      this.setCompanies()
    }

    this.setBranchData(selectedUser, selectedUserRole, companyId, () => {})
  }

  setBranchData = (selectedUser, selectedUserRole, companyId, completion) => {
    this.setCompanyBranches(companyId, () => {
      this.setDataListForCompanyAdminUser(selectedUser, selectedUserRole || CONSTANTS.USER, companyId, () => {
        completion()
      })
    })
  }

  setDataListForCompanyAdminUser = (selectedUser, selectedUserRole, companyId, completion) => {
    if (selectedUserRole === CONSTANTS.USER) {
      if (this.props.openFrom === FormTypes.editUser) {
        const selectedUserId = selectedUser?.id
        // const companyBranchCount = selectedUser.company_branch_count || 0
        if (selectedUserId /* && companyBranchCount > 0 */) {
          this.getBranches(companyId, selectedUserId, (branches) => {
            completion()
            this.setRestrictedBranchIdsArray(branches, () => {})
          })
        }
      } else {
        completion()
        this.setRestrictedBranchIdsArray(this.state.companyBranches, () => {})
      }
    }
  }

  setCompanies = () => {
    const params = {
      token: getToken()
    }
    companyListApi(params, (error, response) => {
      if (error) {
        this.setStateCompanies([])
      } else {
        if (response.data.data.companies != null) {
          this.setStateCompanies(response.data.data.companies)
        }
      }
    })
  }

  setCompanyBranches = (companyId, setData) => {
    this.setState({
      companyBranches: [],
      restrictedBranches: []
    })
    this.getBranches(companyId, null, (branches) => {
      this.setState({
        companyBranches: branches
      }, () => { setData() })
    })
  }

  getBranches = (companyId, userId, response) => {
    if (companyId && companyId !== '') {
      getLocationsApi(companyId, userId, (branches) => {
        response(branches)
      })
    } else {
      response([])
    }
  }

  setStateCompanies = (companies) => {
    this.setState({ companies })
  }

  setStatecompanyBranches = (branches) => {
    this.setState({ companyBranches: branches })
  }

  setRestrictedBranchIdsArray = (branches, completion) => {
    const branchIdsArray = branches.map((branch) => branch.id)
    this.previousRestrictedBranches = branchIdsArray || []
    this.setState({
      restrictedBranches: branchIdsArray || []
    }, () => {
      this.showAccessLocationError()
      completion()
    })
  }

  resetBranchesData = (selectedUser, selectedUserRole, companyId) => {
    this.setState({
      restrictedBranches: []
    })
    this.setDataListForCompanyAdminUser(selectedUser, selectedUserRole, companyId, () => {})
  }

  schema = UserFieldsSchema

  focusEvent = input => {
    if (input.name === 'first_name' && this.state.errors.first_name === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_USERFIRSTNAME_SET)
    } else if (input.name === 'last_name' && this.state.errors.last_name === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_USERLASTNAME_SET)
    } else if (input.name === 'telephone' && this.state.errors.telephone === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_USERPHONESET)
    } else if (input.name === 'email' && this.state.errors.email === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_USEREMAIL_SET)
    }
  }

  triggerChange = (input) => {
    this.submitData(input.name)
  }

  handleRetryClick = event => {
    this.submitData(event.currentTarget.id)
  }

  handleSelectCompany = (companyID) => {
    callFirebaseEvent(FA_CONSTANTS.FA_USERCOMPANY_SET)
    this.setState({
      companyID,
      errorCompanyId: null,
      branchId: null,
      restrictedBranches: []
    }, () => this.updateInlineEdit(InlineEditKeys.company, companyID))
  }

  handleChangeLocation = (branchId) => {
    callFirebaseEvent(FA_CONSTANTS.FA_USERBRANCH_SET)
    const restrictedBranches = appendAnElementIntoArray(branchId, this.state.restrictedBranches)
    this.setState({
      branchId,
      restrictedBranches
    }, () => this.updateInlineEdit(InlineEditKeys.branch))
  }

  handleSelectRestrictBranches = (hasChanges, branchIdsArray) => {
    if (hasChanges) {
      this.setState({
        restrictedBranches: branchIdsArray,
        branchId: (branchIdsArray.length === 1) ? branchIdsArray[0] : this.state.branchId
      }, () => this.updateInlineEdit(InlineEditKeys.access))
    }
  }

  onUserRoleSelection = (roleId) => {
    callFirebaseEvent(FA_CONSTANTS.FA_USERROLE_SET)
    const userRole = getUserRoleForId(roleId)

    if (userRole === CONSTANTS.SUPPORT) {
      this.setState({
        selectedUserRole: userRole,
        errorSelectedUserRole: null,
        companyID: '',
        branchId: '',
        restrictedBranches: []
      }, () => this.updateInlineEdit(InlineEditKeys.userRole))
    } else {
      this.setState({
        selectedUserRole: userRole,
        errorSelectedUserRole: null,
        branchId: '',
        restrictedBranches: []
      }, () => this.updateInlineEdit(InlineEditKeys.userRole))
    }
  }

  doDelete = (event) => {
    this.props.onDelete()
  }

  sendInvite = (event) => {
    if (!this.state.apiCallInProgress) {
      this.setState({
        apiCallInProgress: true
      })
      this.setShowSnackBar(false)
      const params = {
        user_id: this.state.selectedUserId,
        token: getToken()
      }
      sendInviteMail(params, (error, response) => {
        this.setState({
          apiCallInProgress: false
        }, () => {
          if (error) {
            let errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : strings.something_wrong
            if (error.message === 'Network Error') {
              errorMessage = strings.no_internet_connection
            }
            this.setShowSnackBar(true, 'error', errorMessage.length !== 0 ? errorMessage : strings.something_went_wrong)
          } else {
            if (response.data.status === 1) {
              callFirebaseEvent(FA_CONSTANTS.FA_USERINVITE_RESENT)
              this.setState({
                isSendInvite: true
              })
            }
          }
        })
      })
    }
  }

  // salutation on click
  handleRadioClick = event => {
    const { name, value } = event.target
    const { data, errors } = { ...this.state }
    data[name] = value
    delete errors[name]
    this.setState({ data, errors })

    callFirebaseEvent(FA_CONSTANTS.FA_USERSALUTATION_SET)

    if (this.isEdit) {
      this.isInlineApiCall = true
      this.doSubmit(this.state.errors, event.target.value === 'Mrs' ? 'mrs' : 'mr')
    }
  }

  updateInlineEdit (key, companyId) {
    const selectedUser = this.props.selectedUser
    if (key === InlineEditKeys.company) {
      this.setBranchData(selectedUser, this.state.selectedUserRole, companyId, () => {
        if (this.isEdit) {
          this.setRestrictedBranchIdsArray(this.state.companyBranches, () => {
            this.submitData(InlineEditKeys.company)
          })
        }
      })
    } else if (key === InlineEditKeys.branch) {
      this.showAccessLocationError()
      if (this.isEdit) { this.submitData(InlineEditKeys.branch) }
    } else if (key === InlineEditKeys.userRole) {
      if (this.previouslySelectedRole === CONSTANTS.SUPPORT && this.state.selectedUserRole !== CONSTANTS.SUPPORT) {
        this.setShowSnackBar(false)
        this.showRoleView(this.props.classes)
        this.showSelectedCompanyError()
      } else {
        if (this.isEdit) {
          this.setRestrictedBranchIdsArray(this.state.companyBranches, () => {
            this.submitData(InlineEditKeys.userRole)
          })
        } else {
          this.resetBranchesData(this.props.selectedUser, this.state.selectedUserRole, this.state.companyID)
        }
      }
    } else if (key === InlineEditKeys.access) {
      this.showAccessLocationError()
      if (this.isEdit) {
        this.submitData(InlineEditKeys.access)
      }
    }
  }

  saveInlineData = (input, error) => {
    if (this.isEdit) {
      clearTimeout(this.timer)
      if (error) {
        this.setInputState(StateType.none, input.name)
      } else { this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL, input) }
    }
  }

  setInputState = (stateType, key) => {
    const currentState = { ...this.state.currentState }

    // reset previous companyId  in case failure
    if (StateType.failure === stateType && key === InlineEditKeys.company) {
      this.setState({
        companyID: this.previousCompanyID,
        branchId: this.previousBranchId
      }, () => {
        const selectedUser = this.props.selectedUser
        this.setBranchData(selectedUser, this.state.selectedUserRole, this.previousCompanyID, () => {})
      })
    }

    currentState[key] = stateType
    this.setState({ currentState })
    setTimeout(this.resetCurrentState, RESET_INTERVAL, key, stateType)
  }

  resetCurrentState = (key, stateType) => {
    const currentState = { ...this.state.currentState }
    const data = { ...this.state.data }

    // reset userrole and salution with previous values in case failure
    if (StateType.failure === stateType) {
      switch (key) {
        case 'mr':
        case 'mrs':
          data.salutation = (data.salutation === strings.salutation_mrs) ? strings.salutation_mr : strings.salutation_mrs
          break
        case InlineEditKeys.userRole:
          if (this.state.selectedUserRole === CONSTANTS.SUPPORT) {
            this.setState({
              selectedUserRole: this.previouslySelectedRole,
              companyID: this.previousCompanyID,
              branchId: this.previousBranchId,
              restrictedBranches: this.previousRestrictedBranches
            }, () => {
              this.resetBranchesData(this.props.selectedUser, this.state.selectedUserRole, this.state.companyID)
            })
          } else {
            this.setState({
              selectedUserRole: this.previouslySelectedRole,
              branchId: this.previousBranchId,
              restrictedBranches: this.previousRestrictedBranches
            }, () => {
              this.resetBranchesData(this.props.selectedUser, this.state.selectedUserRole, this.state.companyID)
            })
          }

          break
        case InlineEditKeys.branch:
          this.setState({
            branchId: this.previousBranchId,
            restrictedBranches: this.previousRestrictedBranches
          }, () => {
            this.showAccessLocationError()
          })
          break
        case InlineEditKeys.access:
          this.setState({
            restrictedBranches: this.previousRestrictedBranches
          }, () => {
            this.showAccessLocationError()
          })
          break
        default: break
      }
    }

    // saving current companyId,branchId and role to reset in case failure
    if (StateType.success === stateType) {
      switch (key) {
        case InlineEditKeys.userRole:
          this.resetBranchesData(this.props.selectedUser, this.state.selectedUserRole, this.state.companyID)
          this.previouslySelectedRole = this.state.selectedUserRole
          this.previousCompanyID = this.state.companyID
          this.previousRestrictedBranches = this.state.restrictedBranches
          this.previousBranchId = this.state.branchId
          break
        case InlineEditKeys.company:
          this.previousCompanyID = this.state.companyID
          this.previousBranchId = null
          this.previousRestrictedBranches = this.state.restrictedBranches
          break
        case InlineEditKeys.branch:
          this.previousBranchId = this.state.branchId
          this.previousRestrictedBranches = this.state.restrictedBranches
          break
        case InlineEditKeys.access:
          this.previousRestrictedBranches = this.state.restrictedBranches
          break
        default: break
      }
    }

    currentState[key] = StateType.none
    this.setState({ data, currentState })
  }

  submitData = (key) => {
    this.setInputState(StateType.loding, key)
    this.isInlineApiCall = true
    this.doSubmit(this.state.errors, key)
  }

  showSelectedUserRoleError () {
    if (this.state.selectedUserRole === null || this.state.selectedUserRole === '') {
      this.setState({ errorSelectedUserRole: strings.userselection_error_msg })
    }
  }

  showSelectedCompanyError () {
    if (this.state.companyID === '' || !this.state.companyID) {
      this.setState({ errorCompanyId: strings.companyselection_error_msg })
    }
  }

  checkHaveAccessLocationError = () => {
    if (this.state.selectedUserRole === CONSTANTS.USER) {
      if (this.state.companyBranches.length > 1 && (this.state.restrictedBranches || []).length === 0) {
        return true
      }
    }
    return false
  }

  showAccessLocationError = () => {
    if (this.checkHaveAccessLocationError()) {
      this.setState({ errorAccessLocation: strings.access_location_error_msg })
    } else {
      this.setState({ errorAccessLocation: null })
    }
  }

  doSubmit = (errors, key) => {
    if (this.state.showSnackBar) {
      this.setShowSnackBar(false)
    }
    let isCompanyIdMissing = false
    if (this.state.selectedUserRole !== CONSTANTS.SUPPORT && this.state.userRole === CONSTANTS.SUPPORT) {
      if (!this.state.companyID) {
        isCompanyIdMissing = true
      }
    }

    const hasAccessLocationError = this.checkHaveAccessLocationError()

    let isError = false
    if (!this.isEdit) {
      if (errors) {
        isError = true
      } else {
        isError = false
      }
    }
    /** **START: should execute if there is no error */

    if ((!isError) &&
    this.state.selectedUserRole &&
    this.state.selectedUserRole !== '' &&
    !isCompanyIdMissing &&
    !hasAccessLocationError) {
      const params = {
        salutation: this.state.data.salutation,
        first_name: this.state.data.first_name,
        last_name: this.state.data.last_name,
        email: this.state.data.email,
        role: this.state.selectedUserRole,
        phone: this.state.data.telephone,
        token: getData('acccess_token'),
        company_id: this.state.companyID,
        branch_id: this.state.branchId || 0
      }

      if (params.role === CONSTANTS.SUPPORT) {
        params.role = CONSTANTS.ADMIN
      } else if (params.role === CONSTANTS.ADMIN) {
        params.role = CONSTANTS.COMPANY_ADMIN
      } else if (params.role === CONSTANTS.USER) {
        params.role = CONSTANTS.TECHNICIAN
        params.restricted_branches = this.state.restrictedBranches
      }

      if (this.props.openFrom === FormTypes.addUser) {
        this.setState({ setProgressBar: true })
        createUserAPI(params, (error, response) => {
          this.setState({ setProgressBar: false })
          if (error) {
            let errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : strings.something_wrong
            const status = (error.response && error.response.data) ? error.response.data.status : 1000
            if (status === 0) { errorMessage = strings.user_exists }
            if (error.message === 'Network Error') {
              errorMessage = strings.no_internet_connection
            }
            this.setShowSnackBar(true, 'error', errorMessage.length !== 0 ? errorMessage : strings.something_went_wrong)
          } else {
            callFirebaseEvent(FA_CONSTANTS.FA_USER_CREATED)
            this.setShowSnackBar(true, 'success', strings.user_added)

            sleep(1000).then(() => {
              this.props.onResponse(this.state.responseStatus)
            })
          }
        })
      } else if (this.props.openFrom === FormTypes.editUser) {
        params.user_id = this.state.selectedUserId
        params.status = this.state.selectedUserStatus
        if (((params.role === CONSTANTS.COMPANY_ADMIN || params.role === CONSTANTS.TECHNICIAN) && params.company_id !== '') || params.role === CONSTANTS.ADMIN) {
          editUserAPI(params, (error, response, key) => {
            if (error) {
              this.setInputState(StateType.failure, key)
              let errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : strings.something_wrong
              if (error.message === 'Network Error') {
                errorMessage = strings.no_internet_connection
              }
              this.setShowSnackBar(true, 'error', errorMessage.length !== 0 ? errorMessage : strings.something_went_wrong)
            } else {
              callFirebaseEvent(FA_CONSTANTS.FA_USER_EDITED)
              this.setInputState(StateType.success, key)
              if (this.screenType === ScreenTypes.desktop) { this.props.onEdit(true) }
            }
          }, key)
        } else {
          this.setShowSnackBar(true, 'error', 'Please select the company field')
          if (key === InlineEditKeys.userRole) {
            this.setState({
              selectedUserRole: this.previouslySelectedRole
            })
          }
        }
      }
    } else {
      this.setShowSnackBar(true, 'error', strings.something_went_wrong)
      if (this.props.openFrom === FormTypes.editUser) {
        this.setInputState(StateType.failure, key)
      }
      if (isCompanyIdMissing) {
        this.showSelectedCompanyError()
      }
      if (this.state.selectedUserRole || this.state.selectedUserRole === '') {
        this.showSelectedUserRoleError()
      }
      if (hasAccessLocationError) {
        this.showAccessLocationError()
      }
    }
  }

  setFilterValue () {
    this.isSettingFilterCalled = true
    let selectedUserRole = CONSTANTS.USER

    const branch = getUserBranch()
    if (branch && branch.id) {
      this.setState({ branchId: branch.id })
    }

    const searchFilters = getSearchFilters()
    if (searchFilters && searchFilters.userType && searchFilters.userType.length === 1) {
      selectedUserRole = convertUserRoleString(searchFilters.userType[0].id)

      const userCompanyId = getUserCompanyId()
      this.setBranchData(null, selectedUserRole, userCompanyId, () => {})
      this.setState({ selectedUserRole })
    }
    this.setState({ selectedUserRole })
  }

  setShowSnackBar = (isShow, status, message) => {
    if (isShow) {
      this.setState({
        showSnackBar: isShow,
        responseStatus: {
          status,
          message
        }
      })
    } else {
      this.setState({ showSnackBar: false })
    }
  }

  showRoleView (classes) {
    if (this.state.selectedUserRole !== CONSTANTS.SUPPORT || this.props.openFrom === FormTypes.addUser) {
      const hasError = (this.state.errorSelectedUserRole !== null)
      return (
        <FullFieldLayoutGrid className={classes.rolesView}>
          <ListPicker
            dataCy='role-selector'
            name={strings.title_userrole}
            listPickerItems={getUserRoleList(this.state.userRole, this.props.openFrom)}
            selectItem={(roleId) => this.onUserRoleSelection(roleId)}
            selectedItem={getIdForUserRole(this.state.selectedUserRole)}
            helperText={strings.userselection_error_msg}
            hasError={hasError}
            stateType={this.state.currentState.userRole}
          />
        </FullFieldLayoutGrid>
      )
    }
  }

  showCompanyView (classes) {
    if (this.state.userRole === CONSTANTS.SUPPORT && this.state.selectedUserRole &&
      this.state.selectedUserRole !== CONSTANTS.SUPPORT && this.props.openFrom === FormTypes.addUser) {
      if (this.state.companies?.length > 0) {
        const hasError = (this.state.errorCompanyId)
        return (
          <FullFieldLayoutGrid>
            <ListPicker
              dataCy='company-selector'
              name={strings.title_company}
              listPickerItems={getCompanyList(this.state.companies)}
              selectItem={(value) => this.handleSelectCompany(value)}
              selectedItem={this.state.companyID}
              helperText={strings.companyselection_error_msg}
              hasError={hasError}
              stateType={this.state.currentState.company}
            />
          </FullFieldLayoutGrid>
        )
      }
    }
  }

  showBranchLocation (classes) {
    if (this.state.selectedUserRole !== CONSTANTS.SUPPORT) {
      if (this.state.companyID &&
        this.state.companyID !== 0 &&
        this.state.companyBranches?.length > 1) {
        return (
          <FullFieldLayoutGrid>
            <ListPicker
              dataCy='location-selector'
              name={strings.location}
              listPickerItems={getLocationList(this.state.companyBranches, true)}
              selectItem={(value) => this.handleChangeLocation(value)}
              selectedItem={this.state.branchId || 0}
              stateType={this.state.currentState.branch}
            />
          </FullFieldLayoutGrid>

        )
      }
    }
  }

  showAccessLocation (classes) {
    if (this.state.selectedUserRole === CONSTANTS.USER) {
      if (this.state.companyID &&
        this.state.companyID !== 0 &&
         this.state.companyBranches?.length > 1) {
        const hasError = (this.state.errorAccessLocation)
        return (
          <FullFieldLayoutGrid>
            <AccessLocationsMenu
              dataCy='access-location-selector'
              name={strings.access}
              locations={getLocationList(this.state.companyBranches)}
              onChange={(hasChanges, branchIdsArray) => this.handleSelectRestrictBranches(hasChanges, branchIdsArray)}
              selectedItems={this.state.restrictedBranches}
              branchId={this.state.branchId}
              stateType={this.state.currentState.access}
              hasError={hasError}
              helperText={strings.access_location_error_msg}
            />
          </FullFieldLayoutGrid>

        )
      }
    }
  }

  getInputFieldComponent = (
    name, label, classes,
    showHide, type = 'text',
    disabled = false, id = '',
    isEdit = false,
    stateType = StateType.none,
    enableMultiline = false
  ) => {
    return (
      <FullFieldLayoutGrid>
        {this.renderInput(
          name,
          label,
          classes,
          showHide, type, disabled, id, isEdit, stateType)}
      </FullFieldLayoutGrid>
    )
  }

  render () {
    const { errors } = { ...this.state }

    const { classes } = this.props
    let disabled = false

    if (this.props.openFrom === FormTypes.editUser) {
      disabled = true
    } else if (!this.isSettingFilterCalled) {
      this.setFilterValue()
    }

    return (
      <div>
        <Container>
          {this.state.setProgressBar === true
            ? (
            <ProgressLoader />
              )
            : (
                ''
              )}
        </Container>
        <Container className={classes.layout}>
          <form className={this.props.classes.leftRightMargin}>
            <Grid data-cy='add-edit-user-container'>
              {this.props.openFrom === FormTypes.editUser
                ? (
                <Grid>
                  {this.screenType === ScreenTypes.desktop &&
                    <CloseButton onClose={() => this.props.onClose()} />}

                  {this.state.selectedUserStatus === 'NEW'
                    ? (
                    <SendInvite
                      isSendInvite={this.state.isSendInvite}
                      sendInvite={this.sendInvite.bind(this)}
                    />

                      )
                    : (
                        ''
                      )}

                  {this.screenType === ScreenTypes.desktop &&
                    <div>
                      <EditUserTitle />
                      <DeleteUser doDelete={this.doDelete.bind(this)} />
                    </div>}

                </Grid>
                  )
                : (
                    ''
                  )}

              <FullFieldLayoutGrid>
                {this.showRoleView(classes)}
              </FullFieldLayoutGrid>

              <FullFieldLayoutGrid>
                <FormControl component='fieldset' margin='none' className={classes.formControlWidth}>
                  <FormLabel data-cy='user-form-salutation-text' component='legend'>{strings.salutation}</FormLabel>
                  <RadioGroup
                    data-cy='radio-group'
                    aria-label='gender'
                    name='salutation'
                    className={classes.group}
                    onChange={this.handleRadioClick}
                    value={this.state.data.salutation}
                  >
                    {this.renderRadioButton('Mrs', strings.salutation_mrs, this.state.currentState.mrs)}

                    {this.renderRadioButton('Mr', strings.salutation_mr, this.state.currentState.mr)}
                  </RadioGroup>
                  <FormHelperText error>
                    {errors.salutation !== null ? errors.salutation : ' '}
                  </FormHelperText>
                </FormControl>
              </FullFieldLayoutGrid>

              {this.getInputFieldComponent(
                'first_name',
                strings.first_name,
                classes,
                false, 'text', false, 'first_name', this.isEdit, this.state.currentState.first_name
              )}

              {this.getInputFieldComponent(
                'last_name',
                strings.last_name,
                classes,
                false, 'text', false, 'last_name', this.isEdit, this.state.currentState.last_name
              )}

              {this.getInputFieldComponent('email', strings.email, '', '', 'text', disabled)}

              {this.getInputFieldComponent(
                'telephone',
                strings.telephone,
                classes,
                false, 'text', false, 'telephone', this.isEdit, this.state.currentState.telephone
              )}

              {this.showCompanyView(classes)}

              {this.showBranchLocation(classes)}

              {this.showAccessLocation(classes)}

              <FullFieldLayoutGrid
                className={this.props.classes.topMargin40PX}
              >
                {this.isEdit
                  ? ''
                  : this.renderButton(getButtonTitle(this.props.openFrom === FormTypes.editUser), classes.button)}
              </FullFieldLayoutGrid>

              {this.isEdit && this.screenType !== ScreenTypes.desktop &&
                <DeleteUser linkClassName={classes.deleteSmallScreen} doDelete={this.doDelete.bind(this)} />}

            </Grid>
            {(this.state.showSnackBar) ? (<Snackbar type={this.state.responseStatus.status} message={this.state.responseStatus.message} />) : ''}
          </form>
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(AddEditUserContainer)
