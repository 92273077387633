import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { visibilityChanged } from '../redux/slices/appSlice'

export function useAppVisibility () {
  const getBrowserVisibilityProp = () => {
    if (typeof document.hidden !== 'undefined') {
      return 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      return 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      return 'webkitvisibilitychange'
    } else if (typeof document.mozvisibilitychange !== 'undefined') {
      return 'mozvisibilitychange'
    }
  }

  const getBrowserDocumentHiddenProp = () => {
    if (typeof document.hidden !== 'undefined') {
      return 'hidden'
    } else if (typeof document.msHidden !== 'undefined') {
      return 'msHidden'
    } else if (typeof document.webkitHidden !== 'undefined') {
      return 'webkitHidden'
    } else if (typeof document.mozHidden !== 'undefined') {
      return 'mozHidden'
    }
  }

  const dispatch = useDispatch()

  const onVisibilityChange = () => {
    const isVisible = !document[getBrowserDocumentHiddenProp()]
    dispatch(visibilityChanged(isVisible))
  }

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()
    window.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      window.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })
}
