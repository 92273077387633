import * as FA_CONSTANTS from '../../utils/AnalyticsConstants'
import { callFirebaseEvent } from '../../helpers/GenericHelpers'

export const ORDER_NUMBER = '-9'
export const CUSTOMER = '-3'
export const LOCATION = '-4'
export const CONTACT = '-2'
export const PHONE = '-8'

export function trackEventsForOrderItemEnter (id, asset) {
  switch (id) {
    case ORDER_NUMBER :
      callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ENTERED_ORDER_NUMBER, {}, asset)
      break
    case CUSTOMER :
      callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ENTERED_CUSTOMER, {}, asset)
      break
    case LOCATION :
      callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ENTERED_LOCATION, {}, asset)
      break
    case CONTACT :
      callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ENTERED_CONTACT, {}, asset)
      break
    case PHONE :
      callFirebaseEvent(FA_CONSTANTS.FA_ORDER_ENTERED_PHONE, {}, asset)
      break
    default :
      break
  }
}
