import { strings } from '../../localizations/strings'

const carouselData = [
  {
    image: strings.qrcode_carousel_item_1_image_path,
    title: strings.qrcode_carousel_item_1_title,
    description: strings.qrcode_carousel_item_1_description
  },
  {
    image: strings.qrcode_carousel_item_2_image_path,
    title: strings.qrcode_carousel_item_2_title,
    description: strings.qrcode_carousel_item_2_description
  },
  {
    image: strings.qrcode_carousel_item_3_image_path,
    title: strings.qrcode_carousel_item_3_title,
    description: strings.qrcode_carousel_item_3_description
  },
  {
    image: strings.qrcode_carousel_item_4_image_path,
    title: strings.qrcode_carousel_item_4_title,
    description: strings.qrcode_carousel_item_4_description
  },
  {
    image: strings.qrcode_carousel_item_5_image_path,
    title: strings.qrcode_carousel_item_5_title,
    description: strings.qrcode_carousel_item_5_description
  }
]

export default carouselData
