export const URLPATH_PARKING = '/parking'
export const URLPATH_SELECTCOMPANY = '/select_company'
export const URLPATH_ADDMACHINE = '/add_machine'
export const URLPATH_BRANCHLOCATION = '/location'
export const URLPATH_MANAGEACCOUNT = '/manage_account'
export const URLPATH_INVITEPERSON = '/invite_person'
export const URLPATH_DATAPRIVACY = 'https://klickcheck.com/datenschutz'
export const URLPATH_TNC = 'https://klickcheck.com/nutzungsbedingungen'
export const URLPATH_PERSONALDATA = '/manageaccount/personaldata'
export const URLPATH_HOME_COMPANYDATA = '/manageaccount/companydata'
export const URLPATH_USERMANAGMENT = '/manageaccount/usermanagement'
export const URLPATH_HOME_NOTIFICATION = '/manageaccount/notification'
export const URLPATH_DELETEACCOUNT = '/manageaccount/deleteaccount'
export const URLPATH_HOME = '/'
export const URLPATH_SIGN_UP = '/signup'
export const URLPATH_ACTIVATE = '/activate/:token'
export const URLPATH_LOGIN = '/login'
export const URLPATH_LOGOUT = '/logout'
export const URLPATH_RESPONSE = '/response'
export const URLPATH_RESPONSE_FAILURE = '/responsefailure'
export const URLPATH_FORGOT_PASSWORD = '/forgot_password'
export const URLPATH_RESET_TOKEN = '/reset/:token'
export const URLPATH_CREATE_USER = '/m/create_user' // invite user
export const URLPATH_EDIT_USER = '/m/edit_user'
export const URLPATH_EDIT_ME = '/m/edit_me'
export const URLPATH_COMPANY_DATA = '/m/company_data'
export const URLPATH_USERLIST = '/users'
export const URLPATH_NOTIFICATIONS = '/m/notification'
export const URLPATH_DELETECOMPANY = '/m/deletecompany'
export const URLPATH_LOGIN_GERMAN = '/anmelden'
export const URLPATH_EDITUSER = '/manageaccount/edituser'
export const URLPATH_CREATEUSER = '/create_user' // invite user
export const URLPATH_M_CREATEUSER = '/m/create_user' // invite user
export const URLPATH_QRCODE = '/qr_code'
export const TECHNICIAN = 'TECHNICIAN'
export const ADMIN = 'ADMIN'
export const COMPANY_ADMIN = 'COMPANY_ADMIN'
export const URLPATH_IMPRINT = 'https://klickcheck.com/impressum'
export const URLPATH_DATENSCHUTZ = 'https://klickcheck.com/datenschutz'
export const URLPATH_NUTZUNGSBEDINGUNGEN = 'https://klickcheck.com/nutzungsbedingungen'
export const URLPATH_ANDROID_PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.zeppelin.zlab.klickcheck.android&hl=gsw'
export const URLPATH_IOS_APP_STORE = 'https://apps.apple.com/de/app/klickcheck/id1426312062'
export const URLPATH_FEEDBACK = '?open=feedback'
export const URLPATH_IMAGE = '?open='
export const URLPATH_ORDERS = '/orders'
export const URLPATH_ASSET_LIST = '/assets'
export const URLPATH_CREATE_ASSET = '/create_asset'
export const URLPATH_EDIT_ASSET = '/edit_asset'
export const URLPATH_PROTOCOL_SUMMARY = '/:guid/protocol_summary'
export const URLPATH_PROTOCOL_IMAGES = '/:guid/protocol_images'

export const URLPATH_LANDING = URLPATH_ASSET_LIST
