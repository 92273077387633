import { Component } from 'react'
import LocationIcon from '@material-ui/icons/LocationOn'
import {
  getData,
  saveUserDataToLocalStorage,
  getUserBranch,
  getUserCompanyId,
  saveDataInLocalStorage,
  getUserRole,
  getBranchFilterId,
  updateValueofFilters,
  saveSearchFiltersFromApi,
  getBranchIdForSearch,
  isUserHaveAllLocationAccess
} from '../../utils/Authentication.js'
import { saveBranchLocation, userDetailApi } from '../../helpers/api.js'
import { getBranchName, getBranchFrom, callFirebaseEvent } from '../../helpers/GenericHelpers.js'
import { strings } from '../../localizations/strings.js'
import MenuDropDown from '../../common/MenuDropDown'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import * as CONSTANTS from '../../utils/GenericConstants'

class LocationDropDown extends Component {
  constructor (props) {
    super(props)
    const branchFilterId = getBranchFilterId()
    this.state = {
      selectedBranch: (branchFilterId && branchFilterId === -1) ? -1 : getUserBranch()
    }
  }

  handleSelectBranch = (branchId) => {
    let branch = null
    let branchFilterObject = []
    if (branchId === -1) {
      branchFilterObject = [{ id: -1, name: strings.location_not_assigned }]
      this.setState({ selectedBranch: -1 })
    } else {
      branch = getBranchFrom(this.props.branches, branchId)
      this.setState({ selectedBranch: branch })
    }

    if ((getUserRole() || CONSTANTS.TECHNICIAN) === CONSTANTS.ADMIN) {
      updateValueofFilters('branches', branchFilterObject)
      saveDataInLocalStorage('branch', JSON.stringify(branch))
      this.getUserDetails()
    } else {
      this.saveUserBranchLocation(branchId)
    }
  }

  saveUserBranchLocation = (branchId) => {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId(),
      branch_id: branchId
    }
    saveBranchLocation(params, (error, response) => {
      if (error) {
        const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
        callFirebaseEvent(FA_CONSTANTS.FA_E_SAVE_BRANCH, { description: errorMessage })
      } else {
        this.getUserDetails()
      }
    })
  }

  getUserDetails = () => {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    userDetailApi(params, (error, response) => {
      if (!error) {
        this.trackBranchUpdation()
        this.trackBranchFilter()
        const user = response.data.data.user
        saveUserDataToLocalStorage(user)

        if (user && (user.search_filters) && ((user.role || CONSTANTS.ADMIN) === CONSTANTS.TECHNICIAN)) {
          saveSearchFiltersFromApi(user.search_filters)
        }
        this.props.onSelectBranch()
      }
    })
  }

  trackBranchUpdation = () => {
    const prevBranch = getUserBranch()
    const prevBranchName = getBranchName(prevBranch)
    const parameter = (prevBranchName !== '') ? { previous_branch: prevBranchName } : {}
    callFirebaseEvent(FA_CONSTANTS.FA_BRANCH_CHANGED, parameter)
  }

  trackBranchFilter = () => {
    let prevBranchName = ''

    if (getBranchIdForSearch() === -1) {
      prevBranchName = FA_CONSTANTS.FA_NOT_ASSIGNED
    } else {
      const prevBranch = getUserBranch()
      prevBranchName = getBranchName(prevBranch)
    }

    const parameter = { search_term: (prevBranchName !== '') ? prevBranchName : FA_CONSTANTS.FA_SHOW_ALL }
    callFirebaseEvent(FA_CONSTANTS.FA_FILTERUSER_BRANCH_SET, parameter)
  }

  getMenuDropDownProps () {
    if (this.state.selectedBranch === -1) {
      return [this.state.selectedBranch, strings.location_not_assigned]
    } else if (this.state.selectedBranch) {
      return [this.state.selectedBranch.id, getBranchName(this.state.selectedBranch)]
    } else {
      return [0, strings.allLocations]
    }
  }

  render () {
    const [selectedBranchId, branchName] = this.getMenuDropDownProps()

    return (
      <MenuDropDown
        listItem={this.getList()}
        onSelectItem={(branch) => this.handleSelectBranch(branch)}
        selectedItem={branchName}
        icon={LocationIcon}
        selectedId={selectedBranchId}
        style={{ primary: { backgroundColor: '#0080af' }, secondary: { backgroundColor: '#0d548c' } }}
      />
    )
  }

  getList = () => {
    const list = []
    if (this.props.branches.length > 1) {
      list.push({ id: 0, value: strings.allLocations })

      const isHaveAllAccess = isUserHaveAllLocationAccess()
      if (isHaveAllAccess) {
        list.push({ id: -1, value: strings.location_not_assigned })
      }

      for (let i = 0; i < this.props.branches.length; i++) {
        const branch = this.props.branches[i]
        list.push({ id: branch.id, value: getBranchName(branch) })
      }
    }
    return list
  }
}

export default LocationDropDown
