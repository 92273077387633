import { Component } from 'react'
import PropTypes from 'prop-types'
import { getUserList, userDetailApi } from '../../../helpers/api'
import UserTile from './UserTile'
import InfiniteScroll from 'react-infinite-scroll-component'
import Hidden from '@material-ui/core/Hidden'
import { TailSpin } from 'react-loader-spinner'
import { debounce } from 'lodash'
import AssetCountLabel from '../../AssetManagement/AssetCountLabel'
import NoMachine from '../../LayoutComponents/NoMachine'
import { withStyles } from '@material-ui/styles'
import Snackbar from '../../LayoutComponents/Snackbar'
import * as FA_CONSTANTS from '../../../utils/AnalyticsConstants.js'
import { callFirebaseEvent, ListType, getBranchName, getUserRoleString, getIDsArray, checkUserCompanyHaveBranches, createDocumentTitle } from '../../../helpers/GenericHelpers.js'
import {
  getToken,
  getData,
  getUserCompanyId,
  getUserBranch,
  getSearchFilters,
  saveUserSearchFilters,
  saveSearchFiltersFromApi,
  getBranchIdForSearch
} from '../../../utils/Authentication.js'
import AccessoryMobileView from '../../AssetManagement/AccessoryMobileView'
import FilterChipView from '../../../common/Filter/FilterChipView'
import ListScreenTitle from '../../LayoutComponents/ListScreenTitle.jsx'
import { UserListTitlesArray, UserListTitlesArrayNoLoc } from '../../LayoutComponents/ListTitles.jsx'
import { strings } from '../../../localizations/strings.js'
import { connect } from 'react-redux'
import { dataUpdate, dataExpirationThunk, APP_SLICE } from '../../../redux/slices/appSlice'
import * as CONSTANTS from '../../../utils/GenericConstants'

const useStyles = theme => ({
  fixHeader: {
    backgroundColor: 'white',
    paddingTop: '10px',
    position: 'sticky',
    top: '156px',
    zIndex: '1000',
    '@media(max-width: 959px)': {
      paddingTop: '0px',
      top: '67px'
    }
  },
  noResultsViewStyle: {
    paddingLeft: '26px',
    paddingBottom: '10px'
  }
})

class UserList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      users_list: [],
      hasMoreItems: true,
      searchTerm: '',
      userBranch: getUserBranch(),
      page: 1,
      company_id: getUserCompanyId(),
      totalItemCount: 0,
      noUsers: false,
      showResponse: false,
      ResponseStatus: '',
      filter: getSearchFilters(),
      branchAvailable: true,
      callWithNoFilters: false
    }
    this.loadFirstPageUsers()
  }

  componentDidMount () {
    document.title = createDocumentTitle(document.title, strings.menu_user_administartion)
    this.updateSearchFilter()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.shouldUpdateData && !this.props.userPopoverIsOpen) {
      this.handleSearch(this.state.searchTerm)
    }
  }

  updateSearchFilter = () => {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    userDetailApi(params, (error, response) => {
      if (!error) {
        const user = response.data.data.user
        if (user && (user.search_filters) && ((user.role || CONSTANTS.ADMIN) === CONSTANTS.TECHNICIAN)) {
          saveSearchFiltersFromApi(user.search_filters)

          this.setState({
            filter: getSearchFilters()
          })
        }
        this.setState({
          branchAvailable: checkUserCompanyHaveBranches(user)
        })
      }
    })
  }

  loadFirstPageUsers = () => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.getUsersForPage(this.state.page, this.state.filter)
  }

  getUsersForPage = debounce((pageNo, filter) => {
    const params = {
      token: getToken(),
      company_id: getUserCompanyId(),
      branchId: getBranchIdForSearch(),
      page_no: (pageNo) || 1,
      userType: getIDsArray(filter.userType),
      query: encodeURIComponent(this.state.searchTerm),
      needFilter: !this.state.callWithNoFilters
    }

    getUserList(params, (error, response) => {
      const list = this.state.users_list
      if (error) {
        this.props.dataUpdate(APP_SLICE.DATA_UPDATE_FAILED)
        if (!(list && (list.length > 0))) {
          // Call search Without Filters only if prev search was not without filters, else calls get looped
          const needsToCallSearchWithoutFilters = (this.state.callWithNoFilters === false)
          needsToCallSearchWithoutFilters
            ? this.setState({ callWithNoFilters: true, noUsers: !(list && (list.length > 0)) }, () => this.loadFirstPageUsers())
            : this.setState({ callWithNoFilters: false, noUsers: !(list && (list.length > 0)) })
        }

        this.setState({
          hasMoreItems: false,
          noUsers: !(list && (list.length > 0)),
          filter: getSearchFilters()
        })

        this.trackGetAssetsError(error)
      } else {
        this.props.dataUpdate(APP_SLICE.DATA_UPDATE_SUCCEEDED)
        this.props.dataExpirationThunk()
        const users = response.data.data.users
        const lastPageIndex = response.data.meta.last_page
        this.setState({
          users_list: list.concat(users),
          hasMoreItems: pageNo < lastPageIndex,
          page: pageNo + 1,
          totalItemCount: this.getAssetsCount(response),
          noUsers: !((list.concat(users) && (list.concat(users > 0))))
        })

        const newlist = (list.concat(users))
        const count = (newlist && newlist.length)
        this.trackGetAssets(count)
      }
    })
  }, 1000)

  trackGetAssets = (count) => {
    if (this.state.searchTerm && this.state.searchTerm !== '') {
      callFirebaseEvent(FA_CONSTANTS.FA_SEARCH, { search_term: this.state.searchTerm })
    }
    callFirebaseEvent(FA_CONSTANTS.FA_USER_LIST, { number_of_users: (count || 0) })
  }

  trackGetAssetsError = (error) => {
    if (this.state.searchTerm && this.state.searchTerm !== '') {
      callFirebaseEvent(FA_CONSTANTS.FA_ERROR_GET_USER, { search_term: this.state.searchTerm })
    } else {
      const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
      callFirebaseEvent(FA_CONSTANTS.FA_ERROR_GET_USER, { description: errorMessage })
    }
  }

  getAssetsCount = (response) => {
    let count = 0
    if (response.data && response.data.meta) {
      if (this.state.userBranch) {
        if (response.data.meta.current_page === 1) {
          count = response.data.meta.branch_count
        } else count = this.state.totalItemCount
      } else count = (response.data.meta.total) ? response.data.meta.total : 0
    }
    return count
  }

  handleSearch = (searchTerm) => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      searchTerm,
      users_list: [],
      hasMoreItems: true,
      page: 1,
      callWithNoFilters: false
    }, () => {
      this.loadFirstPageUsers()
    })
  }

  handleFilter = (filterParams) => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      filter: { userType: filterParams.userType },
      users_list: [],
      hasMoreItems: true,
      page: 1,
      callWithNoFilters: false
    }, () => {
      this.loadFirstPageUsers()
      saveUserSearchFilters(this.state.filter)
    })
  }

  handleSelectBranch () {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      userBranch: getUserBranch(),
      users_list: [],
      hasMoreItems: true,
      page: 1,
      callWithNoFilters: false
    }, () => {
      this.loadFirstPageUsers()
    })
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  handleResponse = (response) => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      showResponse: true,
      ResponseStatus: response
    })

    this.sleep(1000).then(() => {
      this.setState({
        userBranch: getUserBranch(),
        users_list: [],
        hasMoreItems: true,
        page: 1,
        showResponse: false,
        ResponseStatus: response
      }, () => {
        this.loadFirstPageUsers()
      })
    })
  }

  render () {
    const { classes } = this.props
    const loader = <div data-cy="loader" key={0}>
      <TailSpin
        type="TailSpin"
        color="#0d548c"
        height={60}
        width={30}
        wrapperStyle={{
          justifyContent: 'center'
        }}/>
    </div>
    const items = []
    this.state.users_list.map((item, i) => {
      const branch = { id: item.branch_id, name: item.branch_name, city: item.branch_city, postcode: item.branch_postcode, cost_center: item.branch_cost_center }
      return items.push(
        <UserTile
          asset={item}
          key={item.id}
          name={item.full_name}
          email={item.email}
          role={getUserRoleString(item).toUpperCase()}
          branchName={getBranchName(branch)}
          userID={item.id}
          history={this.props.history}
          updateBodyForMenuSelection={this.props.updateBodyForMenuSelection}
          companyId={this.state.company_id}
          handleResponse={(response) => this.handleResponse(response)}
          handleBranch={() => this.handleSelectBranch()}
          branchAvailable={this.state.branchAvailable}
        />
      )
    })

    const filterChipsView = <FilterChipView listType={ListType.Users} onFilter={(filterParams) => this.handleFilter(filterParams)} />
    const noResultsView = <div className={classes.noResultsViewStyle}>    <label style={{ color: '#0d548c' }}>{strings.no_users_found_title}</label></div>
    const showMoreUsersView = this.state.callWithNoFilters && !this.state.noUsers
    const viewForMobile = <div> <AccessoryMobileView parentProps={this.props.history} onSelectBranch={() => this.handleSelectBranch()} onFilter={(filterParams) => this.handleFilter(filterParams)} listType={ListType.Users} updateBodyForMenuSelection={this.props.updateBodyForMenuSelection} /> {filterChipsView} {showMoreUsersView && noResultsView}</div>
    return (
      <div>
        <div className={classes.fixHeader}>
          <Hidden only={['lg', 'xl', 'md']}>
            {viewForMobile}
          </Hidden>
          <Hidden only={['sm', 'xs']}>
            {filterChipsView}
          </Hidden>
          {(!this.state.noUsers) &&
            <div>
              {!showMoreUsersView &&
                <AssetCountLabel
                  assetsCount={this.state.totalItemCount}
                  userBranch={this.state.userBranch}
                  isLocationNotAssigned={(getBranchIdForSearch() === -1)}
                  searchTerm={this.state.searchTerm}
                  listType={ListType.Users}
                />}
              <Hidden smDown>
                {showMoreUsersView && noResultsView}
                <ListScreenTitle gridTitles={this.state.branchAvailable ? UserListTitlesArray : UserListTitlesArrayNoLoc} />
              </Hidden>
            </div>}
        </div>

        {(!this.state.noUsers) &&
          <InfiniteScroll
            dataLength={items.length} // This is important field to render the next data
            next={this.loadFirstPageUsers.bind(this)}
            hasMore={this.state.hasMoreItems}
            loader={loader}
          >
            {items}
          </InfiniteScroll>}
        {(this.state.showResponse) ? (<Snackbar type={this.state.ResponseStatus?.status} message={this.state.ResponseStatus?.message} />) : ''}

        {(this.state.noUsers) &&
          <NoMachine searchTerm={this.state.searchTerm} listType={ListType.Users} />}
      </div>
    )
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    shouldUpdateData: state.app.shouldUpdateData,
    userPopoverIsOpen: state.userPopover.isOpen
  }
}

const mapDispatchToProps = {
  dataUpdate,
  dataExpirationThunk
}

export default withStyles(useStyles)(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true })(UserList)
)
