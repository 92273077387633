import { Component } from 'react'
import AssetListTileDesktop from './AssetListTileDesktop'
import AssetListTileMobile from './AssetListTileMobile'
import Hidden from '@material-ui/core/Hidden'
import { Grid, Popover } from '@material-ui/core'

import Order from '../Orders/Order'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'

import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import * as CONSTANTS from '../../utils/url_constant.js'
import {
  callFirebaseEvent,
  isInActiveOrInService,
  getOrderId,
  ScreenTypes,
  CallingFrom
} from '../../helpers/GenericHelpers.js'
import { CreateOrderButton, OrderIdButton } from './OrderUpdateButtons'
import { connect } from 'react-redux'
import { opened as assetPopoverOpened, closed as assetPopoverClosed } from '../../redux/slices/assetPopoverSlice'

const useStyles = theme => ({
  root: {
    flexGrow: 'none',
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '100%',
    [theme.breakpoints.only('sm', 'xs')]: {
      paddingLeft: '23px'
    }
  }
})

class AssetTile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      bgColor: null
    }
    this.dataChanged = false
  }

  getOrderIcon (asset) {
    let orderIcon = ''
    if (!isInActiveOrInService(asset)) {
      const orderId = getOrderId(asset)
      orderIcon = orderId
        ? <OrderIdButton orderId={orderId} onClick={this.handleClick} />
        : <CreateOrderButton onClick={this.handleClick} />
    }
    return orderIcon
  }

  handleClick = event => {
    event.stopPropagation()
    this.setState({ anchorEl: event.currentTarget })
    this.setState({ bgColor: '#F4FBFE' })
    this.props.assetPopoverOpened()
    callFirebaseEvent(FA_CONSTANTS.FA_ORDER_OPENED, {}, this.props.asset)
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
    this.setState({ bgColor: null })
    this.props.assetPopoverClosed()
    if (this.dataChanged) { this.props.refreshList() }
    callFirebaseEvent(FA_CONSTANTS.FA_ORDER_CLOSED, {}, this.props.asset)
  }

  onResponse = (responseStatus) => {
    this.props.handleResponse(responseStatus)
    this.handleClose()
  }

  onDataChanged = () => {
    this.dataChanged = true
  }

  render () {
    const open = Boolean(this.state.anchorEl)
    const id = open ? 'simple-popover' : undefined

    const topScreen =
  (this.props.width === 'sm' || this.props.width === 'xs')
    ? ((window.innerHeight - 600) / 2)
    : ((window.innerHeight - 600 > 180) ? 180 : 10)

    const leftScreen =
  (this.props.width === 'sm' || this.props.width === 'xs')
    ? ((window.innerWidth - 300) / 2)
    : (window.innerWidth - 320)

    const orderIcon = this.getOrderIcon(this.props.asset)

    if (window.innerWidth < 959) {
      const state = {
        screenType: ScreenTypes.mobile,
        callingFrom: CallingFrom.assetList,
        asset: this.props.asset
      }

      if (open) {
        this.props.history.push(CONSTANTS.URLPATH_ORDERS, state)
      }
    }
    const orderDiv =
  (this.props.assetStatus !== 'INACTIVE' && this.props.assetAvailability !== 'IN_SERVICE')
    ? (
      <Grid item>
        {orderIcon}
        <Popover
          anchorReference='anchorPosition'
          anchorPosition={{ top: topScreen, left: leftScreen }}
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
        >
          <div style={{ height: '600px', width: '300px' }}>
            <Order
              callingFrom={CallingFrom.assetList}
              asset={this.props.asset}
              onResponse={(ResponseStatus) => this.onResponse(ResponseStatus)}
              assetStatus={this.props.assetAvailability}
              onClose={() => this.handleClose()}
              handleDataChanged={this.onDataChanged}
            />
          </div>
        </Popover>
      </Grid>
      )
    : ''

    return (
      <div
        className={this.props.classes.root}
        style={{ backgroundColor: this.state.bgColor }}
      >
        <Hidden only={['sm', 'xs']}>
          <AssetListTileDesktop
            parentProps={this.props}
            orderIcon={orderIcon}
            orderDiv={orderDiv}
            branchAvailable={this.props.branchAvailable}
            updateBodyForAssetSelected={(assetGuid) => this.props.updateBodyForAssetSelected(assetGuid)}
            previewImage={(imageGUID) => this.props.previewImage(imageGUID)}
          />
        </Hidden>
        <Hidden only={['lg', 'xl', 'md']}>
          <AssetListTileMobile
            parentProps={this.props}
            orderIcon={orderIcon}
            orderDiv={orderDiv}
            updateBodyForAssetSelected={(assetGuid) => this.props.updateBodyForAssetSelected(assetGuid)}
            previewImage={(imageGUID) => this.props.previewImage(imageGUID)}
          />
        </Hidden>
      </div>

    )
  }
}

AssetTile.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  assetPopoverOpened,
  assetPopoverClosed
}

export default withStyles(useStyles)(connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true })(AssetTile)
)
