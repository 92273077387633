import { makeStyles } from '@material-ui/core/styles'
import FieldLayoutGrid from './FieldLayoutGrid'
import { strings } from '../../../../../localizations/strings'

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#212121',
    marginTop: '20px'
  }
}))

export default function EditUserTitle (props) {
  const classes = useStyles()

  return (
    <FieldLayoutGrid
      className={classes.title}
    >
      {strings.edit_user_title}
    </FieldLayoutGrid>
  )
}
