import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import { getRestrictedBranchString } from '../../../helpers/GenericHelpers'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import HorizontalSpaceAroundGrid from '../../LayoutComponents/GridComponents/HorizontalSpaceAroundGrid'
import TextButton from '../../LayoutComponents/TextButton'
import { strings } from '../../../localizations/strings'
import classNames from 'classnames'
import { getDropDownIcon } from './AddEditUser/AddEditUserHelpers'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  textField: {
    width: '100%',
    height: '35px'
  },
  selectButtonText: {
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: '#131516',
    marginTop: '10px',
    marginBottom: '18px'
  },
  labelFont: {
    fontSize: '12px'
  },
  menuItem: {
    height: '48px'
  },
  menuItemSelected: {
    backgroundColor: 'white'
  },
  leftLabel: {
    marginLeft: '25px',
    textAlign: 'left'
  },
  rightLabel: {
    marginRight: '25px',
    textAlign: 'right'
  }
}))

const MenuProps = {
  variant: 'menu',
  getContentAnchorEl: null
}

export default function UserAccessLocationsMenu (props) {
  const classes = useStyles()
  const [selectedItems, setSelectedItems] = React.useState(props.selectedItems)
  const [hasChanges, sethasChanges] = React.useState(false)

  React.useEffect(() => {
    setSelectedItems(props.selectedItems)
  }, [props])

  const isCheckedItem = (branchId) => {
    if (branchId === props.branchId || selectedItems.includes(branchId)) {
      return true
    }
    return false
  }

  const handleChange = (event) => {
    sethasChanges(true)
    const newArray = event.target.value || []

    if (newArray.length > selectedItems.length) {
      setSelectedItems(newArray)
    } else {
      const removedItems = selectedItems.filter((id) => !newArray.includes(id))

      if (removedItems.length > 0 && removedItems[0]) {
        setSelectedItems((removedItems[0] === props.branchId) ? selectedItems : newArray)
      }
    }
  }

  const handleClose = () => {
    props.onChange(hasChanges, selectedItems)
    sethasChanges(false)
  }

  const renderValueArray = () => {
    return getRestrictedBranchString(selectedItems.length || 0, props.locations.length || 0)
  }

  const onSelectAll = () => {
    sethasChanges(true)
    const allLocationsIds = props.locations.map((item) => item.id) || []
    setSelectedItems(allLocationsIds)
  }

  const onUnSelectAll = () => {
    sethasChanges(true)
    setSelectedItems((props.branchId) ? [props.branchId] : [])
  }

  const getSelectButton = (text, onClick, isLeft) => {
    return (
      <TextButton
        onClick={onClick}
        buttonText={text}
        className={classNames(classes.selectButtonText, (isLeft ? classes.leftLabel : classes.rightLabel))}
        // labelStyle={isLeft ? { marginLeft: '25px', textAlign: 'left' } : { marginRight: '25px', textAlign: 'right' }}
      />
    )
  }

  return (
    <form className={classes.root} noValidate autoComplete='off'>
      <div>
        <InputLabel className={classes.labelFont} id='demo-mutiple-checkbox-label'>{props.name}</InputLabel>
        <FormControl data-cy={props.dataCy} className={classes.textField} error={props.hasError}>
          <Select
            labelId='demo-mutiple-checkbox-label'
            label='accesslocation'
            id='demo-mutiple-checkbox'
            multiple
            value={selectedItems}
            onChange={handleChange}
            onClose={handleClose}
            IconComponent={() => getDropDownIcon(props.stateType)}
            input={
              <Input />
            }
            displayEmpty
            MenuProps={MenuProps}
            renderValue={renderValueArray}
            className={classes.textField}

          >
            <HorizontalSpaceAroundGrid
              leftGrid={getSelectButton(strings.access_to_all, () => onSelectAll(), true)}
              rightGrid={getSelectButton(strings.only_current_location, () => onUnSelectAll(), false)}
            />

            <Divider />

            {props.locations.map((arrItem, index) => {
              const labelId = `checkbox-list-label-${index}`

              return (
                <MenuItem
                  data-cy={arrItem.name}
                  key={arrItem.id}
                  value={arrItem.id}
                  inputProps={{ 'aria-labelledby': labelId }}
                  className={classes.menuItem}
                  style={{ backgroundColor: 'white' }}
                  disabled={arrItem.id === props.branchId}
                >
                  <Checkbox checked={isCheckedItem(arrItem.id)} />
                  <ListItemText id={labelId} primary={arrItem.name} />
                </MenuItem>
              )
            })}
          </Select>
          {props.hasError &&
            <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
      </div>
    </form>
  )
}
