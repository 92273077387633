import React from 'react'
import TitleBodyLayout from '../AssetDetailTitleBodyLayout'
import LabelValueLayout from '../AssetDetailLabelValueLayout'
import RowsLayout from '../MultiGridLayout'
import {
  AssetDetailsTitle,
  AssetDetailsLabel,
  AssetDetailsValue
} from '../DetailsCommonComponents'
import { CreateOrderButton, OrderIdButton } from '../../OrderUpdateButtons.js'
import { getOrderId, ScreenTypes, CallingFrom } from '../../../../helpers/GenericHelpers'
import { Grid } from '@material-ui/core'
import OrderPopUp from './OrderPopUp'
import Snackbar from '../../../LayoutComponents/Snackbar'
import * as CONSTANTS from '../../../../utils/url_constant.js'

export default function AssetDetailsOrderData (props) {
  const [orderNumber, setOrderNumber] = React.useState(null)
  const [orderValues, setOrderValues] = React.useState(null)
  const [open, setPopoverState] = React.useState(false)
  const [showSnackBar, setshowSnackBar] = React.useState(false)
  const [responseStatus, setResponseStatus] = React.useState(null)
  const [responseMessage, setResponseMessage] = React.useState(null)
  let dataChanged = false

  React.useEffect(() => {
    const setOrderData = () => {
      const values = []
      if (props.orderGroup &&
        props.orderGroup.check_items &&
        props.orderGroup.check_items.length > 0) {
        props.orderGroup.check_items.forEach((checkItem, index) => {
          if (index === 0) {
            setOrderNumber(checkItem)
          } else {
            const checkValue = (checkItem.check_value && checkItem.check_value.value) || ''
            if (checkValue !== '') {
              values.push(checkValue)
            }
          }
        })
      }

      setOrderValues(values)
    }

    setOrderData()
  }, [props])

  const handleClose = () => {
    setPopoverState(false)
    if (dataChanged) { props.forceUpdate() }
  }
  const handleCreateOrder = () => {
    setPopoverState(true)
  }

  const handleOrderIdClick = () => {
    setPopoverState(true)
  }

  const onResponse = (responseStatus) => {
    setResponseStatus(responseStatus.status)
    setResponseMessage(responseStatus.message)
    setshowSnackBar(true)
    handleClose()
    props.forceUpdate()
  }

  const onDataChanged = () => {
    dataChanged = true
  }

  const getTitleComponent = () => {
    if (window.innerWidth < 959) {
      const state = {
        screenType: ScreenTypes.mobile,
        callingFrom: CallingFrom.assetDetails,
        asset: props.asset
      }

      if (open) {
        props.history.push(CONSTANTS.URLPATH_ORDERS, state)
      }
    }
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={1}
      >
        <AssetDetailsTitle>{props.orderGroup.name}</AssetDetailsTitle>

        <Grid item>
          <OrderPopUp
            callingFrom={CallingFrom.assetDetails}
            asset={props.asset}
            onResponse={(ResponseStatus) => onResponse(ResponseStatus)}
            onClose={handleClose}
            open={open}
            handleDataChanged={() => onDataChanged()}
          />
        </Grid>

        {(showSnackBar) ? (<Snackbar type={responseStatus} message={responseMessage} />) : ''}
      </Grid>
    )
  }

  const getBodyComponent = () => {
    const orderId = getOrderId(props.asset)
    return (
      (orderId && orderNumber)
        ? getOrderIdComponent()
        : (
          <CreateOrderButton
            onClick={handleCreateOrder}
            buttonStyle={{ marginTop: '10px' }}
            labelStyle={{ fontSize: '14px', lineHeight: '1.43', letterSpacing: '0.25px' }}
            addCircleImageStyle={{ width: '20px', height: '20px' }}
          />
          )
    )
  }

  const getOrderNumberComponent = () => {
    return (
      <LabelValueLayout
        label={<AssetDetailsLabel>{orderNumber.name}:</AssetDetailsLabel>}
        value={
          <OrderIdButton
            orderId={orderNumber.check_value.value}
            onClick={handleOrderIdClick}
            buttonStyle={{ marginLeft: '10px' }}
            labelStyle={{ fontSize: '16px', fontWeight: '300', lineHeight: '1.25', letterSpacing: '0.29px' }}
          />
        }
      />
    )
  }

  const getValueComponents = (values) => {
    const components = []
    values.map((value, index) => components.push(
      <AssetDetailsValue key={index}>
        {value}
      </AssetDetailsValue>
    ))
    return components
  }

  const getOrderValuesComponent = () => {
    return (
      orderValues ? <RowsLayout components={getValueComponents(orderValues)} /> : ''
    )
  }

  const getOrderIdComponent = (orderId) => {
    return (
      <TitleBodyLayout
        title={getOrderNumberComponent(orderId)}
        body={getOrderValuesComponent()}
        style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px' }}
        gridSpacing={0}
      />
    )
  }

  return (
    <TitleBodyLayout
      title={getTitleComponent()}
      body={getBodyComponent()}
    />

  )
}
