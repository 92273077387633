import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import { Typography, Container } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/Minimize'
import ExpandMore from '@material-ui/icons/Add'
import { FilterTypes, Availability, Activation, indexOfObject } from '../../helpers/GenericHelpers'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { strings } from '../../localizations/strings.js'
import { getChecklists } from '../../helpers/api.js'
import { getData, getUserCompanyId, getSearchFilters } from '../../utils/Authentication.js'
import Divider from '@material-ui/core/Divider'
import HdrWeakIcon from '@material-ui/icons/HdrWeak'
import ListAltIcon from '@material-ui/icons/ListAlt'
import OfflinePinIcon from '@material-ui/icons/OfflinePin'

const StyledMenu = withStyles({
  paper: {
    width: '300px',
    boxShadow: '0 2px 20px 0 rgba(0, 41, 56, 0.2)'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const useStyles = makeStyles(theme => ({
  buttonView: {
    display: 'flex',
    height: '48px',
    padding: '12px 20px 12px 20px'
  },
  locationIcon: {
    color: '#0d548c'
  },
  locationIconActive: {
    color: '#ffffff'
  },
  locationDiv: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '75%'
  },
  locationLabel: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.36px',
    color: '#002938',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  arrowUpImage: {
    color: 'white'
  },
  linkStyle: {
    color: '#0d548c'
  },
  arrowDownImage: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  filterTitle: {
    marginLeft: '10px'
  }
}))

export default function AssetsFilterView (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [openFilterSection, setOpenFilterSection] = useState(true)
  const [category, setCategory] = useState(null)
  const [filterParams, setFilterParams] = useState({ availability: [], checklists: [], activation: [] })

  const handleClick = event => {
    setMenuOpen(prevOpen => !prevOpen)
    setAnchorEl(event.currentTarget)
    const filter = getSearchFilters()
    setFilterParams(filter)
  }

  const handleToggleActivation = value => () => {
    const currentIndex = indexOfObject(filterParams.activation, value)
    const newParams = { availability: [...filterParams.availability], checklists: [...filterParams.checklists], activation: [filterParams.activation] }
    Object.assign(newParams, filterParams)
    if (currentIndex === -1) {
      newParams.activation.push(value)
    } else {
      newParams.activation.splice(currentIndex, 1)
    }
    setFilterParams(newParams)
    props.onFilter(newParams)
  }

  const handleToggleAvailability = value => () => {
    const currentIndex = indexOfObject(filterParams.availability, value)
    const newParams = { availability: [...filterParams.availability], checklists: [...filterParams.checklists], activation: [...filterParams.activation] }
    Object.assign(newParams, filterParams)

    if (currentIndex === -1) {
      newParams.availability.push(value)
    } else {
      newParams.availability.splice(currentIndex, 1)
    }
    setFilterParams(newParams)
    props.onFilter(newParams)
  }

  const handleToggleCategory = (categoryItem) => () => {
    const currentIndex = indexOfObject(filterParams.checklists, categoryItem)
    const newParams = { availability: [...filterParams.availability], checklists: [...filterParams.checklists], activation: [...filterParams.activation] }
    Object.assign(newParams, filterParams)

    if (currentIndex === -1) {
      newParams.checklists.push(categoryItem)
    } else {
      newParams.checklists.splice(currentIndex, 1)
    }
    setFilterParams(newParams)
    props.onFilter(newParams)
  }

  const prevOpen = React.useRef(menuOpen)
  React.useEffect(() => {
    prevOpen.current = menuOpen
  }, [menuOpen])

  const handleClose = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const getSectionsForMenu = () => {
    return <div>  {getActiveStatusSection()}{<Divider />} {getCategorySection()}{<Divider />}{getAvailibilitySection()} </div>
  }

  useEffect(() => {
    const filter = getSearchFilters()
    setFilterParams(filter)
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    if (getUserCompanyId() !== 0) {
      getChecklists(params, (error, response) => {
        if (!error) {
          if (response.data.data.check_lists != null) {
            setCategory(response.data.data.check_lists)
          }
        }
      })
    }
  }, [])

  const getCategorySection = () => {
    if ((category === null) || category.length === 0) {
      return <div />
    }

    const options = (category != null) ? category : []
    const section =
      <div>
        <ListItem button onClick={() => toggleFilterSection(FilterTypes.category)}>
          <ListAltIcon />
          <ListItemText data-cy='filter-button-category' primary={strings.category} style={{ marginLeft: '10px' }} />
          {openFilterSection === FilterTypes.category ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openFilterSection === FilterTypes.category} timeout='auto' unmountOnExit>
          <List data-cy='filter-list' className={classes.root}>
            {options.map((arrItem, index) => {
              const labelId = `checkbox-list-label-${index}`
              return (
                <ListItem data-cy={arrItem.name} key={index} role={undefined} dense button onClick={handleToggleCategory(arrItem)}>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={indexOfObject(filterParams.checklists, arrItem) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={arrItem.name} />
                  <ListItemSecondaryAction />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      </div>
    return section
  }

  const getActiveStatusSection = () => {
    const options = [Activation.active, Activation.inactive]
    const section =
      <div>
        <ListItem button onClick={() => toggleFilterSection(FilterTypes.activation)}>
          <HdrWeakIcon />
          <ListItemText data-cy='filter-button-activation' primary={strings.activation} style={{ marginLeft: '10px' }} />
          {openFilterSection === FilterTypes.activation ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openFilterSection === FilterTypes.activation} timeout='auto' unmountOnExit>
          <List className={classes.root}>
            {options.map((arrItem, index) => {
              const labelId = `checkbox-list-label-${index}`
              return (
                <ListItem data-cy={arrItem.name} key={index} role={undefined} dense button onClick={handleToggleActivation(arrItem)}>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={indexOfObject(filterParams.activation, arrItem) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={arrItem.name} />
                  <ListItemSecondaryAction />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      </div>
    return section
  }

  const getAvailibilitySection = () => {
    const options = [Availability.cin_draft, Availability.cout_draft, Availability.chkd_in, Availability.chkd_out, Availability.mnc]
    const section =
      <div>
        <ListItem button onClick={() => toggleFilterSection(FilterTypes.availability)}>
          <OfflinePinIcon />
          <ListItemText data-cy='filter-button-availability' primary={strings.availabilility} style={{ marginLeft: '10px' }} />
          {openFilterSection === FilterTypes.availability ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openFilterSection === FilterTypes.availability} timeout='auto' unmountOnExit>
          <List data-cy='filter-list' className={classes.root}>
            {options.map((arrItem, index) => {
              const labelId = `checkbox-list-label-${index}`
              return (
                <ListItem data-cy={arrItem.name} key={index} role={undefined} dense button onClick={handleToggleAvailability(arrItem)}>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={indexOfObject(filterParams.availability, arrItem) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={arrItem.name} />
                  <ListItemSecondaryAction />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      </div>
    return section
  }

  const getHeader = () => {
    return (
      <ListItem data-cy='reset-all-filters' key='value' button style={{ backgroundColor: '#f5f8fa' }} onClick={handleResetAllFilters()}>
        <ListItemText id='labelId' primary={strings.clear_all_filters} className={classes.linkStyle} />
        <ListItemSecondaryAction />
      </ListItem>
    )
  }

  const handleResetAllFilters = value => () => {
    const newParams = { availability: [], checklists: [], activation: [] }
    setFilterParams(newParams)
    props.onFilter(newParams)
  }

  const toggleFilterSection = (section) => {
    (openFilterSection === section) ? setOpenFilterSection(null) : setOpenFilterSection(section)
  }

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={{ backgroundColor: menuOpen ? '#0d548c' : 'white' }}
        >
          <props.icon
            className={menuOpen ? classes.locationIconActive : classes.locationIcon}
          />
          <div className={classes.locationDiv}>
            <Hidden xsDown>
              <Typography data-cy='main-filter-button' variant='subtitle1' className={classes.locationLabel} style={{ color: menuOpen ? 'white' : '#002938' }}>{strings.filter}</Typography>
            </Hidden>
          </div>
          {menuOpen ? <ArrowDropUpOutlinedIcon className={classes.arrowUpImage} /> : <ArrowDropDownOutlinedIcon className={classes.arrowDownImage} />}
        </Container>
      </Hidden>
      <Hidden only={['lg', 'xl', 'md']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={menuOpen ? props.style.primary : props.style.sec}
        >
          <img className={classes.locationIcon} alt='' src={props.icon} />

          <div className={classes.locationDiv}>

            <Typography data-cy='main-filter-button' variant='subtitle1' className={classes.locationLabel} style={{ color: menuOpen ? 'white' : 'white' }}>{strings.filter}</Typography>

          </div>
          {menuOpen ? <ArrowDropUpOutlinedIcon className={classes.arrowUpImage} style={{ color: 'white' }} /> : <ArrowDropDownOutlinedIcon className={classes.arrowDownImage} style={{ color: 'white' }} />}
        </Container>
      </Hidden>

      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
      >
        {getHeader()}
        {getSectionsForMenu()}

      </StyledMenu>

    </div>
  )
}
