import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SignUp from './components/AccessControl/SignUp'
import Login from './components/AccessControl/Login'
import ForgotPassword from './components/PasswordManagement/ForgotPassword'
import ResetPassword from './components/PasswordManagement/ResetPassword'
import * as CONSTANTS from './utils/url_constant.js'
import Dashboard from './components/Home/Dashboard'
import Order from './components/Orders/Order'
import UserInfo from './components/ManageUser/UserList/AddEditUser/AddEditUserSmall'
import AssetCreate from './components/AssetManagement/AssetCreation'
import { useAppConnectivity } from './hooks/AppConnectivity'
import { useAppVisibility } from './hooks/AppVisbility'
import PdfViewer from './components/Protocol/PdfViewer'

// A react component - Renders the whole application using the components defined above
function App () {
  useAppConnectivity()
  useAppVisibility()

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={CONSTANTS.URLPATH_HOME} component={Login} />
        <Route path={CONSTANTS.URLPATH_INVITEPERSON} component={Dashboard} />
        <Route path={CONSTANTS.URLPATH_SELECTCOMPANY} component={Dashboard} />
        <Route path={CONSTANTS.URLPATH_QRCODE} component={Dashboard} />
        <Route path={CONSTANTS.URLPATH_ASSET_LIST} component={Dashboard} />
        <Route path={CONSTANTS.URLPATH_USERLIST} component={Dashboard} />
        <Route path={CONSTANTS.URLPATH_EDIT_ME} component={Dashboard} />
        <Route path={CONSTANTS.URLPATH_LOGIN} component={Login} />
        <Route path={CONSTANTS.URLPATH_SIGN_UP} component={SignUp} />
        <Route
          path={CONSTANTS.URLPATH_FORGOT_PASSWORD}
          component={ForgotPassword}
        />
        <Route path={CONSTANTS.URLPATH_RESET_TOKEN} component={ResetPassword} />
        <Route path={CONSTANTS.URLPATH_ACTIVATE} component={ResetPassword} />
        <Route path={CONSTANTS.URLPATH_ORDERS} component={Order} />

        <Route path={CONSTANTS.URLPATH_CREATEUSER} component={UserInfo} />

        <Route path={CONSTANTS.URLPATH_CREATE_ASSET} component={AssetCreate} />
        <Route path={CONSTANTS.URLPATH_EDIT_ASSET} component={AssetCreate} />

        <Route path={CONSTANTS.URLPATH_PROTOCOL_SUMMARY} component={PdfViewer} />
        <Route path={CONSTANTS.URLPATH_PROTOCOL_IMAGES} component={PdfViewer} />

        <Route path='*' component={Dashboard} />
      </Switch>
    </BrowserRouter>
  )
}
export default App
