import { configureStore } from '@reduxjs/toolkit'
import { appSlice } from './slices/appSlice'
import { assetPopoverSlice } from './slices/assetPopoverSlice'
import { userPopoverSlice } from './slices/userPopoverSlice'

export default configureStore({
  reducer: {
    app: appSlice.reducer,
    assetPopover: assetPopoverSlice.reducer,
    userPopover: userPopoverSlice.reducer
  }
})
