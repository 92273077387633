import Joi from 'joi-browser'
import { strings } from '../../../../localizations/strings'
import { MAIL_FORMAT } from '../../../../utils/Matcher.js'

export const UserFieldsSchema = {
  salutation: Joi.string()
    .required()
    .label('salutation').error(errors => {
      return {
        message: strings.salutation_error_msg
      }
    }),
  email: Joi.string().max(192)
    .email()
    .required()
    .regex(MAIL_FORMAT)
    .label(strings.email)
    .error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case 'any.empty':
            err.message = strings.mandatory_field
            break

          case 'string.max':
            err.message = strings.max_character_length
            break

          default: err.message = strings.email_id_error_msg
            break
        }
      })
      return errors
    }),

  first_name: Joi.string().allow('').max(192)
    .label(strings.first_name).error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case 'string.max':
            err.message = strings.max_character_length
            break

          default: break
        }
      })
      return errors
    }),

  telephone: Joi.string().allow('').max(192)
    .label(strings.telephone).error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case 'string.max':
            err.message = strings.max_character_length
            break

          default: break
        }
      })
      return errors
    }),

  last_name: Joi.string().max(192)
    .required()
    .label(strings.last_name).error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case 'any.empty':
            err.message = strings.last_name_error_msg
            break

          case 'string.max':
            err.message = strings.max_character_length
            break

          default: break
        }
      })
      return errors
    })
}
