import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import FieldLayoutGrid from './FieldLayoutGrid'

const useStyles = makeStyles(theme => ({
  layoutLoader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 0px 10px',
    width: '100%',
    height: '100%',
    zIndex: 2,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export default function ProgressLoader (props) {
  const classes = useStyles()

  return (
    <Grid container>
      <FieldLayoutGrid
        className={classes.layoutLoader}
      >
        <CircularProgress />
      </FieldLayoutGrid>
    </Grid>
  )
}
