import { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import UserIcon from '../../../assets/images/user_list_placeholder.svg'
import { getRestrictedBranchString } from '../../../helpers/GenericHelpers'
import classNames from 'classnames'

const useStyles = theme => ({
  image: {
    width: '80px',
    height: '80px',
    position: 'relative',
    textAlign: 'center'
  },
  icon: {
    display: 'flex'
  },
  topRight: {
    position: 'absolute',
    top: '-12px',
    right: '-12px'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '4px'
  },
  content: {
    wordBreak: 'break-word',
    paddingLeft: '10px'
  },
  inventoryNoChecklistName: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: '400'
  },
  restrictedAccess: {
    fontSize: '12px'
  },
  assetName: {
    fontWeight: '500'
  }
})

class UserTileLarge extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { classes } = this.props
    const user = this.props.parentProps
    const isTechnician = (user.role && (user.role === 'USER' || user.role === 'NUTZER'))
    const companyBranchCount = user.asset.company_branch_count || 0
    const restrictedBranchCount = user.asset.branch_count || 0
    return (
      <Grid container spacing={1} className={this.props.classes.content}>
        <Grid item xs={1} className={this.props.classes.icon}>
          <img src={UserIcon} alt='UserIcon' />
        </Grid>
        <Grid item xs>
          <Typography data-cy='user-name' variant='subtitle2' className={classes.assetName}>
            {user.name}
          </Typography>
          <Typography data-cy='user-email' variant='subtitle2' className={classes.inventoryNoChecklistName}>
            {user.email}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography data-cy='user-role' variant='subtitle2' className={classes.inventoryNoChecklistName}>
            {user.role}
          </Typography>
        </Grid>
        {this.props.branchAvailable &&
          <Grid item xs={2}>
            <Typography data-cy='user-branch' variant='subtitle2' className={classNames(classes.inventoryNoChecklistName)}>
              {user.branchName}
            </Typography>
            {isTechnician && (companyBranchCount > 0) &&
              <Typography
                variant='subtitle2'
                className={(user.branchName && user.branchName !== '')
                  ? classNames(classes.inventoryNoChecklistName, classes.restrictedAccess)
                  : classNames(classes.inventoryNoChecklistName)}
              >
                {getRestrictedBranchString(restrictedBranchCount, companyBranchCount, 'description')}
              </Typography>}
          </Grid>}
        <Grid data-cy='user-edit' item xs={2}>
          {this.props.orderDiv}
        </Grid>
      </Grid>
    )
  }
}

UserTileLarge.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(UserTileLarge)
