import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import FieldLayoutGrid from './FieldLayoutGrid'

const useStyles = makeStyles(theme => ({
  closeButton: {
    float: 'right',
    width: '25px',
    height: '25px',
    marginTop: '1%'
  }
}))

export default function CloseButton (props) {
  const classes = useStyles()

  return (
    <FieldLayoutGrid
      style={{ paddingRight: '4%' }}
    >
      <CloseIcon
        data-cy='close-form-icon'
        className={classes.closeButton}
        onClick={() => props.onClose()}
      />
    </FieldLayoutGrid>
  )
}
