import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const useStyle = theme => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#0d548c',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#0d548c',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 22,
    height: 22
  },
  track: {
    borderRadius: 12,
    border: '1px solid #c1c7c9',
    backgroundColor: '#f2f3f4',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
})

class KCSwitch extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <div>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked
          }}
          {...this.props}

        />
      </div>
    )
  }
}

export default withStyles(useStyle)(KCSwitch)
