import { Component } from 'react'
import { getData, saveUserDataToLocalStorage, getUserBranch, getUserCompanyId } from '../../utils/Authentication.js'
import { getBranchList, saveBranchLocation, userDetailApi } from '../../helpers/api.js'
import { getBranchName, getBranchFrom, callFirebaseEvent } from '../../helpers/GenericHelpers.js'
import { strings } from '../../localizations/strings.js'
import AddItemForm from './AddItemForm'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import FilterIcon from '@material-ui/icons/AccountCircle'
import { ReactComponent as IconAssetList } from '../../assets/images/machine.svg'

class AddItemView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      branches: [],
      selectedBranch: getUserBranch()
    }
  }

  componentDidMount () {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    if (getUserCompanyId() !== 0) {
      getBranchList(params, (error, response) => {
        if (error) {
          // this.props.handleBranchAvailability([])

          const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
          callFirebaseEvent(FA_CONSTANTS.FA_E_GET_BRANCHES, { description: errorMessage })
        } else {
          if (response.data.data.branches != null) {
            this.setState({ branches: response.data.data.branches })
          }
          // this.props.handleBranchAvailability(response.data.data.branches)
        }
      })
    }
  }

  onResponse = (ResponseStatus) => {
    this.props.onResponse(ResponseStatus)
  }

  handleSelectBranch = (branchId) => {
    const branch = getBranchFrom(this.state.branches, branchId)
    this.setState({ selectedBranch: branch })
    this.saveUserBranchLocation(branchId)
  }

  saveUserBranchLocation = (branchId) => {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId(),
      branch_id: branchId
    }
    saveBranchLocation(params, (error, response) => {
      if (error) {
        const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
        callFirebaseEvent(FA_CONSTANTS.FA_E_SAVE_BRANCH, { description: errorMessage })
      } else {
        this.getUserDetails()
      }
    })
  }

  getUserDetails = () => {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    userDetailApi(params, (error, response) => {
      if (!error) {
        this.trackBranchUpdation()
        saveUserDataToLocalStorage(response.data.data.user)
        this.props.onSelectBranch()
      }
    })
  }

  trackBranchUpdation = () => {
    const prevBranch = getUserBranch()
    const prevBranchName = getBranchName(prevBranch)
    const parameter = (prevBranchName !== '') ? { previous_branch: prevBranchName } : {}
    callFirebaseEvent(FA_CONSTANTS.FA_BRANCH_CHANGED, parameter)
  }

  render () {
    const branchName = getBranchName(this.state.selectedBranch)

    return (
      <AddItemForm
        parentProps={this.props.history}
        listItem={this.getList()}
        filterParams={this.props.filterParams}
        onSelectItem={(branch) => this.handleSelectBranch(branch)}
        selectedItem={(branchName !== '') ? branchName : strings.allLocations}
        icon={this.props.action === 'addUser' ? FilterIcon : IconAssetList}
        label={this.props.action === 'addUser' ? strings.add_user : strings.add_machine}
        branch={(this.state.selectedBranch)}
        selectedId={(this.state.selectedBranch) ? this.state.selectedBranch.id : 0}
        style={{ primary: { backgroundColor: '#0d548c' }, secondary: { backgroundColor: '#0d548c' } }}
        onResponse={(ResponseStatus) => this.onResponse(ResponseStatus)}
      />
    )
  }

  getList = () => {
    const list = []
    if (this.state.branches.length > 0) {
      list.push({ id: 0, value: strings.allLocations })
    }
    for (let i = 0; i < this.state.branches.length; i++) {
      const branch = this.state.branches[i]
      list.push({ id: branch.id, value: getBranchName(branch) })
    }
    return list
  }
}

export default AddItemView
