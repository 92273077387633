import { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import AssetImage from './AssetImage'

const useStyles = theme => ({
  content: {
    wordBreak: 'break-word',
    paddingLeft: '10px',
    '&:hover': {
      background: 'rgba(0, 174, 239, 0.05)'
    }
  },
  mainGrid: {
    cursor: 'pointer'
  }
})

class AssetTileDesktop extends Component {
  handleOnClick = event => {
    event.stopPropagation()
    this.props.updateBodyForAssetSelected(this.props.parentProps.assetGuid)
  }

  handleOnImageClick = event => {
    event.stopPropagation()
    let guid
    try {
      guid = this.props.parentProps.asset.image.guid
    } catch {
      return
    }
    if (guid) {
      this.props.previewImage(guid)
    }
  }

  render () {
    return (
      <Grid data-cy='each-machine' container spacing={1} className={this.props.classes.content}>
        <Grid item xs={2}>
          <AssetImage onClick={this.handleOnImageClick} width='80px' height='80px' imgSrc={this.props.parentProps.image} assetStatus={this.props.parentProps.assetStatus} assetAvailability={this.props.parentProps.assetAvailability} />
        </Grid>
        <Grid onClick={this.handleOnClick} item xs className={this.props.classes.mainGrid}>
          <Typography data-cy='asset-name' variant='subtitle1'>
            {this.props.parentProps.assetName}
          </Typography>
          <Typography data-cy='asset-checklist-name' variant='subtitle1'>
            {this.props.parentProps.checklistName}
          </Typography>
        </Grid>
        <Grid onClick={this.handleOnClick} item xs={2} className={this.props.classes.mainGrid}>
          <Typography data-cy='asset-inventory-no' variant='subtitle1'>
            {this.props.parentProps.inventoryNo}
          </Typography>
        </Grid>
        {this.props.branchAvailable &&
          <Grid onClick={this.handleOnClick} item xs={2} className={this.props.classes.mainGrid}>
            <Typography data-cy='asset-branch-name' variant='subtitle1'>
              {this.props.parentProps.branchName}
            </Typography>
          </Grid>}
        <Grid data-cy='asset-order' item xs={2}>
          {this.props.orderDiv}
        </Grid>
      </Grid>
    )
  }
}

AssetTileDesktop.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(AssetTileDesktop)
