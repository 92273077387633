import { strings } from '../../localizations/strings'

export const AssetListTitlesArray = [
  { title: strings.asset_list_header_1, gridSpaceXs: 2, key: 'machine-header' },
  { title: strings.asset_list_header_2, gridSpaceXs: true, key: 'asset-detail-header' },
  { title: strings.asset_list_header_3, gridSpaceXs: 2, key: 'inventory-no-header' },
  { title: strings.location, gridSpaceXs: 2, key: 'location-header' },
  { title: strings.asset_list_header_5, gridSpaceXs: 2, key: 'order-no-header' }
]

export const AssetListTitlesArrayNoloc = [
  { title: strings.asset_list_header_1, gridSpaceXs: 2, key: 'machine-header' },
  { title: strings.asset_list_header_2, gridSpaceXs: true, key: 'asset-detail-header' },
  { title: strings.asset_list_header_3, gridSpaceXs: 2, key: 'inventory-no-header' },
  { title: strings.asset_list_header_5, gridSpaceXs: 2, key: 'order-no-header' }
]

export const UserListTitlesArray = [
  { title: '', gridSpaceXs: 1 },
  { title: strings.user_list_header_1, gridSpaceXs: true },
  { title: strings.user_list_header_2, gridSpaceXs: 2 },
  { title: strings.location, gridSpaceXs: 2 },
  { title: strings.user_list_header_4, gridSpaceXs: 2 }
]

export const UserListTitlesArrayNoLoc = [
  { title: '', gridSpaceXs: 1 },
  { title: strings.user_list_header_1, gridSpaceXs: true },
  { title: strings.user_list_header_2, gridSpaceXs: 2 },
  { title: strings.user_list_header_4, gridSpaceXs: 2 }
]
