import { Component } from 'react'

import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CommonCarousel from '../../common/CommonCarousel'

import carouselData from './QRCodeCarouselData'

const useStyles = theme => ({
  carouselMain: {
    backgroundColor: '#ffffff00',
    marginBottom: '50px'
  },
  carouselText: {
    color: '#4d4d4d',
    '@media(max-width: 1279px)': {
      fontSize: '14px',
      color: 'black'
    }
  },
  carouselContainer: {
    display: 'flex',
    '@media(max-width: 1279px)': {
      justifyContent: 'center'
    }
  },
  carouselContent: {
    paddingLeft: '80px !important',
    marginTop: '12%',
    order: 1,
    '@media(max-width: 1279px)': {
      paddingLeft: '15% !important',
      paddingRight: '11% !important',
      marginTop: '0px',
      order: 2
    }
  },
  carouselImage: {
    paddingRight: '20px',
    order: 2,
    '@media(max-width: 1279px)': {
      order: 1
    }
  },
  carouselTitle: {
    '@media(max-width: 1279px)': {
      fontSize: '16px'
    }
  }
})

class QRCodeCarousel extends Component {
  render () {
    const { classes } = this.props
    const carouselItems = carouselData.map(function (item) {
      return (
        <div className={classes.carouselMain} key={1}>
          <Grid container spacing={2} className={classes.carouselContainer}>
            <Grid item xs={12} lg={8} md={12} sm={12} className={classes.carouselContent}>
              <Typography variant='h6' align='left' className={classes.carouselTitle}>
                {item.title}
              </Typography>
              <Typography variant='subtitle1' className={classes.carouselText} align='left'>
                {item.description}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} md={9} sm={9} className={classes.carouselImage}>
              <img src={item.image} alt='' />
            </Grid>
          </Grid>
        </div>
      )
    })

    return <CommonCarousel items={carouselItems} />
  }
}

QRCodeCarousel.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(QRCodeCarousel)
