import { Component } from 'react'
import PropTypes from 'prop-types'
import { assetListApi, userDetailApi } from '../../helpers/api'
import AssetTile from './AssetTile'
import InfiniteScroll from 'react-infinite-scroll-component'
import Hidden from '@material-ui/core/Hidden'
import { TailSpin } from 'react-loader-spinner'
import { debounce } from 'lodash'
import AssetCountLabel from './AssetCountLabel'
import NoMachine from '../LayoutComponents/NoMachine'
import { withStyles } from '@material-ui/styles'
import Snackbar from '../LayoutComponents/Snackbar'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import {
  getBranchName,
  callFirebaseEvent,
  ListType,
  getIDsArray,
  checkUserCompanyHaveBranches,
  createDocumentTitle
} from '../../helpers/GenericHelpers.js'
import * as CONSTANTS from '../../utils/GenericConstants'
import FilterChipView from '../../common/Filter/FilterChipView'
import {
  getToken,
  getData,
  getUserBranch,
  getUserCompanyId,
  getSearchFilters,
  saveAssetSearchFilters,
  saveSearchFiltersFromApi,
  getBranchIdForSearch
} from '../../utils/Authentication.js'
import AccessoryMobileView from './AccessoryMobileView'
import ListScreenTitle from '../LayoutComponents/ListScreenTitle.jsx'
import { AssetListTitlesArray, AssetListTitlesArrayNoloc } from '../LayoutComponents/ListTitles.jsx'
import { strings } from '../../localizations/strings.js'
import { connect } from 'react-redux'
import { dataUpdate, dataExpirationThunk, APP_SLICE } from '../../redux/slices/appSlice'

const useStyles = theme => ({
  fixHeader: {
    backgroundColor: 'white',
    position: 'sticky',
    top: '156px',
    paddingTop: '10px',
    zIndex: '1000',
    '@media(max-width: 959px)': {
      paddingTop: '0px',
      top: '67px'
    }
  },
  noResultsViewStyle: {
    paddingLeft: '26px',
    paddingBottom: '10px'
  }
})

class AssetList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      asset_list: [],
      hasMoreItems: true,
      searchTerm: '',
      userBranch: getUserBranch(),
      page: 1,
      totalAssets: 0,
      noAssets: false,
      showResponse: false,
      ResponseStatus: '',
      filter: getSearchFilters(),
      branchAvailable: true,
      callWithNoFilters: false
    }
    this.loadInitialAssets()
  }

  componentDidMount () {
    document.title = createDocumentTitle(document.title, strings.asset_list)
    this.updateSearchFilter()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.shouldUpdateData && !this.props.assetPopoverIsOpen) {
      this.handleSearch(this.state.searchTerm)
    }
  }

  updateSearchFilter = () => {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    userDetailApi(params, (error, response) => {
      if (!error) {
        const user = response.data.data.user

        if (user && (user.search_filters) && ((user.role || CONSTANTS.ADMIN) === CONSTANTS.TECHNICIAN)) {
          saveSearchFiltersFromApi(user.search_filters)

          this.setState({
            filter: getSearchFilters()
          })
        }

        this.setState({
          branchAvailable: checkUserCompanyHaveBranches(user)
        })
      }
    })
  }

  loadInitialAssets = () => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.getAssetListData(this.state.page, this.state.filter)
  }

  getAssetListData = debounce((pageNo, filter) => {
    const params = {
      token: getToken(),
      company_id: getUserCompanyId(),
      branchId: getBranchIdForSearch(),
      page_no: (pageNo) || 1,
      chklt_id: getIDsArray(filter.checklists),
      activation: getIDsArray(filter.activation),
      availability: getIDsArray(filter.availability),
      query: encodeURIComponent(this.state.searchTerm),
      needFilter: !this.state.callWithNoFilters
    }

    assetListApi(params, (error, response) => {
      const list = this.state.asset_list
      if (error) {
        this.props.dataUpdate(APP_SLICE.DATA_UPDATE_FAILED)
        if (!(list && (list.length > 0))) {
          // Call search Without Filters only if prev search was not without filters, else calls get looped
          const needsToCallSearchWithoutFilters = (this.state.callWithNoFilters === false)
          needsToCallSearchWithoutFilters
            ? this.setState({ callWithNoFilters: true, noAssets: !(list && (list.length > 0)) }, () => this.loadInitialAssets())
            : this.setState({ callWithNoFilters: false, noAssets: !(list && (list.length > 0)) })
        }
        this.setState({
          hasMoreItems: false,
          noAssets: !(list && (list.length > 0)),
          filter: getSearchFilters()
        })
        this.trackGetAssetsError(error)
      } else {
        this.props.dataUpdate(APP_SLICE.DATA_UPDATE_SUCCEEDED)
        this.props.dataExpirationThunk()
        this.setState({
          asset_list: list.concat(response.data.data.assets),
          hasMoreItems: true,
          page: pageNo + 1,
          totalAssets: this.getAssetsCount(response),
          noAssets: !((list.concat(response.data.data.assets) && (list.concat(response.data.data.assets > 0))))
        })
        const newlist = (list.concat(response.data.data.assets))
        const count = (newlist && newlist.length)
        this.trackGetAssets(count)
      }
    }
    )
  }, 1000)

  trackGetAssets = (count) => {
    if (this.state.searchTerm && this.state.searchTerm !== '') {
      callFirebaseEvent(FA_CONSTANTS.FA_SEARCH, { search_term: this.state.searchTerm })
    }
    callFirebaseEvent(FA_CONSTANTS.FA_LIST_VIEW, { number_of_machines: (count || 0) })
  }

  trackGetAssetsError = (error) => {
    if (this.state.searchTerm && this.state.searchTerm !== '') {
      callFirebaseEvent(FA_CONSTANTS.FA_E_GET_ASSETS, { search_term: this.state.searchTerm })
    } else {
      const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
      callFirebaseEvent(FA_CONSTANTS.FA_E_GET_ASSETS, { description: errorMessage })
    }
  }

  getAssetsCount = (response) => {
    let count = 0
    if (response.data && response.data.meta) {
      if (this.state.userBranch) {
        if (response.data.meta.current_page === 1) {
          count = response.data.meta.branch_count
        } else count = this.state.totalAssets
      } else count = (response.data.meta.total) ? response.data.meta.total : 0
    }
    return count
  }

  handleSearch = (searchTerm) => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      searchTerm,
      asset_list: [],
      hasMoreItems: true,
      callWithNoFilters: false,
      page: 1
    }, () => {
      this.loadInitialAssets()
    })
  }

  handleFilter = (filterParams) => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      filter: { availability: filterParams.availability, checklists: filterParams.checklists, activation: filterParams.activation },
      asset_list: [],
      hasMoreItems: true,
      callWithNoFilters: false,
      page: 1
    }, () => {
      this.loadInitialAssets()
      saveAssetSearchFilters(this.state.filter)
    })
  }

  handleSelectBranch () {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      userBranch: getUserBranch(),
      asset_list: [],
      hasMoreItems: true,
      callWithNoFilters: false,
      page: 1
    }, () => {
      this.loadInitialAssets()
    })
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  handleResponse = (response) => {
    this.props.dataUpdate(APP_SLICE.DATA_UPDATE_STARTED)
    this.setState({
      showResponse: true,
      ResponseStatus: response
    })

    this.sleep(1000).then(() => {
      this.refreshList()
    })
  }

  refreshList = () => {
    this.setState({
      userBranch: getUserBranch(),
      asset_list: [],
      hasMoreItems: true,
      page: 1,
      showResponse: false,
      ResponseStatus: ''
    }, () => {
      this.loadInitialAssets()
    })
  }

  routeToDetailPage = (response) => {
    this.props.history.push({
      pathname: '/' + response
    })
  }

  previewAssetImage = (imageGUID) => {
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: '?open=' + imageGUID
    })
  }

  render () {
    const { classes } = this.props
    const propsListType = this.props.listType
    const loader = <div data-cy="loader" key={0}>
      <TailSpin
        type="TailSpin"
        color="#0d548c"
        height={60}
        width={30}
        wrapperStyle={{
          justifyContent: 'center'
        }}/>
    </div>
    const items = []
    this.state.asset_list.map((item, i) => {
      return items.push(
        <AssetTile
          history={this.props.history}
          asset={item}
          key={item.id}
          assetName={item.name}
          image={item.image}
          checklistName={item.check_list.name}
          inventoryNo={item.inventory_number ? item.inventory_number : ''}
          branchName={getBranchName(item.branch)}
          orderId={item.asset_transaction && item.asset_transaction.invoice_no ? item.asset_transaction.invoice_no : ''}
          assetAvailability={item.availability}
          assetStatus={item.status}
          assetId={item.id}
          companyId={item.company_id}
          handleResponse={(response) => this.handleResponse(response)}
          branchAvailable={this.state.branchAvailable}
          assetGuid={item.guid}
          updateBodyForAssetSelected={(assetGuid) => this.routeToDetailPage(assetGuid)}
          previewImage={(imageGuid) => this.previewAssetImage(imageGuid)}
          refreshList={this.refreshList}
        />
      )
    })
    const filterChipsView = <FilterChipView listType={ListType.Machines} onFilter={(filterParams) => this.handleFilter(filterParams)} />
    const noResultsView = <div className={classes.noResultsViewStyle}><label style={{ color: '#0d548c' }}>{strings.no_machine_found_title}</label></div>
    const showMoreAssetsView = this.state.callWithNoFilters && !this.state.noAssets
    const viewForMobile = <div><AccessoryMobileView parentProps={this.props.history} onFilter={(filterParams) => this.handleFilter(filterParams)} onSelectBranch={() => this.handleSelectBranch()} listType={propsListType} />  {filterChipsView}  {showMoreAssetsView && noResultsView}    </div>
    return (
      <div>
        <div className={classes.fixHeader}>
          <Hidden only={['lg', 'xl', 'md']}>
            {viewForMobile}
          </Hidden>

          <Hidden only={['sm', 'xs']}>
            {filterChipsView}
          </Hidden>

          {(!this.state.noAssets) &&
            <div>
              {!showMoreAssetsView &&
                <AssetCountLabel
                  assetsCount={this.state.totalAssets}
                  userBranch={this.state.userBranch}
                  isLocationNotAssigned={(getBranchIdForSearch() === -1)}
                  searchTerm={this.state.searchTerm}
                  listType={propsListType}
                />}
              <Hidden smDown>
                {showMoreAssetsView && noResultsView}
                <ListScreenTitle gridTitles={this.state.branchAvailable ? AssetListTitlesArray : AssetListTitlesArrayNoloc} />
              </Hidden>
            </div>}
        </div>

        {(!this.state.noAssets) &&
          <div>
            <InfiniteScroll
              dataLength={items.length} // This is important field to render the next data
              next={this.loadInitialAssets.bind(this)}
              hasMore={this.state.hasMoreItems}
              loader={loader}
            >
              {items}
            </InfiniteScroll>
          </div>}
        {(this.state.showResponse) ? (<Snackbar type={this.state.ResponseStatus.status} message={this.state.ResponseStatus.message} />) : ''}

        {(this.state.noAssets) &&
          <NoMachine searchTerm={this.state.searchTerm} listType={propsListType} />}
      </div>
    )
  }
}

AssetList.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    assetPopoverIsOpen: state.assetPopover.isOpen,
    shouldUpdateData: state.app.shouldUpdateData
  }
}

const mapDispatchToProps = {
  dataUpdate,
  dataExpirationThunk
}

export default withStyles(useStyles)(connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true })(AssetList)
)
