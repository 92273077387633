import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { strings } from '../../localizations/strings.js'
import Container from '@material-ui/core/Container'
import { loginAPI } from '../../helpers/api.js'
import ResponseFailure from '../../common/ResponseFailure'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent } from '../../helpers/GenericHelpers.js'
import * as CONSTANTS from '../../utils/url_constant'
import {
  isLoggedIn,
  saveLoginDataToLocalStorage
} from '../../utils/Authentication.js'
import Joi from 'joi-browser'
import Form from '../../common/form'
import { MAIL_FORMAT } from '../../utils/Matcher.js'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

const useStyles = theme => ({
  pageLayout: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
  middleLayoutss: {
    flex: 1
  },
  plainButton: {
    color: '#555f60',
    textAlign: 'left'
  },
  button: {
    marginTop: '20px',
    height: '48px',
    '& .MuiButton-label': {
      color: '#303030'
    }
  },
  demo: {
    paddingBottom: '2%',
    paddingTop: '2%',
    marginTop: '4%',
    background: 'white',
    border: '10px',
    display: 'block',
    boxShadow: '0 2px 20px 0 rgba(0, 41, 56, 0.1)',
    minHeight: '30vh',
    flexDirection: 'column',
    flex: '1',
    marginBottom: '4%'
  },
  loginForgotPassword: {
    marginTop: '10px',
    fontSize: '14px'
  },
  topPadding20: {
    paddingTop: '20px'
  },
  loginTitle: {
    maxWidth: '318px',
    fontSize: '20px',
    fontFamily: 'Roboto',
    marginBottom: '10px',
    fontWeight: '300'
  }
})

class Login extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        email: '',
        password: ''
      },
      showPassword: false,
      errors: {},
      open: false,
      setOpen: false,
      emailErr: '',
      passwordErr: ''
    }
  }

  schema = {
    email: Joi.string()
      .email()
      .required()
      .regex(MAIL_FORMAT)
      .label('email')
      .error(errors => {
        return {
          message: strings.email_id_error_msg
        }
      }),

    password: Joi.string()
      .required()
      .label('password')
      .error(errors => {
        return {
          message: strings.password_error_msg
        }
      })
  }

  closePopUp = () => {
    this.setState({
      open: false
    })
    this.props.history.push('/login')
  }

  doSubmit = (errors) => {
    const { email, password } = this.state.data
    const params = {
      email,
      password
    }
    if (!errors) {
      loginAPI(params, (error, response) => {
        if (error) {
          const errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
          callFirebaseEvent(FA_CONSTANTS.FA_E_LOGIN_FAILED, { description: errorMessage })
          if (errorMessage === 'Incorrect email or password.') {
            this.setState({
              open: true,
              setOpen: true,
              message_title: strings.invalid_username_password_title,
              message: strings.invalid_username_password_message,
              link: false,
              linkTitle: ''
            })
          } else return window.alert(strings.something_went_wrong)
        } else {
          saveLoginDataToLocalStorage(response)
          if (isLoggedIn()) {
            callFirebaseEvent(FA_CONSTANTS.FA_LOGIN_SUCCESS)
            this.props.history.push(CONSTANTS.URLPATH_LANDING)
          }
        }
      })
    }
  }

  componentDidMount () {
    callFirebaseEvent(FA_CONSTANTS.FA_LOGIN_OPENED)
  }

  focusEvent = input => {
    if (input.name === 'email') {
      if (this.state.errors.email === null) {
        callFirebaseEvent(FA_CONSTANTS.FA_LOGIN_EMAIL_ENTERED)
      } else callFirebaseEvent(FA_CONSTANTS.FA_E_INVALID_EMAIL)
    } else if (input.name === 'password' && this.state.errors.password === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_LOGIN_PASSWORD_ENTERED)
    }
  }

  render () {
    const { classes } = this.props
    if (isLoggedIn()) {
      this.props.history.push(CONSTANTS.URLPATH_LANDING)
    }
    return (
      <div className={classes.pageLayout}>
        <Header id='header' />
        <div className={classes.middleLayoutss} style={{ flex: 1 }}>
          <Container maxWidth='sm' className={classes.demo}>
            <form>
              <Typography
                variant='h5'
                component='h5'
                align='left'
                className={classes.loginTitle}
              >
                {strings.login_title}
              </Typography>

              <Grid container direction='row' justifyContent='center' spacing={3} data-cy='login-form'>
                {this.renderInput('email', strings.email, classes)}

                {this.renderInput(
                  'password',
                  strings.password,
                  classes,
                  'show',
                  'password'
                )}
              </Grid>
              <Grid
                container
                direction='column'
                justifyContent='center'
                spacing={0}
                className={classes.loginForgotPassword}
              />

              {this.renderButton(strings.login_button, classes.button, 'submit')}
              <Grid
                data-cy='forgot-password-link'
                container
                direction='row'
                justifyContent='flex-start'
                spacing={0}
                className={classes.topPadding20}
              >
                {this.renderLink(
                  'forgot_password',
                  strings.forgot_password,
                  classes.plainButton
                )}
              </Grid>
            </form>
          </Container>
        </div>
        <Footer id='footer' />
        <ResponseFailure
          open={this.state.open}
          setOpen={this.state.setState}
          message_title={this.state.message_title}
          message={this.state.message}
          closePopUp={this.closePopUp}
        />
      </div>

    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(Login)
