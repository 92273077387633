import axios from 'axios'
import { API_URL } from '../urlFile'
import { checkUserDetailsChanges, getParameterQueryString, getActivationQueryString } from './GenericHelpers.js'
import { getUserRole } from '../utils/Authentication'
import store from '../redux/logoutStore'
import { logoutAction } from '../redux/actionTypes'
import * as CONSTANTS from '../utils/GenericConstants'
const baseUrl = API_URL
const platformHeader = 'Web'

axios.defaults.headers.common.platform = platformHeader

export function loginAPI (requestParams, handler) {
  axios
    .post(baseUrl + 'login', requestParams)
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      handler(error, null)
    })
}

export function logout (requestParams, handler) {
  axios
    .post(baseUrl + 'logout', requestParams.body, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      handler(error, null)
    })
}

export function signUpAPI (requestParams, handler) {
  axios
    .post(baseUrl + 'register', requestParams)
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      handler(error, null)
    })
}

export function forgetPasswordAPI (requestParams, handler) {
  axios
    .post(baseUrl + 'forgot_password', requestParams)
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      handler(error, null)
    })
}

export function ValidateForgetPasswordToken (requestParams, handler) {
  axios
    .get(baseUrl + 'reset/' + requestParams.token + '/' + requestParams.type)
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      handler(error, null)
    })
}

export function userDetailApi (requestParams, handler) {
  const companyQuery = getParameterQueryString('company_id=', requestParams.company_id)

  axios
    .get(baseUrl + 'user_details?' + companyQuery, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function ResetPasswordAPI (requestParams, handler) {
  axios
    .post(baseUrl + 'reset_password', requestParams)
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      handler(error, null)
    })
}

export function getCompanyData (requestParams, handler) {
  const companyId = (requestParams.companyId && (requestParams.companyId !== 0)) ? requestParams.companyId : ''

  axios
    .get(baseUrl + 'company_details/' + companyId, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function getUserList (requestParams, handler) {
  const companyQuery = getParameterQueryString('company_id=', requestParams.company_id)
  const userTypeQuery = requestParams.userType ? '&role=' + requestParams.userType.toString() : ''
  let branchQuery = ''
  if (companyQuery !== '') {
    branchQuery = '&branch_id=' + (requestParams.branchId ? requestParams.branchId : '')
  }
  const paramString = requestParams.needFilter ? (branchQuery + userTypeQuery) : ''
  const url = baseUrl + 'users/search?' + companyQuery + paramString + '&page=' + requestParams.page_no + '&query=' + requestParams.query
  axios
    .get(url, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function updateCompanyData (requestParams, handler) {
  const companyId = (requestParams.companyId && (requestParams.companyId !== 0)) ? requestParams.companyId : ''

  axios
    .post(
      baseUrl + 'company/update/' + companyId,
      requestParams,
      { headers: { Authorization: 'Bearer ' + requestParams.token } }
    )
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function getFeedBackLink (requestParams, handler) {
  axios
    .get(
      baseUrl + 'get_survey_url',
      requestParams,
      { headers: { Authorization: 'Bearer ' + requestParams.token } }
    )
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function deleteCompanyAccount (requestParams, handler) {
  const companyId = (requestParams.companyId && (requestParams.companyId !== 0)) ? requestParams.companyId : ''

  axios
    .post(
      baseUrl + 'company/deactivate/' + companyId,
      requestParams,
      { headers: { Authorization: 'Bearer ' + requestParams.token } }
    )
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function updateUserData (requestParams, handler, key) {
  if (requestParams.company_id === 0) {
    delete requestParams.company_id
  }

  axios
    .post(baseUrl + 'update_user/' + requestParams.userId, requestParams, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res, key)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null, key)
    })
}

export function createUser (requestParams, handler) {
  if (requestParams.company_id === 0) {
    delete requestParams.company_id
  }

  axios
    .post(baseUrl + 'create_user', requestParams, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function companyListApi (requestParams, handler) {
  axios
    .get(baseUrl + 'company/list', {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function getBranchList (requestParams, handler) {
  const companyQuery = getParameterQueryString('company_id=', requestParams.company_id)
  const userQuery = getParameterQueryString('&user_id=', requestParams.userId)
  axios
    .get(baseUrl + 'branch/list?' + companyQuery + userQuery, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function getChecklists (requestParams, handler) {
  const companyQuery = getParameterQueryString('company/', requestParams.company_id)
  axios
    .get(baseUrl + companyQuery + '/check_list', {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function qrCodeGenerate (requestParams, handler) {
  axios
    .get(baseUrl + 'qr_code/create/' + requestParams.page_count, {
      responseType: 'blob'
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function getOrderChecklist (requestParams, handler) {
  const role = getUserRole()
  const companyQuery = (role !== CONSTANTS.ADMIN) ? getParameterQueryString('company_id=', requestParams.companyId) : ''
  axios
    .get(baseUrl + 'asset/' + requestParams.assetId + '/check_list?' + companyQuery,
      {
        headers: {
          Authorization: 'Bearer ' + requestParams.token
        }
      })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}
export function assetListApi (requestParams, handler) {
  const checklistQuery = requestParams.chklt_id ? '&chklt_id=' + requestParams.chklt_id.toString() : ''
  const activationQuery = getActivationQueryString('&active=', requestParams.activation)
  const availabilityQuery = requestParams.availability ? '&availability=' + requestParams.availability.toString() : ''
  const companyQuery = getParameterQueryString('company_id=', requestParams.company_id)
  let branchQuery = ''
  if (companyQuery !== '') {
    branchQuery = '&branch_id=' + (requestParams.branchId ? requestParams.branchId : '')
  }
  const paramString = requestParams.needFilter ? (branchQuery + activationQuery + checklistQuery + availabilityQuery) : ''
  const url = baseUrl + 'assets/new_search?' + companyQuery + paramString + '&page=' + requestParams.page_no + '&query=' + requestParams.query

  checkUserDetailsChanges()
  axios
    .get(url, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function updateCheckListDraft (requestParams, handler, key) {
  const companyQuery = getParameterQueryString('company_id=', requestParams.companyId)

  axios
    .post(baseUrl + 'asset/draft/' + requestParams.assetId + '?' + companyQuery, requestParams.body, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res, key)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null, key)
    })
}

export function saveBranchLocation (requestParams, handler) {
  if (requestParams.company_id === 0) {
    delete requestParams.company_id
    delete requestParams.branch_id
  }

  axios
    .post(baseUrl + 'save_user_branch', requestParams, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function discardDraft (requestParams, handler) {
  const companyQuery = getParameterQueryString('company_id=', requestParams.companyId)

  axios
    .post(
      baseUrl + 'asset/discard_draft/' + requestParams.assetId + '?' + companyQuery, requestParams.body, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function updateChecklistDraftForCheckedoutAsset (requestParams, handler, key) {
  const companyQuery = getParameterQueryString('company_id=', requestParams.companyId)
  axios
    .post(
      baseUrl + 'asset/checkedout/update/' + requestParams.assetId + '?' + companyQuery, requestParams.body, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res, key)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null, key)
    })
}

export function createUserAPI (requestParams, handler) {
  axios
    .post(
      baseUrl + 'user/create', requestParams, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function editUserAPI (requestParams, handler, key) {
  axios
    .post(
      baseUrl + 'user/edit', requestParams, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res, key)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null, key)
    })
}

export function deleteUserAPI (requestParams, handler, key) {
  axios
    .delete(
      baseUrl + 'users/' + requestParams.id + '?company_id=' + requestParams.companyId, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res, key)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null, key)
    })
}

export function createAsset (requestParams, handler, key) {
  axios
    .post(
      baseUrl + 'asset/create', requestParams, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res, key)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null, key)
    })
}

export function updateAsset (requestParams, handler, key) {
  axios
    .post(
      baseUrl + 'asset/update/' + requestParams.assetId, requestParams, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res, key)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null, key)
    })
}

export function checkGUID (requestParams, handler) {
  const url = baseUrl + 'guid/details' + requestParams.guid
  axios
    .get(url)
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function imageInfoFromGUID (requestParams, handler) {
  const url = baseUrl + 'image/' + requestParams.guid
  axios
    .get(url, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

function logoutFromApp (error) {
  if (error && error.response && error.response.status === CONSTANTS.UNAUTHENTICATED_CODE) {
    store.dispatch(logoutAction())
  }
}

export function sendFeedback (requestParams, handler) {
  axios
    .post(
      baseUrl + 'feedback', requestParams, {
        headers: { Authorization: 'Bearer ' + requestParams.token }
      })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}

export function sendInviteMail (requestParams, handler) {
  const url = baseUrl + 'invite/' + requestParams.user_id
  axios
    .get(url, {
      headers: { Authorization: 'Bearer ' + requestParams.token }
    })
    .then(res => {
      handler(null, res)
    })
    .catch(error => {
      logoutFromApp(error)
      handler(error, null)
    })
}
