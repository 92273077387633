import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import editIcon from '../../../assets/images/editIcon.svg'
import textImage from '../../../assets/images/text_subject.svg'
import checkmarkImage from '../../../assets/images/checkmark.svg'
import closeImage from '../../../assets/images/close.svg'
import cameraImage from '../../../assets/images/camera.svg'
import Divider from '@material-ui/core/Divider'
import classNames from 'classnames'

export const useStylesCommon = makeStyles(theme => ({
  detailTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    letterSpacing: '0.32px',
    color: 'rgba(0,0,0,0.87)'
  },
  detailLabel: {
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing: '0.29px',
    color: 'rgba(0,0,0,0.87)'
  },
  editButtonRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    padding: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }
  },
  editButtonImage: {
    width: '24px',
    height: '24px'
  },
  textImage: {
    width: '12px',
    height: '10px'
  },
  closeImage: {
    width: '13px',
    height: '13px'
  },
  image: {
    width: '70px',
    height: '70px',
    cursor: 'pointer'
  },
  divider: {
    marginLeft: '32px',
    width: '90vw',
    '@media(min-width: 960px)': {
      width: '50vw'
    }
  },
  assetContent: {
    width: '88vw',
    '@media(min-width: 960px)': {
      width: '48vw'
    }
  }
}))

export function AssetDetailsTitle (props) {
  const classes = useStylesCommon()

  return (
    <Typography data-cy={props.children} className={classes.detailTitle}>
      {props.children}
    </Typography>
  )
}

export function AssetDetailsLabel (props) {
  const classes = useStylesCommon()

  return (
    <Typography
      data-cy='asset-details-label'
      style={props.style}
      className={classNames(classes.detailLabel, props.className)}
    >
      {props.children}
    </Typography>
  )
}

export function AssetDetailsValue (props) {
  const classes = useStylesCommon()

  return (
    <Typography
      data-cy='asset-details-value'
      style={props.style || { fontWeight: '300' }}
      className={classes.detailLabel}
    >
      {props.children}
    </Typography>
  )
}

export function EditButton (props) {
  const classes = useStylesCommon()

  return (
    <Button
      onClick={() => props.onClick()}
      classes={{ root: classes.editButtonRoot }}
      style={props.style || {}}
    >
      <img className={classes.editButtonImage} src={editIcon} alt='' />
    </Button>
  )
}

export function TextSubjectImage (props) {
  const classes = useStylesCommon()
  return (
    <img className={classes.textImage} src={textImage} alt='' />
  )
}

export function CheckmarkImage (props) {
  const classes = useStylesCommon()
  return (
    <img className={classes.textImage} src={checkmarkImage} alt='' />
  )
}

export function CloseImage (props) {
  const classes = useStylesCommon()
  return (
    <img className={classes.closeImage} src={closeImage} alt='' />
  )
}

export function CameraImage (props) {
  const classes = useStylesCommon()
  return (
    <img className={classes.textImage} src={cameraImage} alt='' />
  )
}

export function Image (props) {
  const classes = useStylesCommon()

  const handleClick = () => {
    if (props.imageModel) {
      props.onClick(props.imageModel.guid)
    }
  }

  return (
    <img
      data-cy='asset-details-check-item-image'
      className={classes.image}
      src={props.imageUrl}
      alt=''
      onClick={handleClick}
    />
  )
}

export function DetailsDivider (props) {
  const classes = useStylesCommon()
  return (
    <Divider className={classes.divider} />
  )
}
