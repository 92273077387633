import React from 'react'
import Grid from '@material-ui/core/Grid'
import {
  getAssetStatusLabelAndColor,
  checkUserCompanyHaveBranches,
  getBranchName,
  formatOrderDateTime,
  AssetStatusKeysAPI
} from '../../../../helpers/GenericHelpers'
import { strings } from '../../../../localizations/strings'
import { getUserInfo } from '../../../../utils/Authentication'
import {
  AssetDetailsLabel,
  AssetDetailsValue
} from '../DetailsCommonComponents'
import LabelValueLayout from '../AssetDetailLabelValueLayout'
import RowsLayout from '../MultiGridLayout'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  detailLabel: {
    width: '150px',
    '@media(min-width: 960px)': {
      width: '240px'
    }
  }
}))

export default function AssetDetailsDetailComponent (props) {
  const classes = useStyles()
  const [details, setDetails] = React.useState([])
  const [detailsTypes, setDetailsTypes] = React.useState([])

  React.useEffect(() => {
    const detailsTypes = {
      status: { key: 'status', value: strings.current_Status },
      inventoryNumber: { key: 'inventoryNumber', value: strings.inventory_number },
      serialNumber: { key: 'serialNumber', value: strings.serial_number },
      lastChange: { key: 'lastChange', value: strings.last_change },
      location: { key: 'location', value: strings.location },
      timeOfReturn: { key: 'timeOfReturn', value: strings.time_of_return }
    }

    const appendLastChange = (asset, detailsModelArray) => {
      if (asset.asset_transaction && asset.asset_transaction.created_at) {
        const userFirstName = asset.asset_transaction.user_first_name || ''
        const userLastName = asset.asset_transaction.user_last_name || ''

        if (userFirstName !== '' || userLastName !== '') {
          const userLabel = userFirstName + ' ' + userLastName

          detailsModelArray.push({
            type: detailsTypes.lastChange,
            value: { user: userLabel, time: (formatOrderDateTime(asset.asset_transaction.created_at) || '') }
          })
        }
      }
      return detailsModelArray
    }

    const appendLocation = (asset, detailsModelArray) => {
      let branchName = ''
      if (asset.branch && asset.branch.id !== 0) {
        branchName = getBranchName(asset.branch)
      } else {
        const user = getUserInfo()
        if (checkUserCompanyHaveBranches(user)) {
          branchName = strings.none_selected
        }
      }

      if (branchName !== '') {
        detailsModelArray.push({ type: detailsTypes.location, value: branchName })
      }
    }

    const setUpDetailsModel = () => {
      const detailsModelArray = []
      const asset = props.asset
      if (asset) {
        detailsModelArray.push({ type: detailsTypes.status, value: getAssetStatusLabelAndColor(asset) })

        if (asset.inventory_number && asset.inventory_number !== '') {
          detailsModelArray.push({ type: detailsTypes.inventoryNumber, value: asset.inventory_number })
        }

        if (asset.serial_number && asset.serial_number !== '') {
          detailsModelArray.push({ type: detailsTypes.serialNumber, value: asset.serial_number })
        }

        appendLastChange(asset, detailsModelArray)
        appendLocation(asset, detailsModelArray)

        if (asset.availability === AssetStatusKeysAPI.checked_in || asset.availability === AssetStatusKeysAPI.check_in_draft) {
          if (asset.asset_transaction.reported_at && asset.asset_transaction.reported_at !== '') {
            detailsModelArray.push({
              type: detailsTypes.timeOfReturn,
              value: (formatOrderDateTime(asset.asset_transaction.reported_at) || '')
            })
          }
        }
      }

      setDetails(detailsModelArray)
    }
    setDetailsTypes(detailsTypes)
    setUpDetailsModel()
  },
  [props])

  const getDetailComponent = (detail) => {
    return (
      <LabelValueLayout
        key={detail.type.value}
        label={
          <AssetDetailsLabel
            className={classes.detailLabel}
          >{detail.type.value}
          </AssetDetailsLabel>
        }
        value={getDetailValue(detail)}
      />
    )
  }

  const getLastChangeValuesComponents = (detail) => {
    return [<AssetDetailsValue key={detail.value.user}>{detail.value.user}</AssetDetailsValue>,
      <AssetDetailsValue key={detail.value.user}>{detail.value.time}</AssetDetailsValue>]
  }

  const getDetailValue = (detail) => {
    switch (detail.type.key) {
      case detailsTypes.status.key :
        return (
          <AssetDetailsLabel style={{ color: detail.value.color }}>
            {detail.value.label}
          </AssetDetailsLabel>
        )
      case detailsTypes.lastChange.key :
        return (
          <RowsLayout components={getLastChangeValuesComponents(detail)} />
        )
      default :
        return (
          <AssetDetailsValue>{detail.value}</AssetDetailsValue>
        )
    }
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      spacing={1}
      data-cy='asset-details'
    >
      {details.map((detail) => getDetailComponent(detail))}
    </Grid>
  )
}
