import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'

const useStyles = () => ({
  modalStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '9999',
    background: '#fff',
    borderRadius: '3px',
    boxShadow: '0 11px 15px -7px var(--black-20), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)'
  },
  backdropStyle: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0px',
    left: '0px',
    zIndex: '9998',
    background: 'rgba(0, 0, 0, 0.4)'
  }
})

class Modal extends Component {
  render () {
    const { classes, isOpen } = this.props
    if (!isOpen) return null

    return (
      <Container maxWidth='sm' className={this.props.containerClassName}>
        <div className={classes.modalStyle}>
          {this.props.children}
        </div>
        {!this.props.noBackdrop && <div className={classes.backdropStyle} onClick={e => this.close(e)} />}
      </Container>
    )
  }

  close (e) {
    e.preventDefault()

    if (this.props.onClose) {
      this.props.onClose()
    }
  }
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(Modal)
