import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import * as CONSTANTS from '../../utils/url_constant.js'
import { strings } from '../../localizations/strings.js'
import Form from '../../common/form.jsx'
import AssetListHeader from '../AssetManagement/AssetListHeader.jsx'
import Hidden from '@material-ui/core/Hidden'
import SearchbarMobile from '../AssetManagement/SearchbarMobile.jsx'
import { ListType } from '../../helpers/GenericHelpers.js'

const useStyles = theme => ({
  headerStyle: {
    width: '100%'
  }
})
class Header extends Form {
  constructor (props) {
    super(props)
    this.state = {
      isAndroid: window.Android,
      isiOS: window.iOS,
      ttt: this.props.route,
      title: this.getRouteTitle(this.props.route),
      openDrawer: false
    }
  }

  schema = {
  }

  getRouteTitle (route) {
    switch (route) {
      case CONSTANTS.URLPATH_QRCODE:
        return strings.title_create_qrcode
      case CONSTANTS.URLPATH_EDIT_ME:
        return strings.menu_personal_data
      case CONSTANTS.URLPATH_USERLIST:
        return strings.menu_user_administartion
      case CONSTANTS.URLPATH_SELECTCOMPANY:
        return strings.company
      case CONSTANTS.URLPATH_LANDING:
        return strings.asset_list
      default:
        return strings.asset_details_title
    }
  }

  getListType (route) {
    switch (route) {
      case CONSTANTS.URLPATH_QRCODE:
        return null
      case CONSTANTS.URLPATH_EDIT_ME:
        return null
      case CONSTANTS.URLPATH_USERLIST:
        return ListType.Users
      default:
        return ListType.Machines
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ title: this.getRouteTitle(props.route) })
  }

  render () {
    const { classes } = this.props
    const listType = this.getListType(this.props.route)
    const viewForMobile = ((this.state.title === strings.asset_list) || (this.state.title === strings.menu_user_administartion))
      ? <SearchbarMobile onSearch={(newValue) => this.props.handleSearch(newValue, listType)} listType={listType} />
      : <Typography data-cy='header-text' variant='h6'><font color='white'>{this.state.title}</font></Typography>

    const viewForDesktop = ((this.state.title === strings.asset_list) || (this.state.title === strings.menu_user_administartion))
      ? <div style={{ padding: '20px 0 20px 0' }}> <Typography variant='h4'> {this.state.title} </Typography><AssetListHeader onSearch={(newValue) => this.props.handleSearch(newValue, listType)} onFilter={(newValue) => this.props.handleFilter(newValue, listType)} onSelectBranch={() => this.props.handleSelectBranch(listType)} listType={listType} /></div>
      : <Typography data-cy='header-text' variant='h4'>{this.state.title}</Typography>
    if (this.state.isAndroid || this.state.isiOS) {
      return ''
    } else {
      return (
        <div className={classes.headerStyle}>
          <Hidden only={['lg', 'xl', 'md']}>
            {viewForMobile}
          </Hidden>

          <Hidden only={['sm', 'xs']}>
            <div>
              {viewForDesktop}
            </div>
          </Hidden>

        </div>
      )
    }
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(Header)
