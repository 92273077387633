import Form from '../../../common/form'
import AddEditUserContainer from './AddEditUser/AddEditUserContainer'

class User extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      errors: {},
      open: false,
      setOpen: false,
      setProgressBar: false
    }
  }

  handleSubmit = (userData) => {
    this.setState({ setProgressBar: true })
  }

  onResponse = (responseData) => {
    this.closePopUp()
    this.props.onResponse(responseData)
  }

  closePopUp = () => {
    this.setState({
      open: false
    })
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  render () {
    return (
      <div>
        <AddEditUserContainer
          key='desktop'
          selectedUser={this.props.parentProps?.asset}
          openFrom={this.props.openFrom}
          onDelete={() => this.props.onDelete()}
          onClose={() => this.props.onClose()}
          onEdit={(result) => this.props.onEdit(result)}
          onResponse={(response) => this.onResponse(response)}
        />

      </div>
    )
  }
}

export default User
