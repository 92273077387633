import React, { Component } from 'react'
import Joi from 'joi-browser'
import { RadioButton, CheckBox, InputSelect, SecondaryButton, SuccessRadioButton, ErrorRadioButton } from './input'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Link from '@material-ui/core/Link'
import { strings } from '../localizations/strings'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import {
  withStyles
} from '@material-ui/core/styles'
import KCSwitch from '../components/LayoutComponents/Switch'
import CheckIcon from '../../src/utils/CheckIcon'
import Retry from '@material-ui/icons/Replay'
import { TailSpin } from 'react-loader-spinner'
import { StateType } from '../helpers/GenericHelpers.js'

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#0d548c'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0d548c'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0d548c'
      },
      '&:hover fieldset': {
        borderColor: '#0d548c'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0d548c'
      }
    }
  }
})(TextField)

const Button = withStyles({
  root: {
    backgroundColor: '#FAA619'
  },
  contained: {
    '&$disabled': {
      background: '#CCEFFC',
      color: '#ffffff'
    }
  },
  disabled: {},
  label: {
    color: '#303030'
  }
})(MuiButton)

class Form extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: {},
      errors: {},
      showPassword: false
    }

    this.myRef = React.createRef()
  }

  validate = () => {
    const options = { abortEarly: false }
    const { error } = Joi.validate(this.state.data, this.schema, options)
    if (!error) return null

    const errors = {}
    let item = ''
    for (item of error.details) errors[item.path[0]] = item.message

    return errors
  }

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value }
    const schema = { [name]: this.schema[name] }
    const { error } = Joi.validate(obj, schema)
    return error ? error.details[0].message : null
  }

  handleSubmit = label => e => {
    e.preventDefault()
    if (!label === strings.download_pdf) {
      const errors = this.validate()
      this.setState({ errors: errors || {} })
      if (errors) return
    }

    const errors = this.validate()
    const data = { ...this.state.data }
    this.setState({ data, errors: errors || {} })

    let isError = false
    Object.keys(this.state.errors).forEach(
      key =>
        function () {
          if (this.state.errors[key] !== null) isError = true
        }
    )
    if (!isError) {
      this.setState({ errors: errors || {} })
      this.doSubmit(errors)
    }
  }

  handleSecondaryButton = e => {
    this.doSecondaryAction()
  }

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors }
    const errorMessage = this.validateProperty(input)

    if (errorMessage !== '') errors[input.name] = errorMessage
    else delete errors[input.name]

    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data, errors })

    this.saveInlineData(input, errorMessage)
  }

  handleCheckboxClick = name => event => {
    const { data, errors } = this.state
    data[name] = event.target.checked
    if (name === 'tos') {
      if (!event.target.checked) errors[name] = strings.data_privacy_error_msg
      else delete errors[name]
      this.setState({ data, errors })
    }
  }

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleSwitch = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors }
    const errorMessage = this.validateProperty(input)
    // let val = false

    if (errorMessage !== '') errors[input.name] = errorMessage
    else delete errors[input.name]

    // input.value === 'false' ? (val = true) : (val = false)
    this.handleSwitchClick(input.name, input.checked)
  }

  linkClick = (linkName, data) => {
    if (linkName === 'login') this.handleLinkClick(linkName)
    else {
      this.props.history.push({
        pathname: linkName,
        state: { data }
      })
    }
  }

  handleFocusEvent = ({ currentTarget: input }) => {
    this.focusEvent(input)
  }

  // onFocushange, this function will be called
  // In child class we need to override this function to catch the focus change event
  focusEvent = input => {}

  // this function will be called from onChange to save inline data
  // In child class we need to override this function to catch the focus change event
  saveInlineData = data => {}

  renderButton (label, className, type = 'button', disabled = false) {
    return (
      <Grid item xs={12}>
        <Button
          data-cy='form-button'
          ref={this.myRef}
          type={type}
          variant='contained'
          fullWidth
          className={className}
          onClick={this.handleSubmit(label)}
          disabled={disabled}
        >
          {label}
        </Button>
      </Grid>
    )
  }

  renderNewButton (label, className, disabled = false) {
    let backgroundStyle = {}
    if (!disabled) {
      backgroundStyle = { backgroundColor: '#005170', color: '#ffffff' }
    } else {
      backgroundStyle = { backgroundColor: '#005170', color: '#ffffff' }
    }

    return (
      <Grid item xs={12}>
        <MuiButton
          style={backgroundStyle}
          variant='contained'
          fullWidth
          className={className}
          onClick={this.handleSubmit(label)}
          disabled={disabled}
        >
          {label}
        </MuiButton>
      </Grid>
    )
  }

  renderDisbaledButton (label, classes, disabled = true) {
    return (
      <Grid item xs={12}>
        <MuiButton
          style={{ backgroundColor: '#CCEFFC', color: '#ffffff' }}
          variant='contained'
          fullWidth
          className={classes}
          onClick={this.handleSubmit(label)}
          disabled={disabled}
        >
          {label}
        </MuiButton>
      </Grid>
    )
  }

  renderSecondaryButton (label, classes) {
    return (
      <SecondaryButton
        variant='contained'
        style={{ backgroundColor: '#0d548c', color: '#ffffff' }}
        color='primary'
        fullWidth
        className={classes}
        onClick={() => this.handleSecondaryButton()}
      >
        {label}
      </SecondaryButton>
    )
  }

  renderLink (name, label, classes) {
    const { data } = this.state
    return (
      <Grid item xs={12}>
        <Link
          style={{ cursor: 'pointer' }}
          variant='body2'
          className={classes}
          onClick={() => this.linkClick(name, data)}
        >
          {label}
        </Link>
      </Grid>
    )
  }

  renderRadioButton (value, label, stateType = StateType.none) {
    let radioButton
    switch (stateType) {
      case StateType.success:
        radioButton = <SuccessRadioButton value={value} label={label} />
        break
      case StateType.failure:
        radioButton = <ErrorRadioButton value={value} label={label} />
        break
      default:
        radioButton = <RadioButton value={value} label={label} />
        break
    }

    return radioButton
  }

  renderCheckBox (name, value, stateType = StateType.none) {
    return (
      <CheckBox
        name={name}
        onChange={this.handleCheckboxClick(name)}
        checked={value}
        style={{ color: this.getCheckboxColor(stateType) }}
      />
    )
  }

  renderDisabledInput (name, label) {
    const { data } = this.state
    return (
      <CssTextField
        disabled
        id='standard-dense'
        label={label}
        name={name}
        value={data[name]}
        fullWidth
      />
    )
  }

  renderInput (name, label, classes, showHide,
    type = 'text',
    disabled = false,
    id = '', isEdit = false,
    stateType = StateType.none,
    enableMultiline = false,
    placeholder = '',
    rows = 1, rowsMax = 1,
    inputLabelProps = {}) {
    const { data, errors } = this.state
    let errorExist = false
    if (errors !== null && errors[name]) errorExist = true
    else errorExist = false
    const textValue = data[name] ? data[name] : ''
    let lable
    if (name === 'feedback_telephone' && window.innerWidth > 700) {
      lable = label
    } else {
      lable = label.length > 27 ? label.substring(0, 27) + '...' : label
    }
    return (
      <Grid item xs={12}>
        <CssTextField
          type={
            type === 'text' ? 'text' : this.state.showPassword ? 'text' : 'password'
          }
          value={textValue}
          label={lable}
          onChange={this.handleChange}
          error={errorExist}
          multiline={enableMultiline}
          placeholder={placeholder}
          minRows={rows}
          maxRows={rowsMax}
          helperText={errors[name]}
          disabled={disabled}
          name={name}
          id={id}
          onBlur={this.handleFocusEvent}
          InputLabelProps={inputLabelProps}
          InputProps={
            showHide === 'show'
              ? {
                  classes: {},
                  endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      aria-label='toggle password visibility'
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword && data[name]
                        ? (
                        <VisibilityOff />
                          )
                        : (
                        <Visibility />
                          )}
                    </IconButton>
                  </InputAdornment>
                  )
                }
              : (isEdit) ? { endAdornment: (<InputAdornment data-cy='input-adornment' id={name} position='end' onClick={stateType === StateType.failure ? this.handleRetryClick : null}> {this.getTextInputStateIcon(stateType, name)}   </InputAdornment>) } : {}
          }
          fullWidth
        />
      </Grid>
    )
  }

  renderInputSelect (name, label, classes, items) {
    return (
      <InputSelect
        label={label}
        classes={classes}
        items={items}
        name={name}
        reference={this}
      />
    )
  }

  renderSwitch (name, label, checked) {
    return (
      <Grid item xs={12}>
        <Switch
          name={name}
          value={checked}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          color='primary'
          checked={checked}
          onChange={this.handleSwitch}
        />
        {label}
      </Grid>
    )
  }

  renderCustomSwitch (name, label, checked) {
    return (
      <Grid item xs={12}>
        <KCSwitch
          name={name}
          value={checked}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          color='primary'
          checked={checked}
          onChange={this.handleSwitch}
        />
        {label}
      </Grid>
    )
  }

  renderProgressBar () {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }

  getTextInputStateIcon = (stateType) => {
    let icon
    switch (stateType) {
      case StateType.failure:
        icon = <Retry color='error' />
        break
      case StateType.success:
        icon = <CheckIcon />
        break
      case StateType.loding:
        icon = <div key={0}><TailSpin type='TailSpin' color='#0d548c' height={50} width={25} /></div>
        break
      default:
        icon = ''
    }
    return icon
  }

  getCheckboxColor = (stateType) => {
    let color
    switch (stateType) {
      case StateType.failure:
        color = '#ff0000'
        break
      case StateType.success:
        color = '#49b157'
        break
      default:
        color = null
    }
    return color
  }

  anyError = () => {
    let isError = false
    Object.entries(this.state.errors).forEach(entry => {
      const value = entry[1]
      if (value) {
        isError = true
      }
    })
    return isError
  }
}

export default Form
