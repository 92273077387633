import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { strings } from '../../localizations/strings.js'
import AssetsIcon from '../../assets/no_assets.svg'
import { GuidComponentTypes } from '../../helpers/GenericHelpers'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  text: {
    marginTop: '30px',
    textAlign: 'center',
    width: '50%',
    '@media(max-width: 960px)': {
      width: '75%'
    }
  },
  centered: {
    height: '88vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export default function PageNotFound (props) {
  const classes = useStyles()
  const description = props.type === GuidComponentTypes.pageNotFound ? strings.pageNotFound : strings.pageNotAccessible
  return (
    <div className={classes.centered}>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        className={classes.root}
      >
        <Grid item>
          <img src={AssetsIcon} alt='' />
        </Grid>
        <Grid item className={classes.text}>
          <Typography>{description}</Typography>
        </Grid>
      </Grid>
    </div>
  )
}
