import { Component } from 'react'
import '../assets/css/carousel.css'
import { Carousel } from 'react-responsive-carousel'

class CommonCarousel extends Component {
  render () {
    return (
      <Carousel
        showThumbs={false}
        showArrows showIndicators={false} showStatus={false} transitionTime={1}
      >
        {this.props.items}
      </Carousel>
    )
  }
}

export default CommonCarousel
