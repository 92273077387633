import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { closed as closedAssetPopover } from './assetPopoverSlice'
import { closed as closedUserPopover } from './userPopoverSlice'

const EXPIRATION_TIME_IN_SECONDS = 180

export const APP_SLICE = {
  DATA_UPDATE_STARTED: 'started',
  DATA_UPDATE_SUCCEEDED: 'succeeded',
  DATA_UPDATE_FAILED: 'failed'
}

const promisifyTimeout = (delayInMilliseconds) => {
  let timerId = null
  const promise = new Promise((resolve) => {
    timerId = setTimeout(() => {
      const expiredAt = (new Date()).toISOString()
      return resolve(expiredAt)
    }, delayInMilliseconds)
  })

  return {
    timerId,
    promise
  }
}

export const dataExpirationThunk = createAsyncThunk(
  'app/dataHasExpiredAction',
  async (arg, thunkAPI) => {
    const timeout = promisifyTimeout(EXPIRATION_TIME_IN_SECONDS * 1000)
    thunkAPI.dispatch(updateTimerId(timeout.timerId))
    return timeout.promise
  }
)

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    dataHasExpired: false,
    dataIsUpdating: false,
    dataUpdatedAt: null,
    dataExpiredAt: null,
    isConnected: true,
    isVisible: true,
    shouldUpdateData: false,
    timerId: null
  },
  reducers: {
    connectivityChanged: (state, action) => {
      state.isConnected = action.payload

      if (state.isConnected) {
        state.shouldUpdateData = state.dataHasExpired && !state.dataIsUpdating && state.isVisible
      } else {
        state.shouldUpdateData = false
      }
    },
    dataUpdate: (state, action) => {
      switch (action.payload) {
        case APP_SLICE.DATA_UPDATE_STARTED:
          state.dataIsUpdating = true
          state.shouldUpdateData = false
          break
        case APP_SLICE.DATA_UPDATE_SUCCEEDED:
          state.dataHasExpired = false
          state.dataUpdatedAt = (new Date()).toISOString()
          state.dataIsUpdating = false
          break
        case APP_SLICE.DATA_UPDATE_FAILED:
          state.dataIsUpdating = false
          break
        default:
      }
    },
    updateTimerId: (state, action) => {
      if (state.timerId !== null) {
        clearTimeout(state.timerId)
      }
      state.timerId = action.payload
    },
    visibilityChanged: (state, action) => {
      state.isVisible = action.payload

      if (state.isVisible) {
        state.shouldUpdateData = state.dataHasExpired && !state.dataIsUpdating && state.isConnected
      } else {
        state.shouldUpdateData = false
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(closedAssetPopover, (state) => {
      state.shouldUpdateData = state.dataHasExpired && !state.dataIsUpdating && state.isConnected
    })
    builder.addCase(closedUserPopover, (state) => {
      state.shouldUpdateData = state.dataHasExpired && !state.dataIsUpdating && state.isConnected
    })
    builder.addCase(dataExpirationThunk.fulfilled, (state, action) => {
      state.timerId = null
      state.dataHasExpired = true
      state.dataExpiredAt = new Date(action.payload).toISOString()
    })
  }
})

export const { connectivityChanged, dataUpdate, updateTimerId, visibilityChanged } = appSlice.actions
