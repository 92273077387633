import RowsLayout from '../MultiGridLayout'
import { AssetCheckTypes } from '../../../../helpers/GenericHelpers'
import AssetTextCheck from './CheckItems/AssetTextCheck'
import AssetImageCheck from './CheckItems/AssetImageCheck'
import AssetDefectCheck from './CheckItems/AssetDefectCheck'

export default function AssetDetailsCheckItemGrid (props) {
  const getCheckItemComponents = () => {
    switch (props.checkItem.type) {
      case AssetCheckTypes.text:
        return (
          <AssetTextCheck key={props.checkItem.id} checkItem={props.checkItem} />
        )
      case AssetCheckTypes.image:
        return (
          <AssetImageCheck
            key={props.checkItem.id}
            checkItem={props.checkItem}
            previewImage={(imageGUID) => props.previewImage(imageGUID)}
          />
        )
      case AssetCheckTypes.okNotOk:
        return (
          <AssetDefectCheck
            key={props.checkItem.id}
            checkItem={props.checkItem}
            previewImage={(imageGUID) => props.previewImage(imageGUID)}
          />
        )
      default :
        return [<div key={1}>{props.checkItem.name}</div>,
          <div key={2}>Default Case Value</div>]
    }
  }

  return (
    <RowsLayout
      components={[getCheckItemComponents()]}
    />
  )
}
