import ColumnsLayout from './MultiGridLayout'
import { Image } from './DetailsCommonComponents'

export default function AssetDetailsCheckGroupBody (props) {
  const getImageComponet = (image) => {
    return image.small_url &&
      <Image
        key={image.small_url}
        imageUrl={image.small_url}
        imageModel={image}
        onClick={(imageGUID) => props.previewImage(imageGUID)}
      />
  }

  return (
    <ColumnsLayout
      components={props.images.map((image) => getImageComponet(image))}
      gridSpacing={1}
      style={props.style || {}}
      direction='row'
    />
  )
}
