import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import { strings } from '../../localizations/strings.js'
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined'

const useStyles = makeStyles(theme => ({
  wrapIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    [theme.breakpoints.only('sm', 'xs')]: {
      fontWeight: 'normal'
    },
    fontSize: '18px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal'
  },
  addCircleIcon: {
    width: '24px',
    height: '24px'
  },
  buttonRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    padding: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }
  },
  buttonLabel: {
    textTransform: 'none'
  }
}))

export function CreateOrderButton (props) {
  const classes = useStyles()

  return (
    <Button
      data-cy='create-order-button'
      onClick={(event) => props.onClick(event)}
      classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
      style={props.buttonStyle || {}}
    >
      <Typography
        variant='subtitle1'
        color='secondary'
        className={classes.wrapIcon}
        style={props.labelStyle || {}}
      >
        <AddCircleOutlinedIcon
          className={classes.addCircleIcon}
          style={props.addCircleImageStyle || {}}
        />
        <span>&nbsp;</span>
        {strings.createOrder}
      </Typography>
    </Button>
  )
}

export function OrderIdButton (props) {
  const classes = useStyles()

  return (
    <Button
      onClick={(event) => props.onClick(event)}
      classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
      style={props.buttonStyle || {}}
    >
      <Typography
        data-cy='order-id-button'
        variant='subtitle1'
        color='secondary'
        className={classes.wrapIcon}
        style={props.labelStyle || {}}
      >
        {props.orderId}
        <span>&nbsp;</span>
        <ArrowRightOutlinedIcon />
      </Typography>
    </Button>
  )
}
