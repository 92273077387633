import { withStyles } from '@material-ui/styles'
import Form from '../common/form'
import { Grid } from '@material-ui/core'
import { strings } from '../localizations/strings'
import Container from '@material-ui/core/Container'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'
import Joi from 'joi-browser'
import Snackbar from '../../src/components/LayoutComponents/Snackbar'
import { getUserInfo, getData } from '../utils/Authentication.js'
import { getUserRoleString, StateType, callFirebaseEvent, getRole } from '../helpers/GenericHelpers'
import { sendFeedback } from '../helpers/api.js'
import { MAIL_FORMAT } from '../utils/Matcher'
import { AssetDetailsLabel } from '../components/AssetManagement/AssetDetails/DetailsCommonComponents'

import * as FA_CONSTANTS from '../utils/AnalyticsConstants.js'

const useStyles = theme => ({
  title: {
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '40px'
  },
  subHeading: {
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.75',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  closeButton: {
    marginTop: '-30px',
    float: 'right',
    width: '30px',
    height: '30px',
    paddingRight: '10px'
  },

  layout: {
    maxWidth: '600px',
    minWidth: '500px'
  },

  layoutMobile: {
    maxWidth: '350px',
    minWidth: '300px'
  },

  layoutLoader: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    zIndex: 2,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center'
  },
  topMargin5PX: {
    marginTop: '5px'
  },
  topMargin14PX: {
    marginTop: '14px'
  },
  topMargin20PX: {
    marginTop: '20px'
  },

  topMargin40PX: {
    marginTop: '20px',
    marginBottom: '20px'
  },

  description: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#555f60',
    marginTop: '10px',
    marginLeft: '-14px'
  },
  button: {
    backgroundColor: '#faa619',
    '& .MuiButton-label': {
      color: '#303030'
    },
    '&:hover': {
      backgroundColor: '#faa619'
    }
  },
  savePhone: {
    marginTop: '5px',
    '@media(min-width: 600px)': {
      marginTop: '30px',
      paddingLeft: '25px'
    }
  },
  userLabels: {
    fontWeight: 'normal',
    color: '#555f60'
  }
})

class FeedbackForm extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        comment: '',
        feedback_telephone: '',
        save_profile: true,
        name: '',
        email: '',
        role: ''
      },
      errors: {},
      showSnackBar: false,
      setProgressBar: false
    }
  }

  schema = {
    comment: Joi.string().max(2000)
      .required()
      .label(strings.problem_feedback).error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = strings.problem_feedback_missing
              break

            case 'string.max':
              err.message = strings.feedback_max_character_length
              break

            default: break
          }
        })
        return errors
      }),
    feedback_telephone: Joi.string().allow('').max(192)
      .error(errors => { return { message: strings.max_character_length } }),

    email: Joi.string().max(192)
      .email()
      .required()
      .regex(MAIL_FORMAT)
      .label(strings.email)
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = strings.mandatory_field
              break

            case 'string.max':
              err.message = strings.max_character_length
              break

            default: err.message = strings.email_id_error_msg
              break
          }
        })
        return errors
      }),
    name: Joi.string()
      .allow(''),
    role: Joi.string()
      .allow(''),
    save_profile: Joi.boolean()
      .allow('')

  }

  componentDidMount () {
    const { data } = this.state
    const user = getUserInfo()
    if (user != null) {
      let name
      if (user.first_name !== null) name = user.first_name + ' '
      if (user.last_name !== null) name = name ? name + user.last_name : user.last_name
      data.name = name
      if (user.phone !== null) data.feedback_telephone = user.phone
      data.email = user.email
      data.role = getUserRoleString(user)
      this.setState({ data })
    }
  }

  handleCheckboxClick = name => event => {
    const { data } = { ...this.state }
    data[name] = event.target.checked
    this.setState({ data })
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  doSubmit = (errors) => {
    this.setState({
      showSnackBar: false
    }, () => {
      /** **START: should execute if there is no error */
      if (!errors) {
        const params = {
          name: this.state.data.name,
          email: this.state.data.email,
          role: getRole(getUserInfo()),
          phoneNumber: this.state.data.feedback_telephone,
          saveProfile: this.state.data.save_profile,
          message: this.state.data.comment,
          token: getData('acccess_token')

        }

        this.submitFeedback(params)
      }
    })
  }

  submitFeedback = (params) => {
    this.setState({ setProgressBar: true }, () => {
      sendFeedback(params, (error, response) => {
        if (error) {
          let errorMessage
          if (error.message === 'Network Error') {
            errorMessage = strings.no_internet_connection
          } else {
            errorMessage = error && error.response && error.response.message ? error.response.message : strings.something_went_wrong
          }
          this.setState({
            setProgressBar: false,
            showSnackBar: true,
            responseStatus: {
              status: 'error',
              message: errorMessage
            }
          })
        } else {
          this.setState({
            setProgressBar: false,
            showSnackBar: true,
            responseStatus: {
              status: 'success',
              message: strings.feedback_message_success
            }
          })

          this.sleep(1000).then(() => {
            callFirebaseEvent(FA_CONSTANTS.FA_FEEDBACK_SENT)
            if (params.saveProfile) { this.props.userInfoChanged() }
            this.props.onClose()
          })
        }
      })
    })
  }

  render () {
    const {
      classes,
      messageTitle,
      messageSubTitle,
      onClose
    } = this.props
    return (
      <div>

        {this.state.setProgressBar === true
          ? (
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.layoutLoader}
            >
              <CircularProgress />
            </Grid>
          </Grid>
            )
          : (
              ''
            )}

        <div className={window.innerWidth > 600 ? classes.layout : classes.layoutMobile}>
          <CloseIcon
            className={classes.closeButton} onClick={() => { onClose() }}
          />
          <p data-cy='feedback-form-title' className={classes.title}>{messageTitle}</p>
          <p data-cy='feedback-form-subtitle' className={classes.subHeading}>{messageSubTitle.split('\n').map((item, key) => {
            return <span key={key}>{item}<br /></span>
          })}
          </p>
          <Container>
            <Grid container>

              <Grid
                data-cy='feedback-form-text-input'
                item
                xs={12}
                sm={12}
                md={12}
              >
                {this.renderInput('comment', strings.message, classes, false,
                  'text', false, '', false,
                  StateType.none, true,
                  strings.please_write_us_here,
                  2, Infinity,
                  { shrink: true })}
              </Grid>
              <Grid
                data-cy='feedback-form-phone-input'
                item
                xs={12}
                sm={5}
                md={5}
                className={this.props.classes.topMargin20PX}
              >
                {this.renderInput('feedback_telephone', strings.feedback_telephone, classes, false, 'text', false, '')}
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={7}
                md={7}
                className={this.props.classes.savePhone}
              >
                <Grid data-cy='feedback-form-save-in-profile' item>
                  {this.renderCheckBox(
                    'save_profile',
                    this.state.data.save_profile
                  )}
                </Grid>
                <Grid data-cy='feedback-form-save-in-profile-text' item className={classes.description}>
                  {strings.save_in_profile}
                </Grid>
              </Grid>

              <Grid item xs={12} className={this.props.classes.topMargin20PX}>
                <AssetDetailsLabel className={classes.userLabels}>{this.state.data.name}</AssetDetailsLabel>
                <AssetDetailsLabel className={classes.userLabels}>{this.state.data.email}</AssetDetailsLabel>
              </Grid>
              <Grid
                item
                direction='row'
                justifyContent='flex-start'
                container
                spacing={0}
                xs={12}
                sm={12}
                md={12}
                className={this.props.classes.topMargin40PX}
              >
                {this.renderButton(strings.send_message, classes.button)}
              </Grid>

            </Grid>
            {(this.state.showSnackBar) ? (<Snackbar type={this.state.responseStatus.status} message={this.state.responseStatus.message} />) : ''}
          </Container>
        </div>
      </div>

    )
  }
}

export default withStyles(useStyles)(FeedbackForm)
