import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  text: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    fontSize: '18px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal'
  },
  buttonRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    padding: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }
  },
  buttonLabel: {
    textTransform: 'none'
  }
}))

export default function TextButton (props) {
  const classes = useStyles()

  return (
    <Button
      data-cy='text-button'
      onClick={(event) => props.onClick(event)}
      classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
      style={props.buttonStyle || {}}
    >
      <Typography
        variant='subtitle1'
        color='secondary'
        className={classNames(classes.text, props.className)}
        style={props.labelStyle || {}}
      >
        {props.buttonText}
      </Typography>
    </Button>
  )
}
