import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { strings } from '../../localizations/strings.js'
import Form from '../../common/form'
import { qrCodeGenerate } from '../../helpers/api.js'
import classNames from 'classnames'
import { ReactComponent as IconQRCode } from '../../assets/images/qr_code.svg'
import QRCodeCarousel from './QRCodeCarousel'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import IconCircledPlus from '../../assets/images/circled_plus.svg'
import IconCircledMinus from '../../assets/images/circled_minus.svg'
import IconCircledFilledPlus from '../../assets/images/circled_filled_plus.svg'
import IconCircledFilledMinus from '../../assets/images/circled_filled_minus.svg'
import * as GENERIC_CONSTANTS from '../../utils/GenericConstants'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent, createDocumentTitle } from '../../helpers/GenericHelpers.js'
import IconInactiveCircledPlus from '../../assets/images/inactive_circled_plus.svg'
import IconInactiveCircledMinus from '../../assets/images/inactive_circled_minus.svg'

const useStyles = theme => ({
  root: {
    width: '100%'
  },
  gridLayout: {
    alignItems: 'top',
    paddingLeft: '3%',
    paddingRight: '3%'
  },
  textQRCount: {
    paddingTop: '12px'
  },
  activeButtonStyle: {
    cursor: 'pointer'
  },
  introductionSection: {
    backgroundColor: '#f5f8fa',
    paddingTop: '32px',
    paddingBottom: '40px',
    '@media(max-width: 599px)': {
      paddingBottom: '24px',
      paddingTop: '24px'
    }
  },
  qrIcon: {
    paddingTop: '8px',
    color: '#0d548c',
    width: 50,
    height: 50
  },
  createSection: {
    marginTop: '48px',
    marginBottom: '24px',
    '@media(max-width: 1279px)': {
      marginTop: '32px',
      marginBottom: '16px'
    }
  },
  createSectionMargin: {
  },
  buttonLabel: {
    textTransform: 'none'
  },
  downloadButton: {
    backgroundColor: '#0d548c',
    '@media(max-width: 1279px)': {
      marginBottom: '24px'
    }
  },
  incrementDecrementGrid: {
    '@media(min-width: 1280px)': {
      marginRight: '56px',
      marginBottom: '16px'
    },
    '@media(max-width: 1279px)': {
      marginBottom: '16px',
      order: 2
    }
  },
  linkColor: {
    color: '#0d548c',
    '&:hover': {
      color: '#0080AF'
    }
  },
  carouselSection: {
    position: 'relative'
  },
  carouselBackground: {
    '@media(min-width: 1280px)': {
      backgroundColor: '#f5f8fa',
      position: 'absolute',
      width: '100%',
      height: '55%',
      top: '12%',
      left: '0'
    }

  },
  carouselStyle: {
    zIndex: '9',
    paddingLeft: '144px',
    paddingRight: '192px',
    '@media(max-width: 1279px)': {
      paddingLeft: '50px',
      paddingRight: '50px',
      backgroundImage: 'linear-gradient(180deg, white 11%, #F5F8FA 0%)'
    }
  },
  buttonRootDownload: {
    boxShadow: 'none',
    paddingTop: '15px',
    paddingBottom: '15px',
    '&:hover': {
      backgroundColor: '#0080AF',
      boxShadow: 'none'
    }
  },
  titleFont: {
    '@media(max-width: 959px)': {
      fontSize: '16px',
      fontWeight: '500',
      marginBottom: '16px'
    }
  },
  descriptionFont: {
    '@media(max-width: 959px)': {
      fontSize: '14px'
    }
  },
  createSectionTitle: {
    marginBottom: '16px',
    '@media(max-width: 1279px)': {
      order: 1
    }
  },
  createSectionDescription: {
    '@media(max-width: 1279px)': {
      order: 4
    },
    '@media(min-width: 1280px)': {
      marginBottom: '32px'
    }
  },
  createSectionButton: {
    '@media(max-width: 1279px)': {
      order: 3
    }
  },
  introductionDescriptionSection: {
    '@media (min-width: 600px)': {
      paddingTop: '16px'
    }
  }
})

class CreatQRCode extends Form {
  constructor (props) {
    super(props)
    this.state = {
      qrPageCount: 1,
      errors: {},
      disabled: false
    }
  }

  schema = {}
  initialPageCount = 1
  maxLimit = 20

  incrementCount = event => {
    if (this.state.qrPageCount < this.maxLimit) { this.setState({ qrPageCount: this.state.qrPageCount + 1 }) }
  }

  decrementCount = event => {
    if (this.state.qrPageCount > this.initialPageCount) { this.setState({ qrPageCount: this.state.qrPageCount - 1 }) }
  }

  doSubmit = () => {
    this.setState({ disabled: true })
    const params = {
      page_count: this.state.qrPageCount
    }

    qrCodeGenerate(params, (error, response) => {
      this.setState({ disabled: false })
      if (error) {
        return window.alert(error.response)
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const filename =
          Math.floor(Date.now() / 1000) + '_Klickcheck_QrCode_list.pdf'
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        callFirebaseEvent(FA_CONSTANTS.FA_QR_CODES_DOWNLOADED)
      }
      this.setState({ disabled: false })
    })
  }

  componentDidMount () {
    document.title = createDocumentTitle(document.title, strings.title_create_qrcode)
    callFirebaseEvent(FA_CONSTANTS.FA_QR_CODES_OPENED)
  }

  setDecrementIcon () {
    if (this.state.qrPageCount !== this.initialPageCount) {
      return (
        <div>
          <img
            data-cy='active-decrement-icon'
            className={this.props.classes.activeButtonStyle}
            width='54px' height='54px' src={IconCircledMinus} alt=''
            onMouseOver={e => (e.currentTarget.src = IconCircledFilledMinus)}
            onMouseOut={e => (e.currentTarget.src = IconCircledMinus)}
            onClick={this.decrementCount.bind(this)}
          />
        </div>
      )
    } else {
      return (
        <div>
          <img
            data-cy='inactive-decrement-icon'
            width='54px' height='54px' src={IconInactiveCircledMinus} alt=''
            onClick={this.decrementCount.bind(this)}
          />
        </div>
      )
    }
  }

  setIncrementIcon () {
    if (this.state.qrPageCount < this.maxLimit) {
      return (
        <div>
          <img
            data-cy='active-increment-icon'
            className={this.props.classes.activeButtonStyle}
            width='54px' height='54px' src={IconCircledPlus}
            onMouseOver={e => (e.currentTarget.src = IconCircledFilledPlus)}
            onMouseOut={e => (e.currentTarget.src = IconCircledPlus)}
            alt=''
            onClick={this.incrementCount.bind(this)}
          />
        </div>
      )
    } else {
      return (
        <div>
          <img
            data-cy='inactive-increment-icon'
            width='54px' height='54px' src={IconInactiveCircledPlus} alt=''
            onClick={this.incrementCount.bind(this)}
          />
        </div>
      )
    }
  }

  counter () {
    const { classes } = this.props
    return (
      <Grid container lg={3} md={12} sm={12} xs={12} item className={this.props.classes.incrementDecrementGrid}>
        <Grid item xs={2} lg={2} md={2} sm={2}>
          {this.setDecrementIcon()}
        </Grid>
        <Grid item className={classes.textQRCount} xs={8}>
          <Typography variant='subtitle1' align='center'>
            <span data-cy='qr-count'>{this.state.qrPageCount * 24}</span> QR-Codes
          </Typography>
        </Grid>
        <Grid item xs={2} lg={2} md={2} sm={2} align='right'>
          {this.setIncrementIcon()}
        </Grid>
      </Grid>
    )
  }

  render () {
    const { classes } = this.props
    const qrCodeDescriptionElement =
      <span>
        <Typography variant='subtitle1' align='left' className={classNames(classes.descriptionFont, classes.introductionDescriptionSection)}>
          {strings.qrcode_intro_description}
        </Typography>
      </span>
    return (
      <>
        <Grid container className={classNames(classes.gridLayout, classes.introductionSection)} xs={12} item>
          <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
            <IconQRCode className={classes.qrIcon} />
          </Grid>
          <Grid item xs={10} sm={11} md={11} lg={8} xl={8}>
            <Typography variant='h6' align='left' className={classes.titleFont}>
              {strings.qrcode_intro_title}
              <Hidden only={['xs']}>
                {qrCodeDescriptionElement}
              </Hidden>
            </Typography>
          </Grid>
          <Hidden only={['lg', 'xl', 'md', 'sm']}>
            <Grid item xs={12}>
              {qrCodeDescriptionElement}
            </Grid>
          </Hidden>
        </Grid>

        <Grid container className={classNames(classes.gridLayout, classes.createSection)}>
          <Grid item lg={5} md={11} sm={11} xs={11} className={classes.createSectionTitle}>
            <Typography variant='h6' align='left' className={classNames(classes.createSectionMargin, classes.titleFont)}>
              {strings.qrcode_create_section_title}
            </Typography>
          </Grid>
          <Grid item lg={6} />
          <Grid item lg={6} md={11} sm={11} xs={11} className={classes.createSectionDescription}>
            <Typography variant='subtitle1' align='left' className={classNames(classes.createSectionMargin, classes.descriptionFont)}>
              {strings.qrcode_create_section_text_1}
            </Typography>
          </Grid>
          <Grid item lg={6} />

          {this.counter()}

          <Grid item xs={12} lg={3} md={12} sm={12} className={classes.createSectionButton}>
            <Button
              data-cy='download-button'
              ref={this.myRef}
              variant='contained'
              fullWidth
              className={classes.downloadButton}
              classes={{ label: classes.buttonLabel, root: classes.buttonRootDownload }}
              onClick={this.handleSubmit(strings.download_pdf)}
              disabled={this.state.disabled}
            >
              {strings.download_pdf}
            </Button>
          </Grid>
        </Grid>

        <Grid container className={classNames(classes.gridLayout, classes.descriptionFont)} lg={6} md={11} sm={11} xs={11} item>
          <Grid container>
            <Typography variant='subtitle2' align='left' className={classes.linkColor}>
              {strings.qrcode_create_section_text_2}
            </Typography>

            <Typography variant='subtitle2' align='left'>
              {strings.qrcode_create_section_text_3}
              <a href={GENERIC_CONSTANTS.qrCodePaperShopLink} className={classes.linkColor} target='_blank' rel='noopener noreferrer'>
                {strings.qrcode_create_section_text_4}
              </a>
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classNames(classes.gridLayout, classes.createSection)} lg={6} md={11} sm={11} xs={11} item>
          <Typography variant='h6' align='left' className={classNames(classes.createSectionMargin, classes.titleFont)}>
            {strings.qrcode_carousel_title}
          </Typography>
          <Typography variant='subtitle1' align='left' className={classNames(classes.createSectionMargin, classes.descriptionFont)}>
            {strings.qrcode_carousel_text_1}
          </Typography>
        </Grid>

        <div className={classes.carouselSection}>
          <div className={classes.carouselBackground} />
          <div className={classes.carouselStyle}>
            <QRCodeCarousel />
          </div>
        </div>

      </>
    )
  }
}

CreatQRCode.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(CreatQRCode)
