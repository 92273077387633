import TitleBodyLayout from '../AssetDetailTitleBodyLayout'
import { AssetDetailsTitle } from '../DetailsCommonComponents'
import CheckGroupBody from './AssetDetailsCheckGroupBody'

export default function AssetDetailsCheckGroupBody (props) {
  const getTitleComponent = () => {
    return (
      <AssetDetailsTitle>{props.checkGroup.name}</AssetDetailsTitle>
    )
  }

  const getBodyComponent = () => {
    return (
      <CheckGroupBody
        checkItems={props.checkGroup.check_items}
        previewImage={(imageGUID) => props.previewImage(imageGUID)}
      />
    )
  }

  return (
    <TitleBodyLayout
      title={getTitleComponent()}
      body={getBodyComponent()}
    />
  )
}
