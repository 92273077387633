import { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import CompanyLogo from '../assets/klickcheck_logo.svg'
import { strings } from '../localizations/strings'

const useStyles = () => ({
  root: {
    width: '100%',
    boxShadow: '0 2px 20px 0 rgba(0, 41, 56, 0.2)',
    backgroundColor: 'white'
  },
  headerImageSize: {
    width: '180px',
    flex: 1
  },
  headerStyle: {
    paddingTop: '1.5%',
    paddingBottom: '1%',
    margin: '0px',
    padding: '0px',
    backgroundColor: 'white'
  },
  loginAlignment: {
    textAlign: 'right',
    marginTop: 'auto',
    marginBottom: 'auto',
    flex: 1,
    '@media(max-width: 583px)': {
      textAlign: 'left !important'
    }
  },
  loginButton: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '300',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.4',
    letterSpacing: '0.1px',
    textAlign: 'right',
    color: '#4d4d4d',
    marginTop: '8px'
  }

})
class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isAndroid: window.Android,
      isiOS: window.iOS,
      mdScreenSize: 12,
      xsScreenSize: 12,
      smScreenSize: 12

    }
  }

  componentDidMount () {
    const url = window.location.href.split('/')
    if (url.pop() !== 'login') {
      this.setState({ mdScreenSize: 6, xsScreenSize: 12, smScreenSize: 6 })
    }
  }

  render () {
    const url = window.location.href.split('/')
    const { classes } = this.props
    if (this.state.isAndroid || this.state.isiOS) {
      return ''
    } else {
      return (
        <>
          <div className={classes.root}>
            <Container>
              <Grid container className={classes.headerStyle}>
                <Grid item md={this.state.mdScreenSize} sm={this.state.smScreenSize} xs={this.state.xsScreenSize}>
                  <img
                    className={classes.headerImageSize}
                    src={CompanyLogo}
                    alt=''
                  />
                </Grid>
                {(url.pop() !== 'login')
                  ? <Grid item md={this.state.mdScreenSize} sm={this.state.smScreenSize} xs={this.state.xsScreenSize} className={classes.loginAlignment}><a className={classes.loginButton} href='/login'>{strings.login_head2}</a></Grid>
                  : ''}
              </Grid>
            </Container>
          </div>
        </>
      )
    }
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(Header)
