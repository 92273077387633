import { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'

import { strings } from '../../localizations/strings.js'
import * as CONSTANTS from '../../utils/url_constant.js'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { Grid, Link } from '@material-ui/core'
import {
  getUserInfo, resetCurrentUser, getToken,
  getData,
  saveUserDataToLocalStorage,
  getUserCompanyId
} from '../../utils/Authentication.js'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent, getMenuUserRoleString } from '../../helpers/GenericHelpers.js'
import { logout, userDetailApi } from '../../helpers/api.js'

const useStyles = theme => ({
  footerSidebar: {
    bottom: '0',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.7)'
  },
  footerSideBarProfile: {
    marginBottom: '8%'
  },
  footerSideBarLinks: {
    backgroundColor: '#f5f8fa',
    paddingBottom: '4%'
  },
  userFullName: {
    fontSize: '20px'
  },
  userEmail: {
    fontSize: '14px'
  },
  staticLink: {
    color: 'rgba(0, 0, 0, 0.7)',
    textDecorationLine: 'none'
  },
  listItemText: {
    fontSize: '14px',
    lineHeight: 'normal',
    paddingLeft: '5%'
  },
  dividerPadding: {
    marginBottom: '6%'
  },
  logoutLink: {
    textAlign: 'center',
    marginTop: '5%'
  },
  dividerPaddingProfile: {
    width: '70%',
    marginLeft: '15%',
    marginBottom: '15px'
  }
})

class DrawerHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        fullName: '',
        firstName: '',
        lastName: '',
        email: ''

      }
    }
  }

  onItemClickHandler (event) {
    if (event.target.getAttribute('data-key') === CONSTANTS.URLPATH_LOGOUT) {
      const params = {
        token: getToken()
      }
      logout(params, (error, response) => {
        if (error) {
          callFirebaseEvent(FA_CONSTANTS.FA_E_LOGOUT, { description: error.response.data.message })
        } else {
          callFirebaseEvent(FA_CONSTANTS.FA_LOGGED_OUT)
        }
        resetCurrentUser()
        this.props.sidebarProps.history.push(CONSTANTS.URLPATH_LOGIN)
      })
    } else if (event.target.getAttribute('data-key') === CONSTANTS.URLPATH_EDIT_ME) {
      this.props.sidebarProps.history.push(CONSTANTS.URLPATH_EDIT_ME)
      this.props.sidebarHandler(event)
    }
  }

  componentDidMount () {
    if (getData('acccess_token') !== null) {
      const params = {
        token: getData('acccess_token'),
        company_id: getUserCompanyId()
      }
      userDetailApi(params, (error, response) => {
        if (!error) {
          saveUserDataToLocalStorage(response.data.data.user)
          this.setState({
            userInfoChanged: true
          })
        }
      })
    }
  }

  updateUserInfo () {
    const userInfo = getUserInfo()
    let companyName = ''
    let firstName = ''
    let lastName = ''
    let fullName = ''
    let userEmail = ''
    if (userInfo) {
      companyName = getMenuUserRoleString(userInfo)
      firstName = (userInfo.first_name) ? userInfo.first_name : ''
      lastName = (userInfo.last_name) ? userInfo.last_name : ''
      fullName = firstName + ' ' + lastName
      userEmail = userInfo.email
    }
    return (
      <div className={this.props.classes.footerSideBarProfile}>
        <Grid
          container
          align='center'
          direction='column'
          justifyContent='center'
        >
          <Grid item xs={12}>
            <div data-cy='drawer-full-name' className={this.props.classes.userFullName}>{fullName}</div>
            <div data-cy='drawer-email' className={this.props.classes.userEmail}>{userEmail}</div>
            {}<div data-cy='drawer-company-name' className={this.props.classes.userEmail}>{companyName}</div>
          </Grid>
        </Grid>
        <Grid
          container
          align='center'
          direction='row'
          justifyContent='center'
        >
          <Grid item xs={6}>
            <div className={this.props.classes.logoutLink}>
              <Link
                style={{ cursor: 'pointer' }}
                data-cy={CONSTANTS.URLPATH_EDIT_ME}
                underline='always'
                data-key={CONSTANTS.URLPATH_EDIT_ME}
                onClick={this.onItemClickHandler.bind(this)}
              >
                {strings.menu_personal_data}
              </Link>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={this.props.classes.logoutLink}>
              <Link
                style={{ cursor: 'pointer' }}
                data-cy='logout'
                to={CONSTANTS.URLPATH_LOGIN}
                data-key={CONSTANTS.URLPATH_LOGOUT}
                underline='always'
                onClick={this.onItemClickHandler.bind(this)}
              >
                {strings.menu_log_out}
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  render () {
    return (
      <div className={this.props.classes.footerSidebar}>
        <Divider className={this.props.classes.dividerPaddingProfile} />
        {this.updateUserInfo()}
        <div className={this.props.classes.footerSideBarLinks}>
          <Divider className={this.props.classes.dividerPadding} />
          <a href={CONSTANTS.URLPATH_DATAPRIVACY} target='__blank' className={this.props.classes.staticLink}>
            <ListItemText primary={strings.menu_data_privacy} classes={{ primary: this.props.classes.listItemText }} />
          </a>
          <a href={CONSTANTS.URLPATH_TNC} target='__blank' className={this.props.classes.staticLink}>
            <ListItemText primary={strings.menu_tnc} classes={{ primary: this.props.classes.listItemText }} />
          </a>
          <a href={CONSTANTS.URLPATH_IMPRINT} target='__blank' className={this.props.classes.staticLink}>
            <ListItemText primary={strings.menu_imprint} classes={{ primary: this.props.classes.listItemText }} />
          </a>
        </div>
      </div>
    )
  }
}

DrawerHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(DrawerHeader)
