import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '20px',
    paddingBottom: '25px',
    paddingLeft: '32px'
  }
}))

export default function AssetDetailTitleBodyLayout (props) {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      className={classes.root}
      style={props.style || {}}
      spacing={props.gridSpacing || 1}
    >
      <Grid item>
        {props.title}
      </Grid>
      <Grid item>
        {props.body}
      </Grid>
    </Grid>
  )
}
