import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import { companyListApi } from '../../helpers/api.js'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { strings } from '../../localizations/strings.js'
import { createDocumentTitle } from '../../helpers/GenericHelpers'
import {
  getToken,
  saveDataInLocalStorage,
  getUserCompanyId,
  updateValueofFilters
} from '../../utils/Authentication.js'

const useStyles = theme => ({
  root: {
    background: 'black',
    fontFamily: 'Roboto'
  },
  plainButton: {
    color: '#555f60',
    textAlign: 'left'
  },
  description: {
    textAlign: 'center',
    padding: '10px'
  },
  border: {
    borderColor: '#f2f3f4'
  },
  button: {
    marginTop: '20px',
    height: '48px'
  },
  demo: {
    marginTop: '10px',
    paddingBottom: '3.8%'
  },
  dividerPadding: {
    marginBottom: '10px'
  },
  topPadding10: {
    marginTop: '10px'
  },
  background: {
    background: '#f5f8fa',
    marginBottom: '115px'
  },
  trItem: {
    height: '35px',
    cursor: 'pointer',
    border: 'solid thin'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    fontStretch: 'normal'
  },
  companyName: {
    wordWrap: 'break-word',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    color: 'gray'
  }
})
class CompanyList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      token: getToken(),
      company_list: []
    }
  }

  componentDidMount () {
    document.title = createDocumentTitle(document.title, strings.company)
    const params = {
      token: this.state.token
    }
    companyListApi(params, (error, response) => {
      if (error) {
        return window.alert('Failed to retrieve companies')
      } else {
        this.setState({ company_list: response.data.data.companies })
      }
    })
  }

  handleClick = company => e => {
    const userCompanyId = getUserCompanyId()
    if (!(company) || (userCompanyId !== ((company && company.id) || 0))) {
      saveDataInLocalStorage('company', JSON.stringify(company))
      window.localStorage.removeItem('branch')
      updateValueofFilters('checklists', [])
    }
    this.setState({})
  }

  render () {
    const { classes } = this.props
    const companyId = getUserCompanyId()
    return (
      <>
        <Container maxWidth='sm' className={classes.demo}>
          <List data-cy='company-list' component='nav' aria-label='main mailbox folders'>
            <Container key={0} style={{ padding: 0 }}>
              <ListItem data-cy='all-companies' button onClick={this.handleClick(null)}>
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  edge='start'
                  checked={companyId === 0}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'labelId' }}
                />
                <Grid
                  item
                  xs={6}
                  sm={6}
                  style={{ wordWrap: 'break-word' }}
                  className={classes.companyName}
                >
                  <ListItemText primary={strings.all} />
                </Grid>
              </ListItem>
              <Divider />
            </Container>
            {this.state.company_list.map(item => (
              <Container key={item.id} style={{ padding: 0 }}>
                <ListItem data-cy={item.name} button onClick={this.handleClick(item)}>
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    edge='start'
                    checked={companyId === item.id}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'labelId' }}
                  />

                  <Grid
                    item
                    xs={6}
                    sm={6}
                    style={{ wordWrap: 'break-word' }}
                    className={classes.companyName}
                  >
                    <ListItemText data-cy='company-name' primary={item.name} />
                  </Grid>
                  <Grid data-cy='company-email' item xs={6} sm={6} style={{ wordWrap: 'break-word' }}>
                    {item.email}
                  </Grid>
                </ListItem>
              </Container>
            ))}
          </List>
        </Container>
      </>
    )
  }
}
export default withStyles(useStyles)(CompanyList)
