import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { strings } from '../../localizations/strings.js'
import Container from '@material-ui/core/Container'
import Form from '../../common/form'
import Joi from 'joi-browser'
import { MAIL_FORMAT } from '../../utils/Matcher.js'
import {
  isLoggedIn,
  getUserId,
  getData,
  getUserInfo,
  getUserCompanyId
} from '../../utils/Authentication.js'
import { updateUserData } from '../../helpers/api.js'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent, StateType, createDocumentTitle } from '../../helpers/GenericHelpers.js'
import Snackbar from '../LayoutComponents/Snackbar'
import Typography from '@material-ui/core/Typography'

const useStyles = theme => ({
  root: {
    marginTop: '30px'
  },
  formControl: {
    margin: theme.spacing(1)
  },
  group: {
    margin: theme.spacing(1, 0),
    flexDirection: 'row'
  },
  items: {},
  plainButton: {
    color: '#555f60',
    textAlign: 'left'
  },
  description: {
    textAlign: 'center',
    padding: '10px'
  },
  border: {
    borderColor: '#f2f3f4'
  },
  button: {
    marginTop: '20px',
    height: '48px'
  },
  marginBottom: {
    marginBottom: '30px'
  },
  topPadding10: {
    marginTop: '10px'
  },
  margin: {
    margin: theme.spacing(1)
  },
  inputSelect: {
    minWidth: 120
  },
  switchMargin: {
    marginLeft: '-4px'
  },
  newsletterDescription: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#555f60'
  },
  newsletterTitle: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '400',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    color: '#131516'
  }

})

const WAIT_INTERVAL = 1000
const RESET_INTERVAL = 4000

class EditUser extends Form {
  constructor (props) {
    super(props)
    if (!isLoggedIn()) {
      this.props.history.push('/login')
    }
    this.state = {
      data: {
        salutation: '',
        firstname: '',
        lastname: '',
        tele: '',
        email: '',
        mailSubscription: false,
        previouslySubscribed: false
      },
      userId: '',
      errors: {},
      message: '',
      isAndroid: window.Android,
      showSnackBar: false,
      responseStatus: {
        status: '',
        message: ''
      },
      currentState: {
        firstname: StateType.none,
        lastname: StateType.none,
        tele: StateType.none,
        mrs: StateType.none,
        mr: StateType.none,
        mailSubscription: StateType.none
      }
    }
    this.timer = null
    // This flag will be used to differentiate whether it's a inline-editing api call or not
    this.isInlineApiCall = false
  }

  getScreenType () {
    if (window.Android) {
      return window.Android.getData('editType')
    } else if (window.iOS) {
      return window.editType
    } else {
      return this.props.editType
    }
  }

  isMobile = () => {
    return window.Android || window.iOS
  }

  componentDidMount () {
    document.title = createDocumentTitle(document.title, strings.user_list_header_4)
    const { data } = this.state
    const user = getUserInfo()
    if (user != null) {
      data.salutation = user.salutation
      if (user.first_name !== null) data.firstname = user.first_name
      data.lastname = user.last_name
      if (user.phone !== null) data.tele = user.phone
      data.email = user.email
      data.mailSubscription = user.news_letter === 1
      data.previouslySubscribed = user.news_letter === 1
      this.setState({ data })
    }
    callFirebaseEvent(FA_CONSTANTS.FA_PROFILE_SETTING)
  }

  schema = {
    salutation: Joi.string()
      .allow('')
      .label('salutation'),

    firstname: Joi.string().max(192)
      .allow('')
      .label('firstname')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'string.max':
              err.message = strings.max_character_length
              break

            default: break
          }
        })
        return errors
      }),

    lastname: Joi.string().max(192)
      .required()
      .label('lastname')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = strings.last_name_error_msg
              break

            case 'string.max':
              err.message = strings.max_character_length
              break

            default: break
          }
        })
        return errors
      }),

    email: Joi.string().max(192)
      .email()
      .required()
      .regex(MAIL_FORMAT)
      .label(strings.email)
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = strings.mandatory_field
              break

            case 'string.max':
              err.message = strings.max_character_length
              break

            default: err.message = strings.email_id_error_msg
              break
          }
        })
        return errors
      }),

    tele: Joi.string().max(192)
      .allow('')
      .label('tele').error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'string.max':
              err.message = strings.max_character_length
              break

            default: break
          }
        })
        return errors
      }),

    mailSubscription: Joi.string()
      .allow('')
  }

  doSubmit = (input, val) => {
    const {
      salutation,
      firstname,
      lastname,
      email,
      tele,
      mailSubscription
    } = this.state.data

    const params = {
      salutation,
      first_name: firstname,
      last_name: lastname,
      email,
      phone: tele,
      userId: getUserId(),
      token: getData('acccess_token'),
      company_id: getUserCompanyId(),
      mailchimp: mailSubscription
    }

    if (this.state.showSnackBar) {
      this.setState({
        showSnackBar: false
      })
    }

    if (navigator.onLine) {
      updateUserData(params, (error, response, key) => {
        if (error) {
          const errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : strings.something_wrong
          this.setInputState(StateType.failure, key)
          this.setState({
            showSnackBar: true,
            responseStatus: {
              status: 'error',
              message: errorMessage.length !== 0 ? errorMessage : strings.something_went_wrong
            }
          })
          // if (this.isInlineApiCall) {
          //   this.isInlineApiCall = false
          //   this.setInputState(StateType.failure, key)
          // } else {
          //   this.setState({
          //     showSnackBar: true,
          //     responseStatus: {
          //       status: 'error',
          //       message: errorMessage
          //     }
          //   })
          // }
        } else {
          // notify to dashboard to update userinfo in left side menu
          this.props.userInfoChanged()
          this.triggerFirebaseEvents(mailSubscription)
          this.setInputState(StateType.success, key)
          // if (this.isInlineApiCall) {
          //   this.isInlineApiCall = false
          //   this.setInputState(StateType.success, key)
          // } else {
          //   this.setState({
          //     showSnackBar: true,
          //     responseStatus: {
          //       status: 'success',
          //       message: response.data.message
          //     }
          //   })
          // }
        }
      }, input)
    } else {
      this.setState({
        showSnackBar: true,
        responseStatus: {
          status: 'error',
          message: strings.no_internet_connection
        }
      })
    }
  }

  // send firebase events
  triggerFirebaseEvents = (mailSubscription) => {
    callFirebaseEvent(FA_CONSTANTS.FA_PROFILE_SAVED)
    if (mailSubscription !== this.state.previouslySubscribed) {
      this.setState({ previouslySubscribed: mailSubscription })
      if (mailSubscription) {
        callFirebaseEvent(FA_CONSTANTS.FA_NEWSLETTER_SUBSCRIBED)
      } else {
        callFirebaseEvent(FA_CONSTANTS.FA_NEWSLETTER_UNSUBSCRIBED)
      }
    }
  }

  sendDataToAndroid = (callEvent, data) => {
    window.Android.communicateWithNative(callEvent, data)
  }

  // salutation on click
  handleRadioClick = event => {
    const { name, value } = event.target
    const { data, errors } = { ...this.state }
    data[name] = value
    delete errors[name]
    this.setState({ data, errors })
    callFirebaseEvent(FA_CONSTANTS.FA_PROFILE_SALUTATION)
    this.isInlineApiCall = true
    this.doSubmit(event.target.value === 'Mrs' ? 'mrs' : 'mr', '')
  }

  focusEvent = input => {
    if (input.name === 'firstname' && this.state.errors.firstname === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_PROFILE_FIRST_NAME)
    } else if (input.name === 'lastname' && this.state.errors.lastname === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_PROFILE_LAST_NAME)
    } else if (input.name === 'tele' && this.state.errors.tele === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_PROFILE_PHONE_SET)
    }
  }

  handleSwitchClick = (name, value) => {
    const { data } = { ...this.state }
    data[name] = value
    this.isInlineApiCall = true
    this.setState({ data })
    this.doSubmit(name, '')
  }

  handleCheckboxClick = name => event => {
    const { data } = { ...this.state }
    data[name] = event.target.checked
    this.isInlineApiCall = true
    this.setState({ data })
    this.doSubmit(name, '')
  }

  saveInlineData = (input, error) => {
    clearTimeout(this.timer)
    if (error) {
      this.setInputState(StateType.none, input.name)
    } else { this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL, input) }
  }

  triggerChange = (input) => {
    this.submitData(input.name)
  }

  handleRetryClick = event => {
    this.submitData(event.currentTarget.id)
  }

  submitData = (key) => {
    this.setInputState(StateType.loding, key)
    this.isInlineApiCall = true
    this.doSubmit(key, '')
  }

  setInputState = (stateType, key) => {
    const currentState = { ...this.state.currentState }
    currentState[key] = stateType
    this.setState({ currentState })
    setTimeout(this.resetCurrentState, RESET_INTERVAL, key, stateType)
  }

  resetCurrentState = (key, stateType) => {
    const currentState = { ...this.state.currentState }
    const data = { ...this.state.data }
    if (StateType.failure === stateType) {
      switch (key) {
        case 'mailSubscription':
          data.mailSubscription = data.mailSubscription !== true
          break
        case 'mr':
        case 'mrs':
          data.salutation = (data.salutation === strings.salutation_mrs) ? strings.salutation_mr : strings.salutation_mrs
          break
        default:break
      }
    }
    currentState[key] = StateType.none
    this.setState({ data, currentState })
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <div
          className={classes.root}
        >
          <Container maxWidth='sm'>
            <Grid container spacing={2}>
              <Grid item>
                <FormControl component='fieldset' margin='none'>
                  <FormLabel component='legend'>{strings.salutation}</FormLabel>
                  <RadioGroup
                    aria-label='gender'
                    name='salutation'
                    className={classes.group}
                    onChange={this.handleRadioClick}
                    value={this.state.data.salutation}
                  >
                    {this.renderRadioButton('Mrs', strings.salutation_mrs, this.state.currentState.mrs)}

                    {this.renderRadioButton('Mr', strings.salutation_mr, this.state.currentState.mr)}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid data-cy='first-name' item xs={12}>
                {this.renderInput('firstname', strings.first_name, classes, false, 'text', false, '', true, this.state.currentState.firstname)}
              </Grid>
              <Grid data-cy='last-name' item xs={12}>
                {this.renderInput('lastname', strings.last_name, classes, false, 'text', false, '', true, this.state.currentState.lastname)}
              </Grid>

              <Grid data-cy='phone' item xs={12}>
                {this.renderInput('tele', strings.telephone, classes, false, 'text', false, '', true, this.state.currentState.tele)}
              </Grid>

              <Grid data-cy='email' item xs={12}>
                {this.renderDisabledInput('email', strings.e_mail)}
              </Grid>

              <Grid data-cy='newsletter-title' item xs={12}>
                <Typography
                  align='left'
                  className={classes.newsletterTitle}
                >
                  {strings.klickcheck_newsletter}
                </Typography>
              </Grid>

              <Grid data-cy='newsletter-grid' item xs={1} className={classes.switchMargin}>
                {this.renderCheckBox(
                  'mailSubscription',
                  this.state.data.mailSubscription,
                  this.state.currentState.mailSubscription
                )}
              </Grid>
              <Grid item xs={11} className={classes.newsletterDescription}>
                {strings.news_letter}
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              {this.renderButton(strings.save, classes.button)}
            </Grid> */}
            {(this.state.showSnackBar) ? (<Snackbar type={this.state.responseStatus.status} message={this.state.responseStatus.message} />) : ''}
          </Container>
        </div>

      </>
    )
  }
}

EditUser.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(EditUser)
