import React from 'react'
import * as CONSTANTS from '../../utils/url_constant.js'
import Layout from '../../common/Layout'
import Header from '../LayoutComponents/Header'
import Sidebar from '../LayoutComponents/Sidebar'
import CompanyList from '../ManageCompany/CompanyList'
import QRCode from '../QRCode/CreatQRCode'
import { getFeedBackLink, userDetailApi } from '../../helpers/api.js'
import { strings } from '../../localizations/strings.js'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent, ListType } from '../../helpers/GenericHelpers.js'
import AssetList from '../AssetManagement/AssetList'
import EditUser from '../ManageUser/EditUser'
import UserList from '../ManageUser/UserList/UserList'
import GuidHelper from '../LayoutComponents/GuidHelper'
import {
  getData,
  getUserCompanyId,
  saveUserDataToLocalStorage
  , deleteToken
} from '../../utils/Authentication.js'
import store from '../../redux/logoutStore'
import * as CONSTANT from '../../utils/GenericConstants'
import ImageLightbox from '../../common/LightBox'
import FeedbackForm from '../../common/FeedbackForm.jsx'
import { Popover } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const useStyles = theme => ({
  popoverStyle: {
    background: 'rgba(0, 0, 0, 0.4)',
    borderRadius: '3px',
    boxShadow: '0 11px 15px -7px var(--black-20), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)'
  }
})

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: props.history.location.pathname,
      modalID: undefined,
      userInfoChanged: false,
      openDrawer: false,
      openPopover: false
    }
    this.updateStateForMenuSelection = this.updateStateForMenuSelection.bind(this)
    this.assetListRef = React.createRef()
    this.userListRef = React.createRef()
    this.feedbackModalID = 'feedback'
    store.subscribe(() => {
      if (store.getState() === CONSTANT.LOGOUT) {
        deleteToken()
        this.props.history.push(CONSTANTS.URLPATH_LOGIN)
      }
    })
  }

  updateStateForMenuSelection (route) {
    const query = this.props.history.location.search
    this.setState({ page: route }, () => {
      if (query === CONSTANTS.URLPATH_FEEDBACK) {
        callFirebaseEvent(FA_CONSTANTS.FA_FEEDBACK_SELECTED)
        this.handleOpenModal(this.feedbackModalID, true)
      } else if (query.startsWith(CONSTANTS.URLPATH_IMAGE)) {
        this.handleOpenModal(query, false)
      } else if (query.length === 0 && this.state.modalID) {
        // closing pop up with out creating an entry in history
        this.setState({
          modalID: undefined,
          openPopover: false
        })
      }
    })
  }

  componentDidMount () {
    this.updateStateForMenuSelection(this.props.history.location.pathname)
  }

  UNSAFE_componentWillReceiveProps () {
    this.updateStateForMenuSelection(this.props.history.location.pathname)
  }

  getComponentForRoute (route) {
    switch (route) {
      case CONSTANTS.URLPATH_SELECTCOMPANY:
        return <CompanyList history={this.props.history} />
      case CONSTANTS.URLPATH_ASSET_LIST:
        return (
          <AssetList
            ref={this.assetListRef}
            history={this.props.history}
            listType={ListType.Machines}
            updateBodyForMenuSelection={this.updateStateForMenuSelection}
          />
        )
      case CONSTANTS.URLPATH_EDIT_ME:
        return (
          <EditUser
            history={this.props.history}
            userInfoChanged={this.notifyUserInfoUpdated}
          />
        )
      case CONSTANTS.URLPATH_QRCODE:
        return <QRCode />
      case CONSTANTS.URLPATH_USERLIST:
        return (
          <UserList
            ref={this.userListRef}
            listType={ListType.Users}
            history={this.props.history}
            updateBodyForMenuSelection={this.updateStateForMenuSelection}
          />
        )
      default:
        return (<GuidHelper history={this.props.history} />)
    }
  }

  handleCloseModal = () => {
    callFirebaseEvent(FA_CONSTANTS.FA_FEEDBACK_ABORTED)
    this.setState({
      modalID: undefined,
      openPopover: false
    }, () => {
      this.props.history.replace(this.props.history.location.pathname)
    })
  }

  handleCloseFeedback = () => {
    callFirebaseEvent(FA_CONSTANTS.FA_FEEDBACK_ABORTED)
    this.setState({
      modalID: undefined,
      openPopover: false
    }, () => {
      this.props.history.replace(this.props.history.location.pathname)
    })
  }

  handleOpenModal = (modelID, openPop) => {
    this.setState({
      modalID: modelID,
      openPopover: openPop
    })
  }

  notifyUserInfoUpdated = () => {
    this.getUserDetails()
  }

  getUserDetails = () => {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    userDetailApi(params, (error, response) => {
      if (!error) {
        saveUserDataToLocalStorage(response.data.data.user)
        this.setState({
          userInfoChanged: true
        })
      }
    })
  }

  handleSearch = (searchTerm, listType) => {
    listType === ListType.Machines ? this.assetListRef.current.handleSearch(searchTerm) : this.userListRef.current.handleSearch(searchTerm)
  }

  handleFilter = (filterParams, listType) => {
    listType === ListType.Machines ? this.assetListRef.current.handleFilter(filterParams) : this.userListRef.current.handleFilter(filterParams)
  }

  handleSelectBranch (listType) {
    listType === ListType.Machines ? this.assetListRef.current.handleSelectBranch() : this.userListRef.current.handleSelectBranch()
  }

  handleGetSurveyUrl = () => {
    callFirebaseEvent(FA_CONSTANTS.FA_FEEDBACK_CONTINUED)
    this.setState({
      modalID: undefined
    }, () => {
      this.props.history.replace(this.props.history.location.pathname)
    })
    const params = {
      token: this.state.token
    }
    getFeedBackLink(params, (error, response) => {
      if (error) {
        return window.alert(error.response.data.message)
      } else {
        window.open(response.data.data.link, '_blank')
      }
    })
  }

  render () {
    return (
      <div>
        <Layout
          body={this.getComponentForRoute(this.state.page)}
          sidemenu={
            <Sidebar
              history={this.props.history}
              updateBodyForMenuSelection={this.updateStateForMenuSelection}
            />
          }
          header={
            <Header
              route={this.state.page}
              handleSearch={(value, listType) => this.handleSearch(value, listType)}
              handleSelectBranch={(listType) => this.handleSelectBranch(listType)}
              handleFilter={(value, listType) => this.handleFilter(value, listType)}
            />
          }
        />

        {(this.state.modalID !== this.feedbackModalID && this.state.modalID)
          ? <ImageLightbox onClose={this.handleCloseModal} guid={this.state.modalID} />
          : ''}
        {this.state.openPopover &&
          <Popover
            className={this.props.classes.popoverStyle}
            anchorReference='anchorPosition'
            anchorPosition={{ top: 50, left: window.innerWidth / 2 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={this.state.openPopover}
            onClose={this.handleCloseFeedback}
          >
            {this.state.modalID === this.feedbackModalID &&
              <div style={{ padding: '5px' }}>
                <FeedbackForm
                  messageTitle={strings.problem_feedback}
                  messageSubTitle={strings.feedBacksubTitle}
                  onClose={this.handleCloseFeedback}
                  userInfoChanged={this.notifyUserInfoUpdated}
                />
              </div>}
          </Popover>}
      </div>
    )
  }
}

export default withStyles(useStyles)(Dashboard)
