import { Component } from 'react'
import FilterIcon from '@material-ui/icons/Tune'
import UserFilterView from './UserFilterView'
import AssetsFilterView from './AssetsFilterView'
import { ListType } from '../../helpers/GenericHelpers'

class FilterView extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {

  }

  usersFilter () {
    return (
      <div>
        <UserFilterView
          icon={FilterIcon}
          style={{ primary: { backgroundColor: '#0d548c' }, secondary: { backgroundColor: '#0d548c' } }}
          listType={this.props.listType}
          onFilter={(filterParams) => this.props.onFilter(filterParams)}
        />
      </div>
    )
  }

  assetsFilter () {
    return (
      <div>
        <AssetsFilterView
          icon={FilterIcon}
          style={{ primary: { backgroundColor: '#0d548c' }, secondary: { backgroundColor: '#0d548c' } }}
          listType={this.props.listType}
          filterParams={this.props.filterParams}
          onFilter={(filterParams) => this.props.onFilter(filterParams)}
        />
      </div>
    )
  }

  render () {
    return (
      this.props.listType === ListType.Machines ? this.assetsFilter() : this.usersFilter()
    )
  }
}

export default FilterView
