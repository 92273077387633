import { makeStyles } from '@material-ui/core/styles'
import {
  CheckmarkImage,
  CloseImage,
  AssetDetailsLabel
} from '../../DetailsCommonComponents'
import {
  AssetCheckTypes,
  getReportedDateDefectCheck
} from '../../../../../helpers/GenericHelpers'
import ImagesGrid from '../../ImagesGrid'
import AssetCheckLayout from './AssetCheckLayout'

const useStyles = makeStyles(theme => ({
  commentLabel: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '1.14',
    letterSpacing: '0.25px',
    color: '#131516',
    marginTop: '-8px'
  },
  reportedLabel: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: '#131516',
    marginTop: '-8px'
  }
}))

export default function AssetDetailsCheckItemGrid (props) {
  const classes = useStyles()

  const checkValueModel = props.checkItem.check_value
  const checkValue = checkValueModel?.value
  const getTypeIconComponent = () => {
    return (checkValue === AssetCheckTypes.notOk)
      ? <CloseImage />
      : <CheckmarkImage />
  }

  const getCheckValuesComponents = () => {
    const components = [<AssetDetailsLabel key={props.checkItem.name}>{props.checkItem.name}</AssetDetailsLabel>]

    const images = (checkValueModel.check_value_images) ? checkValueModel.check_value_images : []
    if (checkValue === AssetCheckTypes.notOk) {
      if (images.length > 0) {
        components.push(
          <ImagesGrid
            key='imageGrid'
            images={images}
            previewImage={(imageGUID) => props.previewImage(imageGUID)}
          />
        )
      }

      const comment = checkValueModel?.comment
      if (comment && comment !== '') {
        const commentComponent =
          <AssetDetailsLabel
            key={props.checkItem.name}
            className={classes.commentLabel}
          >
            {comment}
          </AssetDetailsLabel>
        components.push(commentComponent)
      }

      const reported = getReportedDateDefectCheck(checkValueModel)
      if (reported && reported !== '') {
        const reportedComponent =
          <AssetDetailsLabel
            key={props.checkItem.name}
            className={classes.reportedLabel}
          >
            {reported}
          </AssetDetailsLabel>
        components.push(reportedComponent)
      }
    }

    return components
  }

  return (
    <AssetCheckLayout
      assetTypeIcon={getTypeIconComponent()}
      checkValueComponents={getCheckValuesComponents()}
      valueGridSpacing={1}
    />
  )
}
