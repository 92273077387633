import { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import AndroidAppImage from '../assets/images/play_store_badge.png'
import IOSAppImage from '../assets/images/ios_app_badge.png'
import * as CONSTANTS from '../utils/url_constant'

const useStyles = () => ({
  root: {
    width: '100%',
    backgroundColor: 'rgba(0, 174, 239, 0.05)'
  },
  footerBody: {
    paddingTop: '1%',
    paddingBottom: '1%',
    flexDirection: 'column',
    flex: '1'
  },
  footerText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal'
  },
  gridStyle: {
    color: '#4d4d4d',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '1%',
    marginTop: '1%'
  },
  footerAlignment: {
    marginBottom: '1%',
    marginTop: '1%'
  },
  footerLinkLeft: {
    marginBottom: '1%'
  },
  hrefStyle: {
    color: '#0d548c'
  },
  footerImageAndorid: {
    height: '25px'
  },
  footerImageIOS: {
    height: '30px'
  },
  imageStyle: {
    paddingTop: '2%',
    textAlign: 'center'
  },
  containerLeft: {
    paddingTop: '2%'
  }
})
class Footer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isAndroid: window.Android,
      isiOS: window.iOS,
      hover: false
    }
  }

  onHovers = e => {
    const hovers = this.state.hover
    this.setState({ hover: !hovers })
    if (this.state.hover) {
      e.target.style.color = '#0d548c'
    } else {
      e.target.style.color = '#005170'
    }
  }

  render () {
    const { classes } = this.props
    if (this.state.isAndroid || this.state.isiOS) {
      return ''
    } else {
      return (
        <>
          <div className={classes.root}>
            <Container className={classes.footerBody}>
              <Grid container>
                <Grid
                  xs={12}
                  sm={6}
                  md={6}
                  className={classes.gridStyle}
                  item
                >
                  <Grid container className={classes.containerLeft}>
                    <Grid
                      xs={12}
                      sm={4}
                      md={3}
                      item
                      className={classes.footerLinkLeft}
                    >
                      <a
                        data-cy='datenschutz'
                        href={CONSTANTS.URLPATH_DATENSCHUTZ}
                        onMouseEnter={e => this.onHovers(e)}
                        onMouseLeave={e => this.onHovers(e)}
                        className={classes.hrefStyle}
                      >
                        Datenschutz
                      </a>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={4}
                      md={4}
                      item
                      className={classes.footerLinkLeft}
                    >
                      <a
                        data-cy='nutzungsbedingungen'
                        href={CONSTANTS.URLPATH_NUTZUNGSBEDINGUNGEN}
                        onMouseEnter={e => this.onHovers(e)}
                        onMouseLeave={e => this.onHovers(e)}
                        className={classes.hrefStyle}
                      >
                        Nutzungsbedingungen
                      </a>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={4}
                      item
                      className={classes.footerLinkLeft}
                    >
                      <a
                        data-cy='imprint'
                        href={CONSTANTS.URLPATH_IMPRINT}
                        onMouseEnter={e => this.onHovers(e)}
                        onMouseLeave={e => this.onHovers(e)}
                        className={classes.hrefStyle}
                      >
                        Impressum
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={6}
                  md={6}
                  className={classes.footerAlignment}
                  item
                >

                  <Grid container className={classes.imageStyle}>
                    <Grid
                      md={2}
                      item
                      className={classes.footerLinkLeft}
                    />
                    <Grid
                      xs={6}
                      sm={12}
                      md={4}
                      item
                      className={classes.footerLinkLeft}
                    >
                      <a href={CONSTANTS.URLPATH_ANDROID_PLAY_STORE}>
                        <img
                          className={classes.footerImageAndorid}
                          src={AndroidAppImage}
                          alt=''
                        />
                      </a>
                    </Grid>

                    <Grid
                      xs={6}
                      sm={12}
                      md={4}
                      item
                      className={classes.footerLinkLeft}
                    >
                      <a href={CONSTANTS.URLPATH_IOS_APP_STORE}>
                        <img
                          className={classes.footerImageIOS}
                          src={IOSAppImage}
                          alt=''
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>

      )
    }
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(Footer)
