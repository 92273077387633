import { withStyles } from '@material-ui/styles'
import Form from '../../common/form'
import { Grid, Link } from '@material-ui/core'
import { strings } from '../../localizations/strings.js'

import Container from '@material-ui/core/Container'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'

import { trackEventsForOrderItemEnter } from './OrderCheckItemsHelper'

import Joi from 'joi-browser'
import { StateType, ScreenTypes } from '../../helpers/GenericHelpers'
import Snackbar from '../LayoutComponents/Snackbar'

const useStyles = theme => ({
  root: {},
  layout: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 0px 10px',
    zIndex: 1,
    position: 'relative'
  },
  layoutLoader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 0px 10px',
    width: '300px',
    height: '600px',
    zIndex: 2,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#212121',
    marginTop: '1%'
  },

  plainButton: {
    color: '#ffffff',
    backgroundColor: '#0d548c',
    textAlign: 'left'
  },

  leftMargin: {
    marginLeft: '20px',
    marginTop: '20px'

  },

  rightMargin: {
    marginLeft: '20px'

  },
  leftRightMargin: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  topMargin: {
    marginTop: '20px'
  },

  topMargin40PX: {
    marginTop: '40px'
  },
  margin: {
    margin: '20px'
  },
  subTitleLink: {
    textAlign: 'left',
    color: '#fa3900',
    marginLeft: '20px',
    marginRight: '20px'
  },
  invoiceTitle: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#0d548c',
    marginTop: '1%',
    paddingRight: '20px'

  },
  closeButton: {
    float: 'right',
    width: '25px',
    height: '25px',
    marginTop: '1%'
  },
  button: {
    '& .MuiButton-label': {
      color: '#303030'
    }
  }
})

const WAIT_INTERVAL = 1000
const RESET_INTERVAL = 4000
class OrderDetails extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      oldData: {},
      errors: {},
      currentState: {},
      disableEntry: false,
      isModalOpen: false,
      schema: this.getSchemaParams(),
      showSnackBar: false,
      responseStatus: {}
    }
    this.timer = null
    this.isEditForm = false
  }

  schema = {}

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  getSchemaParams = () => {
    let schemaObj = {
      'Firma/Kunde': Joi.string().allow('').max(192)
        .error(errors => { return { message: strings.max_character_length } }),
      Einsatzort: Joi.string().allow('').max(192)
        .error(errors => { return { message: strings.max_character_length } }),
      'Ansprechpartner vor Ort': Joi.string().allow('').max(192)
        .error(errors => { return { message: strings.max_character_length } }),
      'Telefonnummer des Ansprechpartners': Joi.string().allow('').max(192)
        .error(errors => { return { message: strings.max_character_length } })
    }
    this.props.checkList.check_items.map(item => {
      if (item.id === -9) {
        schemaObj = Object.assign({ ...schemaObj }, {
          [item.name]: Joi.string().trim().label(item.name)
            .required()
            .max(192)
            .error(errors => {
              errors.forEach(err => {
                switch (err.type) {
                  case 'any.empty':
                    err.message = strings.mandatory_field
                    break

                  case 'string.max':
                    err.message = strings.max_character_length
                    break

                  default: break
                }
              })
              return errors
            })
        })
      }
      return ''
    })
    return schemaObj
  }

  componentDidMount () {
    this.schema = this.state.schema
    const data = this.state.data
    const oldData = this.state.oldData
    const currentState = this.state.currentState
    this.props.checkList.check_items.forEach(item => {
      data[item.name] = ((typeof item.check_value.value === 'undefined') ? '' : item.check_value.value)
      currentState[item.name] = StateType.none
    }
    )
    this.props.checkList.check_items.map(item => (
      oldData[item.name] = ((typeof item.check_value.value === 'undefined') ? '' : item.check_value.value)
    ))
    this.setState({ data, oldData, currentState })
  }

  saveInlineData = (input, error) => {
    if (this.isEditForm) {
      clearTimeout(this.timer)
      if (error) {
        this.setInputState(StateType.none, input.name)
      } else { this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL, input) }
    }
  }

  triggerChange = (input) => {
    this.submitData(input.name)
  }

  handleRetryClick = event => {
    this.submitData(event.currentTarget.id)
  }

  submitData = (key) => {
    this.setInputState(StateType.loding, key)
    this.doSubmit(this.state.errors, key)
  }

  setInputState = (stateType, key) => {
    const currentState = { ...this.state.currentState }
    currentState[key] = stateType
    this.setState({ currentState })
    setTimeout(this.resetCurrentState, RESET_INTERVAL, key, stateType)
  }

  resetCurrentState = (key, stateType) => {
    const currentState = { ...this.state.currentState }
    currentState[key] = StateType.none
    this.setState({ currentState })
  }

  showBar = (responseStatus) => {
    this.setState({ showSnackBar: true, responseStatus })
  }

  doSubmit = (errors, key) => {
    if (!this.isEditForm && errors === null) {
      this.props.onSubmit(this.state.data, this.state.oldData, key)
    } else if (this.isEditForm && !this.anyError()) {
      this.setState({ showSnackBar: false }, () => {
        this.props.onSubmit(this.state.data, this.state.oldData, key)
      })
    } else this.setInputState(StateType.none, key)
  }

  doDiscard = (event) => {
    event.preventDefault()
    this.props.onDiscard(this.props.assetId)

    /*
    if (this.props.assetStatus === 'DRAFT') {
      this.setState({ isModalOpen: true })
    } else {
      this.props.onDiscard(this.props.assetId)
    }
    */
  }

  checkDiscardStatus = () => {
    if (this.props.assetStatus !== 'CHECKED_OUT') {
      this.setState({ disableEntry: true })
      return (<div onClick={this.doDiscard.bind(this)}>discard draft</div>)
    }
  }

  updateDisabledStatus (id) {
    return (this.props.assetStatus === 'CHECKIN_DRAFT' && id === -9) || this.props.assetStatus === 'CHECKED_OUT'
  }

  addCloseIcon () {
    return (
      <CloseIcon
        data-cy='close-form-icon'
        className={this.props.classes.closeButton} onClick={() => this.props.onClose()}
      />
    )
  }

  focusEvent = input => {
    trackEventsForOrderItemEnter(input.id, this.props.asset)
  }

  render () {
    this.isEditForm = this.props.isUpdate
    const { classes, checkList } = this.props
    const disabled = false
    let disableDiscardDraft = false
    let title = ''
    let subTitle = 'subtitle'
    const submitButtonTitle = this.props.submitButtonTitle

    if (this.props.assetStatus === 'CHECKED_OUT') {
      title = strings.order_details
      subTitle = ''
      disableDiscardDraft = false
    }
    if (this.props.assetStatus === 'DRAFT') {
      title = strings.checkout_draft
      subTitle = strings.delete_draft
    }
    if (this.props.assetStatus === 'CHECKED_IN') {
      title = strings.create_order_data
      subTitle = ''
      disableDiscardDraft = false
    }
    if (this.props.assetStatus === 'CHECKIN_DRAFT') {
      title = strings.return_draft
      subTitle = ''
      disableDiscardDraft = false
    }

    const header =
      <div>
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            style={{ paddingRight: '4%' }}
          >
            {this.addCloseIcon()}
          </Grid>
          <Grid
            container
            className={this.props.classes.leftMargin}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              data-cy='order-form-head-title'
              className={this.props.classes.title}
              item
            >
              {title}

            </Grid>
          </Grid>
        </Grid>
      </div>

    return (
      <div>
        <Container>
          {(this.props.setProgressBar === true)
            ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.layoutLoader}
                >
                  <CircularProgress />
                </Grid>
              </Grid>
              )
            : ''}

        </Container>
        <Container className={classes.layout}>

          {(this.props.screenType === ScreenTypes.desktop) ? header : ''}

          <Grid
            container
            className={this.props.classes.leftRightMargin}
          >
            {(subTitle !== '')
              ? (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  item
                >
                  <Link
                    style={{ cursor: 'pointer' }}
                    data-cy='delete-draft-link'
                    className={this.props.classes.subTitleLink}
                    underline='always'
                  >
                    {disableDiscardDraft === false ? <div onClick={this.doDiscard.bind(this)}>{subTitle}</div> : ''}
                  </Link>
                </Grid>
                )
              : ''}
          </Grid>

          <form className={this.props.classes.leftRightMargin}>
            <Grid container>
              {checkList.check_items.map(item =>
                <Grid
                  data-cy={item.name}
                  item
                  key={item.id}
                  container
                  direction='row'
                  justifyContent='flex-start'
                  spacing={0}
                  xs={12}
                  sm={12}
                  md={12}
                  className={this.props.classes.topMargin}
                >{disabled}

                  {this.renderInput(item.name, item.name, classes, '', 'text', this.updateDisabledStatus(item.id), item.id, this.isEditForm, this.state.currentState[item.name])}
                </Grid>
              )}

              <Grid
                item
                direction='row'
                justifyContent='flex-start'
                container
                spacing={0}
                xs={12}
                sm={12}
                md={12}
                className={this.props.classes.topMargin40PX}
                data-cy='create-draft-button'
              >

                {this.isEditForm ? '' : this.renderButton(submitButtonTitle, classes.button, disabled)}
              </Grid>
            </Grid>
          </form>
          {(this.state.showSnackBar) ? (<Snackbar type={this.state.responseStatus.status} message={this.state.responseStatus.message} />) : ''}
        </Container>
      </div>
    )
  }
}

export default withStyles(useStyles)(OrderDetails)
