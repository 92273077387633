import { Component } from 'react'
import UserTileLarge from './UserTileLarge'
import UserTileSmall from './UserTileSmall'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

import { Button, Grid, Popover, Link } from '@material-ui/core'

import { strings } from '../../../localizations/strings.js'
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import User from './User'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'

import * as FA_CONSTANTS from '../../../utils/AnalyticsConstants.js'
import * as CONSTANTS from '../../../utils/url_constant.js'
import { callFirebaseEvent } from '../../../helpers/GenericHelpers.js'

import Modal from '../../../common/Modal'
import PopUp from '../../../common/Popup'
import { getUserId, getData } from '../../../utils/Authentication'
import { deleteUserAPI } from '../../../helpers/api.js'
import Snackbar from '../../LayoutComponents/Snackbar'
import { connect } from 'react-redux'
import { opened as userPopoverOpened, closed as userPopoverClosed } from '../../../redux/slices/userPopoverSlice'

const useStyles = theme => ({
  root: {
    flexGrow: 'none',
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '100%',
    [theme.breakpoints.only('sm', 'xs')]: {
      paddingLeft: '23px'
    }
  },
  wrapIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    [theme.breakpoints.only('sm', 'xs')]: {
      fontWeight: '400'
    }
  },
  buttonRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    padding: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }
  },
  buttonLabel: {
    textTransform: 'none'
  },
  arrowUpImage: {
    color: 'primary',
    verticalAlign: 'middle'
  },
  arrowDownImage: {
    verticalAlign: 'middle',
    color: 'primary'
  }
})

class UserTile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      bgColor: null,
      isModalOpen: false,
      id: 0,
      companyId: 0,
      showSnackBar: false,
      responseStatus: ''
    }

    this.isEdit = false
    this.responseData = ''
  }

  getOrderIcon (wrapIconClass) {
    const orderIcon =
      <Typography className={wrapIconClass} variant='subtitle1' color='secondary'>

        <Link underline='always'>{strings.edit_user}</Link>

        {this.state.anchorEl ? <ArrowDropUpOutlinedIcon className={this.props.classes.arrowUpImage} /> : <ArrowDropDownOutlinedIcon className={this.props.classes.arrowDownImage} />}
      </Typography>
    return orderIcon
  }

  handleClick = event => {
    this.setState({
      showSnackBar: false
    })
    if (!navigator.onLine) {
      alert(strings.no_internet_connection)
    } else {
      if (getUserId() === this.props.asset.id) {
        // window.history.pushState('Data', 'Title', CONSTANTS.URLPATH_EDIT_ME)
        this.props.updateBodyForMenuSelection(CONSTANTS.URLPATH_EDIT_ME)
        this.props.history.push(CONSTANTS.URLPATH_EDIT_ME)
      } else {
        this.setState({ anchorEl: event.currentTarget })
        this.setState({ bgColor: '#F4FBFE' })
        this.props.userPopoverOpened()
      }
    }

    callFirebaseEvent(FA_CONSTANTS.FA_ORDER_OPENED, {}, this.props.asset)
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
    this.setState({ bgColor: null })

    if (this.isEdit) {
      this.props.handleBranch()
    }

    this.props.userPopoverClosed()
    callFirebaseEvent(FA_CONSTANTS.FA_ORDER_CLOSED, {}, this.props.asset)
  }

  handleEditSuccess = (result) => {
    this.isEdit = result
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: null })
  }

  handleDeleteEvent = (userId) => {
    callFirebaseEvent(FA_CONSTANTS.FA_USERDELETE_OPEN)
    this.setState({
      isModalOpen: true,
      id: userId,
      companyId: this.props.asset.company_id
    })
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  handleDeleteUser = () => {
    if (this.state.showSnackBar) {
      this.setState({
        showSnackBar: false
      })
    }
    const params = {
      id: this.state.id,
      companyId: this.state.companyId,
      token: getData('acccess_token')
    }
    deleteUserAPI(params, (error, response) => {
      if (error) {
        let errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : strings.something_wrong
        if (error.message === 'Network Error') {
          errorMessage = strings.no_internet_connection
        }
        this.setState({
          showSnackBar: true,
          responseStatus: {
            status: 'error',
            message: errorMessage.length !== 0 ? errorMessage : strings.something_went_wrong
          }
        }, () =>
          this.handleResponse())
      } else {
        callFirebaseEvent(FA_CONSTANTS.FA_USERDELETED)
        this.setState({
          // showSnackBar: true,
          responseStatus: {
            status: 'success',
            message: strings.user_deleted
          }
        }, () =>
          this.handleResponse()
        )
      }
    })
  }

  handleResponse = () => {
    this.sleep(500).then(() => {
      this.onResponse(this.state.responseStatus)
      this.handleCloseModal()
    })
  }

  onResponse = (responseStatus) => {
    this.props.handleResponse(responseStatus)
    this.handleClose()
  }

  render () {
    let deleteMessgaeTitle = ''
    if (this.props.asset != null) {
      if (this.props.asset.first_name != null) {
        deleteMessgaeTitle = strings.delete_user_message + ' ' + this.props.asset.first_name + ' ' + this.props.asset.last_name + ' ' + strings.delete_user_message1
      } else {
        deleteMessgaeTitle = strings.delete_user_message + ' ' + this.props.asset.last_name + ' ' + strings.delete_user_message1
      }
    }
    const open = Boolean(this.state.anchorEl)
    const id = open ? 'simple-popover' : undefined

    const topScreen =
  (this.props.width === 'sm' || this.props.width === 'xs')
    ? ((window.innerHeight - 600) / 2)
    : ((window.innerHeight - 600 > 180) ? 180 : 10)
    const leftScreen =
  (this.props.width === 'sm' || this.props.width === 'xs')
    ? ((window.innerWidth - 300) / 2)
    : (window.innerWidth - 320)

    const orderIcon = this.getOrderIcon(this.props.assetAvailability, this.props.orderId, this.props.classes.wrapIcon)
    if (window.innerWidth < 959) {
      const state = this.props.asset

      if (open) {
        this.props.history.push(CONSTANTS.URLPATH_CREATEUSER, state)
      }
    }
    const orderDiv =
      <Grid item>
        <Button
          onClick={this.handleClick}
          classes={{ label: this.props.classes.buttonLabel, root: this.props.classes.buttonRoot }}
        >
          {orderIcon}
        </Button>
        <Popover
          anchorReference='anchorPosition'
          anchorPosition={{ top: topScreen, left: leftScreen }}
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          style={{ marginBottom: '70px' }}
        >
          <div>
            <User
              openFrom='EDIT_USER'
              parentProps={this.props}
              onResponse={(response) => this.props.handleResponse(response)}
              onEdit={(result, responseData) => this.handleEditSuccess(result, responseData)}
              onClose={() => this.handleClose()}
              onDelete={() => this.handleDeleteEvent(this.props.asset.id)}
            />
          </div>
        </Popover>
      </Grid>

    return (
      <div
        data-cy='each-user'
        className={this.props.classes.root}
        style={{ backgroundColor: this.state.bgColor }}
      >
        <Hidden only={['sm', 'xs']}>
          <UserTileLarge
            parentProps={this.props}
            orderDiv={orderDiv}
            branchAvailable={this.props.branchAvailable}
          />
        </Hidden>
        <Hidden only={['lg', 'xl', 'md']}>

          <UserTileSmall
            parentProps={this.props}
            orderDiv={orderDiv}
          />
        </Hidden>

        <Modal
          isOpen={this.state.isModalOpen}
          onClose={this.handleCloseModal}
        >
          <div style={{ padding: '20px' }}>
            <PopUp
              messageTitle={strings.delete_user_title}
              message={deleteMessgaeTitle}
              buttonTitle={strings.delete_user_now}
              onClose={this.handleCloseModal}
              openFor={strings.delete_user_title}
              onDelete={this.handleDeleteUser}
              onCancel={this.handleCloseModal}
            />
          </div>
        </Modal>

        {(this.state.showSnackBar) ? (<Snackbar type={this.state.responseStatus.status} message={this.state.responseStatus.message} />) : ''}
      </div>

    )
  }
}

UserTile.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  userPopoverOpened,
  userPopoverClosed
}

export default withStyles(useStyles)(connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true })(UserTile)
)
