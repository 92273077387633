import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { strings } from '../../localizations/strings.js'
import { getBranchName, ListType } from '../../helpers/GenericHelpers.js'
import { getUserBranchCount } from '../../utils/Authentication'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '5px',
    paddingBottom: '15px',
    paddingLeft: '26px',
    '@media(max-width: 583px)': {
      paddingLeft: '5%'
    }
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: '0.56px',
    color: 'rgba(0, 0, 0, 0.6)'
  }
}))

export default function ComplexGrid (props) {
  const classes = useStyles()

  const getCityName = () => {
    const branchcount = getUserBranchCount() || 0

    if (branchcount > 1) {
      if (props.isLocationNotAssigned) {
        return strings.location_not_assigned
      }
      const location = getBranchName(props.userBranch)
      return location !== '' ? location : strings.allLocations
    }
    return ''
  }

  const getConjunction = () => {
    const branchcount = getUserBranchCount() || 0

    if (branchcount > 1) {
      const location = getBranchName(props.userBranch)
      return (location !== '' || props.isLocationNotAssigned) ? strings.in : strings.at
    }
    return ''
  }

  const getSearchTerm = () => {
    if (props.searchTerm) {
      return strings.for + ' ' + props.searchTerm
    }
    return ''
  }

  const getMachineLabel = () => {
    if (props.listType === ListType.Machines) {
      if (props.assetsCount === 0) {
        return strings.no_machine
      } else if (props.assetsCount === 1) {
        return strings.one_machine
      } else if (props.assetsCount > 1) {
        return props.assetsCount + ' ' + strings.machines
      }
      return strings.machines
    } else {
      if (props.assetsCount === 0) {
        return strings.no_users
      } else if (props.assetsCount === 1) {
        return strings.one_user
      } else if (props.assetsCount > 1) {
        return props.assetsCount + ' ' + strings.users
      }
      return strings.users
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>
        <span data-cy='item-count'>{getMachineLabel()} </span>
        <span data-cy='search-term'>{getSearchTerm()} </span>
        <span data-cy='location-search'>{getConjunction()}</span>
        <span style={{ fontWeight: '500' }}> {getCityName()}</span>
      </Typography>
    </div>
  )
}
