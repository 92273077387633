import Grid from '@material-ui/core/Grid'

export default function MultiGridLayout (props) {
  return (
    (props.components && props.components.length > 0) &&
      (
        <Grid
          item
          container
          direction={props.direction || 'column'}
          justifyContent={props.justify || 'flex-start'}
          alignItems={props.alignItems || 'flex-start'}
          style={props.style || {}}
          className={props.className}
          spacing={props.gridSpacing || 0}
        >
          {props.components.map((component, index) => (<Grid key={index} item>{component}</Grid>))}
        </Grid>
      )
  )
}
