import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import UserIcon from '../../../assets/images/user_list_placeholder.svg'
import ChevronRight from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(theme => ({
  image: {
    width: '80px',
    height: '80px',
    marginTop: '3px',
    position: 'relative',
    textAlign: 'center'
  },
  topRight: {
    position: 'absolute',
    top: '-5px',
    right: '-5px'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '4px'
  },
  content: {
    wordBreak: 'break-word'
  },
  icon: {
    display: 'flex',
    alignItems: 'center'
  },
  dividerPadding: {
    marginRight: '10%',
    marginTop: '3%',
    marginBottom: '-5%',
    marginLeft: '10%'
  },
  inventoryNoChecklistName: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: '400'
  },
  assetName: {
    fontWeight: '500'
  }
}))

export default function UserTileSmall (props) {
  const classes = useStyles()
  return (
    <>
      <Grid container spacing={3}>
        <Grid item className={classes.icon}>
          <img src={UserIcon} alt='UserIcon' />
        </Grid>
        <Grid item xs className={classes.content}>
          <Typography data-cy='user-role' variant='subtitle2' className={classes.inventoryNoChecklistName}>
            {props.parentProps.role}
          </Typography>
          <Typography data-cy='user-name' variant='subtitle1' className={classes.assetName}>
            {props.parentProps.name}
          </Typography>
          <Grid item xs align='left'>
            <Typography data-cy='user-email' variant='subtitle2' className={classes.inventoryNoChecklistName}>
              {props.parentProps.email}
            </Typography>
          </Grid>
          <Grid data-cy='user-edit' item xs align='left'>
            {props.orderDiv}
          </Grid>
        </Grid>

        <Grid item className={classes.icon}>
          <ChevronRight className={classes.icon} />
        </Grid>
      </Grid>
      <Divider className={classes.dividerPadding} />
    </>
  )
}
