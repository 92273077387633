import RowsLayout from '../MultiGridLayout'
import CheckItemGrid from './AssetDetailsCheckItemGrid'

export default function AssetDetailsCheckGroupBody (props) {
  const getCheckItemGrid = (checkItem) => {
    return (
      <CheckItemGrid
        checkItem={checkItem}
        previewImage={(imageGUID) => props.previewImage(imageGUID)}
      />)
  }

  return (
    <RowsLayout
      components={props.checkItems.map((checkItem) => getCheckItemGrid(checkItem))}
      gridSpacing={1}
    />
  )
}
