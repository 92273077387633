import { FilterTypes, Availability, Activation, userRole, customBranchFilterArray } from '../helpers/GenericHelpers'
import * as CONSTANTS from '../utils/GenericConstants'

export function getData (key) {
  return getDataFromLocalStorage(key)
}

export function saveLoginDataToLocalStorage (response) {
  saveToken(response.data.data.token)
  saveUserDataToLocalStorage(response.data.data.user)
}

export function saveUserDataToLocalStorage (user) {
  saveUserInfo(JSON.stringify(user))
}

export function saveDataInLocalStorage (key, value) {
  window.localStorage.setItem(key, value)
}

export function getDataFromLocalStorage (key) {
  return window.localStorage.getItem(key)
}

export function removeDataFromLocalStorage (key) {
  window.localStorage.removeItem(key)
}

export function saveToken (token) {
  window.localStorage.setItem('acccess_token', token)
}

export function saveUserInfo (user) {
  window.localStorage.setItem('user', user)
}

export function deleteToken () {
  window.localStorage.removeItem('acccess_token')
}

export function resetCurrentUser () {
  window.localStorage.removeItem('user')
  window.localStorage.removeItem('company')
  window.localStorage.removeItem('branch')
  window.localStorage.removeItem('search_filters')
  window.localStorage.removeItem('acccess_token')
}

export function isLoggedIn () {
  const token = getData('acccess_token')
  if (token) {
    return true
  }
  return false
}

export function getUserInfo () {
  const user = getData('user')
  return JSON.parse(user)
}

export function getToken () {
  const token = getDataFromLocalStorage('acccess_token')
  return token
}

export function getUserId () {
  const user = getUserInfo()
  return user.id
}

export function getUserRole () {
  const user = getUserInfo()
  return (user && user.role) ? user.role : ''
}

export function getUserCompany () {
  const user = getUserInfo()
  if (user) {
    if ((user.role || CONSTANTS.TECHNICIAN) === CONSTANTS.ADMIN) {
      return JSON.parse(getData('company')) || null
    } else {
      return user.company || null
    }
  }
}

export function getUserCompanyId () {
  const company = getUserCompany()
  return company && company.id ? company.id : 0
}

export function getUserBranchCount () {
  const user = getUserInfo()
  return user ? user.branch_count : 0
}

export function getUserBranch () {
  const user = getUserInfo()
  if (user) {
    if ((user.role || CONSTANTS.TECHNICIAN) === CONSTANTS.ADMIN) {
      return JSON.parse(getData('branch')) || null
    } else {
      return user.branch || null
    }
  }
}

export function saveAssetSearchFilters (searchFilter) {
  const filterSettings = getSearchFilters()
  filterSettings.availability = searchFilter.availability
  filterSettings.activation = searchFilter.activation
  filterSettings.checklists = searchFilter.checklists
  saveDataInLocalStorage('search_filters', JSON.stringify(filterSettings))
}

export function saveUserSearchFilters (searchFilter) {
  const filterSettings = getSearchFilters()
  filterSettings.userType = searchFilter.userType

  saveDataInLocalStorage('search_filters', JSON.stringify(filterSettings))
}

export function updateValueofFilters (fieldKey, fieldValue) {
  const filterSettings = getSearchFilters()
  filterSettings[fieldKey] = fieldValue

  saveDataInLocalStorage('search_filters', JSON.stringify(filterSettings))
}

export function saveSearchFiltersFromApi (searchFilter) {
  const filterSettings = {}
  filterSettings.checklists = searchFilter.checklists ? searchFilter.checklists : []
  filterSettings.company_id = searchFilter.companies ? searchFilter.companies : []
  filterSettings.branches = customBranchFilterArray(searchFilter.branches)

  filterSettings.availability = searchFilter.availability ? getObjectsFromIDs(searchFilter.availability, FilterTypes.availability) : []
  filterSettings.activation = searchFilter.asset_status ? getObjectsFromIDs(searchFilter.asset_status, FilterTypes.status) : []
  filterSettings.userType = searchFilter.user_role ? getObjectsFromIDs(searchFilter.user_role, FilterTypes.userType) : []

  saveDataInLocalStorage('search_filters', JSON.stringify(filterSettings))
}

function getObjectsFromIDs (idArray, type) {
  const objArray = []
  let mapArray = []

  switch (type) {
    case FilterTypes.availability: {
      mapArray = [Availability.chkd_in, Availability.chkd_out, Availability.cin_draft, Availability.cout_draft, Availability.mnc]
      break
    }
    case FilterTypes.status: {
      mapArray = [Activation.active, Activation.inactive, Activation.all]
      break
    }
    case FilterTypes.userType: {
      mapArray = [userRole.support, userRole.admin, userRole.user]
      break
    }
    default:
      mapArray = []
  }

  for (let i = 0; i < idArray.length; i++) {
    for (let j = 0; j < mapArray.length; j++) {
      if (idArray[i] === mapArray[j].id) {
        objArray.push(mapArray[j])
        break
      }
    }
  }
  return objArray
}

export function getSearchFilters () {
  const filter = JSON.parse(getData('search_filters'))
  if (filter) {
    return JSON.parse(getData('search_filters'))
  } else {
    const emptyFilter = { availability: [], checklists: [], activation: [], userType: [] }
    return emptyFilter
  }
}

export function getBranchFilterId () {
  const filter = getSearchFilters()
  if (filter && filter.branches && filter.branches[0]) {
    return filter.branches[0].id
  }

  return null
}

export function getBranchIdForSearch () {
  const branchFilterId = getBranchFilterId()
  if (branchFilterId && branchFilterId === -1) {
    return -1
  } else {
    const branch = getUserBranch()
    return (branch && branch.id) ? branch.id : null
  }
}

export function isUserHaveAllLocationAccess () {
  const user = getUserInfo()
  if (user.role === CONSTANTS.TECHNICIAN && user.company_role === CONSTANTS.USER) {
    if (user.branch_count > 1 && user.branch_count === user.company.branch_count) {
      return true
    }
    return false
  }
  return true
}
