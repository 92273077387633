import CheckIcon from '../../../../utils/CheckIcon'
import Retry from '@material-ui/icons/Replay'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import { TailSpin } from 'react-loader-spinner'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { getBranchList } from '../../../../helpers/api.js'
import { FormTypes, getBranchName, StateType } from '../../../../helpers/GenericHelpers.js'
import { getToken } from '../../../../utils/Authentication.js'
import { strings } from '../../../../localizations/strings'
import * as CONSTANTS from '../../../../utils/GenericConstants'

export const InlineEditKeys = {
  userRole: 'userRole',
  company: 'company',
  branch: 'branch',
  access: 'access'
}

export function getLocationsApi (companyId, userId, branchResponse) {
  const params = {
    token: getToken(),
    company_id: companyId,
    userId: userId || null
  }

  if (companyId !== 0) {
    getBranchList(params, (error, response) => {
      if (error) {
        window.alert(error)
        branchResponse([])
      } else {
        if (response.data.status === 1) {
          if (response.data.data.branches) {
            branchResponse(response.data.data.branches)
          }
        } else {
          branchResponse([])
        }
      }
    })
  }
}

export function getLocationList (branches, addNone = false) {
  const list = []
  for (let i = 0; i < branches?.length; i++) {
    const branch = branches[i]
    list.push({ id: branch.id, name: getBranchName(branch) })
  }

  if (addNone && list.length > 0) {
    list.push({ id: 0, name: strings.none_selected })
  }
  return list
}

export function getCompanyList (companies) {
  const list = []
  for (let i = 0; i < companies?.length; i++) {
    const company = companies[i]
    list.push({ id: company.id, name: company.name })
  }
  return list
}

export function getUserRoleList (userRole, openFrom) {
  const list = []

  list.push({ id: 1, name: strings.service_technician_role })
  list.push({ id: 2, name: strings.admin })

  if (userRole === CONSTANTS.SUPPORT && openFrom === FormTypes.addUser) {
    list.push({ id: 3, name: strings.supportuser_role })
  }

  return list
}

export function getButtonBackground (classes, userRoleStateType) {
  let bgstyle
  switch (userRoleStateType) {
    case StateType.failure:
      bgstyle = classes.errorStyle
      break
    case StateType.success:
      bgstyle = classes.successStyle
      break
    default:
      bgstyle = classes.buttonStyle
      break
  }
  return bgstyle
}

export function getButtonTitle (isEdit) {
  if (isEdit) {
    return strings.save_profile
  } else { return strings.create_user_now }
}

export function convertUserRoleString (userType) {
  let userRole = CONSTANTS.USER
  if (userType === CONSTANTS.SUPPORT_USER) {
    userRole = CONSTANTS.SUPPORT
  } else if (userType === CONSTANTS.COMPANY_ADMIN) {
    userRole = CONSTANTS.ADMIN
  }

  return userRole
}

export function getUserRole (user) {
  let role = CONSTANTS.USER
  if (user) {
    if (user.role === CONSTANTS.ADMIN) {
      role = CONSTANTS.SUPPORT
    } else {
      role = user.company_role
    }
  }

  return role
}

export function getIdForUserRole (userRole) {
  let userRoleId = ''

  switch (userRole) {
    case 'USER':
      userRoleId = 1
      break

    case 'ADMIN':
      userRoleId = 2
      break

    case 'SUPPORT':
      userRoleId = 3
      break

    default:
      break
  }

  return userRoleId
}

export function getUserRoleForId (id) {
  let userRole = ''

  switch (id) {
    case 1:
      userRole = 'USER'
      break

    case 2:
      userRole = 'ADMIN'
      break

    case 3:
      userRole = 'SUPPORT'
      break

    default:
      break
  }

  return userRole
}

export const getDropDownIcon = (stateType) => {
  let icon
  switch (stateType) {
    case StateType.failure:
      icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><Retry color='error' /></div>
      break
    case StateType.success:
      icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><CheckIcon /></div>
      break
    case StateType.loding:
      icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><TailSpin type='TailSpin' color='#0d548c' height={40} width={20} /></div>
      break
    case StateType.location:
      icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><LocationOnIcon /></div>
      break
    default:
      icon = <ArrowDropDown />
  }
  return icon
}

export function appendAnElementIntoArray (element, array) {
  let newArray = array ? [...array] : []
  if (element !== 0 && !(array.includes(element))) {
    if (array.length === 1) {
      newArray = [element]
    } else {
      newArray.push(element)
    }
  } else if (element === 0 && (array.length === 1)) {
    newArray = []
  }

  return newArray
}
