import React from 'react'
import Grid from '@material-ui/core/Grid'
import Header from './HeaderComponent/AssetDetailsHeader'
import Details from './DetailComponent/AssetDetailsDetailComponent'
import OrderData from './OrderComponent/AssetDetailsOrderData'
import Checklist from './ChecklistComponent/AssetDetailsChecklist'
import { getOrderChecklist } from '../../../helpers/api.js'
import { getData, getUserCompanyId } from '../../../utils/Authentication'
import { isInActiveOrInService, createDocumentTitle } from '../../../helpers/GenericHelpers'
import { DetailsDivider } from './DetailsCommonComponents'
import { strings } from '../../../localizations/strings'

export default function AssetDetails (props) {
  const componentKeys = {
    header: 'detailsHeader',
    detail: 'detailsDetail',
    orderData: 'detailsOrder',
    checklist: 'detailsChecklist'
  }

  const [gridTypes, setGridTypes] = React.useState(null)
  const [gridModels, setGridModels] = React.useState([])
  const [assetDetails, setAssetDetails] = React.useState(null)
  const [orderGroup, setOrderGroup] = React.useState(null)
  const [checkGroups, setCheckGroups] = React.useState(null)
  const [update, setUpdate] = React.useState(true)

  React.useEffect(() => {
    const gridTypes = {
      header: 'header',
      details: 'details',
      orderData: 'orderData',
      checklist: 'checklist'
    }
    setGridTypes(gridTypes)

    if (props.guidDetails) {
      document.title = createDocumentTitle(document.title, strings.asset_details_title + ' ' + props.guidDetails.asset_guid)
      const params = {
        companyId: getUserCompanyId(),
        assetId: props.guidDetails.asset_id,
        token: getData('acccess_token')
      }

      getOrderChecklist(params, (error, response) => {
        if (!error) {
          setAssetDetails(response.data.data)
          setOrderAndCheckGroups(response.data.data)
          if (props?.handleMachineInfo) { props.handleMachineInfo(response.data.data) }
        }
      })
    }
    let hasOrderData = false
    let hasCheckGroups = false
    const gridModelsArray = [
      { type: gridTypes.header },
      { type: gridTypes.details }]

    const setOrderAndCheckGroups = (assetDetails) => {
      const asset = assetDetails.asset
      if (asset) {
        const checklistArray = []
        if (assetDetails &&
        assetDetails.check_list &&
        assetDetails.check_list.check_groups) {
          assetDetails.check_list.check_groups.forEach((checkGroup) => {
            if (checkGroup.id) {
              if (checkGroup.id < 0) {
                hasOrderData = true
                setOrderGroup(checkGroup)
              } else {
                hasCheckGroups = true
                checklistArray.push(checkGroup)
              }
            }
          })
        }

        if (hasOrderData) {
          gridModelsArray.push({ type: gridTypes.orderData })
        }

        if (hasCheckGroups) {
          gridModelsArray.push({ type: gridTypes.checklist })
          setCheckGroups(checklistArray)
        }

        setGridModels(gridModelsArray)
      }
    }
  }, [props, update])

  const previewAssetImage = (imageGUID) => {
    if (imageGUID) {
      props.history.replace({
        pathname: props.history.location.pathname,
        search: '?open=' + imageGUID
      })
    }
  }

  const getGridModelComponent = (gridModel) => {
    switch (gridModel.type) {
      case gridTypes.header:
        return (
          <Header
            key={componentKeys.header}
            assetDetails={assetDetails}
            previewAssetImage={(imageGUID) => previewAssetImage(imageGUID)}
          />
        )
      case gridTypes.details:
        return (
          <div key={componentKeys.detail}>
            <Details
              key={componentKeys.detail}
              assetDetails={assetDetails}
              forceUpdate={updateData}
            />
            <DetailsDivider />
          </div>
        )
      case gridTypes.orderData:
        return (
          !(isInActiveOrInService(assetDetails?.asset)) &&
            <div key={componentKeys.orderData}>
              <OrderData
                history={props.history}
                key={componentKeys.orderData}
                asset={assetDetails?.asset}
                orderGroup={orderGroup}
                forceUpdate={updateData}
              />
              <DetailsDivider />
            </div>
        )
      default:
        return (
          checkGroups &&
            <Checklist
              key={componentKeys.checklist}
              checkGroups={checkGroups}
              previewImage={(imageGUID) => previewAssetImage(imageGUID)}
            />
        )
    }
  }

  const updateData = () => {
    setUpdate(!update)
  }

  return (
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      {gridModels.map((gridModel) => getGridModelComponent(gridModel))}
    </Grid>
  )
}
