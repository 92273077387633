import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import CheckIcon from '../../../src/utils/CheckIcon'
import Retry from '@material-ui/icons/Replay'
import { TailSpin } from 'react-loader-spinner'
import { StateType } from '../../helpers/GenericHelpers.js'
import InputAdornment from '@material-ui/core/InputAdornment'
import LocationOnIcon from '@material-ui/icons/LocationOn'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

export default function MultilineTextFields (props) {
  const classes = useStyles()
  const [listPickerItem, setListPickerItem] = React.useState(null)

  const handleChange = (event) => {
    props.selectItem(event.target.value)
    setListPickerItem(event.target.value)
  }

  const getDropDownIcon = (stateType) => {
    let icon
    switch (stateType) {
      case StateType.failure:
        icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><Retry color='error' /></div>
        break
      case StateType.success:
        icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><CheckIcon /></div>
        break
      case StateType.loding:
        icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><TailSpin type='TailSpin' color='#0d548c' height={40} width={20} /></div>
        break
      case StateType.location:
        icon = <div style={{ backgroundColor: 'white', zIndex: '1' }} key={0}><LocationOnIcon /></div>
        break
      default:
        icon = ''
    }
    return icon
  }

  return (
    <form className={classes.root} noValidate autoComplete='off'>
      <div>
        <TextField
          id='standard-select-listPickerItem'
          data-cy={props.dataCy}
          icon={null}
          error={props.hasError}
          select
          label={props.name}
          value={listPickerItem || props.selectedItem}
          onChange={handleChange}
          helperText={props.hasError ? props.helperText : ''}
          style={{ width: '100%' }}
          InputProps={
            { endAdornment: (<InputAdornment data-cy='list-picker-adornment' id={props.name} position='end'> {getDropDownIcon(props.stateType)}   </InputAdornment>) }
          }
        >
          {props.listPickerItems.map((option) => (
            <MenuItem data-cy={option.name} key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </form>
  )
}
