import { useEffect, useState } from 'react'
import FsLightbox from 'fslightbox-react'
import { imageInfoFromGUID } from '../helpers/api.js'
import { getData } from '../utils/Authentication.js'

function ImageLightbox (props) {
  const [imageUrl, setImageUrl] = useState(null)
  const handleClose = () => {
    props.onClose()
  }
  useEffect(() => {
    const accessToken = getData('acccess_token')
    const params = { guid: props.guid.split('=').slice(-1)[0], token: accessToken }
    imageInfoFromGUID(params, (error, response) => {
      if (!error) {
        if (response.data.data.large_url) {
          setImageUrl(response.data.data.large_url)
        } else {
          setImageUrl(response.data.data.small_url)
        }
      }
    })
  }, [props])

  return (
    <div>
      {imageUrl &&
        <div data-cy='big-asset-image' style={{ height: window.innerHeight, width: window.innerWidth }}>
          <FsLightbox
            type='image'
            openOnMount
            sources={[
              imageUrl
            ]}
            onClose={() => { handleClose() }}
          />
        </div>}
    </div>
  )
}

export default ImageLightbox
