import Grid from '@material-ui/core/Grid'

export default function AssetDetailLabelValueLayout (props) {
  return (
    <Grid
      item
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      style={props.style || { display: 'flex', flexWrap: 'nowrap' }}
      spacing={props.gridSpacing || 0}
    >
      <Grid item>
        {props.label}
      </Grid>
      <Grid data-cy='asset-detail-value' item>
        {props.value}
      </Grid>
    </Grid>
  )
}
