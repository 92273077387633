import Grid from '@material-ui/core/Grid'

export default function HorizontalSpaceAroundGrid (props) {
  return (
    <Grid
      item
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      style={props.style || {}}
      spacing={props.gridSpacing || 0}
    >
      <Grid item>
        {props.leftGrid}
      </Grid>
      <Grid item>
        {props.rightGrid}
      </Grid>
    </Grid>
  )
}
