import { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'

import { strings } from '../../localizations/strings.js'

import SearchBar from 'material-ui-search-bar'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

import LocationDropDown from '../Home/LocationDropDown'
import FilterView from '../../common/Filter/FilterView'
import { ListType, makePercent, callFirebaseEvent, HeaderCompnentsTypes } from '../../helpers/GenericHelpers'
import { ReactComponent as FilterIcon } from '../../assets/images/create_user.svg'
import AddItemView from '../../common/AddItem/AddItemView'
import {
  getData,
  getUserCompanyId
} from '../../utils/Authentication.js'
import { getBranchList } from '../../helpers/api.js'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'

const useStyles = theme => ({
  root: {
    padding: '20px 0 10px 0',
    marginRight: '32px',
    '@media(max-width: 583px)': {
      marginLeft: '5%',
      marginRight: '5%'
    }
  },
  searchBar: {
    width: '70%'
  },
  dropDown: {
    width: '30%',
    paddingLeft: '32px'
  },
  closeButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    opacity: '1'
  },
  searchButton: {
    color: '#0d548c',
    opacity: '1'
  },
  input: {
    width: '100%',
    color: '#002938',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.44',
    letterSpacing: '0.17px'
  },
  iconButton: {
    opacity: 1,
    transform: 'scale(1, 1)',
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)'
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0
    }
  },
  wrapIcon: {
    color: '#002938',
    display: 'inline-flex',
    verticalAlign: 'middle',
    [theme.breakpoints.only('sm', 'xs')]: {
      fontWeight: '400'
    }
  },
  buttonRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    padding: '0px 0px 0px 0px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }
  },
  buttonLabel: {
    textTransform: 'none'
  }
})

class AssetListHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      filterParams: { availability: [], checklists: [] },
      branches: [],
      branchesAvailable: false,
      showCreateUser: true,
      showFilter: true,
      anchorEl: null,
      bgColor: null,
      listType: ListType.Machines
    }
  }

  getOrderIcon (wrapIconClass) {
    const orderIcon =
      <Typography className={wrapIconClass} variant='subtitle1' color='secondary'>
        <FilterIcon className={this.props.classes.wrapIcon} />
        <span>&nbsp;</span>
        {strings.create_user}
      </Typography>
    return orderIcon
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
    this.setState({ bgColor: null })
  }

  handleOnSearch = (searchTerm) => {
    this.setState({ value: searchTerm })
    this.props.onSearch(searchTerm)
  }

  handleOnFilter = (filterParams) => {
    this.setState({ filterParams })
    this.props.onFilter(filterParams)
  }

  componentDidMount () {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    if (getUserCompanyId() !== 0) {
      getBranchList(params, (error, response) => {
        if (error) {
          this.setState({ branches: [], branchesAvailable: false })
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
          callFirebaseEvent(FA_CONSTANTS.FA_E_GET_BRANCHES, { description: errorMessage })
        } else {
          const branches = response.data.data.branches || []
          this.setState({ branches, branchesAvailable: (branches && branches.length > 1) })
        }
      })
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (this.state.listType !== this.props.listType) { this.setState({ value: '', listType: this.props.listType }) }
  }

  getGridModelsArray () {
    const gridModelsArray = [{ type: HeaderCompnentsTypes.search, gridUnit: 2 }, { type: HeaderCompnentsTypes.filter, gridUnit: 1 }]
    if (this.state.branchesAvailable) {
      gridModelsArray.push({ type: HeaderCompnentsTypes.location, gridUnit: 1 })
    }
    if (this.props.listType === ListType.Users) {
      gridModelsArray.push({ type: HeaderCompnentsTypes.addUser, gridUnit: 1 })
    }
    if (this.props.listType === ListType.Machines) {
      gridModelsArray.push({ type: HeaderCompnentsTypes.addMachine, gridUnit: 1 })
    }

    return gridModelsArray
  }

  getTotalGridCounts (gridModelsArray) {
    let gridCount = 0
    gridModelsArray.forEach(gridModel => {
      const gridUnit = gridModel.gridUnit || 0
      gridCount += gridUnit
    })

    return gridCount
  }

  getGridModelComponent (gridModel, numberOfGrids) {
    const { classes } = this.props
    const listType = this.props.listType
    const gridWidth = makePercent(gridModel.gridUnit * 100 / numberOfGrids)

    if (gridModel.type === HeaderCompnentsTypes.search) {
      return (
        <Grid item className={classes.searchBar} style={{ width: gridWidth }} key={gridModel.type}>
          <SearchBar
            data-cy='search'
            value={this.state.value}
            placeholder={listType === ListType.Users ? strings.searchUsersPlaceHolder : window.innerWidth > 600 ? strings.searchPlaceHolderWeb : strings.searchPlaceHolderMobile}
            classes={{ input: classes.input, iconButton: classes.iconButton, iconButtonHidden: classes.iconButtonHidden }}
            onChange={(newValue) => this.handleOnSearch(newValue)}
            onCancelSearch={() => this.handleOnSearch('')}
            closeIcon={<ClearIcon className={classes.closeButton} />}
            searchIcon={<SearchIcon className={classes.searchButton} />}
          />

        </Grid>
      )
    } else if (gridModel.type === HeaderCompnentsTypes.filter) {
      return (
        <Grid item style={{ width: gridWidth, paddingLeft: '8px' }} key={gridModel.type}>
          <FilterView
            listType={listType}
            filterParams={this.state.filterParams}
            onFilter={(filterParams) => this.handleOnFilter(filterParams)}
          />
        </Grid>
      )
    } else if (gridModel.type === HeaderCompnentsTypes.location) {
      return (
        <Grid item style={{ width: gridWidth, paddingLeft: '32px' }} key={gridModel.type}>
          <LocationDropDown
            onSelectBranch={() => this.props.onSelectBranch()}
            branches={this.state.branches}
          />
        </Grid>
      )
    } else if (gridModel.type === HeaderCompnentsTypes.addUser) {
      return (
        <Grid item style={{ width: gridWidth, paddingLeft: '32px' }} key={gridModel.type}>
          <AddItemView
            history={this.props.history}
            onSelectBranch={() => this.props.onSelectBranch()}
            onResponse={() => this.handleOnSearch('')}
            action='addUser'
          />
        </Grid>
      )
    } else if (gridModel.type === HeaderCompnentsTypes.addMachine) {
      return (
        <Grid item style={{ width: gridWidth, paddingLeft: '32px' }} key={gridModel.type}>
          <AddItemView
            history={this.props.history}
            onSelectBranch={() => this.props.onSelectBranch()}
            onResponse={() => this.handleOnSearch('')}
            action='addMachine'
          />
        </Grid>
      )
    }

    return ''
  }

  render () {
    const { classes } = this.props
    const gridModels = this.getGridModelsArray()
    const numberOfGrids = this.getTotalGridCounts(gridModels)

    return (
      <div className={classes.root}>
        <Grid container direction='row' justifyContent='flex-start'>
          {gridModels.map((gridModel) => this.getGridModelComponent(gridModel, numberOfGrids))}
        </Grid>
      </div>
    )
  }
}

AssetListHeader.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(AssetListHeader)
