import { makeStyles } from '@material-ui/core/styles'
import AssetEmptyImage from '../../assets/images/asset_no_picture.png'
import IconAssetAvailable from '../../assets/images/asset_available.svg'
import IconAssetUnAvailable from '../../assets/images/asset_unavailable.svg'
import IconAssetMaintenance from '../../assets/images/asset_maintenance.svg'
import IconAssetCheckInDraft from '../../assets/images/asset_check_in_draft.svg'
import IconAssetCheckOutDraft from '../../assets/images/asset_check_out_draft.svg'
import IconAssetDeactivated from '../../assets/images/asset_deactivated.svg'

const useStyles = makeStyles(theme => ({
  image: props => ({
    width: props.width,
    height: props.height,
    marginTop: '3px',
    position: 'relative',
    textAlign: 'center'
  }),
  topRight: {
    position: 'absolute',
    top: '-5px',
    right: '-5px'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '4px'
  }
}))

export default function AssetImage (props) {
  const getAssetAvailabilityIcon = (assetStatus, assetAvailability) => {
    if (assetStatus === 'INACTIVE') {
      return IconAssetDeactivated
    } else {
      switch (assetAvailability) {
        case 'CHECKED_IN':
          return IconAssetAvailable
        case 'CHECKED_OUT' :
          return IconAssetUnAvailable
        case 'IN_SERVICE':
          return IconAssetMaintenance
        case 'DRAFT':
          return IconAssetCheckOutDraft
        case 'CHECKIN_DRAFT':
          return IconAssetCheckInDraft
        default:
          return IconAssetCheckInDraft
      }
    }
  }
  const hasImage = !!((props.imgSrc && props.imgSrc.small_url))
  const src = hasImage ? props.imgSrc.small_url : AssetEmptyImage
  const assetAvailabilityIcon = getAssetAvailabilityIcon(props.assetStatus, props.assetAvailability)
  const classes = useStyles(props)
  return (
    <div className={classes.image} onClick={props.onClick}>
      <img data-cy='asset-image' className={classes.img} alt='Asset' src={src} style={{ cursor: hasImage ? 'pointer' : null }} />
      <img data-cy={props.assetAvailability} className={classes.topRight} alt='Availability' src={assetAvailabilityIcon} />
    </div>
  )
}
