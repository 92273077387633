import React from 'react'
import { withStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import PageNotFound from '../LayoutComponents/PageNotFound'
import { checkGUID } from '../../helpers/api.js'
import { GuidComponentTypes } from '../../helpers/GenericHelpers'

const useStyles = theme => ({
  pdfViewer: {
    width: '100vw',
    height: '100vh',
    border: 'none'
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
})

class PdfViewer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      noData: false,
      showPdf: null
    }
  }

  componentDidMount () {
    const paths = this.props.location.pathname.split('/') || ['']
    const guidParam = { guid: '/' + (paths[1] || paths[0] || '') }
    const pdfType = paths[2] || ''

    checkGUID(guidParam, (error, response) => {
      if (!error && response.data.status) {
        const data = response?.data?.data
        if (data?.type === GuidComponentTypes.transactions ||
            data?.type === GuidComponentTypes.transactionPdfImage) {
          if (pdfType === GuidComponentTypes.protocolImages && data.pdf_link_image && data.pdf_link_image !== '') {
            this.setStateValues(false, false, data.pdf_link_image)
          } else if (data.pdf_link_summary && data.pdf_link_summary !== '') {
            this.setStateValues(false, false, data.pdf_link_summary)
          } else {
            this.setStateValues()
          }
        } else {
          this.setStateValues()
        }
      } else {
        this.setStateValues()
      }
    })
  }

  setStateValues = (loading = false, noData = true, showPdf = null) => {
    this.setState({
      loading,
      noData,
      showPdf
    })
  }

  getComponent = () => {
    const { classes } = this.props
    if (this.state.loading) return <CircularProgress className={classes.loader} />
    else if (this.state.noData) return <PageNotFound />
    else if (this.state.showPdf) {
      return (
        <iframe
          title='protocolSummary'
          id='protocolSummary'
          name='protocolSummary'
          src={this.state.showPdf}
          className={classes.pdfViewer}
        />
      )
    }
  }

  render () {
    return (
      <div> {this.getComponent()} </div>
    )
  }
}

export default withStyles(useStyles)(PdfViewer)
