import RowsLayout from '../MultiGridLayout'
import CheckGroupGrid from './AssetDetailsCheckGroupGrid'
import { DetailsDivider } from '../DetailsCommonComponents'

export default function AssetDetailsChecklist (props) {
  const getCheckGroupGrid = (checkGroup, index) => {
    return (
      <div>
        <CheckGroupGrid
          checkGroup={checkGroup}
          previewImage={(imageGUID) => props.previewImage(imageGUID)}
        />
        {(props.checkGroups.length !== (index + 1)) && <DetailsDivider />}
      </div>
    )
  }

  return (
    <RowsLayout
      components={props.checkGroups.map((checkGroup, index) => getCheckGroupGrid(checkGroup, index))}
    />
  )
}
