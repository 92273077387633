import { withStyles } from '@material-ui/styles'
import Form from '../../common/form'
import { Grid } from '@material-ui/core'
import { strings } from '../../localizations/strings.js'
import Container from '@material-ui/core/Container'
import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  getToken,
  getUserInfo,
  getUserCompanyId,
  getData,
  getUserBranch,
  getSearchFilters,
  isUserHaveAllLocationAccess
} from '../../utils/Authentication.js'
import { getBranchList, companyListApi, getChecklists, createAsset, updateAsset } from '../../helpers/api.js'
import { getBranchName, StateType, ScreenTypes, FormTypes } from '../../helpers/GenericHelpers.js'
import Joi from 'joi-browser'
import Snackbar from '../LayoutComponents/Snackbar'
import * as CONSTANTS from '../../utils/GenericConstants'
import ListPicker from '../../common/AddItem/ListPicker'
import MobileContainer from '../LayoutComponents/MobileContainer'
import * as URL from '../../utils/url_constant.js'

const useStyles = theme => ({
  root: {},

  layout: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 0px 10px',
    zIndex: 1,
    position: 'relative',
    width: '350px',
    height: '600px'
  },
  layoutMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 0px 10px',
    zIndex: 1,
    position: 'relative'
  },
  layoutLoader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px 0px 10px',
    height: '100%',
    width: '100%',
    zIndex: 2,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#212121',
    marginTop: '20px'

  },

  leftMargin: {
    marginLeft: '20px',
    marginTop: '5px',
    marginBottom: '5px'

  },
  topMargin: {
    marginTop: '20px'
  },

  topBottomMargin: {
    marginTop: '10px',
    marginBottom: '20px'
  },

  topMargin40PX: {
    marginTop: '40px',
    marginBottom: '40px'
  },

  closeButton: {
    float: 'right',
    width: '25px',
    height: '25px',
    marginTop: '1%'
  },

  button: {
    backgroundColor: '#faa619',
    '& .MuiButton-label': {
      color: '#303030'
    },
    '&:hover': {
      backgroundColor: '#faa619'
    }
  }

})

const WAIT_INTERVAL = 1000
const RESET_INTERVAL = 4000
class AssetCreation extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        inventory_number: '',
        machine_name: '',
        serial_number: ''
      },
      currentState: {
        inventory_number: StateType.none,
        machine_name: StateType.none,
        serial_number: StateType.none,
        company: StateType.none,
        branch: StateType.location,
        machineCategory: StateType.none
      },
      companyID: null,
      branchId: null,
      machineCategoryID: '',
      showSnackBar: false,
      setProgressBar: false,
      branches: [],
      companies: [],
      machineCategory: [],
      errors: {},
      errorCompanyId: null,
      errorMachineCategoryId: null

    }
    this.isSettingFilterCalled = false
    // This flag will be used to differentiate whether it's a inline-editing api call or not
    this.userRole = CONSTANTS.TECHNICIAN
    this.isEditForm = false
    this.screenType = ScreenTypes.desktop
    this.formType = FormTypes.addMachine
    this.machineInfo = null
    this.machineId = null
    this.getScreenTypeData()
    this.timer = null
  }

  schema = {
    inventory_number: Joi.string().max(192)
      .required()
      .label(strings.inventory_number).error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = strings.inventory_number_missing
              break

            case 'string.max':
              err.message = strings.max_character_length
              break

            default: break
          }
        })
        return errors
      }),
    machine_name: Joi.string().max(192)
      .required()
      .label(strings.machine_name).error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = strings.name_missing
              break

            case 'string.max':
              err.message = strings.max_character_length
              break

            default: break
          }
        })
        return errors
      }),
    serial_number: Joi.string().allow('').max(192)
      .label(strings.serial_number).error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'string.max':
              err.message = strings.max_character_length
              break

            default: break
          }
        })
        return errors
      })

  }

  // since we are using same component for mobile and web
  // need to check whether it's web or mobile
  getScreenTypeData = () => {
    if (this.props.location && this.props.location.state) {
      this.screenType = this.props.location.state.screenType
      this.formType = this.props.location.state.formType
    } else {
      this.formType = this.props.openFrom
    }
    if (this.formType === FormTypes.editMachine) { this.getMachineDataFromProps() }
  }

  // if it's edit form, read machine data from props
  getMachineDataFromProps = () => {
    if (this.screenType === ScreenTypes.mobile) {
      this.machineInfo = this.props.location.state.machineData.asset
    } else {
      this.machineInfo = this.props.machineData.asset
    }
  }

  getCategory = companyId => {
    const params = {
      token: getData('acccess_token'),
      company_id: companyId
    }
    getChecklists(params, (error, response) => {
      if (error) {
        this.setState({ machineCategory: [], machineCategoryID: null })
      } else {
        if (response.data.data.check_lists != null) {
          this.setState({ machineCategory: response.data.data.check_lists })
        }
      }
    }

    )
  }

  callBranchLocationApi (companyId) {
    const params = {
      token: getToken(),
      company_id: companyId
    }
    if (companyId !== 0) {
      getBranchList(params, (error, response) => {
        if (error) {
          window.alert(error)
          // this.props.handleBranchAvailability([])

        // const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
        } else {
          if (response.data.status === 1) {
            if (response.data.data.branches != null) {
              this.setState({ branches: response.data.data.branches })
            }
          } else {
            this.setState({ branches: 0 })
          }
        // this.props.handleBranchAvailability(response.data.data.branches)
        }
      })
    }
  }

  handleChangeLocation = (branchId) => {
    this.setState({ branchId }, () => {
      if (this.isEditForm) { this.submitData('branch') }
    })
  }

  handleSelectCompany = (companyID) => {
    this.setState({ companyID, errorCompanyId: null, branchId: null, machineCategoryID: null },
      () => {
        this.getCategory(companyID)
        this.callBranchLocationApi(companyID)
        if (this.isEditForm) { this.submitData('company') }
      }

    )
  }

  handleSelectCheckList = (checkListId) => {
    this.setState({ machineCategoryID: checkListId, errorMachineCategoryId: null },
      () => {
        if (this.isEditForm) { this.submitData('machineCategory') }
      })
  }

  componentDidMount () {
    let companyId = ''

    // user role for company drop down
    // support user will be able to select company
    const userInfo = getUserInfo()
    if (userInfo) {
      if (userInfo.role === CONSTANTS.ADMIN) {
        this.userRole = CONSTANTS.SUPPORT
      }
    }

    if (this.formType === FormTypes.editMachine) {
      const { data } = { ...this.state }
      data.machine_name = (this.machineInfo.name) ? this.machineInfo.name : ''
      data.inventory_number = (this.machineInfo.inventory_number) ? this.machineInfo.inventory_number : ''
      data.serial_number = (this.machineInfo.serial_number) ? this.machineInfo.serial_number : ''

      const branchId = (this.machineInfo.branch && this.machineInfo.branch.id) ? this.machineInfo.branch.id : '0'

      this.setState({
        data,
        branchId,
        companyID: this.machineInfo.company.id,
        machineCategoryID: this.screenType === ScreenTypes.desktop ? this.props.machineData.check_list.id : this.props.location.state.machineData.check_list.id
      })

      this.machineId = this.machineInfo.id
      this.isEditForm = true
      companyId = this.machineInfo.company.id
    } else {
      if (getUserCompanyId() !== null && getUserCompanyId() !== 0) {
        companyId = getUserCompanyId()
        this.setState({ companyID: getUserCompanyId() })
      }

      if (getUserBranch() != null && getUserBranch().id) {
        this.setState({ branchId: getUserBranch().id })
      }
    }

    // get branch location list for preselection
    if (companyId !== '') {
      this.callBranchLocationApi(companyId)
    }

    // get branch location list for preselection
    if (companyId !== '') {
      this.getCategory(companyId)
    }
    // if support user, get the company list
    if (this.userRole === CONSTANTS.SUPPORT) {
      const params = {
        token: getToken()
      }
      companyListApi(params, (error, response) => {
        if (!error) {
          if (response.data.data.companies != null) {
            this.setState({ companies: response.data.data.companies })
          }
        }
      })
    }
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  saveInlineData = (input, error) => {
    if (this.isEditForm) {
      clearTimeout(this.timer)
      if (error) {
        this.setInputState(StateType.none, input.name)
      } else { this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL, input) }
    }
  }

  triggerChange = (input) => {
    this.submitData(input.name)
  }

  handleRetryClick = event => {
    this.submitData(event.currentTarget.id)
  }

  submitData = (key) => {
    this.setInputState(StateType.loding, key)
    this.doSubmit(this.state.errors, key)
  }

  setInputState = (stateType, key) => {
    const currentState = { ...this.state.currentState }
    currentState[key] = stateType
    this.setState({ currentState })
    setTimeout(this.resetCurrentState, RESET_INTERVAL, key, stateType)
  }

  resetCurrentState = (key, stateType) => {
    const currentState = { ...this.state.currentState }
    currentState[key] = key === 'branch' ? StateType.location : StateType.none
    this.setState({ currentState })
  }

  doSubmit = (errors, key) => {
    this.setState({
      showSnackBar: false
    }, () => {
      /** **START: should execute if there is no error */
      if (this.isAllMandatoryInformationAvailable(errors)) {
        const params = {
          name: this.state.data.machine_name,
          inventory_number: this.state.data.inventory_number,
          last_name: this.state.data.last_name,
          serial_number: this.state.data.serial_number,
          company_id: this.state.companyID,
          check_list_id: this.state.machineCategoryID,
          branch_id: (this.state.branchId) ? this.state.branchId : '0',
          token: getData('acccess_token')
        }

        if (this.isEditForm) { this.updateMachine(params, key) } else this.addMachine(params)
      } else {
        if (this.isEditForm) { this.setInputState(key === 'branch' ? StateType.location : StateType.none, key) }
      }
    })
  }

  formOnClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    } else {
      // if there is no props
      this.handleArrowClick()
    }
  }

  addMachine = (params) => {
    this.setState({ setProgressBar: true }, () => {
      createAsset(params, (error, response) => {
        if (error) {
          let errorMessage = this.handleErrorMessage(error)
          if (error.message === 'Network Error') {
            errorMessage = strings.no_internet_connection
          }
          this.setState({
            setProgressBar: false,
            showSnackBar: true,
            responseStatus: {
              status: 'error',
              message: errorMessage.length !== 0 ? errorMessage : strings.something_went_wrong
            }
          })
        } else {
          this.setState({
            setProgressBar: false,
            showSnackBar: true,
            responseStatus: {
              status: 'success',
              message: strings.machine_added
            }
          })

          this.sleep(1000).then(() => {
            if (this.screenType === ScreenTypes.desktop && this.props.onResponse) {
              this.props.onResponse(this.state.responseStatus)
            } else {
              this.handleArrowClick()
            }
          })
        }
      })
    })
  }

  updateMachine = (params, key) => {
    params.assetId = this.machineId
    params.image_id = this.machineInfo.image && this.machineInfo.image.id ? this.machineInfo.image.id : 0
    updateAsset(params, (error, response) => {
      if (error) {
        this.setInputState(StateType.failure, key)
        let errorMessage = this.handleErrorMessage(error)
        if (error.message === 'Network Error') {
          errorMessage = strings.no_internet_connection
        }
        this.setState({
          showSnackBar: true,
          responseStatus: {
            status: 'error',
            message: errorMessage.length !== 0 ? errorMessage : strings.something_went_wrong
          }
        })
      } else {
        this.setInputState(StateType.success, key)
        if (this.props.updateData) { this.props.updateData() }
      }
    }, key)
  }

  isAllMandatoryInformationAvailable = (error) => {
    let validForm = true
    if (!this.isEditForm && error) {
      validForm = false
    }
    if (this.isEditForm && this.anyError()) {
      validForm = false
    }
    if (this.userRole === CONSTANTS.SUPPORT && !this.state.companyID) {
      validForm = false
      this.setState({ errorCompanyId: strings.companyselection_error_msg })
    }
    if (!this.state.machineCategoryID) {
      validForm = false
      this.setState({ errorMachineCategoryId: strings.companyselection_error_msg })
    }
    return validForm
  }

  handleErrorMessage = (error) => {
    let errorMessage = strings.something_wrong
    if (error.response && error.response.data && error.response.data.message) {
      switch (error.response.data.message) {
        case CONSTANTS.ERROR_DUPLICATE_ASSETNAME:
          errorMessage = strings.err_duplicate_assetname
          break
        case CONSTANTS.ERROR_DUPLICATE_INVENOTRY_NUMBER:
          errorMessage = strings.err_duplicate_invoicenumber
          break
        case CONSTANTS.ALREADY_DELETED_CHECKLIST_MESSAGE:
          errorMessage = strings.checklist_not_available
          break
        default:
          errorMessage = error.response.data.message
          break
      }
    }

    return errorMessage
  }

  linkAction = () => {
  }

  addCloseIcon () {
    return (
      <CloseIcon
        data-cy='close-form-icon'
        className={this.props.classes.closeButton} onClick={() => this.formOnClose()}
      />
    )
  }

  showCompanyView (classes) {
    if (this.state.companies.length > 0) {
      const hasError = (this.state.errorCompanyId !== null)
      return (
        <Grid
          data-cy='machine-form-company'
          item
          container
          direction='row'
          justifyContent='flex-start'
          spacing={0}
          xs={12}
          sm={12}
          md={12}
          className={classes.topBottomMargin}
        >
          <ListPicker name={strings.title_company} listPickerItems={this.getCompanyList()} selectItem={(value) => this.handleSelectCompany(value)} selectedItem={this.state.companyID} helperText={strings.companyselection_error_msg} hasError={hasError} stateType={this.state.currentState.company} />
        </Grid>
      )
    }
  }

  showCategoryView (classes) {
    const hasError = (this.state.errorMachineCategoryId !== null)
    return (
      <Grid
        data-cy='machine-form-category'
        item
        container
        direction='row'
        justifyContent='flex-start'
        spacing={0}
        xs={12}
        sm={12}
        md={12}
        className={classes.topBottomMargin}
      >
        <ListPicker name={strings.machine_category} listPickerItems={this.getCompanyCheckList()} selectItem={(value) => this.handleSelectCheckList(value)} selectedItem={this.state.machineCategoryID} helperText={strings.checklist_error_msg} hasError={hasError} stateType={this.state.currentState.machineCategory} />
      </Grid>
    )
  }

  showBranchLocation (classes) {
    if (this.state.branches.length > 1) {
      return (
        <Grid
          data-cy='machine-form-location'
          item
          key={1}
          container
          direction='row'
          justifyContent='flex-start'
          spacing={0}
          xs={12}
          sm={12}
          md={12}
          className={classes.topBottomMargin}
        >
          <ListPicker name={strings.location} listPickerItems={this.getLocationList()} selectItem={(value) => this.handleChangeLocation(value)} selectedItem={this.state.branchId} stateType={this.state.currentState.branch} />
        </Grid>

      )
    }
  }

  getLocationList = () => {
    const list = []
    for (let i = 0; i < this.state.branches.length; i++) {
      const branch = this.state.branches[i]
      list.push({ id: branch.id, name: getBranchName(branch) })
    }
    if (list.length > 1 && isUserHaveAllLocationAccess()) { list.push({ id: '0', name: strings.none_selected }) }
    return list
  }

  getCompanyList = () => {
    const list = []
    for (let i = 0; i < this.state.companies.length; i++) {
      const company = this.state.companies[i]
      list.push({ id: company.id, name: company.name })
    }
    return list
  }

  getCompanyCheckList = () => {
    const list = []
    for (let i = 0; i < this.state.machineCategory.length; i++) {
      const checklist = this.state.machineCategory[i]
      list.push({ id: checklist.id, name: checklist.name })
    }
    return list
  }

  setFilterValue () {
    this.isSettingFilterCalled = true
    let branch = ''
    if (getUserInfo().role === CONSTANTS.TECHNICIAN) {
      branch = getUserInfo().branch
    } else if (getUserInfo().role === CONSTANTS.ADMIN) {
      branch = getUserBranch()
    }

    if (branch !== null && branch.id !== null) {
      this.setState({ branchId: branch.id })
    }
    if (getSearchFilters() !== null && getSearchFilters().checklists !== null && getSearchFilters().checklists.length === 1) {
      const checkListId = getSearchFilters().checklists[0].id
      this.setState({ machineCategoryID: checkListId, errorMachineCategoryId: null })
    }
  }

  // if edit and mobile screen push with GUID
  handleArrowClick = () => {
    if (this.formType === FormTypes.editMachine && this.screenType === ScreenTypes.mobile) {
      this.props.history.push(this.props.location.state.guid)
    } else { this.props.history.push(URL.URLPATH_ASSET_LIST) }
  }

  getToolbarTitle = () => {
    return this.formType === FormTypes.addMachine ? strings.creating_new_machine : strings.edit_machine
  }

  getCloseIcon = (classes) => {
    if (this.screenType === ScreenTypes.desktop) {
      return (
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={12}
            item
            style={{ paddingRight: '4%' }}
          >
            {this.addCloseIcon()}
          </Grid>
        </Grid>
      )
    }
  }

  getTitle = (classes) => {
    if (this.screenType === ScreenTypes.desktop) {
      return (
        <Grid
          data-cy='create-edit-asset-form-header-text'
          xs={12}
          sm={12}
          md={12}
          className={classes.title}
          item
        >
          {(this.formType === FormTypes.addMachine) ? strings.creating_new_machine : strings.edit_machine}
        </Grid>
      )
    }
  }

  render () {
    const { classes } = this.props
    if (this.formType === FormTypes.addMachine && !this.isSettingFilterCalled) {
      this.setFilterValue()
    }

    const childView =
      <div>
        <Container>
          {this.state.setProgressBar === true
            ? (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.layoutLoader}
              >
                <CircularProgress />
              </Grid>
            </Grid>
              )
            : (
                ''
              )}
        </Container>
        <Container className={this.screenType === ScreenTypes.desktop ? classes.layout : classes.layoutMobile}>
          {this.getCloseIcon(classes)}
          <Grid
            container
            className={classes.leftMargin}
          >
            {this.getTitle(classes)}

          </Grid>
          <Container>
            <Grid>

              {this.showCompanyView(classes)}

              {this.showCategoryView(classes)}

              {this.showBranchLocation(classes)}

              <Grid
                data-cy='machine-form-inventory-number'
                item
                container
                direction='row'
                justifyContent='flex-start'
                spacing={0}
                xs={12}
                sm={12}
                md={12}
              >
                {this.renderInput('inventory_number', strings.inventory_number, classes, false, 'text', false, '', this.isEditForm, this.state.currentState.inventory_number)}
              </Grid>
              <Grid
                data-cy='machine-form-name'
                item
                container
                direction='row'
                justifyContent='flex-start'
                spacing={0}
                xs={12}
                sm={12}
                md={12}
                className={this.props.classes.topMargin}
              >
                {this.renderInput(
                  'machine_name',
                  strings.machine_name,
                  classes,
                  false, 'text', false, '',
                  this.isEditForm, this.state.currentState.machine_name)}
              </Grid>

              <Grid
                data-cy='machine-form-serial-number'
                item
                container
                direction='row'
                justifyContent='flex-start'
                spacing={0}
                xs={12}
                sm={12}
                md={12}
                className={this.props.classes.topMargin}
              >
                {this.renderInput(
                  'serial_number',
                  strings.serial_number,
                  classes,
                  false, 'text', false, '',
                  this.isEditForm, this.state.currentState.serial_number)}

              </Grid>

              <Grid
                data-cy='machine-form-save-button'
                item
                direction='row'
                justifyContent='flex-start'
                container
                spacing={0}
                xs={12}
                sm={12}
                md={12}
                className={this.props.classes.topMargin40PX}
              >
                {this.isEditForm ? '' : this.renderButton(strings.save, classes.button)}
              </Grid>
            </Grid>
            {(this.state.showSnackBar) ? (<Snackbar type={this.state.responseStatus.status} message={this.state.responseStatus.message} />) : ''}
          </Container>
        </Container>

      </div>

    let parentView = childView
    // if it's a mobile screen wrap the child component inside Mobile container
    if (this.screenType === ScreenTypes.mobile) {
      parentView =
        <MobileContainer title={this.getToolbarTitle()} handleClick={() => this.handleArrowClick()}>
          {childView}
        </MobileContainer>
    }

    return (
      <div>
        {parentView}
      </div>

    )
  }
}

export default withStyles(useStyles)(AssetCreation)
