import { Component } from 'react'
import { Chip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { ListType, FilterTypes, indexOfObject } from '../../helpers/GenericHelpers'
import { getSearchFilters, saveAssetSearchFilters, saveUserSearchFilters } from '../../utils/Authentication.js'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import HdrWeakIcon from '@material-ui/icons/HdrWeak'
import ListAltIcon from '@material-ui/icons/ListAlt'
import OfflinePinIcon from '@material-ui/icons/OfflinePin'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import { strings } from '../../localizations/strings.js'

const useStyles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1)
  },
  containerStyle: {
    paddingLeft: '26px'
  }
})

class FilterChipView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      chipsArray: []
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.updateChips()
  }

  componentDidMount () {
    this.updateChips()
  }

  updateChips () {
    this.props.listType === ListType.Machines ? this.assetFilterChips() : this.userFilterChips()
  }

  userFilterChips () {
    const filter = getSearchFilters()
    const chips = [...this.getChipObjects(FilterTypes.userType, filter.userType)]
    this.setState({
      chipsArray: chips
    })
  }

  assetFilterChips () {
    const filter = getSearchFilters()
    const chips = [...this.getChipObjects(FilterTypes.status, filter.activation), ...this.getChipObjects(FilterTypes.category, filter.checklists), ...this.getChipObjects(FilterTypes.availability, filter.availability)]
    this.setState({
      chipsArray: chips
    })
  }

  getChipObjects (val, arr) {
    let chips = []
    chips = arr.map((obj) => {
      const chipObject = { key: val, item: obj }
      return chipObject
    })
    return chips
  }

  render () {
    const { classes } = this.props
    const me = this

    function handleDelete (chip) {
      removeFilter(chip)
      const filter = getSearchFilters()
      me.props.onFilter(filter)
    }

    function removeFilter (chip) {
      const arr = me.state.chipsArray
      const current = indexOfObject(arr, chip)
      arr.splice(current, 1)
      const filter = getSearchFilters()

      switch (chip.key) {
        case FilterTypes.userType:
          filter.userType = removeSearchFilterFromArray(filter.userType, chip)
          break

        case FilterTypes.availability:
          filter.availability = removeSearchFilterFromArray(filter.availability, chip)
          break

        case FilterTypes.status:
          filter.activation = removeSearchFilterFromArray(filter.activation, chip)
          break

        case FilterTypes.category:
          filter.checklists = removeSearchFilterFromArray(filter.checklists, chip)
          break
        default:
      }
      me.props.listType === ListType.Machines ? saveAssetSearchFilters(filter) : saveUserSearchFilters(filter)
      me.setState({ chipsArray: arr })
    }

    function removeSearchFilterFromArray (arr, filt) {
      const index = indexOfObject(arr, filt.item)
      if (index !== -1) {
        arr.splice(index, 1)
      }
      return arr
    }

    function iconForChip (chip) {
      switch (chip.key) {
        case FilterTypes.userType:
          return <PeopleAltIcon />
        case FilterTypes.availability:
          return <OfflinePinIcon />
        case FilterTypes.status:
          return <HdrWeakIcon />
        case FilterTypes.category:
          return <ListAltIcon />
        default:
          return <div />
      }
    }

    return (
      <div data-cy='filter-chip-container' className={classes.containerStyle}>
        {(this.state.chipsArray.length > 0) &&
          <Hidden only={['sm', 'xs']}>
            <Typography variant='body'> <font color='grey'>{strings.selected_filter}</font> </Typography>
          </Hidden>}

        {this.state.chipsArray.map((chip) => {
          const label = chip.item.name
          return (
            <Chip
              data-cy='filter-chip'
              icon={iconForChip(chip)}
              key={chip.item.name + chip.key}
              label={label}
              style={{ backgroundColor: '#0d548c28' }}
              className={classes.chip}
              onDelete={() => handleDelete(chip)}
            />
          )
        })}
      </div>
    )
  }
}

FilterChipView.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(FilterChipView)
