import { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'

import { strings } from '../../localizations/strings.js'

import SearchBar from 'material-ui-search-bar'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import IconAssetList from '../../assets/images/machineIcon.svg'
import UserIcon from '../../assets/images/userIcon.svg'
import { ListType } from '../../helpers/GenericHelpers.js'

const useStyles = theme => ({
  root: {
    padding: '10px 0 10px 0'
  },
  searchBar: {
    width: '100%',
    zIndex: 1
  },
  iconStyle: {
    zIndex: 2,
    position: 'absolute',
    borderRadius: '20px 0px 0px 20px',
    backgroundColor: '#f5f8fa',
    width: '40px',
    height: '48px'

  },
  imageStyle: {
    marginLeft: '10px'
  },
  closeButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    opacity: '1'
  },
  searchButton: {
    color: '#0d548c',
    opacity: '1'
  },
  MuiPaperRounded93: {
    borderRadius: '20px'
  },
  input: {
    width: '100%',
    color: '#002938',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.44',
    letterSpacing: '0.17px',
    paddingLeft: '30px'
  },
  iconButton: {
    opacity: 1,
    transform: 'scale(1, 1)',
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)'
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0
    }
  }
})

class SearchBarMobile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      listType: ListType.Machines
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (this.state.listType !== this.props.listType) { this.setState({ value: '', listType: this.props.listType }) }
  }

  handleOnSearch = (searchTerm) => {
    this.setState({ value: searchTerm })
    this.props.onSearch(searchTerm)
  }

  render () {
    const { classes } = this.props
    const icon = this.props.listType === ListType.Machines ? IconAssetList : UserIcon
    const style = this.props.listType === ListType.Machines ? { marginTop: '15px' } : { marginTop: '12px' }
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item className={classes.searchBar}>
            <SearchBar
              data-cy='search'
              className={classes.MuiPaperRounded93}
              value={this.state.value}
              placeholder={strings.searchPlaceHolderMobile}
              classes={{ input: classes.input, iconButton: classes.iconButton, iconButtonHidden: classes.iconButtonHidden }}
              onChange={(newValue) => this.handleOnSearch(newValue)}
              onCancelSearch={() => this.handleOnSearch('')}
              closeIcon={<ClearIcon className={classes.closeButton} />}
              searchIcon={<SearchIcon className={classes.searchButton} />}
            />

          </Grid>
          <Grid item className={classes.iconStyle}>
            <div className={classes.imageStyle} style={style}>
              <img alt='' src={icon} />
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SearchBarMobile.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(SearchBarMobile)
