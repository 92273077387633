import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/styles'
import { ValidateForgetPasswordToken, ResetPasswordAPI } from '../../helpers/api.js'

import Typography from '@material-ui/core/Typography'
import Form from '../../common/form'
import Joi from 'joi-browser'

import { strings } from '../../localizations/strings.js'
import Response from '../../common/Response'
import ResponseFailure from '../../common/ResponseFailure'
import FormHelperText from '@material-ui/core/FormHelperText'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

import Grid from '@material-ui/core/Grid'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent } from '../../helpers/GenericHelpers.js'

const useStyles = theme => ({
  pageLayout: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
  middleLayoutss: {
    flex: 1
  },
  description: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    marginBottom: '10px',
    fontWeight: '300'
  },
  button: {
    marginTop: '20px',
    height: '48px',
    '& .MuiButton-label': {
      color: '#303030'
    }
  },
  demo: {
    paddingTop: '2%',
    marginTop: '4%',
    background: 'white',
    border: '10px',
    display: 'block',
    boxShadow: '0 2px 20px 0 rgba(0, 41, 56, 0.1)',
    flexDirection: 'column',
    flex: '1',
    paddingBottom: '30px'
  }
})

class ResetPassword extends Form {
  state = {
    data: { password: '', password_confirmation: '' },
    errors: {},
    token: this.props.match.params.token,
    pathname: this.props.location.pathname,
    status: '',
    showPassword: false,
    token_type: '',
    openF: false,
    setOpenF: false,
    open: false,
    setOpen: false,
    message_title: '',
    message: '',
    response: '',
    windowWidth: 0,
    windowHeight: 0,
    // Call the server
    isAndroid: window.Android,
    isiOS: window.iOS,
    customError: ''
  }

  schema = {
    password: Joi.string()
      .required()
      .min(8)
      .label('password')
      .error(errors => {
        return {
          message: strings.password_error_msg
        }
      }),

    password_confirmation: Joi.string()
      .required()
      .min(8)
      .label('password')
      .error(errors => {
        return {
          message: strings.password_error_msg
        }
      })
  }

  closePopUp = () => {
    this.setState({
      open: false
    })
    this.props.history.push('/')
  }

  closePopUpFailure = () => {
    this.setState({
      openF: false
    })
  }

  componentDidMount () {
    const path = this.state.pathname.split('/', 2)
    this.state.token_type = 'xyz'
    if (path[1] === 'activate') {
      this.state.token_type = 'ACTIVATE'
      callFirebaseEvent(FA_CONSTANTS.FA_RESET_PASSWORD_OPENED_INVITE)
    } else if (path[1] === 'reset') {
      this.state.token_type = 'FORGOT'
      callFirebaseEvent(FA_CONSTANTS.FA_RESET_PASSWORD_OPENED_FORGOT_PASSWORD)
    }
    const params = {
      token: this.state.token,
      type: this.state.token_type
    }
    ValidateForgetPasswordToken(params, (error, response) => {
      if (error) {
        return window.alert(error.message)
      } else {
        if (response.data.status === 2) {
          if (this.state.isAndroid) {
            this.sendDataToAndroid('session_expired', response.data.message)
          } else if (this.state.isiOS === 'iOS') {
            this.sendDataToiOS(2, response.data.message)
          } else {
            // this.props.history.push("/forgot_password");
            this.props.history.push({
              pathname: '/forgot_password',
              state: {
                password_exp: true,
                status: response.data.status,
                message: response.data.message,
                message_title: response.data.message_title
              }
            })

            // this.setState({ open: true, setOpen: true });
            this.setState({
              status: response.data.status,
              message: response.data.message,
              message_title: response.data.message_title
            })
          }
        } else this.setState({ status: response.data.status })
      }
    })
  }

  // On focus change from input field it will be called
  focusEvent = input => {
    // If password and c_password is a valid input than value of password and c_password will be null in error object
    if (input.name === 'password' && this.state.errors.password === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_RESET_PASSWORD_FIRST_PASSWORD_ENTERED)
    } else if (input.name === 'password_confirmation' && this.state.errors.password_confirmation === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_RESET_PASSWORD_SECOND_PASSWORD_ENTERED)
    }
  }

  doSubmit = (errors) => {
    if (errors === null) {
    // Call the server
      if (this.state.data.password !== this.state.data.password_confirmation) {
        this.setState({ openF: true, setOpenF: true, message: strings.password_mismatch })
        // return window.alert('Password mismatch. Please try again ')
      } else {
        // this.setState({ customError: '' })
        const params = {
          token: this.state.token,
          password: this.state.data.password,
          password_confirmation: this.state.data.password_confirmation,
          type: this.state.token_type
        }
        ResetPasswordAPI(params, (error, response) => {
          if (error) {
            const errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : strings.something_went_wrong
            callFirebaseEvent(FA_CONSTANTS.FA_E_RESET_PASSWORD, { description: errorMessage })
            return window.alert(errorMessage)
          } else {
            const { status, message } = response.data
            if (this.state.isiOS === 'iOS') {
              if (status === 1) {
                callFirebaseEvent(FA_CONSTANTS.FA_RESET_PASSWORD_SUCCESS)
                this.sendDataToiOS(1, 'success')
              } else this.sendDataToiOS(1, message)
            } else if (this.state.isAndroid) {
              if (response.data.status === 1) {
                callFirebaseEvent(FA_CONSTANTS.FA_RESET_PASSWORD_SUCCESS)
                this.sendDataToAndroid('success_setPassword', 'success')
              } else {
                this.sendDataToAndroid(
                  'success_setPassword',
                  response.data.message
                )
              }
            } else {
              callFirebaseEvent(FA_CONSTANTS.FA_RESET_PASSWORD_SUCCESS)
              this.setState({
                open: true,
                message_title: response.data.message_title,
                message: response.data.message
              })
            }
          }
        })
      }
    }
  }

  // this function will be used to send data from react to Android app when Page is loaded in Android webview.
  sendDataToAndroid = (callEvent, data) => {
    window.Android.communicateWithNative(callEvent, data)
  }

  sendDataToiOS = (messageId, data) => {
    try {
      if (messageId === 1) {
        window.webkkit.messageHandlers.setPassword.postMessage(data)
      } else if (messageId === 2) {
        window.webkkit.messageHandlers.sessionExpired.postMessage(data)
      } else if (messageId === 3) {
        window.webkkit.messageHandlers.resetPassword.postMessage(data)
      }
    } catch (err) {}
  }

  linkAction = () => {
    this.setState({
      open: false
    })
    this.props.history.push('/forgot_password')
  }

  render () {
    const { classes } = this.props
    if (this.state.status === 2) {
      return (
        <ResponseFailure
          open={this.state.open}
          setOpen={this.state.setOpen}
          message_title={this.state.message_title}
          message={this.state.message}
          closePopUp={this.closePopUp}
          link
          linkTitle={strings.password_exp_link_title}
          linkAction={this.linkAction}
        />
      )
    }
    if (this.state.status === 1) {
      return (
        <div className={classes.pageLayout}>
          <Header id='header' />
          <div className={classes.middleLayoutss} style={{ flex: 1 }}>
            <Container maxWidth='sm' className={classes.demo}>
              <form>
                <Typography
                  variant='h5'
                  component='h5'
                  align='left'
                  className={classes.description}
                >
                  {strings.reset_password_page_title}
                </Typography>
                <Grid container direction='row' justifyContent='center' spacing={2}>
                  {this.renderInput(
                    'password',
                    strings.enter_password_msg,
                    classes,
                    'hide',
                    'password'
                  )}
                  {this.renderInput(
                    'password_confirmation',
                    strings.enter_password_again_msg,
                    classes,
                    'show',
                    'password'
                  )}
                  <FormHelperText error='true'>
                    {this.state.customError}
                  </FormHelperText>
                  {this.renderButton(strings.reset_password_msg, classes.button, 'submit')}
                </Grid>

              </form>
            </Container>
          </div>
          <Footer id='footer' />
          <Response
            open={this.state.open}
            setOpen={this.state.setState}
            message_title={this.state.message_title}
            message={this.state.message}
            closePopUp={this.closePopUp}
          />
          <ResponseFailure
            open={this.state.openF}
            setOpen={this.state.setOpenF}
            messageTitle={this.state.message_title}
            message={this.state.message}
            closePopUp={this.closePopUpFailure}
          />
        </div>
      )
    }

    return null
  }
}

export default withStyles(useStyles)(ResetPassword)
