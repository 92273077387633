import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  topMargin: {
    marginTop: '20px'
  }
}))

export default function FullFieldLayoutGrid (props) {
  const classes = useStyles()

  return (
    <Grid
      key={props.key}
      item
      container
      direction='row'
      justifyContent='flex-start'
      spacing={0}
      xs={12}
      sm={12}
      md={12}
      className={props.className || classes.topMargin}
    >
      {props.children}
    </Grid>
  )
}
