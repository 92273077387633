const SuccessIcon = () =>
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path id='a' d='M6.252 11.252l-4.17-4.17L.667 8.497l5.585 5.585 12-12L16.837.667z' />
    </defs>
    <g fill='none' fillRule='evenodd' transform='translate(2 6)'>
      <mask id='b' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <g fill='#FFF' mask='url(#b)'>
        <path d='M-2-6h24v24H-2z' />
      </g>
    </g>
  </svg>

export default SuccessIcon
