import Grid from '@material-ui/core/Grid'

export default function FieldLayoutGrid (props) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </Grid>
  )
}
