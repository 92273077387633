import React from 'react'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import MenuRounded from '@material-ui/icons/MenuRounded'
import PropTypes from 'prop-types'
import { ThemeProvider, withStyles } from '@material-ui/styles'
import theme from '../theme'
import Hidden from '@material-ui/core/Hidden'
import menuIcon from '../assets/images/menuIcon.png'
import { showMobileLayoutHeader } from './../helpers/GenericHelpers'

import {
  Root,
  Header,
  Nav,
  Content,
  presets
} from 'mui-layout'

const useStyles = theme => ({
  header: {
    backgroundColor: 'white',
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.12)'
  },
  mobileHeader: {
    backgroundColor: '#0d548c',
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.12)'
  },
  mobileHeaderNone: {
    display: 'none'
  }
})

const config = presets.createFixedLayout({ navWidth: 300 })

class Layout extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <ThemeProvider theme={theme}>
        <Root config={config}>

          <Hidden only={['sm', 'xs']}>
            <Header
              className={classes.header}
              renderMenuIcon={open => (open ? <ChevronLeft /> : <MenuRounded />)}
            >
              {this.props.header}
            </Header>
          </Hidden>
          <Hidden only={['lg', 'xl', 'md']}>
            {showMobileLayoutHeader(this.props.header.props.route) &&
              <Header
                className={classes.mobileHeader}
                renderMenuIcon={open => (open ? <ChevronLeft /> : <img data-cy='side-menu' alt='' src={menuIcon} />)}
              >
                {this.props.header}
              </Header>}
            <Header
              className={classes.mobileHeaderNone}
              renderMenuIcon={open => (open ? <ChevronLeft /> : <img alt='' src={menuIcon} />)}
            >
              {this.props.header}
            </Header>
          </Hidden>
          <Nav renderIcon={collapsed => collapsed ? <ChevronRight /> : <ChevronLeft />}>
            {this.props.sidemenu}
          </Nav>
          <Content>
            {this.props.body}
          </Content>
        </Root>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  body: PropTypes.any,
  header: PropTypes.any,
  sidemenu: PropTypes.any,
  classes: PropTypes.object
}

export default withStyles(useStyles)(Layout)
