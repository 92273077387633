import { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import InfoIcon from '../assets/ic_info.svg'
import { Typography } from '@material-ui/core'

const useStyles = theme => ({
  messageStyle: {
    marginBottom: '25px'
  },
  closeIcon: {
    alignItems: 'flex-first',
    marginRight: '16px',
    marginTop: '16px'
  },
  titleLabel: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  messageLabel: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.56px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  buttonsView: {
    marginBottom: '5px',
    marginRight: '5px'
  },
  link: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: '1.43px',
    textAlign: 'right',
    color: '#0d548c'
  }
})

class Response extends Component {
  componentDidMount () {
  }

  renderIcon (warning) {
    if (warning) {
      return <img src={InfoIcon} alt='icon' />
    } else {
      return (
        <CheckCircleRoundedIcon
          fontSize='large'
          style={{ color: 'green', margin: 'auto' }}
        />
      )
    }
  }

  render () {
    const {
      open,
      messageTitle,
      message,
      closePopUp,
      link,
      linkAction,
      hideIcon,
      secondLinkAction,
      warning,
      classes,
      overrideTitlesStyle,
      overrideMessageStyle
    } = this.props

    let check
    if (link === true) {
      check = (
        <DialogContent
          className={classes.buttonsView}
        >
          <DialogContentText
            id='alert-dialog-slide-description'
            alignItems='flex-first'
          >
            <Grid container>
              <Grid item xs='6'>
                {(this.props.secondLink === true)
                  ? (

                    <Link
                      data-cy='first-link'
                      variant='body2'
                      onClick={() => secondLinkAction()}
                      className={classes.link}
                      style={this.props.overrideSecondLinkStyle || {}}
                    >
                      {this.props.secondLinkTitle}
                    </Link>

                    )
                  : ''}
              </Grid>
              <Grid item xs='6' style={{ textAlign: 'right' }}>
                <Link
                  data-cy='second-link'
                  variant='body2'
                  onClick={() => linkAction()}
                  className={classes.link}
                  style={this.props.overrideLinkStyle || {}}
                >
                  {this.props.linkTitle}
                </Link>
              </Grid>

            </Grid>

          </DialogContentText>
        </DialogContent>
      )
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          onClose={() => closePopUp()}
        >
          <DialogActions>
            <CloseIcon
              onClick={() => closePopUp()}
              className={classes.closeIcon}
            />
          </DialogActions>

          {messageTitle &&
            <DialogTitle id='alert-dialog-slide-title'>
              <Typography
                className={classes.titleLabel}
                style={overrideTitlesStyle || {}}
              >
                {messageTitle}
              </Typography>
            </DialogTitle>}

          {(hideIcon === true) ? this.renderIcon(warning) : ''}

          <DialogContent>
            <DialogContentText
              id='alert-dialog-slide-description'
              className={classes.messageStyle}
            >
              <Typography
                className={classes.messageLabel}
                style={overrideMessageStyle || {}}
              >
                {message}
              </Typography>

            </DialogContentText>
          </DialogContent>

          {check}
        </Dialog>
      </div>
    )
  }
}

export default withStyles(useStyles)(Response)
