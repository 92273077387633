import LabelValueLayout from '../../AssetDetailLabelValueLayout'
import RowsLayout from '../../MultiGridLayout'
import { useStylesCommon } from '../../DetailsCommonComponents'

export default function AssetDetailsCheckItemGrid (props) {
  const classesCommon = useStylesCommon()

  const getValueComponent = () => {
    return (
      <RowsLayout
        components={props.checkValueComponents}
        className={classesCommon.assetContent}
        gridSpacing={props.valueGridSpacing || 0}
      />
    )
  }

  return (
    <LabelValueLayout
      label={props.assetTypeIcon}
      value={getValueComponent()}
      gridSpacing={1}
    />
  )
}
