import { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import IconLogo from '../../assets/klickcheck_logo.svg'

import * as CONSTANTS from '../../utils/url_constant.js'

const useStyles = theme => ({

  headerView: {
    paddingBottom: 20,
    color: '#FFFFFF',
    textAlign: 'left'
  },
  headerLogo: {
    marginRight: 16,
    marginLeft: 14,
    marginTop: 40,
    width: 200
  },
  titleHeader: {
    textSize: 20,
    marginTop: 50,
    marginLeft: 16,
    marginRight: 16,
    color: '#FFFFFF'
  },
  titleText: {
    marginLeft: 16,
    marginRight: 16,
    color: '#FFFFFF',
    textSize: 10
  }

})

class DrawerHeader extends Component {
  // isAdminUser() {

  //   if (this.state.data != null && this.state.data.userInfo != null && this.state.data.userInfo.role === "ADMIN") {
  //     return <div><Box marginLeft={2} fontSize={16} fontFamily="fontFamily" m={1}>
  //       {strings.menu_admin_user}
  //     </Box> </div>
  //   }
  //   return false;
  // }

  // updateUserInfo() {
  //   let { first_name, last_name } = this.state.data
  //   if (this.state.data != null && this.state.data.userInfo != null && this.state.data.userInfo.first_name != null) {
  //     first_name = this.state.data.userInfo.first_name;
  //   }

  //   if (this.state.data != null && this.state.data.userInfo != null && this.state.data.userInfo.last_name != null) {
  //     last_name = this.state.data.userInfo.last_name;
  //   }

  //   var full_name;

  //   if (first_name === "" && last_name === "" && this.state.data.userInfo != null && this.state.data.userInfo.full_name != null) {
  //     full_name = this.state.data.userInfo.full_name;
  //   }
  //   else {
  //     full_name = first_name + " " + last_name
  //   }

  //   return (<div>
  //     <Box marginLeft={2} fontSize={16} fontFamily="fontFamily" m={1}>
  //       {full_name}
  //     </Box>
  //   </div>);
  // }
  constructor (props) {
    super(props)

    this.state = {
      data: {
        // userInfo: getUserInfo(),
        // full_name: "",
        // first_name: "",
        // last_name: "",
        // email: ""

      }
    }
  }

  render () {
    const { classes } = this.props
    return (
      <>
        <div className={classes.headerView}>
          <a href={CONSTANTS.URLPATH_HOME}><img className={classes.headerLogo} src={IconLogo} alt='Logo' /></a>
          {/* <Typography component="div">
            {this.isAdminUser()}
            {this.updateUserInfo()}
          </Typography> */}
        </div>
      </>
    )
  }
}

DrawerHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(DrawerHeader)
