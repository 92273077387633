import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    login_title: 'Log in with your account',
    login_head1: 'I already have a user account.',
    login_head2: 'Log in',
    login_description: 'Log in to improve your rental documentation today.',
    signup_description: 'New at klickcheck? Register here.',
    email: 'E-mail address',
    password: 'Password',
    forgot_password: 'Forgot your password?',
    category: 'Category',
    activation: 'Activation',
    forgot_password_title: 'Reset Password',
    forgot_password_description:
      'Enter your e-mail address, to reset your password:',
    forgot_password_submit_button: 'Reset Password',

    reset: 'Reset your password',
    order_created: 'Order successfully created',
    order_drafted: 'Order successfully edited',
    signup: 'New in klickcheck? ',
    signup_link: 'Register now',
    company_name: 'Company name',
    salutation: 'Salutation',
    salutation_mr: 'Mr',
    salutation_mrs: 'Mrs',
    first_name: 'First name',
    last_name: 'Last name',
    e_mail: 'E-Mail',
    e_mail_usertype: 'An admin account will be created for this user',
    telephone: 'Phone  (optional)',
    email_subscribe:
      'I want to receive updates about klickcheck via e-mail. I can revoke this consent at any time.',
    data_privacy_1: 'I agree to the ',
    data_privacy_2: 'terms of use',
    data_privacy_3: ' and the ',
    data_privacy_4: 'Data privacy',
    data_privacy_5: ' statement of klickcheck.',
    login_button: 'LOGIN',
    submit: 'Submit',
    userselection_error_msg: 'Please select user role',
    companyselection_error_msg: 'Please select company',
    salutation_error_msg: 'Please select salutation',
    data_privacy_error_msg: 'Please select data privacy',
    first_name_error_msg: 'Please provide first name',
    last_name_error_msg: 'Please provide last name',
    company_name_error_msg: 'Please enter company name',
    email_id_error_msg: 'Please provide valid email-id',
    password_error_msg: 'The password must consist of at least 8 characters.',
    access_location_error_msg: 'Please select at least one accessible location',
    send_mail_msg: 'send email',
    reset_password_page_title: 'Please set your password',
    enter_password_msg: 'Your password',
    enter_password_again_msg: 'Repeat password',
    reset_password_msg: 'Save',
    filter: 'Filter',
    activated: 'Activated',
    deactivated: 'Deactivated',
    activated_n_deactivated: 'Activated & Deactivated',
    availabilility: 'Availability',
    createUser_description: 'Who do you want to invite to klickcheck?',
    role: 'Role',
    admin: 'Admin',
    user: 'User',
    send_invitation: 'Send invite',
    cancel: 'Cancel',
    actions: 'Actions',
    give_admin_rights: 'Give user admin rights',
    revoke_admin_rights: 'Revoke admin rights from user',
    status: 'Status',
    add_user: 'Add user',
    menu_home: 'Home',
    menu_add_machine: 'Add Machine',
    menu_asset_parking: 'Parking',
    menu_manage_account: 'Manage Account',
    menu_select_company: 'Select Company',
    menu_invite_person: 'Invite person to klickcheck',
    menu_data_privacy: 'Data Privacy',
    menu_log_out: 'Log out',
    menu_admin_user: 'Logged in as administrator',
    menu_personal_data: 'Profile',
    menu_compnay_data: 'Company data',
    menu_user_administartion: 'User Management',
    menu_notifications: 'Notifications',
    menu_delete_company_account: 'Delete company account',
    company_address: 'Address',
    company_email_address: 'Company e-mail',
    street_house_number: 'Street and house number',
    post_code: 'Post code',
    city: 'City',
    contact_us: 'Contact',
    save: 'Save',
    save_profile: 'Save profile',
    street_house_number_error: 'Please provide street and house number',
    post_code_error_msg: 'Please provide postcode',
    city_error_msg: 'Please provide city',
    delete_company_account_head1:
      'If you delete your company account, all users and data of your company will be deleted from our system and the klickcheck app will no longer be usable.',
    delete_company_account_head2:
      'Do you really want to delete your company account?',
    no: 'NO',
    yes: 'YES',
    delete_company_account: 'Delete company account',
    delete_company_popup_title: 'Please note',
    delete_company_popup_description:
      'If you click on "delete company account", we will completely delete your company data from our system. The klickcheck app will then no longer be usable.',
    landing_page_title: 'Download klickcheck app',
    landing_page_content:
      'In order to issue or withdraw machines, you need the klickcheck app. These can be obtained from the play- or app-store.',
    password_exp_link_title: 'Send link again',
    select_company: 'Select company',
    create_user: 'Add user',
    create_user_now: 'Add now',
    edit_user: 'edit',
    edit_user_title: 'Edit user',
    delete_user_title: 'Delete user',
    delete_user_message: 'Do you want to delete the user account?',
    delete_user_message1: 'will be logged out of klickcheck and cannot log in again.',
    delete_user_now: 'Delete now',
    send_invite_title: "Invitation wasn't accepted yet",
    resend_title: 'Resend invitation',
    send_mail_title: 'Invitation was sent',

    title_create_qrcode: 'Create QR Codes',
    qrcode_pages: 'Set the number of pages',
    download_pdf: 'Download PDF',
    invalid_username_password_title: 'Invalid Username and/or password',
    invalid_username_password_message: 'Please enter valid username and/or password',
    menu_tnc: 'Terms & Conditions',
    menu_imprint: 'Imprint',
    title_creat_qrcode: 'Create qr-codes',
    qr_code_description_title_1: 'how does it work ?',
    feedBacksubTitle: 'Help us to become even better!',
    feedBackMessage:
      'With your opinion you support  us to optimize the app even better for you. \n\nIn order to collect your feedback, we will forward you to a website. \n\nThank you very much, \nYour team from klickcheck',
    please_write_us_here: 'Please write to us here ...',
    letsGo: "Let's go",
    asset_list: 'My Machines',
    asset_list_header_1: 'Machine',
    asset_list_header_2: 'Asset detail',
    asset_list_header_3: 'Inventory no',
    asset_list_header_4: 'Branch',
    asset_list_header_5: 'Order no',
    user_list_header_1: 'User',
    user_list_header_2: 'Role',
    location: 'Location',
    user_list_header_4: 'Profile',
    create_order_data: 'Create checkout draft',
    order_number: 'Order number*',
    mandatory_field: '*Mandatory',
    max_character_length: 'Max. 192 characters allowed.',
    place_of_action: 'Construction site',
    customer_company: 'Customer / Company',
    contact_for: 'Contact person',
    customer_telephone: 'Contact person phone number',
    create_draft: 'Create draft',
    create_draft_inprogress: 'Draft is being created…',
    save_draft: 'Draft is being saved…',
    checkout_draft: 'Checkout draft',
    delete_draft: 'Delete draft',

    save_order: 'Order is being saved…',
    order_details: 'Order details',
    order_delete: 'Delete order',
    return_draft: 'Checkin draft',

    myMachines: 'My Machines',
    searchPlaceHolderWeb: 'Search for devices or orders...',
    searchPlaceHolderMobile: 'Search…',
    searchUsersPlaceHolder: 'Search users',
    allLocations: 'All locations',
    no_machine_created_yet: 'No machines have been created yet.',
    no_users_created_yet: 'No users have been created yet.',
    no_machine_found_step1: 'Check that your spelling is correct.',
    no_machine_found_step2: 'Use more general search terms.',
    no_machine_found_step3: 'Use less search terms.',
    no_machine_head2: 'Try the following:',
    no_machine_head1: 'No entries found',
    in: 'in',
    at: 'at',
    for: 'for',
    of: 'of',
    to: 'to',
    createOrder: 'Create Order',
    machine_found: 'Entries found',
    one_machine: 'One machine',
    no_machine: 'No machines',
    one_user: 'One user',
    no_users: 'No users',
    machines: 'machines',
    users: 'users',
    popUpDeleteOrder: 'Delete draft?',
    popUpDeleteDescription: 'Do you really want to delete the checkout draft? New defects that might have been added via the app will also be deleted.',
    popUpDeleteDraft: 'Delete draft',
    password_mismatch: 'The entries don\'t match!',
    machine_status: 'Machine status has changed.',
    machine_failure_status: 'Error in updating the order data',
    qrcode_intro_title: 'Find machines faster with QR codes',
    qrcode_intro_description: 'Label your machines with individual QR codes. In the app, machines can be called up simply by QR code - so you can find machines even faster! And this is how it works:',
    qrcode_create_section_title: '1. Generate and print QR codes',
    qrcode_create_section_text_1: 'Generate QR codes here that you can print out as stickers and attach to your machines. 24 individual QR codes are created per DIN A4 page.',
    qrcode_create_section_text_2: 'Note',
    qrcode_create_section_text_3: 'DIN A4 foil labels with 24 labels in 63.5 x 33.9 mm format are suitable for printing, e.g. ',
    qrcode_create_section_text_4: 'Ultra-Resistant Labels from Avery Zweckform.',
    qrcode_carousel_title: '2. Assign QR codes to machines',
    qrcode_carousel_text_1: 'You assign your machines to the individual QR codes in the klickcheck app:',
    order_deleted: 'Order deleted',
    qrcode_carousel_item_1_title: 'Scan QR code',
    qrcode_carousel_item_1_description: 'Use the "Scan QR Code" function to scan one of your QR codes.',
    qrcode_carousel_item_1_image_path: '/qr_code_carousel/qr_code_carousel_1_en.png',
    qrcode_carousel_item_2_title: 'New QR code',
    qrcode_carousel_item_2_description: 'When scanning a new QR code, a notification appears in the app.',
    qrcode_carousel_item_2_image_path: '/qr_code_carousel/qr_code_carousel_2_en.png',
    qrcode_carousel_item_3_title: 'Assign scanned QR code',
    qrcode_carousel_item_3_description: 'Afterwards you can either add a new machine ...',
    qrcode_carousel_item_3_image_path: '/qr_code_carousel/qr_code_carousel_3_en.png',
    qrcode_carousel_item_4_title: 'Assign scanned QR code',
    qrcode_carousel_item_4_description: 'or select an existing machine from the list to link it to the QR code.',
    qrcode_carousel_item_4_image_path: '/qr_code_carousel/qr_code_carousel_4_en.png',
    qrcode_carousel_item_5_title: 'Ready!',
    qrcode_carousel_item_5_description: 'In the future, you can access the machine by scanning the QR code.',
    qrcode_carousel_item_5_image_path: '/qr_code_carousel/qr_code_carousel_5_en.png',
    something_went_wrong: 'Something went wrong. Please try again',
    news_letter: 'I want to receive updates about klickcheck via e-mail. I can revoke this consent at any time.',
    klickcheck_newsletter: 'klickcheck Newsletter',

    logged_in_as: 'Logged in as',
    support: 'Support',

    user_roles: 'User rights',
    service_technician_role: 'User',
    company_admin_role: 'Admin',
    supportuser_role: 'Support',
    title_company: 'Company',
    title_userrole: 'User Role',
    company: 'Company',
    all: 'All',
    clear_all_filters: 'Reset all filters',
    show_all: 'show all',

    location_not_assigned: 'Location not assigned',
    no_machine_found_title: 'No machines found with the current search/filter, but there are others',
    no_users_found_title: 'No users found with the current search/filter, but there are others',
    selected_filter: 'Selected Filter:',
    checkin_draft: 'Checkin draft',
    available: 'Available',
    maintenance: 'Maintenance',
    not_available: 'Not Available',
    user_added: 'User was added',
    user_exists: 'User account already exists',
    no_internet_connection: 'No internet connection. Please try again.',
    add_machine: 'Add Machine',
    creating_new_machine: 'Creating New Machine',
    edit_machine: 'Edit Machine',
    machine_category: 'Machine category',
    inventory_number: 'Machine or inventory number',
    machine_name: 'Name',
    serial_number: 'Serial number',
    checklist_error_msg: 'Please choose a machine category',
    inventory_number_missing: 'Please enter Machine or Inventory number',
    name_missing: 'Please enter a valid name',
    none_selected: 'None selected',
    machine_added: 'Machine added',
    err_duplicate_assetname: 'A machine with this name already exists',
    err_duplicate_invoicenumber: 'A machine with this inventory number already exists',
    checklist_not_available: 'Machine category not available anymore. Please select another one.',
    message_asset_updated: 'Successfully edited machine',
    asset_details_title: 'Detail View',

    details: 'Details',
    current_Status: 'Current status',
    last_change: 'Last change',
    time_of_return: 'Time of return',

    new: 'New',
    pageNotAccessible: 'No access to this item.',
    pageNotFound: 'Resource you are looking for does not exist or has been removed permanently. Please contact support.',

    access: 'Access',
    no_access: 'No access',
    restricted_access: 'Restricted access',
    locations: 'locations',
    no_restrictions: 'No restriction',
    access_to_all: 'Access to all',
    only_current_location: 'Only current location',

    problem_feedback: 'Problem / Feedback',
    problem_feedback_missing: 'Please enter Problem / Feedback',
    send_message: 'Send message',
    feedback_message_success: 'Message successfully sent',
    feedback_telephone: 'Phone number for call-back',
    save_in_profile: 'Save in my profile data',
    name: 'Name',
    feedback_max_character_length: 'Max. 2000 characters allowed.',
    user_deleted: 'User deleted successfully',
    message: 'Message'
  },

  de: {
    title_company: 'Firma',
    title_userrole: 'Benutzer Rolle',
    login_title: 'Melden Sie sich mit Ihrem Konto an:',
    login_head1: 'Ich besitze bereits Zugangsdaten.',
    login_head2: 'Anmelden',
    login_description:
      'Loggen Sie sich ein, um noch heute die Dokumentation Ihrer Miete zu verbessern.',
    signup_description: 'Neu bei klickcheck? Hier registrieren.',
    salutation: 'Anrede',
    salutation_mr: 'Herr',
    salutation_mrs: 'Frau',
    email: 'Ihre E-Mail-Adresse',
    e_mail_usertype: 'Nutzer wird als Account Admin angelegt.',
    password: 'Ihr Passwort',
    forgot_password: 'Passwort vergessen?',
    reset_password: 'Reset your password',
    add_user: 'Nutzer hinzufügen',
    reset: 'Reset your password',
    category: 'Kategorie',
    activation: 'Aktivierung',
    forgot_password_title: 'Passwort zurücksetzen',
    forgot_password_description:
      'Geben Sie Ihre E-Mail Adresse ein, um Ihr Passwort zurückzusetzen:',
    forgot_password_submit_button: 'absenden',

    menu_home: 'huis',
    menu_add_machine: 'Add Machine',
    menu_asset_parking: 'Parking',
    menu_manage_account: 'Manage Account',
    menu_select_company: 'Select Company',
    menu_invite_person: 'Invite person to klickcheck',
    menu_data_privacy: 'Datenschutz',
    menu_log_out: 'Abmelden',
    menu_admin_user: 'Logged in as administrator',
    menu_personal_data: 'Profileinstellungen',
    menu_compnay_data: 'Company data',
    menu_user_administartion: 'Nutzerverwaltung',
    menu_notifications: 'Notifications',
    menu_delete_company_account: 'Delete company account',
    order_drafted: 'Auftrag erfolgreich bearbeitet',
    order_created: 'Auftrag erfolgreich erstellt',
    signup: 'Neu bei klickcheck? ',
    signup_link: 'Jetzt registrieren',
    login_button: 'ANMELDEN',
    company_name: 'Firmenname',
    first_name: 'Vorname',
    last_name: 'Nachname',
    e_mail: 'E-Mail',
    telephone: 'Telefon  (optional)',
    email_subscribe:
      'Ich möchte Neuigkeiten zu klickcheck per E-Mail erhalten. Ich kann diese Einwilligung jederzeit widerrufen.',
    data_privacy_1: 'Ich stimme den ',
    data_privacy_2: 'AGB',
    data_privacy_3: ' und der ',
    data_privacy_4: 'Datenschutzerklärung',
    data_privacy_5: ' von klickcheck zu.',
    submit: 'Einreichen',
    userselection_error_msg: 'Bitte wählen Sie die Benutzerrolle',
    companyselection_error_msg: 'Bitte Firma auswählen',
    salutation_error_msg: 'Bitte Anrede auswählen',
    data_privacy_error_msg: 'Bitte wählen Sie den Datenschutz',
    first_name_error_msg: 'Bitte geben Sie den Vornamen an',
    last_name_error_msg: 'Bitte geben Sie den Nachnamen an',
    company_name_error_msg: 'Bitte geben Sie den Firmennamen ein',
    email_id_error_msg: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    password_error_msg: 'Das Passwort muss mindestens 8 Zeichen enthalten.',
    access_location_error_msg: 'Bitte wählen Sie mindestens einen zugänglichen Standort',
    send_mail_msg: 'E-Mail senden',
    reset_password_page_title: 'Bitte vergeben Sie Ihr Passwort',
    enter_password_msg: 'Ihr Passwort',
    enter_password_again_msg: 'Passwort wiederholen',
    reset_password_msg: 'speichern',

    activated: 'Aktivierte',
    deactivated: 'Deaktivierte',
    activated_n_deactivated: 'Aktivierte & Deaktivierte',
    availabilility: 'Verfügbarkeit',

    createUser_description: 'Wen möchten Sie zu klickcheck einladen?',
    role: 'Rolle',
    admin: 'Administrator',
    user: 'Nutzer',
    send_invitation: 'Einladung senden',
    cancel: 'Abbrechen',
    actions: 'Aktionen',
    give_admin_rights: 'Administratorrechte geben',
    revoke_admin_rights: 'Administratorrechte entziehen',
    status: 'Status',
    company_address: 'Anschrift',
    company_email_address: 'Firmen-E-Mail',
    street_house_number: 'Straße und Hausnummer',
    post_code: 'PLZ',
    city: 'Ort',
    contact_us: 'Kontakt',
    save: 'Speichern',
    save_profile: 'Profil speichern',
    street_house_number_error: 'Bitte geben Sie   Straße und Hausnummer',
    post_code_error_msg: 'Bitte geben Sie eine Postleitzahl ein',
    city_error_msg: 'Bitte geben Sie die Stadt an',
    no: 'NEIN',
    yes: 'JA',
    delete_company_account: 'firmenkonto löschen',
    delete_company_popup_title: 'Bitte beachten Sie',
    delete_company_popup_description:
      'Wenn Sie auf "Firmenkonto löschen" klicken, werden wir Ihre Firmendaten vollständig aus unserem System löschen.Die klickcheck App ist anschließend nicht mehr nutzbar.',
    delete_company_account_head1:
      'Wenn Sie Ihr Firmenkonto löschen, werden alle Benutzer und Daten Ihres Unternehmens aus unserem System gelöscht und die klickcheck-App ist nicht mehr nutzbar.',
    delete_company_account_head2:
      'Möchten Sie wirklich Ihr Firmenkonto löschen?',
    landing_page_title: 'klickcheck App herunterladen',
    landing_page_content:
      'Um Maschinen auszugeben, oder zurückzunehmen, benötigen Sie die klickcheck App. Diese können Sie über den Play- oder den App-Store beziehen.',
    password_exp_link_title: 'Link erneut senden',
    select_company: 'Unternehmen wählen',
    create_user: 'Nutzer hinzufügen',
    create_user_now: 'Jetzt hinzufügen',
    edit_user: 'bearbeiten',
    edit_user_title: 'Nutzerprofil bearbeiten',
    delete_user_title: 'Nutzer löschen',
    delete_user_message: 'Wollen Sie das Nutzerkonto löschen?',
    delete_user_message1: 'wird von klickcheck abgemeldet und kann sich nicht wieder neu anmelden.',
    delete_user_now: 'Jetzt löschen',
    send_invite_title: 'Einladung wurde noch nicht angenommen.',
    resend_title: 'Einladung erneut senden',
    send_mail_title: 'Einladung wurde versendet',
    title_create_qrcode: 'QR-Codes erstellen',
    qrcode_pages: 'Bestimmen Sie die Seitenanzahl',
    download_pdf: 'PDF zum Druck erstellen',
    invalid_username_password_title:
      'Ungültiger Benutzername und/oder Passwort',
    invalid_username_password_message:
      'Bitte geben Sie einen gültigen Benutzernamen und/oder ein gültiges Passwort ein.',
    menu_tnc: 'Nutzungsbedingungen',
    menu_imprint: 'Impressum',
    title_creat_qrcode: 'qr-codes erstellen',
    qr_code_description_title_1: 'wie funktioniert das ?',
    feedBacksubTitle: 'Helfen Sie uns noch besser zu werden!',
    feedBackMessage:
      'Mit Ihrer Meinung unterstützen Sie uns,  die App noch besser für Sie zu optimieren. \n\nUm Ihr Feedback zu erfassen, leiten wir  Sie auf eine extra Internetseite weiter. \n\nVielen Dank, \nIhr Team von klickcheck',
    please_write_us_here: 'Schreiben Sie uns bitte hier ...',
    letsGo: "Los Geht's",
    asset_list: 'Meine Maschinen',
    asset_list_header_1: 'Maschinen',
    asset_list_header_2: 'Bezeichnung',
    asset_list_header_3: 'Inventar-Nr.',
    asset_list_header_4: 'Standort',
    asset_list_header_5: 'Auftrag Nr.',
    user_list_header_1: 'Nutzer',
    user_list_header_2: 'Rolle',
    location: 'Standort',
    user_list_header_4: 'Profil',
    create_order_data: 'Ausgabe-Entwurf erstellen',
    order_number: 'Auftragsnummer*',
    mandatory_field: '*Pflichtfeld',
    max_character_length: 'Max. 192 Zeichen erlaubt.',
    place_of_action: 'Einsatzort',
    customer_company: 'Kunde / Firma',
    contact_for: 'Ansprechpartner',
    customer_telephone: 'Kundentelefon',
    create_draft: 'Entwurf erstellen',
    create_draft_inprogress: 'Entwurf wird erstellt…',
    save_draft: 'Entwurf wird gespeichert…',

    checkout_draft: 'Ausgabeentwurf',
    delete_draft: 'Entwurf löschen',

    save_order: 'Auftrag wird gespeichert…',
    order_details: 'Auftragsdetails',
    order_delete: 'Auftrag löschen',
    return_draft: 'Rückgabe-Entwurf',

    myMachines: 'Meine Maschinen',
    searchPlaceHolderWeb: 'Geräte oder Aufträge suchen…',
    searchPlaceHolderMobile: 'Suche…',
    searchUsersPlaceHolder: 'Benutzer suchen',
    allLocations: 'Alle Standorte',
    no_machine_created_yet: 'Es wurden noch keine Maschinen angelegt.',
    no_users_created_yet: 'Es wurden noch keine Benutzer angelegt.',
    no_machine_found_step1: 'Achten Sie darauf, dass alle Wörter richtig geschrieben sind.',
    no_machine_found_step2: 'Verwenden Sie allgemeinere Suchbegriffe.',
    no_machine_found_step3: 'Verwenden Sie weniger Suchbegriffe.',
    no_machine_head2: 'Probieren Sie Folgendes:',
    no_machine_head1: 'Keine Einträge gefunden',
    in: 'in',
    at: 'an',
    for: 'für',
    of: 'von',
    to: 'zu',
    one_machine: 'Eine Maschine',
    no_machine: 'Keine Maschinen',
    one_user: 'Eine Benutzer',
    no_users: 'Keine Benutzer',
    machines: 'Maschinen',
    users: 'Benutzer',
    createOrder: 'Auftrag erstellen',
    machine_found: 'Einträge gefunden',

    popUpDeleteOrder: 'Wirklich löschen?',
    popUpDeleteDescription: 'Wollen Sie den Ausgabeentwurf wirklich löschen? Eventuell neu per App aufgenommene Defekte werden mitgelöscht.',
    popUpDeleteDraft: 'Entwurf löschen',
    password_mismatch: 'Die Eingaben stimmen nicht überein!',
    machine_status: 'Status der Maschine geändert.',
    qrcode_intro_title: 'Maschinen schneller finden mit QR-Codes',
    qrcode_intro_description: 'Etikettieren Sie Ihre Maschinen mit individuellen QR-Codes. In der App lassen sich Maschinen einfach per QR-Code aufrufen — damit finden Sie Maschinen noch schneller! Und so geht’s:',
    qrcode_create_section_title: '1. QR-Codes erstellen & drucken',
    qrcode_create_section_text_1: 'Erzeugen Sie hier QR-Codes, die Sie als Aufkleber ausdrucken und an Ihren Maschinen anbringen können. Je DIN A4-Seite werden 24 individuelle QR-Codes erstellt.',
    qrcode_create_section_text_2: 'Hinweis',
    qrcode_create_section_text_3: 'Zum Druck eignen sich DIN A4-Folienetiketten mit 24 Etiketten im Format 63,5 x 33,9 mm, wie z.B. ',
    qrcode_create_section_text_4: 'Ultra-Resistente Etiketten von Avery Zweckform.',
    qrcode_carousel_title: '2. QR-Codes Maschinen zuweisen',
    qrcode_carousel_text_1: 'In der klickcheck-App weisen Sie dann Ihre Maschinen den individuellen QR-Codes zu:',
    order_deleted: 'Auftrag gelöscht',
    qrcode_carousel_item_1_title: 'QR-Code scannen',
    qrcode_carousel_item_1_description: 'Nutzen Sie die Funktion „QR-Code scannen“ um einen Ihrer QR-Codes einzulesen.',
    qrcode_carousel_item_1_image_path: '/qr_code_carousel/qr_code_carousel_1.png',
    qrcode_carousel_item_2_title: 'Neuer QR-Code',
    qrcode_carousel_item_2_description: 'Beim Scan eines neuen QR-Codes erscheint eine Benachrichtigung in der App.',
    qrcode_carousel_item_2_image_path: '/qr_code_carousel/qr_code_carousel_2.png',
    qrcode_carousel_item_3_title: 'Gescannten QR-Code zuordnen',
    qrcode_carousel_item_3_description: 'Im Anschluss können Sie entweder eine neue Maschine hinzufügen …',
    qrcode_carousel_item_3_image_path: '/qr_code_carousel/qr_code_carousel_3.png',
    qrcode_carousel_item_4_title: 'Gescannten QR-Code zuordnen',
    qrcode_carousel_item_4_description: 'oder eine bestehende Maschine aus der Liste auswählen, um diese mit dem QR-Code zu verknüpfen.',
    qrcode_carousel_item_4_image_path: '/qr_code_carousel/qr_code_carousel_4.png',
    qrcode_carousel_item_5_title: 'Fertig!',
    qrcode_carousel_item_5_description: 'Sie können die Maschine zukünftig per QR-Code Scan aufrufen.',
    qrcode_carousel_item_5_image_path: '/qr_code_carousel/qr_code_carousel_5.png',
    machine_failure_status: 'Fehler bei der Aktualisierung der Auftragsdaten',
    something_went_wrong: 'Fehler. Bitte versuchen Sie es nochmal.',
    news_letter: 'Ich möchte Neuigkeiten zu klickcheck per E-Mail erhalten. Ich kann diese Einwilligung jederzeit widerrufen',
    klickcheck_newsletter: 'klickcheck Newsletter',
    filter: 'Filtern',
    logged_in_as: 'Angemeldet als',
    support: 'Support',

    user_roles: 'Nutzerrechte',
    service_technician_role: 'Nutzer',
    company_admin_role: 'Admin',
    supportuser_role: 'Support',
    company: 'Unternehmen',
    all: 'Alle',
    clear_all_filters: 'Alle Filter zurücksetzen',
    show_all: 'show all',

    location_not_assigned: 'Standort nicht zugewiesen',
    no_machine_found_title: 'Keine Maschinen mit der aktuellen Suche/dem aktuellen Filter gefunden, aber es gibt andere',
    no_users_found_title: 'Keine User mit der aktuellen Suche/dem aktuellen Filter gefunden, aber es gibt andere',
    selected_filter: 'Ausgewählte Filter:',
    checkin_draft: 'Rücknahmeentwurf',
    available: 'Verfügbar',
    maintenance: 'Wartung',
    not_available: 'Nicht Verfügbar',
    user_added: 'Nutzer wurde hinzugefügt',
    user_exists: 'Benutzerkonto bereits angelegt',
    no_internet_connection: 'Keine Internetverbindung. Bitte versuchen Sie es erneut.',
    add_machine: 'Maschine hinzufügen',
    creating_new_machine: 'Neue Maschine erstellen',
    edit_machine: 'Maschine bearbeiten',
    machine_category: 'Maschinenkategorie',
    inventory_number: 'Maschinen-/ Inventarnummer',
    machine_name: 'Name',
    serial_number: 'Seriennummer',
    checklist_error_msg: 'Bitte Gerätekategorie wählen',
    inventory_number_missing: 'Bitte  eingeben Maschinen-/ Inventarnummer',
    name_missing: 'Bitte Namen eingeben',
    none_selected: 'Keinen ausgewählt',
    machine_added: 'Maschine hinzugefügt',
    err_duplicate_assetname: 'Eine Maschine mit diesem Namen existiert bereits',
    err_duplicate_invoicenumber: 'Eine Maschine mit dieser Inventarnummer existiert bereits',
    checklist_not_available: 'Maschinenkategorie nicht mehr verfügbar. Bitte wählen Sie eine andere.',
    message_asset_updated: 'Maschine erfolgreich geändert',
    asset_details_title: 'Detailansicht',

    details: 'Details',
    current_Status: 'Aktueller Status',
    last_change: 'Zuletzt bearbeitet',
    time_of_return: 'Rückgabezeit',

    new: 'Neu',
    pageNotAccessible: 'Kein Zugriff auf dieses Inventar.',
    pageNotFound: 'Die von Ihnen gesuchte Ressource existiert nicht oder wurde dauerhaft entfernt. Bitte kontaktieren Sie den Support.',

    access: 'Zugang',
    no_access: 'Kein Zugang',
    restricted_access: 'Beschränkter Zugang',
    locations: 'Standorte',
    no_restrictions: 'Keine Beschränkung',
    access_to_all: 'Zugang zu allen',
    only_current_location: 'Nur aktueller Standort',

    problem_feedback: 'Problem / Feedback',
    problem_feedback_missing: 'Bitte eingeben Problem / Feedback',
    send_message: 'Nachricht senden',
    feedback_message_success: 'Nachricht erfolgreich gesendet',
    feedback_telephone: 'Telefonnummer für Rückruf',
    save_in_profile: 'In meinem Profil speichern',
    name: 'Name',
    feedback_max_character_length: 'Max. 2000 Zeichen erlaubt.',
    user_deleted: 'Benutzer erfolgreich gelöscht',
    message: 'Nachricht'

  }
})

const lan = setAppLanguage()
if (lan) {
  strings.setLanguage(lan)
}

export { strings }

export function setAppLanguage () {
  const languages = window.navigator.languages
  if (languages && languages.length > 0) {
    for (let i = 0; i < languages.length; i++) {
      const language = languages[i]
      if (language.startsWith('de')) {
        return 'de'
      } else if (language.startsWith('en')) {
        return 'en'
      }
    }
  }
}
