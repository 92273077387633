import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Typography, Container } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined'

const StyledMenu = withStyles({
  paper: {
    boxShadow: '0 2px 20px 0 rgba(0, 41, 56, 0.2)'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    height: '48px',
    color: 'rgba(0, 0, 0, 0.7)',
    '&:hover': {
      backgroundColor: '#0d548c',
      color: 'white'
    },
    '&:focus': {
      backgroundColor: '#0d548c',
      color: 'white'
    }
  }
}))(MenuItem)

const useStyles = makeStyles(theme => ({
  buttonView: {
    display: 'flex',
    height: '48px',
    padding: '12px 20px 12px 20px'
  },
  locationIcon: {
    color: '#0d548c'
  },
  locationIconActive: {
    color: '#ffffff'
  },
  locationDiv: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '75%'
  },
  locationLabel: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.36px',
    color: '#002938',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  arrowUpImage: {
    color: 'white'
  },
  arrowDownImage: {
    color: 'rgba(0, 0, 0, 0.87)'
  }
}))

export default function CustomizedMenus (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = event => {
    setOpen(prevOpen => !prevOpen)
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (event) => {
    props.onSelectItem(event.target.value)
    setOpen(false)
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    prevOpen.current = open
  }, [open])

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={{ backgroundColor: open ? '#0d548c' : 'white' }}
        >
          <img className={classes.locationIcon} alt='' src={props.icon} style={{ filter: open ? '' : 'invert(46%) sepia(64%) saturate(1955%) hue-rotate(164deg) brightness(101%) contrast(101%)' }} />
          <props.icon className={open ? classes.locationIconActive : classes.locationIcon} />
          <div className={classes.locationDiv}>
            <Hidden xsDown>
              <Typography data-cy='menu-drop-down' variant='subtitle1' className={classes.locationLabel} style={{ color: open ? 'white' : '#002938' }}>{props.selectedItem}</Typography>
            </Hidden>
          </div>
          {open ? <ArrowDropUpOutlinedIcon className={classes.arrowUpImage} /> : <ArrowDropDownOutlinedIcon className={classes.arrowDownImage} />}
        </Container>
      </Hidden>
      <Hidden only={['lg', 'xl', 'md']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={open ? props.style.primary : props.style.sec}
        >
          <img className={classes.locationIcon} alt='' src={props.icon} />

          <div className={classes.locationDiv}>

            <Typography data-cy='menu-drop-down' variant='subtitle1' className={classes.locationLabel} style={{ color: open ? 'white' : 'white' }}>{props.selectedItem}</Typography>

          </div>
          {open ? <ArrowDropUpOutlinedIcon className={classes.arrowUpImage} style={{ color: 'white' }} /> : <ArrowDropDownOutlinedIcon className={classes.arrowDownImage} style={{ color: 'white' }} />}
        </Container>
      </Hidden>

      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {props.listItem.map((item) =>
          <StyledMenuItem
            data-cy={item.value}
            selected={(props.selectedId === item.id)}
            classes={{ root: classes.menuRoot, selected: classes.selected }}
            key={item.id} value={item.id}
            onClick={event => handleSelect(event)}
          >
            {item.value}
          </StyledMenuItem>)}
      </StyledMenu>
    </div>
  )
}
