import { Popover } from '@material-ui/core'
import Order from '../../../Orders/Order'

export default function OrderPopUp (props) {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={props.open}
      anchorEl={null}
      onClose={() => props.onClose()}
    >

      <div style={{ height: '600px', width: '300px' }}>
        <Order
          callingFrom={props.callingFrom}
          key={Math.random()}
          asset={props.asset}
          onResponse={(ResponseStatus) => props.onResponse(ResponseStatus)}
          companyId={props.asset.company.id}
          assetId={props.asset.id}
          assetStatus={props.asset.availability}
          onClose={() => props.onClose()}
          handleDataChanged={() => props.handleDataChanged()}
        />
      </div>
    </Popover>
  )
}
