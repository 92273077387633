import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'

export const Input = ({ name, label, error, type = 'text', ...rest }) => {
  return (
    <Grid item xs={12}>
      <TextField
        {...rest}
        id={name}
        label={label}
        name={name}
        variant='outlined'
        fullWidth
        error={!!error}
        type={type}
      />
      <FormHelperText error>{error}</FormHelperText>
    </Grid>
  )
}

export const RadioButton = ({ value, label, color = 'secondary' }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio color={color} />}
      label={label}
    />
  )
}

export const SuccessRadioButton = ({ value, label }) => {
  return (
    <FormControlLabel
      value={value}
      control={<GreenRadio />}
      label={label}
    />
  )
}

export const ErrorRadioButton = ({ value, label }) => {
  return (
    <FormControlLabel
      value={value}
      control={<ErrorRadio />}
      label={label}
    />
  )
}

export const CheckBox = ({ name, label, color = 'primary', ...rest }) => {
  return (
    <FormControlLabel
      control={<Checkbox data-cy='newsletter-checkbox' {...rest} color={color} name={name} />}
      label={label}
    />
  )
}

export const InputSelect = ({ name, label, classes, items, reference }) => {
  const { data } = reference.state

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleChange = event => {
    data[name] = event.target.value
    reference.setState({ data })
  }

  return (
    <FormControl variant='outlined' className={classes.inputSelect}>
      <InputLabel ref={inputLabel} htmlFor='outlined-val-simple'>
        {label}
      </InputLabel>
      <Select
        value={data[name]}
        onChange={handleChange}
        labelWidth={labelWidth}
        inputProps={{
          name: 'val',
          id: 'outlined-val-simple'
        }}
      >
        {items.map((menu) => <MenuItem key={data[name]} value={menu.value}>{menu.value}</MenuItem>)}
      </Select>

    </FormControl>
  )
}

export const SecondaryButton = withStyles(theme => ({
  root: {
    primaryTextColor: '#000000',
    backgroundColor: '#ffffff',
    border: '1px solid #0d548c',
    '&:hover': {
      backgroundColor: '#0d548c'
    }
  },
  label: {
    color: 'black'
  }
}))(Button)

export const GreenRadio = withStyles(theme => ({
  root: {
    color: '#49b157',
    '&$checked': {
      color: '#49b157'
    }
  },
  checked: {}

}))(Radio)
export const ErrorRadio = withStyles(theme => ({
  root: {
    color: '#ff0000',
    '&$checked': {
      color: '#ff0000'
    }
  },
  checked: {}

}))(Radio)
