import React from 'react'
import { checkGUID } from '../../helpers/api.js'
import { GuidComponentTypes, ScreenTypes, FormTypes } from '../../helpers/GenericHelpers'
import AssetDetails from '../AssetManagement/AssetDetails/AssetDetails'
import MobileContainer from '../LayoutComponents/MobileContainer'
import { strings } from '../../localizations/strings'
import { EditButton } from '../AssetManagement/AssetDetails/DetailsCommonComponents'
import * as CONSTANTS from '../../utils/url_constant.js'
import PageNotFound from '../LayoutComponents/PageNotFound'

export default function GuidHelper (props) {
  const [guidDetails, setGuidDetails] = React.useState(null)
  const [guidComponent, setGuidComponent] = React.useState(GuidComponentTypes.assetDetails)
  let machineData = null

  React.useEffect(() => {
    const params = { guid: props.history.location.pathname }
    checkGUID(params, (error, response) => {
      if (!error && response.data.status) {
        const data = response?.data?.data
        setGuidComponent(getGuidComponent(data))
        setGuidDetails(data)
      } else {
        if (response && (response.data.message === 'No access to this item.')) {
          setGuidComponent(GuidComponentTypes.pageNotAccessible)
        } else {
          setGuidComponent(GuidComponentTypes.pageNotFound)
        }
      }
    })

    const getGuidComponent = (data) => {
      switch (data.type) {
        case GuidComponentTypes.assetDetails:
          return GuidComponentTypes.assetDetails
        default:
          props.history.push({
            pathname: '/' + data.asset_guid,
            search: '?open=' + data.guid
          })
          return GuidComponentTypes.assetDetails
      }
    }
  }, [props.history])

  const handleArrowClick = () => {
    props.history.push(CONSTANTS.URLPATH_ASSET_LIST)
  }

  const handleEditClick = () => {
    if (machineData) {
      const machineInfo = {
        screenType: ScreenTypes.mobile,
        formType: FormTypes.editMachine,
        machineData,
        guid: props.history.location.pathname
      }
      props.history.push(CONSTANTS.URLPATH_EDIT_ASSET, machineInfo)
    }
  }

  const handleMachineInfo = (data) => {
    machineData = data
  }

  const renderMobileComponent = (renderComponent) => {
    return (
      <MobileContainer
        title={strings.asset_details_title}
        handleClick={() => handleArrowClick()}
        showIcon
        icon={renderComponent.icon}
      >
        {renderComponent.component}
      </MobileContainer>
    )
  }

  const renderComponent = () => {
    if (guidComponent === GuidComponentTypes.pageNotFound || guidComponent === GuidComponentTypes.pageNotAccessible) {
      if (window.innerWidth < 959) {
        renderComponent.component = <PageNotFound type={guidComponent} />
        renderComponent.icon = ''
        return (
          renderMobileComponent(renderComponent)
        )
      } else {
        return <PageNotFound type={guidComponent} />
      }
    } else if (guidComponent === GuidComponentTypes.assetDetails) {
      if (window.innerWidth < 959) {
        renderComponent.component = <AssetDetails guidDetails={guidDetails} history={props.history} handleMachineInfo={(data) => handleMachineInfo(data)} />
        renderComponent.icon = <EditButton onClick={handleEditClick} />
        return (
          renderMobileComponent(renderComponent)
        )
      } else {
        return (
          <AssetDetails guidDetails={guidDetails} history={props.history} />)
      }
    }
    return null
  }

  return (
    <div>{renderComponent()}</div>
  )
}
