import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Popover } from '@material-ui/core'
import DetailsBody from './DetailComponentBody'
import { strings } from '../../../../localizations/strings'
import AssetCreation from './../../AssetCreation'
import TitleBodyLayout from '../AssetDetailTitleBodyLayout'
import LabelValueLayout from '../AssetDetailLabelValueLayout'
import { AssetDetailsTitle, EditButton } from '../DetailsCommonComponents'
import { getScreenType, ScreenTypes, FormTypes } from '../../../../helpers/GenericHelpers'

export default function AssetDetailsDetailComponent (props) {
  const [open, setPopoverState] = React.useState(false)
  let isUpdate = false

  const handleEditClick = () => {
    setPopoverState(true)
  }

  const updateData = () => {
    isUpdate = true
  }

  const handleClose = () => {
    setPopoverState(false)
    if (isUpdate) { props.forceUpdate() }
  }

  const handleResponse = () => {
    setPopoverState(false)
    if (isUpdate) { props.forceUpdate() }
  }

  const getTitleComponent = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={1}
      >
        <LabelValueLayout
          label={<AssetDetailsTitle>{strings.details}</AssetDetailsTitle>}
          value={getScreenType() === ScreenTypes.desktop ? <EditButton onClick={handleEditClick} /> : ''}
        />

        <Grid item>
          <Popover
            anchorReference='anchorPosition'
            anchorPosition={{ top: 200, left: 650 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={open}
            onClose={handleClose}
          >
            {props && props.assetDetails && props.assetDetails.asset && open
              ? <AssetCreation key={Math.random()} openFrom={FormTypes.editMachine} machineData={props.assetDetails} onClose={handleClose} handleResponse={handleResponse} updateData={updateData} />
              : ''}
          </Popover>
        </Grid>
      </Grid>

    )
  }

  const getBodyComponent = () => {
    return (
      <DetailsBody asset={props.assetDetails?.asset} />
    )
  }

  return (
    <TitleBodyLayout
      title={getTitleComponent()}
      body={getBodyComponent()}
    />
  )
}
