import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '100%',
    backgroundColor: 'rgba(0, 174, 239, 0.05)'
  },
  image: {
    width: '80px',
    height: '80px'
  },
  content: {
    paddingLeft: '10px'
  }
}))

export default function ComplexGrid (props) {
  const classes = useStyles()

  const getGridTitleComponent = (gridTitle) => {
    return (
      <Grid
        item
        xs={gridTitle.gridSpaceXs}
        key={gridTitle.title}
      >
        <Typography data-cy={gridTitle.key} variant='subtitle1'>
          {gridTitle.title}
        </Typography>
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.content}>
        {props.gridTitles.map((gridTitle) => getGridTitleComponent(gridTitle))}
      </Grid>
    </div>
  )
}
