import { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { strings } from '../../localizations/strings.js'
import AssetsIcon from '../../assets/no_assets.svg'
import UsersIcon from '../../assets/images/no_users_icon.png'
import { ListType } from '../../helpers/GenericHelpers.js'

const useStyles = theme => ({
  container: {
    backgroundColor: '#f2f3f4',
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: '32px'
  },
  gridLayout: {
    marginTop: '32px'
  },
  imageStyle: {
    marginTop: '60px',
    margin: 'auto'
  },
  stepStyle: {
    size: '18px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#555f60'
  },
  listStyling: {
    paddingInlineStart: '20px'
  },
  fontBold: {
    fontWeight: '500'
  }

})

class NoMachine extends Component {
  renderSteps = (step, classes) => {
    return (
      <div>
        <Typography variant='h6' align='left' className={classes}>
          {step}
        </Typography>
      </div>
    )
  }

  render () {
    const isMachine = this.props.listType === ListType.Machines

    const { classes } = this.props
    let head1 = ''
    if (this.props.searchTerm.length) {
      head1 = (
        <span>
          <Typography data-cy='no-results-text' variant='h6' align='left' className={classes.stepStyle}>
            {isMachine ? strings.no_machine : strings.no_users}&nbsp;{strings.for}&nbsp;
            <font color='#606161' fontFamily='Roboto' className={classes.fontBold}><q>{this.props.searchTerm}</q></font>
          </Typography>
        </span>
      )
    } else {
      head1 = (
        <span>
          <Typography data-cy='no-results-text' variant='h6' align='center' className={classes.stepStyle}>
            {isMachine ? strings.no_machine_created_yet : strings.no_users_created_yet}
          </Typography>
        </span>
      )
    }

    return (
      <div className={classes.container}>
        <Grid container direction='column'>
          <Grid item className={classes.gridLayout}>
            {head1}

          </Grid>
          {(this.props.searchTerm.length > 0) &&
            <Grid item className={classes.gridLayout}>
              {this.renderSteps(strings.no_machine_head2, classes.stepStyle)}
            </Grid>}
          {(this.props.searchTerm.length > 0) &&
            <Grid item className={classes.gridLayout}>
              <div>
                <ul className={classes.listStyling}>
                  <li>
                    {this.renderSteps(strings.no_machine_found_step1, classes.stepStyle)}
                  </li>
                  <li>
                    {this.renderSteps(strings.no_machine_found_step2, classes.stepStyle)}
                  </li>
                  <li>
                    {this.renderSteps(strings.no_machine_found_step3, classes.stepStyle)}
                  </li>
                </ul>
              </div>
            </Grid>}
          <div className={classes.imageStyle}>
            <img src={isMachine ? AssetsIcon : UsersIcon} alt='assetsIcon' />
          </div>
        </Grid>
      </div>
    )
  }
}

NoMachine.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(NoMachine)
