import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FieldLayoutGrid from './FieldLayoutGrid'
import SuccessIcon from '../../../../../assets/ic_success.svg'
import { strings } from '../../../../../localizations/strings'

const useStyles = makeStyles(theme => ({
  sendInviteTitle: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    textAlign: 'left'
  }
}))

export default function SendInvite (props) {
  const classes = useStyles()

  return (
    <FieldLayoutGrid>
      <Typography className={classes.sendInviteTitle}>
        {strings.send_invite_title}
      </Typography>

      {props.isSendInvite
        ? (
        <Typography
          className={classes.sendInviteTitle}
          style={{ color: '#44ad43' }}
        >
          <img src={SuccessIcon} alt='' />
          {strings.send_mail_title}
        </Typography>
          )
        : (
        <Link
          className={classes.sendInviteTitle}
          underline='always'
          onClick={() => props.sendInvite()}
        >
          <div>{strings.resend_title}</div>
        </Link>
          )}
    </FieldLayoutGrid>
  )
}
