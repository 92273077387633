import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { Grid, Button } from '@material-ui/core'
import { strings } from '../localizations/strings'

const useStyles = () => ({
  root: {
    maxWidth: '350px',
    minWidth: '300px'
  },
  title: {
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '40px'
  },
  delete_title: {
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.4',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '40px'
  },
  subHeading: {
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.75',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  subtitle: {
    objectFit: 'contain',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '5px',
    marginLeft: '5px'
  },

  closeButton: {
    marginTop: '-40px',
    float: 'right',
    width: '25px',
    height: '25px'
  },
  goButton: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.25px',
    textAlign: 'right',
    float: 'right',
    marginTop: '30px',
    marginBottom: '10px'
  },
  cancelButton: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: '1.25px',
    textAlign: 'right',
    float: 'left',
    marginTop: '30px',
    marginBottom: '0px'
  },
  buttonLabel: {
    color: '#0d548c',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.6)'
    }
  },
  buttonDelete: {
    color: '#fa3900',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.6)'
    }
  },
  buttonRoot: {
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  }
})

class Popup extends Component {
  render () {
    const {
      classes,
      messageTitle,
      messageSubTitle,
      message,
      buttonTitle,
      onClose,
      onGo,
      onDelete,
      onCancel,
      openFor
    } = this.props

    return (
      <div className={classes.root}>
        <CloseIcon
          className={classes.closeButton} onClick={() => { onClose() }}
        />

        {openFor === strings.delete_user_title
          ? (
          <div>
            <p className={classes.delete_title}>{messageTitle}</p>
            <p className={classes.subtitle}>{message.split('\n').map((item, key) => {
              return <span key={key}>{item}<br /></span>
            })}
            </p>
            <Grid
              xs={12}
              sm={12}
              md={12}

              className={this.props.classes.title}
              item
            >
              <Button
                data-cy='popup-button'
                variant='text'
                className={classes.goButton}
                classes={{ label: classes.buttonDelete, root: classes.buttonRoot }}
                onClick={() => { onDelete() }}
              >
                {buttonTitle}
              </Button>

            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              className={this.props.classes.title}
              item
            >
              <Button
                variant='text'
                className={classes.cancelButton}
                classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
                onClick={() => { onCancel() }}
              >
                {strings.cancel}
              </Button>
            </Grid>
          </div>)
          : (
            <div>
              <p className={classes.title}>{messageTitle}</p>
              <p className={classes.subHeading}>{messageSubTitle.split('\n').map((item, key) => {
                return <span key={key}>{item}<br /></span>
              })}
              </p>
              <p className={classes.subtitle}>{message.split('\n').map((item, key) => {
                return <span key={key}>{item}<br /></span>
              })}
              </p>
              <Button
                variant='text'
                className={classes.goButton}
                classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
                onClick={() => { onGo() }}
              >
                {buttonTitle}
              </Button>
            </div>)}

      </div>
    )
  }
}

Popup.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(Popup)
