import { strings } from '../../localizations/strings.js'
import * as CONSTANTS from '../../utils/url_constant.js'
import { ReactComponent as IconQRCode } from '../../assets/images/qr_code.svg'
import { ReactComponent as IconAssetList } from '../../assets/images/asset_list.svg'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import BusinessIcon from '@material-ui/icons/Business'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

export const ASSET_LIST = {
  path: CONSTANTS.URLPATH_ASSET_LIST,
  sidebarName: strings.asset_list,
  navbarName: strings.asset_list,
  icon: IconAssetList
}

export const QRCODE = {
  path: CONSTANTS.URLPATH_QRCODE,
  sidebarName: strings.title_create_qrcode,
  navbarName: strings.title_create_qrcode,
  icon: IconQRCode
}

export const FEEDBACK = {
  path: CONSTANTS.URLPATH_FEEDBACK,
  sidebarName: strings.problem_feedback,
  navbarName: strings.problem_feedback,
  icon: ContactSupportIcon
}

export const SELECTCOMPANY = {
  path: CONSTANTS.URLPATH_SELECTCOMPANY,
  sidebarName: strings.company,
  navbarName: strings.company,
  icon: BusinessIcon
}

export const USERLIST = {
  path: CONSTANTS.URLPATH_USERLIST,
  sidebarName: strings.menu_user_administartion,
  navbarName: strings.menu_user_administartion,
  icon: AccountCircleIcon
}
