import React from 'react'
import { withStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/ArrowBack'

const useStyles = theme => ({
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#212121',
    marginTop: '20px'

  },

  menuButton: {
    color: '#000000'
  },
  toolBar: {
    backgroundColor: '#f5f8fa',
    height: '56px'
  },
  appBartitle: {
    width: '80%',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: '#000000'
  }

})
class MobileContainer extends React.Component {
  handleArrowClick = () => {
    this.props.handleClick()
  }

  getToolbar = (classes) => {
    return (
        <AppBar position='static'>
          <Toolbar data-cy='toolbar' className={classes.toolBar}>
            <IconButton data-cy='mobile-toolbar-button' edge='start' className={classes.menuButton} aria-label='menu' onClick={this.handleArrowClick}>
              <MenuIcon />
            </IconButton>
            <Typography data-cy='mobile-toolbar-text' className={classes.appBartitle}>
              {this.props.title}
            </Typography>

            {this.props.showIcon && this.props.icon}

          </Toolbar>
        </AppBar>
    )
  }

  render () {
    const { classes } = this.props

    return (
        <div>
          {this.getToolbar(classes)}

          {this.props.children}

        </div>
    )
  }
}

export default withStyles(useStyles)(MobileContainer)
