export const FA_MAIN_MENU = 'main_menu'
export const FA_DATA_PRIVACY = 'data_privacy'
export const FA_LOGGED_OUT = 'logged_out'
export const FA_FEEDBACK_SELECTED = 'feedback_selected'
export const FA_FEEDBACK_CONTINUED = 'feedback_continued'
export const FA_FEEDBACK_ABORTED = 'feedback_aborted'
export const FA_FEEDBACK_SENT = 'feedback_sent'

export const FA_E_LOGOUT = 'error_logout'
export const FA_E_GET_USER_DETAILS = 'error_get_user_details'

// Forgot passwprd events
export const FA_FORGOT_PASSWORD_OPENED = 'forgot_password_opened'
export const FA_FORGOT_PASSWORD_EMAIL_ENTERED = 'forgot_password_entered_email'
export const FA_E_FORGOT_PASSWORD = 'forgot_password_error'
export const FA_FORGOT_PASSWORD_SUBMITTED = 'forgot_password_submitted'

// Password reset events
export const FA_RESET_PASSWORD_OPENED_INVITE = 'password_setting_opened_on_invite'
export const FA_RESET_PASSWORD_OPENED_FORGOT_PASSWORD = 'password_setting_opened_on_forgot_password'
export const FA_RESET_PASSWORD_FIRST_PASSWORD_ENTERED = 'password_setting_entered_1st'
export const FA_RESET_PASSWORD_SECOND_PASSWORD_ENTERED = 'password_setting_entered_2nd'
export const FA_E_RESET_PASSWORD = 'password_setting_error'
export const FA_RESET_PASSWORD_SUCCESS = 'password_set'

// Login events
export const FA_LOGIN_OPENED = 'login_opened'
export const FA_LOGIN_EMAIL_ENTERED = 'login_entered_email'
export const FA_LOGIN_PASSWORD_ENTERED = 'login_entered_password'
export const FA_E_LOGIN_FAILED = 'login_error'
export const FA_LOGIN_SUCCESS = 'logged_in'
export const FA_E_INVALID_EMAIL = 'error_invalid_email'

export const FA_LIST_VIEW = 'list_view'
export const FA_NUMBER_OF_MACHINES = 'number_of_machines'
export const FA_E_GET_ASSETS = 'error_get_assets'

export const FA_SEARCH = 'search'

export const FA_BRANCH_CHANGED = 'branch_changed'
export const FA_E_GET_BRANCHES = 'error_get_branches'
export const FA_E_SAVE_BRANCH = 'error_save_branch'

export const FA_ORDER_OPENED = 'order_opened'
export const FA_ORDER_ENTERED_ORDER_NUMBER = 'order_entered_order_number'
export const FA_ORDER_ENTERED_CUSTOMER = 'order_entered_customer'
export const FA_ORDER_ENTERED_LOCATION = 'order_entered_location'
export const FA_ORDER_ENTERED_CONTACT = 'order_entered_contact'
export const FA_ORDER_ENTERED_PHONE = 'order_entered_phone'
export const FA_ORDER_ERROR = 'order_error'
export const FA_ORDER_CLOSED = 'order_closed'
export const FA_ORDER_SAVED = 'order_saved'
export const FA_ORDER_DELETED = 'order_deleted'

// Profile info events
export const FA_PROFILE_SETTING = 'profile_settings'
export const FA_PROFILE_SALUTATION = 'profile_salutation_set'
export const FA_PROFILE_FIRST_NAME = 'profile_first_name_set'
export const FA_PROFILE_LAST_NAME = 'profile_last_name_set'
export const FA_PROFILE_PHONE_SET = 'profile_phone_set'
export const FA_PROFILE_SAVED = 'profile_saved'

// QR Code events
export const FA_QR_CODES_OPENED = 'qr_codes_opened'
export const FA_QR_CODES_DOWNLOADED = 'qr_codes_downloaded'

// Mail chimp events
export const FA_NEWSLETTER_SUBSCRIBED = 'newsletter_subscribed'
export const FA_NEWSLETTER_UNSUBSCRIBED = 'newsletter_unsubscribed'

// Create/Edit info events

export const FA_USER_LIST = 'user_list'
export const FA_ERROR_GET_USER = 'error_get_users'
export const FA_USER_CREATE = 'user_create'
export const FA_USER_EDIT = 'user_edit'
export const FA_USERROLE_SET = 'user_role_set'
export const FA_USERBRANCH_SET = 'user_branch_set'
export const FA_USERCOMPANY_SET = 'user_company_set'
export const FA_USERSALUTATION_SET = 'user_salutation_set'
export const FA_USERFIRSTNAME_SET = 'user_first_name_set'
export const FA_USERLASTNAME_SET = 'user_last_name_set'
export const FA_USERPHONESET = 'user_phone_set'
export const FA_USEREMAIL_SET = 'user_email_set'
export const FA_USER_CREATED = 'user_created'
export const FA_USER_EDITED = 'user_edited'
export const FA_USERINVITE_RESENT = 'user_invite_resent'
export const FA_USERDELETE_OPEN = 'user_deactivate_confirmation_opened'
export const FA_USERDELETED = 'user_deactivated'

// Filter user event
export const FA_FILTERUSER_OPEN = 'filter_settings_opened'
export const FA_FILTERUSER_RESET = 'filter_reset'
export const FA_FILTERUSER_SETRIGHTS = 'filter_for_user_rights_set'
export const FA_FILTERUSER_REMOVERIGHTS = 'filter_for_user_rights_removed'
export const FA_FILTERUSER_BRANCH_SET = 'filter_for_branch_set'

export const FA_SHOW_ALL = 'show all'
export const FA_NOT_ASSIGNED = 'not assigned'
