import Container from '@material-ui/core/Container'
import Joi from 'joi-browser'
import { withStyles } from '@material-ui/styles'
import Form from '../../common/form'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { forgetPasswordAPI } from '../../helpers/api.js'
import { strings } from '../../localizations/strings.js'
import { MAIL_FORMAT } from '../../utils/Matcher.js'
import Response from '../../common/Response'
import ResponseFailure from '../../common/ResponseFailure'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import { callFirebaseEvent } from '../../helpers/GenericHelpers.js'

const useStyles = theme => ({
  root: {},
  pageLayout: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
  middleLayoutss: {
    flex: 1
  },
  formControl: {
    margin: theme.spacing(1)
  },
  group: {
    margin: theme.spacing(1, 0),
    flexDirection: 'row'
  },
  plainButton: {
    color: '#555f60',
    textAlign: 'left'
  },
  description: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    marginBottom: '10px',
    fontWeight: '300'
  },
  border: {
    borderColor: '#f2f3f4'
  },
  button: {
    color: '#555f60',
    height: '48px',
    '& .MuiButton-label': {
      color: '#303030'
    }
  },
  demo: {
    marginTop: '4%',
    paddingTop: '2%',
    background: 'white',
    border: '10px',
    display: 'block',
    boxShadow: '0 2px 20px 0 rgba(0, 41, 56, 0.1)',
    flex: '1',
    flexDirection: 'column',
    paddingBottom: '30px'
  },
  dividerPadding: {
    marginBottom: '10px'
  },
  topPadding10: {
    marginTop: '10px'
  },
  background: {
    background: '#f5f8fa'
  },
  submitButton: {
    paddingTop: '30px'
  }
})

class ForgotPassword extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        email:
          props.location.state &&
          props.location.state.data &&
          props.location.state.data.email
            ? props.location.state.data.email
            : ''
      },
      response: '',
      errors: {},
      open: false,
      setOpen: false,
      message_title: '',
      message: '',
      reset_data: ''
    }
  }

  schema = {
    email: Joi.string()
      .email()
      .required()
      .regex(MAIL_FORMAT)
      .label('email')
      .error(errors => {
        return {
          message: strings.email_id_error_msg
        }
      })
  }

  closePopUp = () => {
    this.setState({
      open: false
    })
    if (this.state.reset_data !== '') {
      this.setState({ reset_data: '' })
      this.props.history.push('/forgot_password')
    } else this.props.history.push('/login')
  }

  linkAction = () => {
    this.closePopUp()
  }

  doSubmit = (errors) => {
    if (errors === null) {
      const params = {
        email: this.state.data.email
      }
      forgetPasswordAPI(params, (error, response) => {
        if (error) {
          callFirebaseEvent(FA_CONSTANTS.FA_E_FORGOT_PASSWORD, { description: error })
          // return window.alert(error)
        } else {
          if (response.data.status === 0) {
            callFirebaseEvent(FA_CONSTANTS.FA_E_FORGOT_PASSWORD, { description: response.data.message })
            this.setState({
              open: true,
              message_title: '',
              message: response.data.message,
              token: response.data.token,
              reset_data: 'wrong_data'
            })
          } else {
            callFirebaseEvent(FA_CONSTANTS.FA_FORGOT_PASSWORD_SUBMITTED)
            this.setState({
              open: true,
              message_title: response.data.message_title,
              message: response.data.message,
              link: true,
              linkTitle: 'OK',
              token: response.data.token
            })
          }
        }
      })
    }
  }

  focusEvent = input => {
    if (this.state.errors.email === null) {
      callFirebaseEvent(FA_CONSTANTS.FA_FORGOT_PASSWORD_EMAIL_ENTERED)
    }
  }

  componentDidMount () {
    if (
      this.props.location.state &&
      this.props.location.state.password_exp !== undefined
    ) {
      this.setState({
        reset_data: this.props.location.state,
        message_title: this.props.location.state.message_title,
        message: this.props.location.state.message
      })
    }
    callFirebaseEvent(FA_CONSTANTS.FA_FORGOT_PASSWORD_OPENED)
  }

  checkResponeFailure = () => {
    if (this.state.reset_data !== '') {
      return (
        <ResponseFailure
          open
          setOpen
          messageTitle={this.state.message_title}
          message={this.state.message}
          closePopUp={this.closePopUp}
        />
      )
    }
  }

  checkResponeSuccess = () => {
    if (this.state.reset_data === '') {
      return (
        <Response
          open={this.state.open}
          setOpen={this.state.setState}
          message_title={this.state.message_title}
          message={this.state.message}
          closePopUp={this.closePopUp}
        />
      )
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.pageLayout}>
        <Header id="header"/>
        <div className={classes.middleLayoutss} style={{ flex: 1 }}>
          <Container maxWidth="sm" className={classes.demo}>
            <form data-cy="forgot-password-form">
              <Typography
                variant="h5"
                component="h5"
                align="left"
                className={classes.description}
              >
                {strings.forgot_password_description}
              </Typography>
              <Grid container direction="row" justifyContent="center" spacing={3} data-cy="reset-pass-email">
                {this.renderInput('email', strings.email, classes)}
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={0}
                className={classes.submitButton}
              />
              {this.renderButton(
                strings.forgot_password_submit_button,
                classes.button,
                'submit'
              )}
            </form>
          </Container>
        </div>
        <Footer id="footer"/>
        {this.checkResponeFailure()}
        {this.checkResponeSuccess()}

      </div>

    )
  }
}

export default withStyles(useStyles)(ForgotPassword)
