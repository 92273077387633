import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Container, Popover } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined'
import * as CONSTANTS from '../../utils/url_constant.js'
import User from '../../components/ManageUser/UserList/User'
import { strings } from '../../localizations/strings.js'
import AssetCreation from '../../components/AssetManagement/AssetCreation.jsx'
import {
  ScreenTypes,
  FormTypes
} from '../../helpers/GenericHelpers.js'

const useStyles = makeStyles(() => ({
  buttonView: {
    display: 'flex',
    height: '48px',
    padding: '12px 20px 12px 20px',
    alignItems: 'center'
  },
  locationIcon: {
    color: '#0d548c',
    width: 22,
    flexShrink: 0
  },
  locationIconActive: {
    color: '#ffffff',
    width: 22,
    flexShrink: 0
  },
  locationDiv: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '75%',
    paddingLeft: '5px'
  },
  locationLabel: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.36px',
    color: '#002938',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  arrowUpImage: {
    color: 'white'
  },
  arrowDownImage: {
    color: 'rgba(0, 0, 0, 0.87)'
  }
}))
export default function AddItemForm (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (event) => {
    setOpen((prevOpen) => !prevOpen)
    setAnchorEl(event.currentTarget)
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    prevOpen.current = open
  }, [open])

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }
  const onResponse = (ResponseStatus) => {
    handleClose()
    props.onResponse(ResponseStatus)
  }

  if (window.innerWidth < 959) {
    const state = null
    if (open) {
      if (props.label === strings.add_user) {
        props.parentProps.push(CONSTANTS.URLPATH_CREATEUSER, state)
      }
      if (props.label === strings.add_machine) {
        const machineInfo = {
          screenType: ScreenTypes.mobile,
          formType: FormTypes.addMachine
        }
        props.parentProps.push(CONSTANTS.URLPATH_CREATE_ASSET, machineInfo)
      }
    }
  }

  const view =
    props.label === strings.add_user
      ? (
      <User
        openFrom={FormTypes.addUser}
        onResponse={(ResponseStatus) => onResponse(ResponseStatus)}
        onClose={() => handleClose()}
      />
        )
      : (
      <AssetCreation
        key={Math.random()}
        openFrom={FormTypes.addMachine}
        parentProps={props.parentProps}
        onResponse={(ResponseStatus) => onResponse(ResponseStatus)}
        onClose={() => handleClose()}
      />
        )

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={{ backgroundColor: open ? '#0d548c' : 'white' }}
        >
          <props.icon
            className={open ? classes.locationIconActive : classes.locationIcon}
          />
          <div className={classes.locationDiv}>
            <Hidden xsDown>
              <Typography
                data-cy='add-item-button'
                variant='subtitle1'
                className={classes.locationLabel}
                style={{ color: open ? 'white' : '#002938' }}
              >
                {props.label}
              </Typography>
            </Hidden>
          </div>
          {open
            ? (
            <ArrowDropUpOutlinedIcon className={classes.arrowUpImage} />
              )
            : (
            <ArrowDropDownOutlinedIcon className={classes.arrowDownImage} />
              )}
        </Container>
      </Hidden>
      <Hidden only={['lg', 'xl', 'md']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={open ? props.style.primary : props.style.sec}
        >
          <img className={classes.locationIcon} alt='' src={props.icon} />

          <div className={classes.locationDiv}>
            <Typography
              data-cy='add-item-button'
              variant='subtitle1'
              className={classes.locationLabel}
              style={{ color: open ? 'white' : 'white' }}
            >
              {props.label}
            </Typography>
          </div>
          {open
            ? (
            <ArrowDropUpOutlinedIcon
              className={classes.arrowUpImage}
              style={{ color: 'white' }}
            />
              )
            : (
            <ArrowDropDownOutlinedIcon
              className={classes.arrowDownImage}
              style={{ color: 'white' }}
            />
              )}
        </Container>
      </Hidden>

      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id='simple-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginBottom: '110px' }}
      >
        {view}
      </Popover>
    </div>
  )
}
