export const qrCodePaperShopLink = 'https://shop.avery-zweckform.com/ultra-resistente-etiketten-l7912-10-kaufen'
export const SUPPORT = 'SUPPORT'
export const TECHNICIAN = 'TECHNICIAN'
export const ADMIN = 'ADMIN'
export const USER = 'USER'
export const SUPPORT_USER = 'SUPPORT_USER'
export const COMPANY_ADMIN = 'COMPANY_ADMIN'
export const ERROR_DUPLICATE_ASSETNAME = 'Duplicate asset name'
export const ERROR_DUPLICATE_INVENOTRY_NUMBER = 'Duplicate inventory no'
export const ALREADY_DELETED_CHECKLIST_MESSAGE = 'Machine category not available anymore. Please select another one.'
export const LOGOUT = 'logout'
export const UNAUTHENTICATED_CODE = 401
