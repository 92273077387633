import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AssetImage from '../../AssetImage'

const useStyles = makeStyles(theme => ({
  content: {
    wordBreak: 'break-word',
    padding: '40px 0px 20px 32px ',
    backgroundColor: 'rgba(0, 174, 239, 0.05)'
  },
  assetContent: {
    maxWidth: '60%',
    marginTop: '5px',
    '@media(min-width: 960px)': {
      paddingLeft: '40px !important'
    },
    '@media(max-width: 959px)': {
      paddingLeft: '16px !important'
    }
  },
  assetName: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '1.5rem',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  assetDetails: {
    color: '#4d4d4d',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '1.5rem'
  }
}))
export default function AssetDetailsHeader (props) {
  const classes = useStyles()

  const handleOnImageClick = event => {
    event.stopPropagation()
    const guid = event.image
    if (guid) {
      props.previewAssetImage(guid)
    }
  }

  return (
    props.assetDetails &&
      <Grid
        container
        className={classes.content}
      >
        <Grid item>
          <AssetImage
            onClick={(e) => {
              let guid
              try {
                guid = props.assetDetails.asset.image.guid
              } catch {
                return
              }
              e.image = guid
              handleOnImageClick(e)
            }}
            width='80px' height='80px'
            imgSrc={props.assetDetails.asset.image}
            assetStatus={props.assetDetails.asset.status}
            assetAvailability={props.assetDetails.asset.availability}
          />
        </Grid>
        <Grid item className={classes.assetContent}>
          <Typography data-cy='asset-detail-asset-name' variant='subtitle1' className={classes.assetName}>
            {props.assetDetails.asset.name}
          </Typography>
          <Typography data-cy='asset-detail-checklist-name' variant='subtitle1' className={classes.assetDetails}>
            {props.assetDetails.check_list.name}
          </Typography>
          <Typography data-cy='asset-detail-inventory-number' variant='subtitle1' className={classes.assetDetails}>
            {props.assetDetails.asset.inventory_number}
          </Typography>
        </Grid>
      </Grid>
  )
}
