import {
  TextSubjectImage,
  AssetDetailsLabel,
  AssetDetailsValue
} from '../../DetailsCommonComponents'
import AssetCheckLayout from './AssetCheckLayout'

export default function AssetDetailsCheckItemGrid (props) {
  const getTypeIconComponent = () => {
    return <TextSubjectImage />
  }

  const getCheckValuesComponents = () => {
    const components = [<AssetDetailsLabel key={props.checkItem.name}>{props.checkItem.name}</AssetDetailsLabel>]
    if (props.checkItem.check_value.value &&
        props.checkItem.check_value.value !== '') {
      components.push(
        <AssetDetailsValue key={props.checkItem.check_value.value}>
          {props.checkItem.check_value.value}
        </AssetDetailsValue>)
    }

    return components
  }

  return (
    <AssetCheckLayout
      assetTypeIcon={getTypeIconComponent()}
      checkValueComponents={getCheckValuesComponents()}
    />
  )
}
