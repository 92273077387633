// A react component - Renders the signup form
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import loader from '../../assets/loader.gif'
import { strings } from '../../localizations/strings.js'
import Container from '@material-ui/core/Container'
import { signUpAPI } from '../../helpers/api.js'
import { isLoggedIn } from '../../utils/Authentication.js'
import Form from '../../common/form'
import Joi from 'joi-browser'
import { MAIL_FORMAT } from '../../utils/Matcher.js'
import Response from '../../common/Response'
import * as CONSTANTS from '../../utils/url_constant.js'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

const useStyles = theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1)
  },
  group: {
    margin: theme.spacing(1, 0),
    flexDirection: 'row'
  },
  items: {},
  plainButton: {
    color: '#555f60',
    textAlign: 'left'
  },
  description: {
    textAlign: 'center',
    padding: '10px'
  },
  border: {
    borderColor: '#f2f3f4'
  },
  button: {
    marginTop: '-20px',
    height: '48px',
    '& .MuiButton-label': {
      color: '#303030'
    }
  },
  demo: {
    paddingTop: '10px',
    marginBottom: '150px'
  },
  dividerPadding: {
    marginBottom: '10px'
  },
  topPadding10: {
    marginTop: '10px'
  },
  signupDescription: {
    marginTop: '15px',
    marginBottom: '20px'
  },
  signupLoginDescription: {
    color: '#555f60'
  }
})

class SignUp extends Form {
  constructor (props) {
    super(props)
    this.state = {
      data: {
        newsletter: false,
        tos: false,
        salutation: '',
        companyName: '',
        firstname: '',
        lastname: '',
        email: '',
        tele: ''
      },
      errors: {},
      windowWidth: 0,
      windowHeight: 0,
      loading: false,
      open: false,
      setOpen: false,
      message_title: '',
      message: ''
    }
    if (isLoggedIn()) {
      this.props.history.push('/')
    }
  }

  schema = {
    email: Joi.string()
      .email()
      .required()
      .regex(MAIL_FORMAT)
      .label(strings.email)
      .error(errors => {
        return {
          message: strings.email_id_error_msg
        }
      }),

    salutation: Joi.string()
      .required()
      .label(strings.salutation)
      .error(errors => {
        return {
          message: strings.salutation_error_msg
        }
      }),

    companyName: Joi.string()
      .required()
      .label(strings.company_name)
      .error(errors => {
        return {
          message: strings.company_name_error_msg
        }
      }),

    firstname: Joi.string()
      .required()
      .label(strings.first_name)
      .error(errors => {
        return {
          message: strings.first_name_error_msg
        }
      }),

    lastname: Joi.string()
      .required()
      .label(strings.last_name)
      .error(errors => {
        return {
          message: strings.last_name_error_msg
        }
      }),

    tele: Joi.number()
      .allow('')
      .label(strings.telephone),

    tos: Joi.boolean()
      .invalid(false)
      .error(errors => {
        return {
          message: strings.data_privacy_error_msg
        }
      }),

    newsletter: Joi.boolean()
  }

  closePopUp = () => {
    this.setState({
      open: false
    })
    this.props.history.push('/login')
  }

  doSubmit = () => {
    // Call the server
    const isAndroid = window.Android
    const isiOS = window.iOS
    const {
      salutation,
      firstname,
      lastname,
      companyName,
      email,
      tele,
      tos,
      newsletter
    } = this.state.data
    const params = {
      salutation,
      first_name: firstname,
      last_name: lastname,
      company_name: companyName,
      email,
      phone: tele,
      tos,
      news_letter: newsletter
    }
    if (navigator.onLine) {
      signUpAPI(params, (error, response) => {
        if (error) {
          window.alert(error.response.data.message)
        } else {
          const { status, message } = response.data
          if (isAndroid) {
            if (status === 1) this.sendDataToAndroid('success')
            else this.sendDataToAndroid(message)
          } else if (isiOS === 'iOS') {
            if (status === 1) this.sendDataToiOS('success')
            else this.sendDataToiOS(message)
          } else {
            this.setState({
              open: true,
              setOpen: true,
              message_title: response.data.message_title,
              message: response.data.message
            })
          }
        }
      })
    } else {
      window.alert('No Internet')
    }
  }

  handleCheckboxClick = name => event => {
    const { data, errors } = this.state
    data[name] = event.target.checked
    if (name === 'tos') {
      if (!event.target.checked) errors[name] = strings.data_privacy_error_msg
      else delete errors[name]
      this.setState({ data, errors })
    }
  }

  // salutation on click
  handleRadioClick = event => {
    const { name, value } = event.target
    const { data, errors } = { ...this.state }
    data[name] = value
    delete errors[name]
    this.setState({ data, errors })
  }

  handleLinkClick = linkName => {
    if (window.Android) { window.Android.redirectNativeScreen('login', 'redirect') } else if (window.iOS) {
      window.webkit.messageHandlers.dismissTappedFromWeb.postMessage('')
    } else {
      this.props.history.push({
        pathname: linkName
      })
    }
  }

  // this functions will be used to send data from react to Mobile app when Page is loaded in Mobile webview.
  sendDataToAndroid = data => {
    window.Android.communicateWithNative('signUp', data)
  }

  sendDataToiOS = data => {
    try {
      window.webkit.messageHandlers.registerSuccess.postMessage(data)
    } catch (err) {}
  }

  render () {
    const { classes } = this.props
    const { errors } = { ...this.state }
    let renderData
    const dataPrivacy = (
      <span>
        {strings.data_privacy_1}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={CONSTANTS.URLPATH_TNC}
        >
          {strings.data_privacy_2}
        </a>
        {strings.data_privacy_3}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={CONSTANTS.URLPATH_DATAPRIVACY}
        >
          {strings.data_privacy_4}
        </a>
        {strings.data_privacy_5}
      </span>
    )
    const login = <span> &nbsp;{strings.login_head2} </span>
    if (this.state.loading) {
      renderData = (
        <div>
          {' '}
          <img
            src={loader}
            style={{ margin: 'auto', display: 'block' }}
            alt={loader}
          />
        </div>
      )
    } else {
      renderData = (
        <div>
          <Header id='header' />
          <Container maxWidth='sm' className={classes.demo}>
            <form>
              {/* <Typography variant="h4" component="h1" align="center">
                Sign Up
          </Typography> */}
              <p />
              <Grid container className={classes.signupLoginDescription}>
                <Grid item xs='auto'>
                  {' '}
                  {strings.login_head1}{' '}
                </Grid>
                <Grid item xs='auto'>
                  {this.renderLink('login', login, classes.link)}
                </Grid>
              </Grid>
              <Grid container className={classes.signupDescription}>
                <Grid item xs='auto'>
                  {' '}
                  {strings.signup_description}{' '}
                </Grid>
              </Grid>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>{strings.salutation}</FormLabel>
                <RadioGroup
                  aria-label='gender'
                  name='salutation'
                  className={classes.group}
                  onChange={this.handleRadioClick}
                >
                  {this.renderRadioButton('Mrs', strings.salutation_mrs)}

                  {this.renderRadioButton('Mr', strings.salutation_mr)}
                </RadioGroup>
                <FormHelperText error>
                  {errors.salutation !== null ? errors.salutation : ' '}
                </FormHelperText>
              </FormControl>

              <Grid container direction='row' justifyContent='center' spacing={3}>
                {this.renderInput('companyName', strings.company_name, classes)}
                {this.renderInput('firstname', strings.first_name, classes)}
                {this.renderInput('lastname', strings.last_name, classes)}

                <Grid item xs={12}>
                  {this.renderInput('email', strings.e_mail, classes)}
                  <FormHelperText>{strings.e_mail_usertype}</FormHelperText>
                </Grid>
                {this.renderInput('tele', strings.telephone, classes)}

                <Grid item xs={12}>
                  <FormControl
                    component='fieldset'
                    className={classes.formControl}
                  >
                    <FormGroup>
                      {this.renderCheckBox(
                        'newsletter',
                        strings.email_subscribe
                      )}
                      {this.renderCheckBox('tos', dataPrivacy)}
                      {
                        <FormHelperText error>
                          {errors.tos !== null ? errors.tos : ' '}
                        </FormHelperText>
                      }
                    </FormGroup>
                  </FormControl>
                </Grid>
                {this.renderButton(strings.submit, classes.button)}
              </Grid>
            </form>
          </Container>
          <Footer id='footer' />
          <Response
            open={this.state.open}
            setOpen={this.state.setState}
            message_title={this.state.message_title}
            message={this.state.message}
            closePopUp={this.closePopUp}
          />
        </div>
      )
    }

    return <div>{renderData}</div>
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(useStyles)(SignUp)
