import Form from '../../../../common/form'
import { strings } from '../../../../localizations/strings.js'
import Snackbar from '../../../LayoutComponents/Snackbar'

import Modal from '../../../../common/Modal'
import PopUp from '../../../../common/Popup'
import { callFirebaseEvent, FormTypes, sleep } from '../../../../helpers/GenericHelpers'

import {
  getData
} from '../../../../utils/Authentication'
import { deleteUserAPI } from '../../../../helpers/api'

import * as FA_CONSTANTS from '../../../../utils/AnalyticsConstants'
import MobileContainer from '../../../LayoutComponents/MobileContainer'

import AddEditUserContainer from './AddEditUserContainer'

class AddEditUserSmall extends Form {
  constructor (props) {
    super(props)
    this.state = {
      setProgressBar: false,
      isModalOpen: false,
      showSnackBar: false

    }
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  componentDidMount () {
    const user = (this.props.location.state)

    if (user != null) {
      callFirebaseEvent(FA_CONSTANTS.FA_USER_EDIT)
      window.history.pushState('Data', 'Title', '/edituser')
    }
  }

  doSubmit = (errors, key) => {
  }

  doDelete = () => {
    callFirebaseEvent(FA_CONSTANTS.FA_USERDELETE_OPEN)
    this.setState({
      isModalOpen: true
    })
  }

  sendInvite = (event) => {
  }

  handleDeleteUser = () => {
    const user = (this.props.location.state)
    if (this.state.showSnackBar) {
      this.setState({
        showSnackBar: false
      })
    }
    const params = {
      id: user.id,
      companyId: user.company_id,
      token: getData('acccess_token')
    }
    deleteUserAPI(params, (error, response) => {
      if (error) {
        let errorMessage = (error?.response?.data?.message) ? error?.response?.data?.message : strings.something_wrong
        if (error.message === 'Network Error') {
          errorMessage = strings.no_internet_connection
        }
        this.setState({
          showSnackBar: true,
          responseStatus: {
            status: 'error',
            message: (errorMessage?.length > 0) ? errorMessage : strings.something_went_wrong
          }
        })
      } else {
        this.handleCloseModal()
        callFirebaseEvent(FA_CONSTANTS.FA_USERDELETED)
        this.setState({
          showSnackBar: true,
          responseStatus: {
            status: 'success',
            message: response?.data?.message
          }
        })

        sleep(1000).then(() => {
          this.handleArrowClick()
        })
      }
    })
  }

  handleArrowClick = () => {
    this.props.history.push('/users')
  }

  getToolbarTitle = () => {
    return this.props.location.state === null ? strings.create_user : strings.edit_user_title
  }

  render () {
    let deleteMessgaeTitle = ''
    const user = (this.props.location.state)
    if (user) {
      if (user.first_name) {
        deleteMessgaeTitle = strings.delete_user_message + ' ' + user.first_name + ' ' + user.last_name + ' ' + strings.delete_user_message1
      } else {
        deleteMessgaeTitle = strings.delete_user_message + ' ' + user.last_name + ' ' + strings.delete_user_message1
      }
    }

    return (
      <MobileContainer title={this.getToolbarTitle()} handleClick={() => this.handleArrowClick()}>
        <div>
          <AddEditUserContainer
            key='mobile'
            selectedUser={user}
            openFrom={user ? FormTypes.editUser : FormTypes.addUser}
            onDelete={() => this.doDelete()}
            onResponse={() => this.handleArrowClick()}
          />
          <Modal
            isOpen={this.state.isModalOpen}
            onClose={this.handleCloseModal}
          >
            <div style={{ padding: '20px' }}>
              <PopUp
                messageTitle={strings.delete_user_title}
                message={deleteMessgaeTitle}
                buttonTitle={strings.delete_user_now}
                onClose={this.handleCloseModal}
                openFor={strings.delete_user_title}
                onDelete={this.handleDeleteUser}
                onCancel={this.handleCloseModal}
              />
            </div>
          </Modal>
          {(this.state.showSnackBar) ? (<Snackbar type={this.state.responseStatus.status} message={this.state.responseStatus.message} />) : ''}
        </div>
      </MobileContainer>
    )
  }
}

export default AddEditUserSmall
