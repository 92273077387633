import md5 from 'md5'
import { logout, userDetailApi } from './api.js'
import * as FA_CONSTANTS from '../utils/AnalyticsConstants'
import * as MENU_ROUTES from '../components/LayoutComponents/MenuRoutes.js'
import { strings } from '../localizations/strings'
import {
  resetCurrentUser,
  getToken,
  getUserCompanyId,
  getUserRole,
  getUserInfo,
  getUserCompany
} from '../utils/Authentication.js'

import * as URL_CONSTANTS from './../utils/url_constant'
import * as CONSTANTS from '../utils/GenericConstants'

export const ListType = {
  Users: 0,
  Machines: 1
}

export const FilterTypes = {
  status: 0,
  availability: 1,
  category: 2,
  userType: 3,
  company: 4,
  branch: 5
}

export const Availability = {
  cout_draft: { name: strings.checkout_draft, id: 'cout_draft' },
  cin_draft: { name: strings.checkin_draft, id: 'cin_draft' },
  chkd_out: { name: strings.not_available, id: 'chkd_out' },
  chkd_in: { name: strings.available, id: 'chkd_in' },
  mnc: { name: strings.maintenance, id: 'mnc' }
}

export const Activation = {
  active: { name: strings.activated, id: 'ACTIVE' },
  inactive: { name: strings.deactivated, id: 'INACTIVE' },
  all: { name: strings.activated_n_deactivated, id: 'activate_n_inactivate' }
}

export const userRole = {
  support: { name: strings.supportuser_role, id: 'SUPPORT_USER' },
  admin: { name: strings.company_admin_role, id: 'COMPANY_ADMIN' },
  user: { name: strings.service_technician_role, id: 'TECHNICIAN' }
}

export const StateType = {
  failure: 1,
  loding: 2,
  success: 3,
  none: 4,
  location: 5
}

export const HeaderCompnentsTypes = {
  search: 'search',
  filter: 'filter',
  location: 'location',
  addUser: 'addUser',
  addMachine: 'addMachine'
}

export const GuidComponentTypes = {
  assetDetails: 'assets',
  assetsImage: 'asset_image',
  transactions: 'transactions',
  transactionImage: 'transaction_image',
  transactionPdfImage: 'transaction_pdf_image',
  protocolSummary: 'protocol_summary',
  protocolImages: 'protocol_images',
  pageNotFound: 'pageNotFound',
  pageNotAccessible: 'pageNotAccessible'
}

export const AssetStatusKeysAPI = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  checked_in: 'CHECKED_IN',
  checked_out: 'CHECKED_OUT',
  in_service: 'IN_SERVICE',
  draft: 'DRAFT',
  check_in_draft: 'CHECKIN_DRAFT'
}

export const AssetStatusColor = {
  active: 'rgba(82, 162, 58, 1)',
  inactive: 'rgba(19, 21, 22, 1)',
  checked_in: 'rgba(82, 162, 58, 1)',
  checked_out: 'rgba(250, 57, 0, 1)',
  in_service: 'rgba(111, 124, 128, 1)',
  draft: 'rgba(82, 162, 58, 1)',
  check_in_draft: 'rgba(250, 57, 0, 1)'
}

export function checkBigScreenWidth () {
  let bigScreen = false
  if (window.innerWidth >= 768) {
    bigScreen = true
  }
  return bigScreen
}

export const AssetCheckTypes = {
  text: 'TEXT',
  image: 'IMAGE',
  okNotOk: 'OK_NOT_OK',
  ok: 'OK',
  notOk: 'NOT_OK'
}

export const ScreenTypes = {
  mobile: 'mobile',
  desktop: 'desktop'
}

export const CallingFrom = {
  assetList: 'assetListPage',
  assetDetails: 'assetDetailPage'
}

export const FormTypes = {
  addMachine: 'ADD_MACHINE',
  editMachine: 'EDIT_MACHINE',
  addUser: 'ADD_USER',
  editUser: 'EDIT_USER'
}

export function getScreenType () {
  if (window.innerWidth > 959) {
    return ScreenTypes.desktop
  } else {
    return ScreenTypes.mobile
  }
}

export function makeAvailabilityString (array) {

}

export function callFirebaseEvent (eventName, eventParams, asset) {
  const user = getUserInfo()

  if (user != null) {
    if (user.role != null) {
      if (user.role === CONSTANTS.TECHNICIAN) {
        let hashId = ''
        if (user.id != null) {
          const createdDate = (user.created_at != null) ? user.created_at : ''
          const companyId = user.company.id || 0 // (user.company.id != null) ? user.company.id : 0
          const companyCreatedDate = (user.company.created_at != null) ? user.company.created_at : ''
          const userInfo = user.id + '-' + createdDate + '.' + companyId + '-' + companyCreatedDate
          hashId = md5(userInfo)
        }

        let branchName = ''
        if (user.branch != null) {
          const post = (user.branch.postcode != null) ? (user.branch.postcode + ' ') : ''
          const city = (user.branch.city != null) ? user.branch.city : ''
          branchName = post + city
        }

        let companyName = ''
        if (user.company != null) {
          if (user.company.name != null) {
            companyName = user.company.name
          }
        }

        const machineData = getMachineData(asset)
        const userDictionary = { hash_id: hashId, company: companyName, branch: branchName }
        const mergedData = Object.assign({}, userDictionary, eventParams, machineData)

        window.analytics_event(eventName, mergedData)
      }
    }
  } else {
    if (window.analytics_event) {
      window.analytics_event(eventName, eventParams)
    }
  }
}

export function getBranchFrom (branches, branchId) {
  if ((branches !== null) && (branchId !== null)) {
    for (let i = 0; i < branches.length; i++) {
      const branch = branches[i]
      if (branch !== null) {
        if (branch.id === branchId) {
          return branch
        }
      }
    }
  }
  return null
}

export function getBranchName (branch) {
  if (branch != null) {
    const postcode = (branch.postcode != null) ? (branch.postcode + ' ') : ''
    const city = (branch.city != null) ? branch.city : ''
    const cc = (branch.cost_center != null) ? (' (' + branch.cost_center + ')') : ''
    return postcode + city + cc
  }
  return ''
}

export function getMenuUserRoleString (user) {
  if (user && user.role) {
    if (user.role === CONSTANTS.ADMIN) {
      return strings.logged_in_as + ' ' + strings.support
    } else {
      let companyName = ''
      if (user.company_role && user.company_role === CONSTANTS.ADMIN) {
        companyName += strings.admin + ' ' + strings.of + ' '
      }
      companyName += (user.company && user.company.name) ? user.company.name : ''
      return companyName
    }
  }
  return ''
}

export function getUserRoleString (user) {
  if (user && user.role) {
    if (user.role === CONSTANTS.ADMIN) {
      return strings.supportuser_role
    } else {
      return (user.company_role && user.company_role === CONSTANTS.ADMIN) ? strings.company_admin_role : strings.service_technician_role
    }
  }
  return ''
}

export function getMachineData (asset) {
  const dataArray = {}
  if (asset) {
    if ((asset.name) && (asset.name !== '')) {
      dataArray.machine_name = asset.name
    }

    if ((asset.checklist_name) && (asset.checklist_name !== '')) {
      dataArray.machine_category = asset.checklist_name
    } else if ((asset.check_list) && (asset.check_list.name) && (asset.check_list.name !== '')) {
      dataArray.machine_category = asset.check_list.name
    }

    if ((asset.inventory_number) && (asset.inventory_number !== '')) {
      dataArray.machine_inventory = asset.inventory_number
    }

    const branchName = getBranchName(asset.branch)
    if (branchName !== '') {
      dataArray.machine_branch = branchName
    }
  }

  return dataArray
}

export function getCurrentTimeStamp () {
  const date = new Date()
  return formatDate(date)
}

export function formatDate (date) {
  const year = date.getFullYear()
  const month = ((date.getMonth() + 1) <= 9) ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1)
  const day = (date.getDate() <= 9) ? ('0' + date.getDate()) : date.getDate()
  const hour = (date.getUTCHours() <= 9) ? ('0' + date.getUTCHours()) : date.getUTCHours()
  const minute = (date.getUTCMinutes() <= 9) ? ('0' + date.getUTCMinutes()) : date.getUTCMinutes()
  const second = (date.getUTCSeconds() <= 9) ? ('0' + date.getUTCSeconds()) : date.getUTCSeconds()
  const utcTimeStamp = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  return utcTimeStamp
}

export function checkUserDetailsChanges () {
  if ((getUserRole() || CONSTANTS.ADMIN) === CONSTANTS.TECHNICIAN) {
    if (getToken() !== null) {
      const params = {
        token: getToken(),
        company_id: getUserCompanyId(),
        body: ''
      }
      userDetailApi(params, (error, response) => {
        if (!error) {
          const companyId = response.data.data.user.company.id
          if (parseInt(params.company_id) !== companyId) {
            if (params.company_id !== null) {
              logout(params, (error, response) => {
                if (error) {
                  callFirebaseEvent(FA_CONSTANTS.FA_E_LOGOUT, { description: error.response.data.message })
                } else {
                  callFirebaseEvent(FA_CONSTANTS.FA_LOGGED_OUT)
                }
                resetCurrentUser()
                window.location = '/login'
              })
            }
          }
        }
      })
    }
  }
}

export function getRoutesForUser () {
  const user = getUserInfo()
  if (user) {
    if (user.role === CONSTANTS.ADMIN) {
      return [MENU_ROUTES.ASSET_LIST, MENU_ROUTES.QRCODE, MENU_ROUTES.FEEDBACK, MENU_ROUTES.USERLIST, MENU_ROUTES.SELECTCOMPANY]
    } else if (user.company_role === CONSTANTS.ADMIN) {
      return [MENU_ROUTES.ASSET_LIST, MENU_ROUTES.QRCODE, MENU_ROUTES.FEEDBACK, MENU_ROUTES.USERLIST]
    }
  }

  return [MENU_ROUTES.ASSET_LIST, MENU_ROUTES.QRCODE, MENU_ROUTES.FEEDBACK]
}

export function getParameterQueryString (keyName, keyId) {
  if (keyId && (keyId !== 0)) {
    return keyName + keyId
  }
  return ''
}

export function getActivationQueryString (paramKey, activation) {
  if (activation && (activation.length !== 0)) {
    const values = activation.indexOf(Activation.all.id)
    if (values !== -1) {
      activation.splice(values, 1)
    }
    return paramKey + activation.toString()
  }
  return paramKey
}

export function getIDsArray (array) {
  const empty = []
  array.map((item) => {
    return empty.push(item.id)
  })
  return empty
}

export function makePercent (value) {
  return (value + '%')
}

export function removeObjectFromArray (arr, obj) {
  return arr.filter(_ => arr.id !== obj.id)
}

export function indexOfObject (arr, obj) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (item.id === obj.id) {
      return i
    }
  }
  return -1
}

export function checkUserCompanyHaveBranches (user) {
  if (user && ((user.role || CONSTANTS.TECHNICIAN) === CONSTANTS.ADMIN)) {
    const userCompany = getUserCompany()
    if (userCompany) {
      return ((userCompany.branch_count && userCompany.branch_count) > 1)
    }
  } else {
    return ((user.branch_count && user.branch_count) > 1)
  }
  return true
}

export function customBranchFilterArray (branches) {
  const customBranches = []
  if (branches) {
    branches.forEach(branch => {
      if (branch.id === '-1') {
        branch.id = -1
      }
      customBranches.push(branch)
    })
  }
  return customBranches
}

export function createDocumentTitle (current, makeTitle) {
  const currentT = current || 'Klickcheck'
  const pieces = currentT.split(':')
  return makeTitle.trim() + ': ' + pieces.pop().trim()
}

export function getAssetStatusLabelAndColor (asset) {
  if (asset?.status === AssetStatusKeysAPI.inactive) {
    return { label: strings.deactivated, color: AssetStatusColor.inactive }
  } else {
    switch (asset?.availability) {
      case AssetStatusKeysAPI.checked_in:
        return { label: strings.available, color: AssetStatusColor.checked_in }
      case AssetStatusKeysAPI.checked_out:
        return { label: strings.not_available, color: AssetStatusColor.checked_out }
      case AssetStatusKeysAPI.in_service:
        return { label: strings.maintenance, color: AssetStatusColor.in_service }
      case AssetStatusKeysAPI.draft:
        return { label: strings.checkout_draft, color: AssetStatusColor.draft }
      case AssetStatusKeysAPI.check_in_draft:
        return { label: strings.checkin_draft, color: AssetStatusColor.check_in_draft }
      default :
        return { label: strings.available, color: AssetStatusColor.checked_in }
    }
  }
}

export function isInActiveOrInService (asset) {
  if (asset?.status === AssetStatusKeysAPI.inactive ||
    asset?.availability === AssetStatusKeysAPI.in_service) {
    return true
  }

  return false
}

export function getOrderId (asset) {
  if (!(asset?.availability === AssetStatusKeysAPI.checked_in)) {
    if (asset.asset_transaction && asset.asset_transaction.invoice_no) {
      return asset.asset_transaction.invoice_no
    }
  }
  return null
}

export function showMobileLayoutHeader (route) {
  switch (route) {
    case URL_CONSTANTS.URLPATH_SELECTCOMPANY:
    case URL_CONSTANTS.URLPATH_ASSET_LIST:
    case URL_CONSTANTS.URLPATH_EDIT_ME:
    case URL_CONSTANTS.URLPATH_QRCODE:
    case URL_CONSTANTS.URLPATH_USERLIST:
      return true
    default:
      return false
  }
}

export function formatOrderDateTime (date) {
  date = date.replace(/ /g, 'T') + 'Z'
  const dateObj = new Date(date)
  const year = dateObj.getUTCFullYear()
  const monthData = dateObj.getUTCMonth()
  const dayData = dateObj.getUTCDate()
  const hourData = dateObj.getUTCHours()
  const minuteData = dateObj.getUTCMinutes()

  const month = ((monthData + 1) <= 9) ? ('0' + (monthData + 1)) : (monthData + 1)
  const day = (dayData <= 9) ? ('0' + dayData) : dayData
  const hour = (hourData <= 9) ? ('0' + hourData) : hourData
  const minute = (minuteData <= 9) ? ('0' + minuteData) : minuteData
  const formattedDateTime = day + '.' + month + '.' + year + ' ' + hour + ':' + minute
  return formattedDateTime
}

export function getReportedDateDefectCheck (checkValue) {
  if (checkValue?.value === AssetCheckTypes.notOk) {
    if (checkValue.reported_at) {
      return formatOrderDateTime(checkValue.reported_at)
    }
    return strings.new
  }
  return null
}

export function getRole (user) {
  if (user && user.role) {
    if (user.role === 'ADMIN') {
      return user.role
    } else {
      return (user.company_role && user.company_role === 'ADMIN') ? 'COMPANY_ADMIN' : 'TECHNICIAN'
    }
  }
  return ''
}

export function getRestrictedBranchString (count, totalCount, type) {
  if (count === totalCount) {
    return (type && (type === 'description')) ? '' : strings.no_restrictions
  } else if (count === 0) {
    return strings.no_access
  } else if (count === 1) {
    return strings.restricted_access
  } else {
    const returnString = (type && (type === 'description')) ? (strings.restricted_access + ' ' + strings.to + ' ') : ''
    return returnString + (count + ' ' + strings.locations)
  }
}

export function getBranchesForIds (branches, idsArray) {
  let newBranchesArray = []
  newBranchesArray = branches.filter((branch) => idsArray.includes(branch.id))

  return newBranchesArray
}

export function sleep (milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
