import { Component } from 'react'
import clsx from 'clsx'
import SuccessIcon from '../../utils/SnackbarSuccess'
import ErrorIcon from '../../utils/SnackbarError'
import InfoIcon from '@material-ui/icons/Info'
import { amber } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'

const variantIcon = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: '#5bb75a'
  },
  error: {
    backgroundColor: '#b00020'
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    size: '14px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    color: '#ffffff'

  }
}))

function SnackbarContentWrapper (props) {
  const classes = useStyles1()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      data-cy='notistack-snackbar'
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <font className={classes.message}>{message}</font>
      }
      action={[
        <IconButton key='close' aria-label='close' color='inherit' onClick={onClose}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

const useStyles = theme => ({
  margin: {
    margin: theme.spacing(1),
    minHeight: '48px'
  },
  marginS: {
    marginLeft: '10px',
    marginRight: '10px'
  }
})

class KCSnackbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: true
    }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render () {
    const { classes } = this.props
    return (
       <div>
         <Snackbar
           anchorOrigin={{
             vertical: 'bottom',
             horizontal: 'center'
           }}
           open={this.state.open}
           autoHideDuration={5000}
           onClose={this.handleClose}
           className={classes.marginS}
         >
           <SnackbarContentWrapper
             variant={this.props.type}
             message={this.props.message}
             className={classes.margin}
           />
         </Snackbar>
       </div>
    )
  }
}

export default withStyles(useStyles)(KCSnackbar)
