import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import { Typography, Container } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { callFirebaseEvent, FilterTypes, userRole, indexOfObject } from '../../helpers/GenericHelpers'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { strings } from '../../localizations/strings.js'
import { getSearchFilters, getUserInfo } from '../../utils/Authentication.js'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import * as CONSTANTS from '../../utils/GenericConstants'

const StyledMenu = withStyles({
  paper: {
    width: '300px',
    boxShadow: '0 2px 20px 0 rgba(0, 41, 56, 0.2)'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const useStyles = makeStyles(theme => ({
  buttonView: {
    display: 'flex',
    height: '48px',
    padding: '12px 20px 12px 20px'
  },
  locationIcon: {
    color: '#0d548c'
  },
  locationIconActive: {
    color: '#ffffff'
  },
  locationDiv: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '75%'
  },
  linkStyle: {
    color: '#0d548c'
  },
  locationLabel: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.33',
    letterSpacing: '0.36px',
    color: '#002938',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  arrowUpImage: {
    color: 'white'
  },
  arrowDownImage: {
    color: 'rgba(0, 0, 0, 0.87)'
  }
}))

export default function UserFilterView (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [openFilterSection, setOpenFilterSection] = useState(true)
  const [filterParams, setFilterParams] = useState({ userType: [] })

  useEffect(() => {
    const filter = getSearchFilters()
    setFilterParams(filter)
  }, [])

  const handleClick = event => {
    callFirebaseEvent(FA_CONSTANTS.FA_FILTERUSER_OPEN)
    setMenuOpen(prevOpen => !prevOpen)
    setAnchorEl(event.currentTarget)
    const filter = getSearchFilters()
    setFilterParams(filter)
  }

  const handleToggleUserType = value => () => {
    const currentIndex = indexOfObject(filterParams.userType, value)

    const newParams = { userType: [...filterParams.userType] }
    Object.assign(newParams, filterParams)

    if (currentIndex === -1) {
      newParams.userType.push(value)

      if (newParams.userType.length === 2) {
        callFirebaseEvent(FA_CONSTANTS.FA_FILTERUSER_SETRIGHTS, { search_term: strings.show_all })
      } else {
        callFirebaseEvent(FA_CONSTANTS.FA_FILTERUSER_SETRIGHTS, { search_term: newParams.userType[0].name })
      }
    } else {
      newParams.userType.splice(currentIndex, 1)
      callFirebaseEvent(FA_CONSTANTS.FA_FILTERUSER_REMOVERIGHTS)
    }
    setFilterParams(newParams)
    props.onFilter(filterParams)
  }

  const prevOpen = React.useRef(menuOpen)
  React.useEffect(() => {
    prevOpen.current = menuOpen
  }, [menuOpen])

  const handleClose = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const getSectionsForMenu = () => {
    return getUserRoleFilter()
  }

  const getHeader = () => {
    return (
      <ListItem data-cy='reset-filters' key='value' button style={{ backgroundColor: '#f5f8fa' }} onClick={handleResetAllFilters()}>
        <ListItemText id='labelId' primary={strings.clear_all_filters} className={classes.linkStyle} />
        <ListItemSecondaryAction />
      </ListItem>
    )
  }

  const handleResetAllFilters = value => () => {
    callFirebaseEvent(FA_CONSTANTS.FA_FILTERUSER_RESET)
    const newParams = { userType: [] }
    setFilterParams(newParams)
    props.onFilter(newParams)
  }

  const getUserRoleFilter = () => {
    let options = [userRole.support, userRole.admin, userRole.user]
    const user = getUserInfo()
    if (user) {
      if (user.role !== CONSTANTS.ADMIN) {
        options = [userRole.admin, userRole.user]
      }
    }

    const section =
      <div>
        <ListItem button onClick={() => toggleFilterSection(FilterTypes.availability)}>
          <PeopleAltIcon />
          <ListItemText data-cy='user-rights-button' primary={strings.user_roles} style={{ marginLeft: '10px' }} />
          {openFilterSection === FilterTypes.availability ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openFilterSection === FilterTypes.availability} timeout='auto' unmountOnExit>
          <List className={classes.root}>
            {options.map((arrItem, index) => {
              const labelId = `checkbox-list-label-${index}`

              return (
                <ListItem data-cy={arrItem.name} key={arrItem.id} role={undefined} dense button onClick={handleToggleUserType(arrItem)}>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={indexOfObject(filterParams.userType, arrItem) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={arrItem.name} />
                  <ListItemSecondaryAction />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      </div>
    return section
  }

  const toggleFilterSection = (section) => {
    (openFilterSection === section) ? setOpenFilterSection(null) : setOpenFilterSection(section)
  }

  return (
    <div>
      <Hidden only={['sm', 'xs']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={{ backgroundColor: menuOpen ? '#0d548c' : 'white' }}
        >
          <props.icon
            className={menuOpen ? classes.locationIconActive : classes.locationIcon}
          />
          <div className={classes.locationDiv}>
            <Hidden xsDown>
              <Typography data-cy='filter-roles' variant='subtitle1' className={classes.locationLabel} style={{ color: menuOpen ? 'white' : '#002938' }}>{strings.filter}</Typography>
            </Hidden>
          </div>
          {menuOpen ? <ArrowDropUpOutlinedIcon className={classes.arrowUpImage} /> : <ArrowDropDownOutlinedIcon className={classes.arrowDownImage} />}
        </Container>
      </Hidden>
      <Hidden only={['lg', 'xl', 'md']}>
        <Container
          aria-controls='customized-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.buttonView}
          style={menuOpen ? props.style.primary : props.style.sec}
        >
          <img className={classes.locationIcon} alt='' src={props.icon} />

          <div className={classes.locationDiv}>

            <Typography data-cy='filter-roles' variant='subtitle1' className={classes.locationLabel} style={{ color: menuOpen ? 'white' : 'white' }}>{strings.filter}</Typography>

          </div>
          {menuOpen ? <ArrowDropUpOutlinedIcon className={classes.arrowUpImage} style={{ color: 'white' }} /> : <ArrowDropDownOutlinedIcon className={classes.arrowDownImage} style={{ color: 'white' }} />}
        </Container>
      </Hidden>

      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
      >
        {getHeader()}
        {getSectionsForMenu()}

      </StyledMenu>

    </div>
  )
}
