import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      light: '#0d548c',
      main: '#0d548c',
      dark: '#0d548c',
      contrastText: '#0d548c'
    },
    secondary: {
      main: '#0d548c'
    },
    error: {
      main: '#fa3900'
    },
    background: {
      default: '#ffffff'
    },
    primaryColorWhite: {
      default: '#ffffff'
    }
  },
  typography: {
    fontFamily: 'Roboto'
  },

  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: '#ffffff'
      }
    },

    MuiButton: {
      root: {
        color: '#0d548c',
        backgroundColor: '#0d548c'
      },
      contained: {
        color: '#303030',
        backgroundColor: '#faa619',
        '&:hover': {
          backgroundColor: '#faa619'
        }
      },
      label: {
        color: 'white',
        textTransform: 'uppercase'
      }
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color'
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '16px'
      }
    },
    MuiGrid: {
      root: {
        fontSize: '14px'
      }
    },
    MuiTypography: {
      subtitle2: {
        fontWeight: '400'
      },
      subtitle1: {
        fontWeight: '400'
      },
      h6: {
        fontWeight: '400'
      }
    }
  }
})

export default theme
