import {
  CameraImage,
  AssetDetailsLabel,
  Image
} from '../../DetailsCommonComponents'
import AssetCheckLayout from './AssetCheckLayout'

export default function AssetDetailsCheckItemGrid (props) {
  const getTypeIconComponent = () => {
    return <CameraImage />
  }

  const getCheckValuesComponents = () => {
    const components = [<AssetDetailsLabel key={props.checkItem.name}>{props.checkItem.name}</AssetDetailsLabel>]

    const imageModel = props.checkItem.check_value &&
            props.checkItem.check_value.check_value_images &&
            props.checkItem.check_value.check_value_images[0]
    const imageUrl = imageModel ? imageModel.small_url : ''

    if (imageUrl !== '') {
      components.push(
        <Image
          key='image'
          imageUrl={imageUrl}
          imageModel={imageModel}
          onClick={(imageGUID) => props.previewImage(imageGUID)}
        />)
    }

    return components
  }

  return (
    <AssetCheckLayout
      assetTypeIcon={getTypeIconComponent()}
      checkValueComponents={getCheckValuesComponents()}
      valueGridSpacing={1}
    />
  )
}
