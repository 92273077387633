import { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import LocationDropDown from '../Home/LocationDropDown'
import AddItemView from '../../common/AddItem/AddItemView'
import FilterView from '../../common/Filter/FilterView'
import { ListType, makePercent, callFirebaseEvent, HeaderCompnentsTypes } from '../../helpers/GenericHelpers'
import * as FA_CONSTANTS from '../../utils/AnalyticsConstants.js'
import {
  getData,
  getUserCompanyId
} from '../../utils/Authentication.js'
import { getBranchList } from '../../helpers/api.js'

const useStyles = theme => ({
  root: {
    backgroundColor: '#0d548c'
  }

})

class AccessoryMobileView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      branches: [],
      branchesAvailable: false,
      showFilters: true,
      showAddItemButton: true
    }
  }

  componentDidMount () {
    const params = {
      token: getData('acccess_token'),
      company_id: getUserCompanyId()
    }
    if (getUserCompanyId() !== 0) {
      getBranchList(params, (error, response) => {
        if (error) {
          this.setState({ branches: [], branchesAvailable: false })
          const errorMessage = (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : ''
          callFirebaseEvent(FA_CONSTANTS.FA_E_GET_BRANCHES, { description: errorMessage })
        } else {
          const branches = response.data.data.branches || []
          this.setState({ branches, branchesAvailable: (branches && branches.length > 1) })
        }
      })
    }
  }

  getGridModelsArray () {
    const gridModelsArray = []
    let orderOfGrids = 1
    if (this.state.branchesAvailable) {
      gridModelsArray.push({ type: HeaderCompnentsTypes.location, order: orderOfGrids })
      orderOfGrids += 1
    }

    gridModelsArray.push({ type: HeaderCompnentsTypes.filter, order: orderOfGrids })
    orderOfGrids += 1

    if (this.props.listType === ListType.Users) {
      gridModelsArray.push({ type: HeaderCompnentsTypes.addUser, order: orderOfGrids })
    }
    if (this.props.listType === ListType.Machines) {
      gridModelsArray.push({ type: HeaderCompnentsTypes.addMachine, gridUnit: 1 })
    }

    return gridModelsArray
  }

  getGridModelComponent (gridModel, numberOfGrids) {
    const gridWidth = ((numberOfGrids % 2 !== 0) && ((gridModel.order === 1))) ? makePercent(100) : makePercent(50)

    if (gridModel.type === HeaderCompnentsTypes.location) {
      return (
        <Grid item style={{ width: gridWidth }}>
          <LocationDropDown
            onSelectBranch={() => this.props.onSelectBranch()}
            branches={this.state.branches}
          />
        </Grid>
      )
    } else if (gridModel.type === HeaderCompnentsTypes.filter) {
      return (
        <Grid item style={{ width: gridWidth }}>
          <FilterView listType={this.props.listType} onFilter={(filterParams) => this.props.onFilter(filterParams)} />
        </Grid>
      )
    } else if (gridModel.type === HeaderCompnentsTypes.addUser) {
      return (
        <Grid item style={{ width: gridWidth }}>
          <AddItemView
            history={this.props.parentProps}
            onSelectBranch={() => this.props.onSelectBranch()}
            action='addUser'
          />
        </Grid>
      )
    } else if (gridModel.type === HeaderCompnentsTypes.addMachine) {
      return (
        <Grid item style={{ width: gridWidth }}>
          <AddItemView
            history={this.props.parentProps}
            onSelectBranch={() => this.props.onSelectBranch()}
            action='addMachine'
          />
        </Grid>
      )
    }
  }

  render () {
    const { classes } = this.props

    const gridModels = this.getGridModelsArray()
    const numberOfGrids = gridModels.length

    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          {gridModels.map((gridModel) => this.getGridModelComponent(gridModel, numberOfGrids))}
        </Grid>
      </div>
    )
  }
}

AccessoryMobileView.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(useStyles)(AccessoryMobileView)
