import { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'

const useStyles = () => ({
  messageStyle: {
    margin: 'auto',
    paddingBottom: '25px',
    paddingTop: '8%'
  }
})
class ResponseFailure extends Component {
  render () {
    const {
      open,
      messageTitle,
      message,
      closePopUp,
      link,
      linkAction,
      classes
    } = this.props
    let check
    let showMessageTitle
    if (link === true) {
      check = (
        <DialogContent
          style={{ marginTop: '0px', marginBottom: '10px', paddingTop: '0px' }}
        >
          <DialogContentText
            id='alert-dialog-slide-description'
            alignItems='flex-first'
          >
            <Grid container justifyContent='flex-end'>
              <Grid item xs='auto'>
                <Link variant='body2' onClick={() => linkAction()}>
                  {this.props.linkTitle}
                </Link>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      )
    }
    if (messageTitle) {
      showMessageTitle = (
        <DialogTitle id='alert-dialog-slide-title' style={{ margin: 'auto' }}>
          {messageTitle}
        </DialogTitle>
      )
    }
    return (
      <div>
        <Dialog
          open={open}
          onClose={() => closePopUp()}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogActions>
            <CloseIcon
              data-cy='close-icon'
              onClick={() => closePopUp()}
              style={{ alignItems: 'flex-first' }}
            />
          </DialogActions>
          {showMessageTitle}
          <ErrorIcon
            fontSize='large'
            style={{ color: 'red', margin: 'auto' }}
          />
          <DialogContent>
            <DialogContentText
              id='alert-dialog-slide-description'
              className={classes.messageStyle}
            >
              {message}
            </DialogContentText>
          </DialogContent>
          {check}
        </Dialog>
      </div>
    )
  }
}
export default withStyles(useStyles)(ResponseFailure)
