import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AssetImage from './AssetImage'

const useStyles = makeStyles(theme => ({
  content: {
    wordBreak: 'break-word'
  },
  dividerPadding: {
    marginRight: '10%',
    marginLeft: '32%'
  },
  inventoryNoChecklistName: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: '400'
  },
  assetName: {
    fontWeight: '500'
  },
  clickableItem: {
    cursor: 'pointer'
  }

}))

export default function AssetListTileMobile (props) {
  const classes = useStyles()

  const handleOnClick = (event) => {
    props.updateBodyForAssetSelected(props.parentProps.assetGuid)
  }

  const handleOnImageClick = event => {
    const guid = event.image
    if (guid) {
      props.previewImage(guid)
    }
  }

  return (
    <>
      <Grid data-cy='each-machine' container spacing={3} className={classes.clickableItem}>
        <Grid item>
          <AssetImage
            onClick={(e) => {
              let guid
              try {
                guid = props.parentProps.asset.image.guid
              } catch {
                return
              }
              e.image = guid
              handleOnImageClick(e)
            }}
            width='80px'
            height='80px'
            imgSrc={props.parentProps.image}
            assetStatus={props.parentProps.assetStatus}
            assetAvailability={props.parentProps.assetAvailability}
          />
        </Grid>
        <Grid item xs className={classes.content}>
          <Typography
            data-cy='asset-inventory-no-checklist-name'
            variant='subtitle2'
            className={classes.inventoryNoChecklistName}
            onClick={() => handleOnClick()}
          >
            {props.parentProps.inventoryNo} {'.'} {props.parentProps.checklistName}
          </Typography>
          <Typography
            data-cy='asset-name'
            variant='subtitle1'
            className={classes.assetName}
            onClick={() => handleOnClick()}
          >
            {props.parentProps.assetName}
          </Typography>
          <Grid
            data-cy='asset-order'
            item xs
            align='left'
          >
            {props.orderDiv}
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.dividerPadding} />
    </>
  )
}
